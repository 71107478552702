import { useMemo } from "react";
import { useDaoStore } from "~/_store/dao/useDaoStore";
import { useBadgeCountStore } from "~/_store/badge/useBadgeCountStore";
import { mobileOpenDrawer } from "~/_api/mobile/toMobileAction";
import { appNavigation } from "~/_api/mobile/toMobileNavigation";
import { getThreadScreenParams } from "~/_api/mobile/appScreenParams";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { assertIsDefined } from "~/_utils/assert";

const useProfileSection = () => {
  const { dao } = useDaoStore();
  assertIsDefined(dao);
  const { badgeCount } = useBadgeCountStore();

  const handleMoveCommunityList = () => {
    mobileOpenDrawer(true);
  };

  const handleMoveNotification = () => {
    appNavigation(({ push }) => {
      push(
        getThreadScreenParams({
          path: generateUrl({
            type: "notification",
            subdomain: dao.subdomain,
          }),
        }),
      );
    });
  };

  const handleClickMore = () => {
    // 실제 모바일 환경에서만 필요한 함수
  };

  const unreadNotification = useMemo(() => {
    const _badgeCount = badgeCount[dao.id];
    if (badgeCount[dao.id]) {
      return _badgeCount.notificationUnread;
    }

    return 0;
  }, [dao.id, badgeCount]);

  return {
    handleMoveCommunityList,
    handleMoveNotification,
    handleClickMore,
    unreadNotification,
  };
};

export { useProfileSection };
