import { combineReducers, configureStore } from "@reduxjs/toolkit";
import type { ReactNode } from "react";
import { Provider, useDispatch } from "react-redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import storage from "redux-persist/lib/storage";

import { daoApi } from "~/api/Dao/daoApi";
import { inventoryApi } from "~/api/Inventory/inventoryApi";
import { marketApi } from "~/api/Market/marketApi";
import { memberApi } from "~/api/Member/memberApi";
import { inventoryReducer } from "~/store/inventory";
import { marketReducer } from "~/store/market";
import postEditorReducer from "~/store/postEditorReducer";
import recentEnterReducer from "~/store/recentEnterReducer";
import channelGroupReducer from "./channel/channelGroupSlice";
import channelNotiSocketReducer from "./channel/channelNotiSocketSlice";
import channelChatMessageReducer from "./chat/channelChatMessageSlice";
import channelChatReducer from "./chat/channelChatSlice";
import channelThreadMessageReducer from "./chat/channelThreadMessageSlice";
import dmMessageReducer from "./chat/dmMessageSlice";
import dmReducer from "./chat/dmSlice";
import marketNftMessageReducer from "./chat/marketNftMessageSlice";
import threadMarketReducer from "./chat/threadMarketSlice";
import threadPostReducer from "./chat/threadPostSlice";
import daoListReducer from "./daoListSlice";
import feedReducer from "./feedReducer";
import globalReducer from "./globalSlice";
import navigationReducer from "./navigationReducer";
import nftReducer from "./nftSlice";
import notificationReducer from "./notificationSlice";
import searchReducer from "./searchReducer";
import { spaceReducer } from "./space";
import threadLayoutControlReducer from "./threadLayoutControlSlice";
import livePostsReducer from "./video/livePostsSlice";
import liveStreamingReducer from "./video/liveStreamingSlice";
import vodReducer from "./video/vodSlice";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["createCommunity", "recentEnter"],
};
const rootReducer = combineReducers({
  // channel
  channelGroup: channelGroupReducer,
  channelNotiSocket: channelNotiSocketReducer,
  // chat
  channelChatMessage: channelChatMessageReducer,
  channelChat: channelChatReducer,
  dmMessage: dmMessageReducer,
  dm: dmReducer,
  marketNftMessage: marketNftMessageReducer,
  threadMarket: threadMarketReducer,
  channelThreadMessage: channelThreadMessageReducer,
  threadPost: threadPostReducer,
  // video
  livePosts: livePostsReducer,
  vod: vodReducer,
  liveStreaming: liveStreamingReducer,
  //
  daoList: daoListReducer,
  feedReducer,
  global: globalReducer,
  navigationReducer,
  nft: nftReducer,
  notification: notificationReducer,
  postEditorReducer,
  recentEnter: recentEnterReducer,
  searchReducer,
  threadLayoutControl: threadLayoutControlReducer,
  //
  inventory: inventoryReducer,
  market: marketReducer,
  space: spaceReducer,
  [inventoryApi.reducerPath]: inventoryApi.reducer,
  [marketApi.reducerPath]: marketApi.reducer,
  [daoApi.reducerPath]: daoApi.reducer,
  [memberApi.reducerPath]: memberApi.reducer,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(
      marketApi.middleware,
      inventoryApi.middleware,
      memberApi.middleware,
      daoApi.middleware,
      // logger,
    ),
});

const persist = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;

function StoreProvider(params: { children: ReactNode }) {
  return (
    <Provider store={store}>
      <PersistGate persistor={persist}>{params.children}</PersistGate>
    </Provider>
  );
}

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export default StoreProvider;
