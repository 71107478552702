import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { GreenButton } from "~/modules/Router/Auth/components/Buttons/GreenButton";

type Props = {
  onClick?: () => void;
};

export const DesktopJoinButton = ({ onClick }: Props) => {
  const { t } = useTranslation([ENameSpace.Common] as const);

  return (
    <GreenButton onClick={onClick} className={"w-[8.75rem]"}>
      <span className="typo-m1 pt-[0.1rem] text-ciety-dk">
        {t(`${ENameSpace.Common}:CommunityJoin.Join`)}
      </span>
    </GreenButton>
  );
};
