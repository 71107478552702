import { omit } from "@fxts/core";

import { env } from "~/env";
import { wrapHeaders } from "~/utils/fetch";
import { defaultErrorHandle } from "~/_libs/fetch/defaultErrorHandle";

type TFetchPostArgs = {
  url: string;
  body?: BodyInit;
  init?: Omit<RequestInit, "method">;
  includeCredential?: boolean;
  baseUrl?: string;
};

const fetchPost = <T>({
  url,
  body,
  init,
  includeCredential = true,
  baseUrl = env.API_BASE_URL,
}: TFetchPostArgs): Promise<T | never> => {
  const REQUIRED_INIT: RequestInit = {
    method: "POST",
    credentials: "include" as const,
    referrerPolicy: "no-referrer-when-downgrade",
    headers: {
      "Content-Type": "application/json",
    },
  };

  let requestInit: RequestInit = {
    ...wrapHeaders(REQUIRED_INIT),
    body,
  };

  if (!includeCredential) {
    requestInit = omit(["credentials" as const], requestInit);
  }

  if (init) {
    requestInit = {
      ...requestInit,
      ...init,
    };
  }

  const requestURL = `${baseUrl}${url}`;
  return fetch(requestURL, requestInit).then(async (res) => {
    if (res.ok) {
      return res.json();
    }

    await defaultErrorHandle(res);
  });
};

export { fetchPost };
