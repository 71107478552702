import { TAppRouteParams } from "~/_api/mobile/appRouteParams";
import { TAppScreenParams } from "~/_api/mobile/appScreenParams";

export const appNavigation = (
  navigation: ({
    navigate,
    push,
  }: {
    navigate: TAppScreenFunc;
    push: TAppScreenFunc;
    replace: TAppScreenFunc;
    reset: TAppRouteFunc;
  }) => void,
) => {
  navigation({
    navigate,
    push,
    replace,
    reset,
  });
};

const navigate: TAppScreenFunc = (params, dispatch = false) => {
  const actionName = dispatch
    ? ENavigationType.NavigationNavigateWithDispatch
    : ENavigationType.NavigationNavigate;

  postToMobile({
    [actionName]: params,
  });
};

const push: TAppScreenFunc = (params, dispatch) => {
  const actionName = dispatch
    ? ENavigationType.NavigationPushWithDispatch
    : ENavigationType.NavigationPush;

  postToMobile({
    [actionName]: params,
  });
};

const replace: TAppScreenFunc = (params, dispatch) => {
  const actionName = dispatch
    ? ENavigationType.NavigationReplaceWithDispatch
    : ENavigationType.NavigationReplace;

  postToMobile({
    [actionName]: params,
  });
};

const reset: TAppRouteFunc = (params) => {
  postToMobile({
    [ENavigationType.NavigationReset]: params,
  });
};

type TAppRouteFunc = (params: TAppRouteParams) => void;
type TAppScreenFunc = (params: TAppScreenParams, dispatch?: boolean) => void;

export const postToMobile = (message: unknown) => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify(message));
};

export const ENavigationType = {
  // 기본 네비게이션 동작
  NavigationNavigate: "navigationNavigate",
  NavigationPush: "navigationPush",
  NavigationReplace: "navigationReplace",
  // 기본 네비게이션 동작 + dispatch
  NavigationNavigateWithDispatch: "navigationNavigateWithDispatch",
  NavigationPushWithDispatch: "navigationPushWithDispatch",
  NavigationReplaceWithDispatch: "navigationReplaceWithDispatch",
  // 초기화
  NavigationReset: "navigationReset",
} as const;

export type ENavigationType =
  typeof ENavigationType[keyof typeof ENavigationType];
