import { atom } from "jotai";
import { TDaoHomeSection } from "~/_types/daoHome/TDaoHome";
import { daoHomeAtom } from "~/_model/daoHome/daoHomeAtom";
import { NEED_HYDRATION } from "~/_constants";
import { getHydrationErrorMessage } from "~/_utils/messageGetter/getHydrationErrorMessage";
import { EAtomName } from "~/_enums/EAtomName";
import { produce } from "immer";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import {
  ECoverImageType,
  THomeSns,
} from "~/_types/daoHome/TDaoHomeSectionProfile";
import { EDevice } from "~/_enums/EDevice";
import { indexBy } from "@fxts/core";
import { assert } from "~/_utils/assert";
import { TDaoHomeMetadataSection } from "~/_types/daoHome/TDaoHomeMetadata";
import {
  EChannelLayout,
  TDaoHomeSectionSns,
} from "~/_types/daoHome/TDaoHomeSectionSns";
import { TDaoHomeSectionLive } from "~/_types/daoHome/TDaoHomeSectionLive";
import { TDaoHomeSectionBannerItem } from "~/_types/daoHome/TDaoHomeSectionBanner";
import { TDaoHomeSectionHotPosts } from "~/_types/daoHome/TDaoHomeSectionHotPosts";
import { TDaoHomeSectionAllPosts } from "~/_types/daoHome/TDaoHomeSectionAllPosts";
import { EDaoHomeViewMode } from "~/_enums/daoHome/EDaoHomeViewMode";

const settingsHomeAtom = atom<{
  viewMode: EDaoHomeViewMode;
  selectedSection: TDaoHomeSection | null;
  dirtySectionIds: Set<string>;
  openAddSectionModal: boolean;
}>({
  viewMode: "Edit",
  selectedSection: null,
  dirtySectionIds: new Set<string>(),
  openAddSectionModal: false,
});

// 다오 홈 원본 데이터
const readOriginalDaoHomeAtom = atom((get) => {
  const daoHome = get(daoHomeAtom);

  if (daoHome === NEED_HYDRATION) {
    throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
  }

  return daoHome.data;
});

// 다오 홈 수정 데이터
const readWriteCopiedDaoHome = atom(
  (get) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    return daoHome.copiedData;
  },
  (get, set, payload: { sections: TDaoHomeSection[] }) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(daoHomeAtom, {
      ...daoHome,
      copiedData: {
        ...daoHome.copiedData,
        sections: payload.sections,
      },
    });
  },
);

// 인덱스처리 된 다오 홈 섹션
const readIndexedSectionByIdAtom = atom((get) => {
  const daoHome = get(daoHomeAtom);
  if (daoHome === NEED_HYDRATION) {
    throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
  }

  return indexBy(({ id }) => id, daoHome.copiedData.sections);
});

// 현재 모드 관리
const readWriteViewModeAtom = atom(
  (get) => {
    const { viewMode } = get(settingsHomeAtom);

    return viewMode;
  },
  (get, set, payload: { viewMode: EDaoHomeViewMode }) => {
    const settingsHome = get(settingsHomeAtom);

    set(settingsHomeAtom, {
      ...settingsHome,
      viewMode: payload.viewMode,
    });
  },
);

// 미리보기
const readIsPreviewModeAtom = atom((get) => {
  const { viewMode } = get(settingsHomeAtom);

  return viewMode === EDaoHomeViewMode.Preview;
});

// 선택된 섹션
const readWriteSelectedSectionAtom = atom(
  (get) => {
    const { selectedSection } = get(settingsHomeAtom);

    return selectedSection;
  },
  (get, set, payload: TDaoHomeSection | null) => {
    const prevState = get(settingsHomeAtom);

    set(settingsHomeAtom, {
      ...prevState,
      selectedSection: payload,
    });
  },
);

// 선택된 섹션 위 아래 이동
const writeSelectedSectionMoveAtom = atom(
  null,
  (get, set, payload: { move: "prev" | "next"; sectionId: string }) => {
    const daoHome = get(daoHomeAtom);
    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    const { selectedSection } = get(settingsHomeAtom);
    const {
      copiedData: { sections },
    } = daoHome;

    const selectedSectionIndex = sections.findIndex(
      (section) => section.id === selectedSection?.id,
    );
    // 선택된 섹션, 현재 섹션 목록 중 존재 하는지 확인 후 처리
    if (selectedSection && selectedSection.id === payload.sectionId) {
      set(
        daoHomeAtom,
        produce(daoHome, (draft) => {
          draft.copiedData.sections.splice(selectedSectionIndex, 1);
          draft.copiedData.sections.splice(
            payload.move === "prev"
              ? selectedSectionIndex - 1
              : selectedSectionIndex + 1,
            0,
            selectedSection,
          );
        }),
      );
    }
  },
);

// 섹션 변경 추적
const readWriteDirtySectionIdsAtom = atom(
  (get) => {
    const { dirtySectionIds } = get(settingsHomeAtom);

    return [...dirtySectionIds.values()];
  },
  (get, set, payload: { dirtySectionId: string }) => {
    const settingsHome = get(settingsHomeAtom);

    settingsHome.dirtySectionIds.add(payload.dirtySectionId);

    set(settingsHomeAtom, settingsHome);
  },
);

// 섹션 관리 패널 -> 섹션 제거
const writeRemoveSectionAtom = atom(
  null,
  (get, set, payload: { id: string }) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    const idx = daoHome.copiedData.sections.findIndex(
      (section) => section.id === payload.id,
    );

    // 삭제하는게 있을시 바로 전 섹션 선택
    if (idx !== -1) {
      const settingsHome = get(settingsHomeAtom);
      const prevSection = daoHome.copiedData.sections[idx - 1];
      set(settingsHomeAtom, {
        ...settingsHome,
        selectedSection: prevSection,
      });
    }

    set(daoHomeAtom, {
      ...daoHome,
      copiedData: {
        ...daoHome.copiedData,
        sections: daoHome.copiedData.sections.filter(
          (section) => section.id !== payload.id,
        ),
      },
    });
  },
);

// 섹션 관리 패널 -> 섹션 추가
const writeAddSectionAtom = atom(
  null,
  (get, set, payload: { section: TDaoHomeMetadataSection }) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    const settingsHome = get(settingsHomeAtom);

    const section = {
      ...payload.section,
      id: Date.now().toString(),
    };

    set(
      daoHomeAtom,
      produce(daoHome, (draft) => {
        const targetIndex = draft.copiedData.sections.findIndex(
          (section) => section.id == settingsHome.selectedSection?.id,
        );

        if (targetIndex != -1) {
          draft.copiedData.sections.splice(targetIndex + 1, 0, section);
        }
      }),
    );

    // 추가한 섹션 선택
    set(settingsHomeAtom, {
      ...settingsHome,
      selectedSection: section,
    });
  },
);

// 섹션 관리 패널 -> 섹션 이동
const writeSetSectionsAtom = atom(
  null,
  (get, set, payload: { sections: TDaoHomeSection[] }) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(daoHomeAtom, {
      ...daoHome,
      copiedData: {
        ...daoHome.copiedData,
        sections: payload.sections,
      },
    });
  },
);

// 커버 이미지 업로드
const writeSectionProfileCoverImageAtom = atom(
  null,
  (get, set, payload: { imageUrl: string }) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(
      daoHomeAtom,
      produce(daoHome, (draft) => {
        draft.copiedData.sections.forEach((section) => {
          if (section.type === EDaoHomeSection.Profile) {
            const coverImage = section.coverImage[draft.copiedData.device];
            coverImage.index = 0;
            coverImage.images = [
              { type: ECoverImageType.Image, url: payload.imageUrl },
            ];
          }
        });
      }),
    );
  },
);

// 커버 이미지 업로드 취소
const writeRemoveSectionProfileCoverImageAtom = atom(null, (get, set) => {
  const daoHome = get(daoHomeAtom);

  if (daoHome === NEED_HYDRATION) {
    throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
  }

  set(
    daoHomeAtom,
    produce(daoHome, (draft) => {
      draft.copiedData.sections.some((section) => {
        if (section.type === EDaoHomeSection.Profile) {
          const coverImage = section.coverImage[draft.copiedData.device];
          coverImage.index = -1;
          coverImage.images = [];
          return true;
        }
      });
    }),
  );
});

// SNS 수정 및 순서 번경
const writeSectionProfileSnsAtom = atom(
  null,
  (get, set, payload: { nextSns: THomeSns | THomeSns[] }) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(
      daoHomeAtom,
      produce(daoHome, (draft) => {
        draft.copiedData.sections.forEach((section) => {
          if (section.type === EDaoHomeSection.Profile) {
            const _nextSns = payload.nextSns;

            if (Array.isArray(_nextSns)) {
              section.sns = _nextSns;
            } else {
              section.sns = section.sns.map((prevSns) =>
                prevSns.id === _nextSns.id ? _nextSns : prevSns,
              );
            }
          }
        });
      }),
    );
  },
);

// SNS 제거
const writeSectionProfileRemoveSnsAtom = atom(
  null,
  (get, set, payload: { id: string }) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(
      daoHomeAtom,
      produce(daoHome, (draft) => {
        draft.copiedData.sections.some((section) => {
          if (section.type === EDaoHomeSection.Profile) {
            section.sns = section.sns.filter(({ id }) => id !== payload.id);
            return true;
          }
        });
      }),
    );
  },
);

// SNS 추가
const writeSectionProfileAddSnsAtom = atom(
  null,
  (get, set, payload: THomeSns) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(
      daoHomeAtom,
      produce(daoHome, (draft) => {
        draft.copiedData.sections.some((section) => {
          if (section.type === EDaoHomeSection.Profile) {
            section.sns.push(payload);
            return true;
          }
        });
      }),
    );
  },
);

// banner 수정
const writeSectionBannerAtom = atom(
  null,
  (
    get,
    set,
    payload: {
      id: string;
      banners: TDaoHomeSectionBannerItem[];
    },
  ) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(
      daoHomeAtom,
      produce(daoHome, (draft) => {
        draft.copiedData.sections.some((section) => {
          if (
            section.id === payload.id &&
            section.type === EDaoHomeSection.Banner
          ) {
            section.banners = payload.banners;
            return true;
          }
        });
      }),
    );
  },
);

// MerchStore title 수정
const writeSectionMerchStoreTitleAtom = atom(
  null,
  (
    get,
    set,
    payload: {
      id: string;
      title: string | null;
    },
  ) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(
      daoHomeAtom,
      produce(daoHome, (draft) => {
        draft.copiedData.sections.some((section) => {
          if (
            section.id === payload.id &&
            section.type === EDaoHomeSection.MerchStore
          ) {
            section.title = payload.title?.trim() || null;
            return true;
          }
        });
      }),
    );
  },
);

// Marketplace title 수정
const writeSectionMarketplaceTitleAtom = atom(
  null,
  (
    get,
    set,
    payload: {
      id: string;
      title: string | null;
    },
  ) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(
      daoHomeAtom,
      produce(daoHome, (draft) => {
        draft.copiedData.sections.some((section) => {
          if (
            section.id === payload.id &&
            section.type === EDaoHomeSection.Marketplace
          ) {
            section.title = payload.title?.trim() || null;
            return true;
          }
        });
      }),
    );
  },
);

// 포스트 섹션 title 수정
const writeSectionPostTitleAtom = atom(
  null,
  (
    get,
    set,
    payload: {
      id: string;
      title: string | null;
    },
  ) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(
      daoHomeAtom,
      produce(daoHome, (draft) => {
        draft.copiedData.sections.some((section) => {
          if (
            section.id === payload.id &&
            (section.type === EDaoHomeSection.Sns ||
              section.type === EDaoHomeSection.LiveStreaming ||
              section.type === EDaoHomeSection.HotPosts ||
              section.type === EDaoHomeSection.AllPosts)
          ) {
            section.title = payload.title?.trim() || null;
            return true;
          }
        });
      }),
    );
  },
);

// 포스트 섹션(Sns) 레이아웃 수정
const writeUpdateSnsLayoutAtom = atom(
  null,
  (
    get,
    set,
    payload: {
      id: string;
      layout: EChannelLayout;
    },
  ) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(
      daoHomeAtom,
      produce(daoHome, (draft) => {
        draft.copiedData.sections.some((section) => {
          if (
            section.id === payload.id &&
            section.type === EDaoHomeSection.Sns
          ) {
            section.layout = payload.layout;

            return true;
          }
        });
      }),
    );
  },
);

// 포스트 섹션 -> 채널 선택할 때
const writeChangeSecionTypeAtom = atom(
  null,
  (
    get,
    set,
    payload: {
      id: string;
      section:
        | TDaoHomeSectionSns
        | TDaoHomeSectionLive
        | TDaoHomeSectionHotPosts
        | TDaoHomeSectionAllPosts;
    },
  ) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(daoHomeAtom, {
      data: daoHome.data,
      copiedData: {
        ...daoHome.copiedData,
        sections: daoHome.copiedData.sections.map((section) => {
          if (section.id === payload.id) {
            return payload.section;
          }
          return section;
        }),
      },
    });
  },
);

// 디바이스
const readWriteDeviceAtom = atom(
  (get) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    return daoHome.copiedData.device;
  },
  (get, set, payload: { device: EDevice }) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(daoHomeAtom, {
      ...daoHome,
      copiedData: {
        ...daoHome.copiedData,
        device: payload.device,
      },
    });
  },
);

// 리비전 (원본 데이터의 리비전)
const readRevisionAtom = atom((get) => {
  const daoHome = get(daoHomeAtom);

  if (daoHome === NEED_HYDRATION) {
    throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
  }

  assert(daoHome.data?.revision != null);
  return daoHome.data.revision;
});

// 섹션 추가 모달
const readWriteOpenAddSectionModal = atom(
  (get) => {
    const settingsHome = get(settingsHomeAtom);

    return settingsHome.openAddSectionModal;
  },
  (get, set, payload: { open: boolean }) => {
    const settingsHome = get(settingsHomeAtom);

    set(settingsHomeAtom, {
      ...settingsHome,
      openAddSectionModal: payload.open,
    });
  },
);

export {
  settingsHomeAtom,

  // 다오 홈 데이터
  readOriginalDaoHomeAtom,
  readWriteCopiedDaoHome,

  // 미리보기 모드
  readIsPreviewModeAtom,
  readWriteViewModeAtom,

  // 섹션 관리
  readIndexedSectionByIdAtom,
  writeRemoveSectionAtom,
  writeAddSectionAtom,
  writeSetSectionsAtom,
  readWriteSelectedSectionAtom,
  readWriteDirtySectionIdsAtom,
  writeSelectedSectionMoveAtom,
  readWriteOpenAddSectionModal,

  // 프로필 설정 패널
  writeSectionProfileCoverImageAtom,
  writeRemoveSectionProfileCoverImageAtom,
  writeSectionProfileSnsAtom,
  writeSectionProfileRemoveSnsAtom,
  writeSectionProfileAddSnsAtom,

  // 디바이스 변경
  readWriteDeviceAtom,

  // 배너
  writeSectionBannerAtom,

  // MerchStore
  writeSectionMerchStoreTitleAtom,

  // Marketplace
  writeSectionMarketplaceTitleAtom,

  // 포스트 섹션
  writeSectionPostTitleAtom,
  writeChangeSecionTypeAtom,
  writeUpdateSnsLayoutAtom,

  // 리비전
  readRevisionAtom,
};
