import { ImgHTMLAttributes, SyntheticEvent, useState } from "react";
import { IntersectionOptions } from "react-intersection-observer";

import { toResizeUrl, TToResizeUrlProps } from "~/utils/converter";
import { LazyImage } from "./LazyImage";
import { SmoothLoadableImage } from "./SmoothLoadableImage";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  toResizeUrlProps: TToResizeUrlProps;
  isLazy?: boolean;
  intersectionOptions?: IntersectionOptions;
  loadSmooth?: boolean;
  fallback?: JSX.Element | null;
}

/**
 * @param toResizeUrlProps width: 요청할 리사이즈 이미지의 폭을 px 단위로 입력합니다.
 * @param isLazy lazy load 여부를 설정합니다.
 * @param intersectionOptions lazy load 시 Intersection Observer API에 대한 options을 설정합니다.
 * @param loadSmooth load 시 fade-in transition을 적용합니다.
 * @param fallback load 오류 시 fallback을 설정합니다.
 */
export function ResizedImage({
  toResizeUrlProps: { url, ...restToResizeUrlProps },
  isLazy = false,
  intersectionOptions,
  loadSmooth = false,
  fallback = null,
  //
  onError,
  onLoad,
  alt,
  ...restProps
}: Props) {
  const [isResizeError, setIsResizeError] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  const getSrc = () =>
    isResizeError
      ? url
      : toResizeUrl({
          url,
          ...restToResizeUrlProps,
        });

  const handleError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    if (isResizeError) {
      onError?.(e);
      setIsImageError(true);
    } else {
      setIsResizeError(true);
    }
  };

  if (isImageError) return fallback;

  let content;
  if (isLazy) {
    content = (
      <LazyImage
        {...restProps}
        src={getSrc()}
        onError={handleError}
        onLoad={onLoad}
        alt={alt}
        intersectionOptions={intersectionOptions}
        loadSmooth={loadSmooth}
      />
    );
  } else if (loadSmooth) {
    content = (
      <SmoothLoadableImage
        {...restProps}
        onLoad={onLoad}
        src={getSrc()}
        onError={handleError}
        alt={alt}
      />
    );
  } else {
    content = (
      <img
        {...restProps}
        src={getSrc()}
        onError={handleError}
        onLoad={onLoad}
        alt={alt ?? ""}
      />
    );
  }

  return <>{content}</>;
}

/**
 * @reference
 * - https://github.com/marpple/ciety-fe/commit/e5e197f9dc248aca71bfbb11a1e2036bbda2d0c4#diff-e3b4b0166b30f612eb6a808ec8e3907c9b2ac3d09762ad0b6482042cecc5b54a
 * - https://github.com/marpple/ciety-fe/commit/302ff165473fa44717975438e84e4656de58abd4#diff-e3b4b0166b30f612eb6a808ec8e3907c9b2ac3d09762ad0b6482042cecc5b54a
 */
