import React, { useEffect } from "react";
import { detectMobile } from "~/_utils/mobile/detectMobile";
import { useUpdateGlobal } from "~/modules/Common/hooks";
import { MobileRoutes } from "./MobileRoutes";
import { useErrorBoundary } from "react-error-boundary";
import {
  ERROR_BAD_REQUEST,
  ERROR_FORBIDDEN,
  ERROR_INTERNAL_SERVER_ERROR,
  ERROR_NOT_FOUND,
  ERROR_OOPS,
  ERROR_UNAUTHORIZED,
  ERROR_UNKNOWN,
} from "~/_constants/errors";

const DesktopRoutes = React.lazy(() => import("~/router/DesktopRoutes"));

const IS_MOBILE = detectMobile();

const includeErrors = [
  ERROR_BAD_REQUEST,
  ERROR_FORBIDDEN,
  ERROR_INTERNAL_SERVER_ERROR,
  ERROR_NOT_FOUND,
  ERROR_OOPS,
  ERROR_UNAUTHORIZED,
  ERROR_UNKNOWN,
  "Failed to fetch",
];

function Router() {
  useUpdateGlobal();

  // 비동기 에러 핸들링
  const { showBoundary } = useErrorBoundary();
  useEffect(() => {
    const listener = (event: PromiseRejectionEvent) => {
      if (event.reason instanceof Error) {
        const includedError = includeErrors.some((error) =>
          event.reason.message.startsWith(error),
        );
        if (includedError) {
          showBoundary(event.reason);
        }
      }
    };

    window.addEventListener("unhandledrejection", listener);

    return () => {
      window.removeEventListener("unhandledrejection", listener);
    };
  }, [showBoundary]);

  return (
    <React.Suspense fallback={<></>}>
      {IS_MOBILE ? <MobileRoutes /> : <DesktopRoutes />}
    </React.Suspense>
  );
}

export default Router;
