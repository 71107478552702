import { wrapHeaders } from "~/utils/fetch";
import { defaultErrorHandle } from "~/_libs/fetch/defaultErrorHandle";

export const fetchAdapter = async (
  url: string,
  init: RequestInit,
): Promise<Response | never> => {
  return fetch(url, wrapHeaders(init)).then(async (res) => {
    if (res.ok) {
      return res;
    }

    return defaultErrorHandle(res);
  });
};
