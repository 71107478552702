import { useContext } from "react";

import { AuthStateContext } from "~/modules/Router/Auth/contexts/AuthStateContext";

const useAuthContext = () => {
  const authContext = useContext(AuthStateContext);

  if (!authContext)
    throw new Error("Does not exist AuthStateContexts.Provider");
  return authContext;
};

export default useAuthContext;
