const Level = {
  Lv10: "10",
  Lv20: "20",
  Lv30: "30",
  Lv40: "40",
  Lv100: "100",
  LvNegative99999: "-99999",
} as const;

type Level = typeof Level[keyof typeof Level];

const RAW_Z_LEVELS = {
  LAYOUT: {
    SIDE_BAR: Level.Lv20,
    CONTENTS: Level.Lv10,
  },
  GLOBAL: {
    HEADLESS_UI_PORTAL_40: Level.Lv40 /** global.css */,
    MOBILE_HEADER_40: Level.Lv40,
    PC_HEADER_40: Level.Lv40,
    PC_EMOJI_PICKER_20: Level.Lv20,
    SELECT_OPTIONS_20: Level.Lv20,
    MOBILE_EMOJI_PICKER_10: Level.Lv10,
    UTIL_MENU_10: Level.Lv10,
    FIXED_VIDEO_10: Level.Lv10,
    SLIDER_ARROW_10: Level.Lv10,
    MOBILE_MODAL_WITH_DRAG_GESTURE: Level.Lv100,
    HIDDEN: Level.LvNegative99999,
    FOOTER: Level.Lv40,
    PIP_VIDEO: Level.Lv30,
  },
  NOTIFICATION: {
    BADGE: Level.Lv10,
  },
  FEED: {
    MENTION_PANEL_40: Level.Lv40,
    PC_EMOJI_PICKER_PANEL_20: Level.Lv20,
    MEMBER_LIST_10: Level.Lv10,
    MEMBER_LIST_BUTTON_20: Level.Lv20,
    COMMENT_EDITOR_CONTENT_EDITABLE_10: Level.Lv10,
    MOBILE_ADD_POST_BUTTON_10: Level.Lv10,
    PC_UPDATE_FEED_BUTTON_10: Level.Lv10,
    MOBILE_UPDATE_FEED_BUTTON_20: Level.Lv20,
    UPDATE_NOTIFICATION_BUTTON_10: Level.Lv10,
    HEART_10: Level.Lv10,
    RELAY_10: Level.Lv10,
    LEFTOVER_IMG_10: Level.Lv10,
    VIDEO_THUMBNAIL_BUTTON_10: Level.Lv10,
  },
  POST: {
    EDITED_AT_TOOLTIP_10: Level.Lv10,
  },
  PORTAL: {
    IMAGE_DETAIL_CLOSE_BUTTON_10: Level.Lv10,
    SETTING_CLOSE_BUTTON_10: Level.Lv10,
  },
  MOBILE_DETAIL: {
    CLOSE_BUTTON_10: Level.Lv10,
  },
  SEARCH: {
    FILTER_PICKER_SIDE_BUTTON_30: Level.Lv30,
    FILTER_PICKER_20: Level.Lv20,
    MOBILE_GO_TO_TOP_BUTTON_10: Level.Lv10,
    FILTER_PICKER_OVERLAY_10: Level.Lv10,
  },
  DM: {
    CONFIRM_MODAL: Level.Lv30,
    MODAL: Level.Lv20,
    MODAL_DRAWER: Level.Lv10,
    ADD_MODAL: Level.Lv20,
    MODAL_HEADER: Level.Lv10,
    MOBILE_CONFIRM_EXIST: Level.Lv10,
    MOBILE_CONFIRM_EXIST_BACKGROUND: Level.Lv100,
  },
  EDITOR: {
    ROOT: Level.Lv30,
    MENTION_TOOLTIP: Level.Lv30,
    LINK_TOOLTIP: Level.Lv30,
    AUTO_EMBED_TOOLTIP: Level.Lv30,
    TEXT_DRAG_BUBBLE: Level.Lv30,
  },
  HOME_SETTING: {
    PANEL: Level.Lv20,
    PANEL_TOOLTIP: Level.Lv10,
    PANEL_SELECT_CHANNEL: Level.Lv20,
    PANEL_HEADER: Level.Lv30,
    //
    MANAGE_PANER_HEADER: Level.Lv30,
    //
    SELECTION: Level.Lv10,
    //
    HEADER: Level.Lv30,
    HEADER_TOOLTIP: Level.Lv10,
    HEADER_BUTTON: Level.Lv20,
    //
    BANNER_OVERLAY: Level.Lv10,
    BANNER_REMOVE_BUTTON: Level.Lv20,
  },
  SIDE_BAR: {
    COMMUNITY_LIST: Level.Lv40,
    COMMUNITY_INFO: Level.Lv30,
    COMMUNITY_TOOLTIP: Level.Lv100,
    CHANNEL_LIST: Level.Lv10,
    PROFILE_AREA: Level.Lv20,
  },
  HOME: {
    PROFILE_HEADER: Level.Lv100,
  },
  FIND: {
    CIRCULAR_GO_BACK_BUTTON: Level.Lv30,
  },
  EXPLORE: {
    SECTION: Level.Lv30,
  },
} as const;

type RAW_TYPE = typeof RAW_Z_LEVELS;

const Z_LEVELS: {
  [LAYER in keyof RAW_TYPE]: {
    [NAME in keyof RAW_TYPE[LAYER]]: Level;
  };
} = RAW_Z_LEVELS;

export { Z_LEVELS, Level };
