import { useAtom } from "jotai";

import { NEED_HYDRATION } from "~/_constants";
import { EAtomName } from "~/_enums/EAtomName";
import { daoSubdomainAtom } from "~/_model/dao/daoSubdomainAtom";
import { getHydrationErrorMessage } from "~/_utils/messageGetter/getHydrationErrorMessage";

function useDaoSubdomainStore() {
  const [daoSubdomain, setDaoSubdomain] = useAtom(daoSubdomainAtom);

  if (daoSubdomain === NEED_HYDRATION) {
    throw new Error(getHydrationErrorMessage(EAtomName.DaoSubdomain));
  }

  return {
    daoSubdomain,
    subdomain: daoSubdomain,
    setDaoSubdomain,
  };
}
export { useDaoSubdomainStore };
