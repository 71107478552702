import { useEffect, useMemo, useState } from "react";

import { getUserAccounts, TGetUserAccountsOutputDtoData } from "~/api";
import { useAuthContext } from "~/modules/Router/Auth/hooks";
import { useErrorBoundary } from "react-error-boundary";

export const useUserAccounts = () => {
  const { userId } = useAuthContext();
  const { showBoundary } = useErrorBoundary();
  const [loading, setLoading] = useState<boolean>(false);
  const [userAccounts, setUserAccounts] =
    useState<TGetUserAccountsOutputDtoData | null>(null);

  const needWalletConnect = useMemo(() => {
    return !loading && userAccounts?.evmCompatibleAccounts.length === 0;
  }, [userAccounts, loading]);
  const loadUserAccounts = () => {
    setLoading(true);
    getUserAccounts({ userId, emailAccount: "true" })
      .then((result) => {
        setUserAccounts(result.data);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch(showBoundary);
  };

  useEffect(() => {
    if (!userId) return;
    loadUserAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return {
    loading,
    userAccounts,
    loadUserAccounts,
    userId,
    needWalletConnect,
  };
};
