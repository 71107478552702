import { EDevice } from "~/_enums/EDevice";
import { fetchPostJson } from "~/_libs/fetch/fetchPostJson";
import { TDaoHome } from "~/_types/daoHome/TDaoHome";
import { ECode } from "../../_enums/ECode";
import { TApiConfig } from "../../_libs/fetch/fetchConfig";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

type TPostCommonDaoInputDto = { daoSubdomain: string; device: EDevice };

type TPostCommonDaoOutputDto = {
  code: typeof ECode.Success;
  data: TDaoHome;
};

/**
 * 커뮤니티 홈 조회
 */
async function postCommonDaoHome(
  body: TPostCommonDaoInputDto,
  config: TApiConfig,
) {
  return fetchPostJson<TPostCommonDaoOutputDto>(
    "/v5draft/common-dao-home",
    { body },
    config,
  ).then((result) => {
    switch (result.code) {
      case "000":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postCommonDaoHome",
            payload: body,
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
}

export { postCommonDaoHome };
