import { ReactNode } from "react";

type TProps = {
  title: string;
  renderIcon: ReactNode;
  renderSubText: ReactNode;
  children: ReactNode;
};

function ErrorLayout({ title, renderIcon, renderSubText, children }: TProps) {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="flex-center h-fit w-fit flex-col">
        {renderIcon}
        <h1 className="typo-headline-m mb-2.5 text-ciety-wh">{title}</h1>
        <p className="typo-r2 text-center text-ciety-gy">{renderSubText}</p>
        <div className="mt-10 flex items-center space-x-4">{children}</div>
      </div>
    </div>
  );
}

export { ErrorLayout };
