import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { EDevice } from "~/_enums/EDevice";

export const ECoverImageType = {
  Image: "Image",
} as const;

export type ECoverImageType =
  typeof ECoverImageType[keyof typeof ECoverImageType];

export const ESnsType = {
  Instagram: "Instagram",
  Youtube: "Youtube",
  Facebook: "Facebook",
  NaverBlog: "NaverBlog",
  NaverChzzk: "NaverChzzk",
  X: "X",
  TikTok: "Tiktok",
  Opensea: "Opensea",
  Afreecatv: "Afreecatv",
  Marpple: "Marpple",
  Github: "Github",
  Other: "Other",
} as const;
export type ESnsType = typeof ESnsType[keyof typeof ESnsType];

export type TImage = { type: typeof ECoverImageType.Image; url: string };
export type TCoverImage = {
  [key in keyof typeof EDevice]: {
    index: number;
    images: TImage[];
  };
};

export type THomeSns = {
  id: string;
  type: ESnsType;
  url: string;
};

export type TDaoHomeSectionProfile = {
  type: typeof EDaoHomeSection.Profile;
  id: string;
  title: string | null;
  description: string;
  coverImage: TCoverImage;
  sns: Array<THomeSns>;
  displayName: string;
  memberCount: number;
};
