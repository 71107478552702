import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { Icon } from "~/modules/Common/Icons";

export function GoogleAccountPolicy() {
  const { t } = useTranslation([ENameSpace.GoogleConnect]);

  return (
    <span className="typo-r2 flex items-center whitespace-pre text-ciety-gy">
      <Icon iconType="Alert Sign Circle" className="mr-[0.38rem] h-4 w-4" />
      {t(
        `${ENameSpace.GoogleConnect}:YoutubeAccountListToCreate.PolicyConnect1`,
      )}
      <a
        href="https://developers.google.com/terms/api-services-user-data-policy"
        target="_blank"
        className="underline"
      >
        {t(
          `${ENameSpace.GoogleConnect}:YoutubeAccountListToCreate.PolicyConnect2`,
        )}
      </a>
      {t(
        `${ENameSpace.GoogleConnect}:YoutubeAccountListToCreate.PolicyConnect3`,
      )}
    </span>
  );
}
