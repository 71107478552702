import { noop } from "@fxts/core";
import {
  ComponentType,
  createContext,
  useContext,
  useMemo,
  useState,
} from "react";
import { OverlayController } from "~/modules/Common/Overlay";

import { getUseContextError } from "~/utils/converter";

const defaultProps = {
  onClose: noop,
  onSubmit: noop,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TProps = any;
type TComponent = ComponentType<TProps>;
type TOverlay = {
  id: string;
  Component: TComponent;
  props: TProps;
};

type TOverlayValueContext = {
  openedOverlays: TOverlay[];
};
type TOverlayActionsContext = {
  open: (id: string, Component: TComponent, props: TProps) => void;
  close: (Component: TComponent) => void;
};
const OverlayValueContext = createContext<TOverlayValueContext | undefined>(
  undefined,
);
const OverlayActionsContext = createContext<TOverlayActionsContext | undefined>(
  undefined,
);

/** Only available within ParamsContext.Provider */
export function OverlayProvider({ children }: TStrictPropsWithChildren) {
  const [openedOverlays, setOpenedOverlays] = useState<TOverlay[]>([]);

  const open = (
    id: string,
    Component: TComponent,
    props: TProps = defaultProps,
  ) => setOpenedOverlays((overlays) => [...overlays, { id, Component, props }]);

  const close = (Component: TComponent) =>
    setOpenedOverlays((overlays) =>
      overlays.filter((overlay) => overlay.Component !== Component),
    );

  const actions = useMemo(() => ({ open, close }), []);

  return (
    <OverlayActionsContext.Provider value={actions}>
      <OverlayValueContext.Provider value={{ openedOverlays }}>
        {children}
        <OverlayController />
      </OverlayValueContext.Provider>
    </OverlayActionsContext.Provider>
  );
}

export const useOverlayValue = () => {
  const value = useContext(OverlayValueContext);
  if (value === undefined) {
    throw new Error(getUseContextError("OverlayValue"));
  }
  return value;
};
export const useOverlayActions = () => {
  const actions = useContext(OverlayActionsContext);
  if (actions === undefined) {
    throw new Error(getUseContextError("OverlayActions"));
  }
  return actions;
};
