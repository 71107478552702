import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { EmptySectionItem } from "~/_app/[dao]/home/components/common/EmptySectionItem";
import { EmptySectionTitle } from "~/_app/[dao]/home/components/common/EmptySectionTitle";
import { EmptySectionButton } from "~/_app/[dao]/home/components/common/EmptySectionButton";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { useNavigate } from "react-router-dom";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { useDaoSubdomainStore } from "~/_store/dao/useDaoSubdomainStore";
import { assertIsDefined } from "~/_utils/assert";

type TProps = {
  sectionId: string;
};

function EmptySns({ sectionId }: TProps) {
  const { daoSubdomain } = useDaoSubdomainStore();
  assertIsDefined(daoSubdomain);
  const { t } = useTranslation([ENameSpace.Home]);
  const { getSection } = useDaoHomeStore();
  const { channel } = getSection(EDaoHomeSection.Sns, sectionId);

  const navigate = useNavigate();
  const handleCreatePost = () => {
    navigate(
      generateUrl({
        type: "feed",
        channelType: channel.type,
        channelId: channel.id,
        subdomain: daoSubdomain,
      }),
    );
  };

  return (
    <div className="px-5 pt-4">
      <EmptySectionItem isMobile>
        <EmptySectionTitle
          title={t(`${ENameSpace.Home}:Root.Sections.Sns.Empty.Title`)}
          className="typo-m1"
        />
        <EmptySectionButton
          onClick={handleCreatePost}
          className="typo-r2 mt-[1.5rem]"
        >
          {t(`${ENameSpace.Home}:Root.Sections.Sns.Empty.Button`)}
        </EmptySectionButton>
      </EmptySectionItem>
    </div>
  );
}

export { EmptySns };
