import { ImgHTMLAttributes } from "react";

import { TToResizeUrlProps } from "~/utils/converter";
import { TFlexedSizeProfileImageVariant } from "./FixedSizeProfileImage";
import { ResizedImage } from "./OptimalImage";

const DEFAULT_WIDTH = 75;
const QUALITY_MULTIPLIER = 3;

export const MEMBER_CONTAINER_ROOT_ELEMENT_ID =
  "member-container-ciety-scroll-bar";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  toResizeUrlProps: TToResizeUrlProps;
  variant?: TFlexedSizeProfileImageVariant;
  fallback?: JSX.Element | null;
}

/** @param toResizeUrlProps width: 이미지의 폭을 px 단위로 입력합니다. */
export default function ResizedProfileImage({
  toResizeUrlProps: { url, width, timeLastUpdated },
  variant,
  ...restProps
}: Props) {
  const widthForHighQuality = Math.floor(
    (width || DEFAULT_WIDTH) * QUALITY_MULTIPLIER,
  );
  const intersectionOptionsForMember = {
    root: document.getElementById(MEMBER_CONTAINER_ROOT_ELEMENT_ID),
  };
  const isUsedInMember = variant === "member";

  return (
    <ResizedImage
      {...restProps}
      isLazy={isUsedInMember}
      intersectionOptions={
        isUsedInMember ? intersectionOptionsForMember : undefined
      }
      toResizeUrlProps={{
        url,
        width: widthForHighQuality,
        timeLastUpdated,
      }}
    />
  );
}
