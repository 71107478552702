import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { EmptySectionItem } from "~/_app/[dao]/home/components/common/EmptySectionItem";
import { useDaoStore } from "~/_store/dao/useDaoStore";
import { EmptySectionButton } from "~/_app/[dao]/home/components/common/EmptySectionButton";
import { EmptySectionTitle } from "~/_app/[dao]/home/components/common/EmptySectionTitle";
import { EmptySectionDescription } from "~/_app/[dao]/home/components/common/EmptySectionDescription";
import { Icon } from "~/modules/Common/Icons";
import { assertIsDefined } from "~/_utils/assert";

function EmptyMerchStore() {
  const { t } = useTranslation([ENameSpace.Home]);
  const { dao } = useDaoStore();
  assertIsDefined(dao);

  return (
    <EmptySectionItem isMobile>
      {({ hasMerchStore, isAdmin }) => {
        // 스토어 O
        if (hasMerchStore) {
          return isAdmin ? (
            <>
              <EmptySectionTitle
                title={t(
                  `${ENameSpace.Home}:Root.Sections.Merch.Empty.NoItemForAdmin.Title`,
                  { displayName: dao.displayName },
                )}
                className="typo-m1"
              />
              <EmptySectionDescription
                description={t(
                  `${ENameSpace.Home}:Root.Sections.Merch.Empty.NoItemForAdmin.Description`,
                )}
                className="typo-r2"
              />
              <EmptySectionButton className="mt-[1.5rem] gap-x-1">
                <Icon
                  iconType="Icon-Create Merch"
                  className="h-[1rem] w-[1rem]"
                />
                {t(
                  `${ENameSpace.Home}:Root.Sections.Merch.Empty.NoItemForAdmin.Button`,
                )}
              </EmptySectionButton>
            </>
          ) : (
            <EmptySectionTitle
              title={t(
                `${ENameSpace.Home}:Root.Sections.Merch.Empty.NoItemForMember`,
                { displayName: dao.displayName },
              )}
              className="typo-m1"
            />
          );
        }

        // 스토어 X
        return isAdmin ? (
          <>
            <EmptySectionTitle
              title={t(
                `${ENameSpace.Home}:Root.Sections.Merch.Empty.NotCreatedStoreForAdmin.Title`,
                { displayName: dao.displayName },
              )}
              className="typo-m1"
            />
            <EmptySectionDescription
              description={t(
                `${ENameSpace.Home}:Root.Sections.Merch.Empty.NotCreatedStoreForAdmin.Description`,
              )}
              className="typo-r2"
            />
            <EmptySectionButton className="mt-[1.5rem]">
              {t(
                `${ENameSpace.Home}:Root.Sections.Merch.Empty.NotCreatedStoreForAdmin.Button`,
              )}
            </EmptySectionButton>
          </>
        ) : (
          <EmptySectionTitle
            title={t(
              `${ENameSpace.Home}:Root.Sections.Merch.Empty.NotCreatedStoreForMember.Title`,
              { displayName: dao.displayName },
            )}
            className="typo-m1"
          />
        );
      }}
    </EmptySectionItem>
  );
}

export { EmptyMerchStore };
