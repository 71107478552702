import { ENationCode } from "~/_enums/ENationCode";

type TCurrencySymbol = "$" | "₩" | "￥";
type TGetCurrencySymbolByNationCode = (
  nationCode: ENationCode,
) => TCurrencySymbol;

export const getCurrencySymbolByNationCode: TGetCurrencySymbolByNationCode = (
  nationCode,
) => {
  let currencySymbol: TCurrencySymbol;
  if (nationCode === ENationCode.Kr) {
    currencySymbol = "₩";
  } else if (nationCode === ENationCode.Jp) {
    currencySymbol = "￥";
  } else {
    currencySymbol = "$";
  }

  return currencySymbol;
};

export const getLocaleNumber = (num: number, nationCode: ENationCode) => {
  if (nationCode === ENationCode.Kr) {
    return num.toLocaleString("ko-KR");
  } else if (nationCode === ENationCode.Jp) {
    return num.toLocaleString("ja-JP");
  } else if (nationCode === ENationCode.En) {
    return num.toLocaleString("en-US");
  } else {
    throw new TypeError(`${nationCode} is not supported`);
  }
};
