export const getCodeFromWalletError = (error: unknown): number | null => {
  try {
    const code: unknown =
      (error as { code: number } | null | undefined)?.code ?? null;
    if (typeof code === `number`) {
      return code;
    }
    return null;
  } catch {
    return null;
  }
};
