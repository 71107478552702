import { ReactNode } from "react";
import { MobileSectionContainer } from "~/_app/[dao]/home/components/mobile/MobileSectionContainer";
import { SnsItem } from "~/_app/[dao]/home/components/common/SnsItem";
import { SnsList } from "~/_app/[dao]/home/components/mobile/Profile/SnsList";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { Icon } from "~/modules/Common/Icons";
import { CommunityProfileImage } from "~/modules/Common/Image";
import { useDaoStore } from "~/_store/dao/useDaoStore";
import { useProfileSection } from "~/_app/[dao]/home/hooks/useProfileSection";
import { RedDotWithCount } from "~/_app/[dao]/home/components/common/RedDotWithCount";
import { assertIsDefined } from "~/_utils/assert";
import { CoverImageWithGradient } from "~/_components/CoverImageWithGradient/CoverImageWithGradient";
import { EDevice } from "~/_enums/EDevice";
import { take, toArray } from "@fxts/core";
import { Portal } from "@headlessui/react";

type TProps = {
  sectionId: string;

  notch: ReactNode | null;

  renderSticky: ReactNode | null;
};

export function Profile({ sectionId, notch, renderSticky }: TProps) {
  const { dao } = useDaoStore();
  assertIsDefined(dao);

  const { t } = useTranslation([ENameSpace.Home]);
  const { getSection, device } = useDaoHomeStore();
  const { sns, description, displayName, memberCount, coverImage } = getSection(
    EDaoHomeSection.Profile,
    sectionId,
  );

  const selectedImageUrl =
    coverImage[device].index == -1
      ? ""
      : coverImage[device].images[coverImage[device].index].url;

  const {
    handleMoveCommunityList,
    handleMoveNotification,
    unreadNotification,
    handleClickMore,
  } = useProfileSection();

  return (
    <MobileSectionContainer className="home-profile relative flex justify-between pb-6">
      {selectedImageUrl && (
        <CoverImageWithGradient
          image={selectedImageUrl}
          variant={EDevice.Mobile}
        />
      )}
      <div className="relative">
        {!renderSticky && (
          <header>
            {notch}
            <nav className="relative flex h-[3.75rem] items-center justify-between px-5">
              <div>
                <button type="button" onClick={handleMoveCommunityList}>
                  <CommunityProfileImage
                    imageUrl={dao.profileImageUrl}
                    displayName={dao.displayName}
                    size="sm2"
                    categoryId={dao.categoryId}
                  />
                </button>
              </div>
              <div className="flex gap-4">
                <button type="button" onClick={handleMoveNotification}>
                  <Icon
                    iconType="Notification Bell"
                    width="1.5rem"
                    className="text-ciety-wh"
                  />
                  {unreadNotification > 0 && (
                    <RedDotWithCount
                      count={unreadNotification}
                      className="absolute -top-[0.3125rem] left-1/2"
                    />
                  )}
                </button>
                <button type="button" onClick={handleClickMore}>
                  <Icon
                    iconType="More Vertical"
                    width="1.5rem"
                    className="text-ciety-wh"
                  />
                </button>
              </div>
            </nav>
          </header>
        )}
        <div
          className={`px-5 ${
            selectedImageUrl ? "mt-[17.75rem]" : "mt-[1.375rem]"
          }`}
        >
          <h1
            className="w-full truncate text-ciety-wh"
            /**
             * font 예외적으로 사용
             * https://www.figma.com/file/ITNNom9vYVtQ4Hg8Tt9aOr?type=design&node-id=1699-39954&mode=design#766115360
             */
            style={{
              fontFamily: "Pretendard",
              fontWeight: 700,
              fontSize: "1.75rem",
              lineHeight: "130%",
            }}
          >
            {displayName}
          </h1>
          <div className="mt-5 text-ciety-gy-30">
            <div className="typo-m2  flex">
              <span className="mr-4">
                {t(`${ENameSpace.Home}:Root.Sections.Profile.MemberCount`, {
                  count: memberCount,
                })}
              </span>
              {/* @TODO 인기멤버 생길시 */}
              {/* <span className="flex items-center">
              인기 멤버 보기
              <Icon iconType="Dropdown_Arrow" className="h-5 w-5 -rotate-90" />
            </span> */}
            </div>
            <p className="typo-r2 mt-2 line-clamp-4">{description}</p>
          </div>

          <SnsList className="mt-5">
            {toArray(take(8, sns)).map((item) => (
              <SnsItem key={item.id} {...item} device={EDevice.Mobile} />
            ))}
          </SnsList>

          {/* @TODO 멤버쉽 생길시 */}
          {/* <button
            type="button"
            className="flex-center typo-m1 mt-5 w-full rounded-full bg-ciety-rd py-2 text-ciety-wh "
          >
            {t(`${ENameSpace.Home}:Root.Sections.Profile.MembershipJoin`)}
          </button> */}
        </div>
      </div>
      <Portal>{renderSticky}</Portal>
    </MobileSectionContainer>
  );
}
