import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const params = new URLSearchParams(document.location.search);

export type ThreadPostState = {
  threadPost: TPost | null;
  threadPostChannelId: string | null;
};
export const initialThreadPostState: ThreadPostState = {
  threadPostChannelId: params.get("channelId") ?? "",
  threadPost: null,
};

export const threadPostSlice = createSlice({
  name: "threadPost",
  initialState: initialThreadPostState,
  reducers: {
    resetToInitialState: () => initialThreadPostState,
    setThreadPost: (
      state,
      action: PayloadAction<ThreadPostState["threadPost"]>,
    ) => {
      state.threadPost = action.payload;
    },
    updateThreadPost: (
      state,
      action: PayloadAction<ThreadPostState["threadPost"]>,
    ) => {
      if (state.threadPost?.id === action.payload?.id) {
        state.threadPost = action.payload;
      }
    },
    setThreadPostChannelId: (
      state,
      action: PayloadAction<ThreadPostState["threadPostChannelId"]>,
    ) => {
      state.threadPostChannelId = action.payload;
    },
  },
});

export const threadPostActions = threadPostSlice.actions;

export default threadPostSlice.reducer;
