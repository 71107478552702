import type { TMarketComment } from "~/modules/Posting/Message/shared/types/message";
import { createMessageSlice } from "~/modules/Posting/Message/shared/utils/createMessageSlice";

const marketNftMessageSlice = createMessageSlice<TMarketComment>({
  name: "marketNftMessage",
  initialState: {
    initialScrollBottom: false,
  },
});

export const marketNftMessageActions = marketNftMessageSlice.actions;

export default marketNftMessageSlice.reducer;
