import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { EmptySectionItem } from "~/_app/[dao]/home/components/common/EmptySectionItem";
import { EmptySectionTitle } from "~/_app/[dao]/home/components/common/EmptySectionTitle";
import { EmptySectionDescription } from "~/_app/[dao]/home/components/common/EmptySectionDescription";

function EmptyHotPosts() {
  const { t } = useTranslation([ENameSpace.Home]);

  return (
    <EmptySectionItem isMobile>
      <EmptySectionTitle
        title={t(`${ENameSpace.Home}:Root.Sections.HotPosts.Empty.Title`)}
        className="typo-m1"
      />
      <EmptySectionDescription
        className="typo-r2"
        description={t(
          `${ENameSpace.Home}:Root.Sections.HotPosts.Empty.Description`,
        )}
      />
    </EmptySectionItem>
  );
}

export { EmptyHotPosts };
