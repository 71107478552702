import { useEffect } from "react";

export const useElectronOpenUrl = (
  callback: (data: TDesktopOpenUrlEventData) => void,
) => {
  useEffect(() => {
    window.desktop?.openUrl?.on(callback);

    return () => {
      window.desktop?.openUrl?.off(callback);
    };
  }, [callback]);
};
