import {
  ERROR_FORBIDDEN,
  ERROR_INTERNAL_SERVER_ERROR,
  ERROR_NOT_FOUND,
  ERROR_OOPS,
  ERROR_UNAUTHORIZED,
} from "~/_constants/errors";
import { NotFound } from "~/_components/Fallback/NotFound";
import { InternalServerError } from "~/_components/Fallback/InternalServerError";
import { Oops } from "~/_components/Fallback/Oops";
import { getParamsFromErrorMessage } from "~/_utils/errors/getParamsFromErrorMessage";
import { Unauthorized } from "~/_components/Fallback/Unauthorized";

type TProps = {
  error: unknown;
  resetErrorBoundary: (...args: unknown[]) => void;
};

function ErrorGlobalFallback({ error }: TProps) {
  const errorMessage = error instanceof Error ? error.message : ERROR_OOPS;

  if (errorMessage === ERROR_UNAUTHORIZED) {
    return <Unauthorized />;
  }

  if (errorMessage === ERROR_FORBIDDEN || errorMessage === ERROR_NOT_FOUND) {
    const { message } = getParamsFromErrorMessage(errorMessage);
    return <NotFound message={message} />;
  }

  if (errorMessage === ERROR_INTERNAL_SERVER_ERROR) {
    return <InternalServerError />;
  }

  return <Oops />;
}

export { ErrorGlobalFallback };
