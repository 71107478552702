import {
  createApi,
  defaultSerializeQueryArgs,
} from "@reduxjs/toolkit/query/react";

import { getBaseQuery } from "~/api/getBaseQuery";
import { isEqualPrimitiveArrayIgnoreOrder } from "~/utils/arrays";
import { GetContractsFromMember, GetItemsFromMember } from "./types";
import { createTagList, getCacheTag } from "./utils";

export const BLOCKCHAIN_ASSET_CACHE_HOLD_SEC = 180;

const DEFAULT_PAGE_SIZE = 50;

export const inventoryApi = createApi({
  reducerPath: "inventoryApi",
  baseQuery: getBaseQuery({}),
  tagTypes: ["Contract", "Nft"],
  endpoints: (builder) => ({
    /* 멤버가 소유한 아이템의 Contract 목록 조회 */
    // https://api-dev.ciety.dev/api#/NFT%20Asset/GetMembersMemberidNftcontractsController_handle
    getContractsFromMember: builder.query<
      GetContractsFromMember["data"],
      GetContractsFromMember["request"] & { isForce?: boolean } // 강제로 Fetch 를 일으켜 캐시를 날리고 새로운 데이터로 replace 하기 위한 변수
    >({
      keepUnusedDataFor: BLOCKCHAIN_ASSET_CACHE_HOLD_SEC,
      query: ({ memberId, chain, pageKey, pageSize = DEFAULT_PAGE_SIZE }) => ({
        url: `members/${memberId}/nft-contracts`,
        method: "GET",
        params: { chain, pageKey, pageSize },
      }),
      providesTags: (result, error, { chain }) => {
        if (!result || error) {
          return [];
        }
        const tag = createTagList({
          results: result?.items,
          idFn: (args) =>
            getCacheTag({
              chain,
              predicates: args.contract.address,
            }),
          tagType: "Contract",
          groupIds: [chain],
        });
        return tag;
      }, // pageKey 변화를 제외한 값 변화를 기반으로 store 캐시 관리
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { pageKey, pageSize, isForce, ...cachedArgs } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: cachedArgs,
          endpointDefinition,
        });
      },
      transformResponse: (response: GetContractsFromMember["response"]) => {
        const { code } = response;

        switch (code) {
          case "000": {
            return response.data;
          }
          case "444": {
            return { items: [], pageKey: null, isExpired: true };
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${inventoryApi.endpoints.getContractsFromMember.name}`,
            );
          }
        }
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg?.isForce || arg.pageKey == null) {
          currentCache.items = newItems.items;
        } else {
          currentCache.items.push(...newItems.items);
        }

        currentCache.pageKey = newItems.pageKey;
        if (newItems.isExpired && !arg.isForce) {
          currentCache.isExpired = newItems.isExpired;
        } else {
          delete currentCache.isExpired;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        if (currentArg?.isForce) {
          return true;
        }

        if (currentArg?.pageKey == null && previousArg?.pageKey != null) {
          return false;
        }

        const isDifferent =
          (currentArg != null && previousArg == null) ||
          (currentArg != null &&
            previousArg != null &&
            (currentArg.chain !== previousArg.chain ||
              currentArg.memberId !== previousArg.memberId ||
              currentArg.pageKey !== previousArg.pageKey));

        return isDifferent;
      },
    }),

    /* 멤버가 소유한 NFT 목록 조회 (특정 컨트랙 한정) */
    // https://api-dev.ciety.dev/api#/NFT%20Asset/GetMembersMemberidNftsController_handle
    getItemsFromMember: builder.query<
      GetItemsFromMember["data"],
      GetItemsFromMember["request"] & { isForce?: boolean } // 강제로 Fetch 를 일으켜 캐시를 날리고 새로운 데이터로 replace 하기 위한 변수
    >({
      keepUnusedDataFor: BLOCKCHAIN_ASSET_CACHE_HOLD_SEC,
      query: ({
        memberId,
        chain,
        contracts,
        pageKey,
        pageSize = DEFAULT_PAGE_SIZE,
      }) => ({
        url: `members/${memberId}/nfts`,
        method: "GET",
        params: { chain, contracts, pageKey, pageSize },
      }),
      providesTags: (result, error, { chain, contracts }) => {
        if (!result || error) {
          return [];
        }
        const tag = createTagList({
          results: result?.nfts,
          idFn: (args) => {
            const {
              nft: { chain, contractAddress, tokenId },
            } = args;
            return getCacheTag({
              chain,
              predicates: [contractAddress, tokenId],
            });
          },
          tagType: "Nft",
          groupIds: [getCacheTag({ chain, predicates: contracts })],
        });
        return tag;
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { pageKey, pageSize, isForce, ...cachedArgs } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: cachedArgs,
          endpointDefinition,
        });
      },
      transformResponse: (response: GetItemsFromMember["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          case "444": {
            // 응답 세션 expired 될 때 데이터 처리
            return {
              nfts: [],
              numDistinctTokensOwned: -1,
              pageKey: null,
              isExpired: true,
            };
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${inventoryApi.endpoints.getItemsFromMember.name}`,
            );
          }
        }
      },
      merge: (currentCache, newItems, { arg }) => {
        if (arg?.isForce || arg.pageKey == null) {
          currentCache.nfts = newItems.nfts;
        } else {
          currentCache.nfts.push(...newItems.nfts);
        }
        // 항상 API 조회시 캐시가 업데이트 되어야 하는 필드
        currentCache.pageKey = newItems.pageKey;
        currentCache.numDistinctTokensOwned = newItems.numDistinctTokensOwned;

        if (newItems.isExpired && !arg.isForce) {
          currentCache.isExpired = newItems.isExpired;
        } else {
          delete currentCache.isExpired;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        if (currentArg?.isForce) {
          return true;
        }

        if (currentArg?.pageKey == null && previousArg?.pageKey != null) {
          return false;
        }

        const isDifferent =
          (currentArg != null && previousArg == null) ||
          (currentArg != null &&
            previousArg != null &&
            (currentArg.chain !== previousArg.chain ||
              currentArg.memberId !== previousArg.memberId ||
              currentArg.pageKey !== previousArg.pageKey ||
              !isEqualPrimitiveArrayIgnoreOrder(
                currentArg.contracts,
                previousArg.contracts,
              )));
        return isDifferent;
      },
    }),
  }),
});
