const SUPPORT_CHAINS = process.env.REACT_APP_SUPPORT_CHAINS;

const SUPPORTED_CHAIN_NAMES = [
  ...new Set(
    SUPPORT_CHAINS?.split(`,`)
      .map((chainId) => {
        chainId = chainId.trim();
        if (chainId === `1`) {
          return `Ethereum Mainnet`;
        }
        if (chainId === `5`) {
          return `Ethereum Testnet Görli`;
        }
        if (chainId === `137`) {
          return `Polygon Mainnet`;
        }
        if (chainId === `80001`) {
          return `Polygon Testnet Mumbai`;
        }

        return null;
      })
      .flatMap((chainName) => {
        return chainName == null ? [] : [chainName];
      }),
  ),
];

export const getSupportedChainsMessage = () => {
  if (SUPPORTED_CHAIN_NAMES.length === 2) {
    return `Please use either ${SUPPORTED_CHAIN_NAMES[0]} or ${SUPPORTED_CHAIN_NAMES[1]}.`;
  } else if (SUPPORTED_CHAIN_NAMES.length >= 1) {
    return `Please use ${SUPPORTED_CHAIN_NAMES.join(`, `)}.`;
  } else {
    return "";
  }
};
