import type { ComponentProps } from "react";
import { useTranslation } from "react-i18next";

import { ENameSpace } from "~/i18n/@types/types";
import { ErrorLayout } from "~/modules/Common/Layout";

type Props = {
  code: string;
};

function Error({ code }: Props) {
  const { t } = useTranslation([ENameSpace.Common]);
  const toMainPage = () => {
    const searchParams = new URLSearchParams(location.search);
    const subdomainFromPathname = location.pathname.startsWith("/@")
      ? location.pathname.split("/")[1]
      : "";

    const subdomain = searchParams.get("subdomain") ?? subdomainFromPathname;

    if (!subdomain) {
      location.replace(`/`);
      return;
    }

    const matched = /^@/g.test(subdomain);
    location.replace(`/${matched ? subdomain : `@${subdomain}`}`);
  };

  const errorCodeEnum: { [key: string]: ComponentProps<typeof ErrorLayout> } = {
    "404": {
      title: t(`${ENameSpace.Common}:Error.404.Title`),
      mainText: t(`${ENameSpace.Common}:Error.404.MainText`),
      subText: [
        t(`${ENameSpace.Common}:Error.404.SubText1`),
        t(`${ENameSpace.Common}:Error.404.SubText2`),
      ],
      buttonText: t(`${ENameSpace.Common}:Error.404.ButtonText`),
      onClickButton: () => window.history.back(),
    },
    "500": {
      title: t(`${ENameSpace.Common}:Error.500.Title`),
      mainText: t(`${ENameSpace.Common}:Error.500.MainText`),
      subText: [
        t(`${ENameSpace.Common}:Error.500.SubText1`),
        t(`${ENameSpace.Common}:Error.500.SubText2`),
      ],
      buttonText: t(`${ENameSpace.Common}:Error.500.ButtonText`),
      onClickButton: toMainPage,
    },
    "406": {
      title: t(`${ENameSpace.Common}:Error.406.Title`),
      mainText: t(`${ENameSpace.Common}:Error.406.MainText`),
      subText: [
        t(`${ENameSpace.Common}:Error.406.SubText1`),
        t(`${ENameSpace.Common}:Error.406.SubText2`),
      ],
      buttonText: t(`${ENameSpace.Common}:Error.406.ButtonText`),
      onClickButton: () => location.reload(),
    },
    "*": {
      title: t(`${ENameSpace.Common}:Error.*.Title`),
      mainText: t(`${ENameSpace.Common}:Error.*.MainText`),
      subText: [
        t(`${ENameSpace.Common}:Error.*.SubText1`),
        t(`${ENameSpace.Common}:Error.*.SubText2`),
      ],
      buttonText: "Main",
      onClickButton: () => location.reload(),
    },
  };

  return (
    <div className="flex h-screen w-full items-center justify-center">
      <ErrorLayout {...(errorCodeEnum[code] ?? errorCodeEnum["*"])} />
    </div>
  );
}
export { Error };
