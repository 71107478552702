import { EAccount } from "~/_enums/EAccount";
import { EChain } from "~/_enums/EChain";
import { ECode } from "~/_enums/ECode";
import { TApiConfig } from "~/_libs/fetch/fetchConfig";
import { fetchPostJson } from "~/_libs/fetch/fetchPostJson";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

type TPostAuthConnectRequestsRegisterInputDto = {
  authRequestId: string;

  /**
   * @default: a9c98c03-858e-4dd6-ae7e-a7d20a5fdf96
   * 인증 요청 검증 키
   */
  key: string;
  payload:
    | TPayloadForEvnCompatibleAccount
    | TPayloadForEmailAccount
    | TPayloadForGoogleAccount
    | TPayloadForAppleAccount;
};

type TPayloadForEvnCompatibleAccount = {
  account: typeof EAccount.EvmCompatibleAccount;
  chain: EChain;
  address: string;
};

type TPayloadForEmailAccount = {
  account: typeof EAccount.EmailAccount;
  /** 이메일 주소 */
  address: string;
};

type TPayloadForGoogleAccount = {
  account: typeof EAccount.Google;
  oauthRedirectUrl: string;
  asLoginAccount?: boolean;
};

type TPayloadForAppleAccount = {
  account: typeof EAccount.Apple;
  oauthRedirectUrl: string;
  /**
   * null인 경우 기존
   * 2인 경우 새로운 버전
   */
  version: 2 | null;
};

type TPostAuthConnectRequestsRegisterOutputDto = {
  code: typeof ECode.Success;
  data:
    | TDataForEvmCompatibleAccount
    | TDataForEmailAccount
    | TDataForGoogleAccount
    | TDataForAppleAccount;
};

type TDataForEvmCompatibleAccount = {
  account: typeof EAccount.EvmCompatibleAccount;
  validation: {
    message: string;
  };
};

type TDataForEmailAccount = {
  account: typeof EAccount.EmailAccount;
};

type TDataForGoogleAccount = {
  account: typeof EAccount.Google;
  authorizationUrl: string;
  scope: string[];
};

type TDataForAppleAccount = {
  account: typeof EAccount.Apple;
  authorizationUrl: string;
};

export const postAuthConnectRequestsRegister = (
  { authRequestId, ...body }: TPostAuthConnectRequestsRegisterInputDto,
  config: TApiConfig,
) => {
  return fetchPostJson<TPostAuthConnectRequestsRegisterOutputDto>(
    `/auth/connect/requests/${authRequestId}/register`,
    {
      body,
    },
    config,
  ).then((result) => {
    switch (result.code) {
      case "000":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postAuthConnectRequestsRegister",
            payload: body,
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
};
