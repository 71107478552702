import { Z_LEVELS } from "~/_constants/zIndexer";
import { cn } from "~/_utils/converter/cn";
import { PolymorphicButton } from "~/modules/Common/Buttons";

type TProps = {
  onClickCover: () => void;
};

export function ExploreSectionCover({ onClickCover }: TProps) {
  return (
    <PolymorphicButton
      as="div"
      className={cn("absolute inset-0")}
      style={{
        zIndex: Z_LEVELS.EXPLORE.SECTION,
      }}
      onClick={onClickCover}
    />
  );
}
