import React, { memo } from "react";

import { DefaultCommunityImage } from "~/modules/Common/Image/components/DefaultCommunityImage/DefaultCommunityImage";
import { remStrToPx, toProfileImages } from "~/utils/converter";
import ResizedProfileImage from "./ResizedProfileImage";

type Size = {
  container: `${number}rem`;
  image: `${number}rem`;
  roundClassName: string;
};

type Props = {
  imageUrl?: string | null;
  displayName: string;
  size?:
    | "sm"
    | "sm2"
    | "md"
    | "lg"
    | "xl"
    | "xxl"
    | "xxl2"
    | "xxl3"
    | "xxxl"
    | "communityList";
  customSize?: Size;
  categoryId?: string;
};

export const SIZES: Record<NonNullable<Props["size"]>, Size> = {
  sm: {
    container: "2.5rem",
    image: "2.5rem",
    roundClassName: "rounded-lg",
  },
  sm2: {
    container: "2.5rem",
    image: "2.375rem",
    roundClassName: "rounded-full",
  },
  communityList: {
    container: "3.25rem",
    image: "2.75rem",
    roundClassName: "rounded-full",
  },
  md: {
    container: "3.75rem",
    image: "3.75rem",
    roundClassName: "rounded-full",
  },
  lg: {
    container: "4.5rem",
    image: "4.05rem",
    roundClassName: "rounded-full",
  },
  xl: {
    container: "6.25rem",
    image: "5.625rem",
    roundClassName: "rounded-full",
  },
  xxl: {
    container: "7.5rem",
    image: "7.5rem",
    roundClassName: "rounded-full",
  },
  xxl2: {
    container: "7.5rem",
    image: "6.75rem",
    roundClassName: "rounded-full",
  },
  xxl3: {
    container: "9.5rem",
    image: "9.5rem",
    roundClassName: "rounded-full",
  },
  xxxl: {
    container: "10rem",
    image: "10rem",
    roundClassName: "rounded-[2.5rem]",
  },
};

export const CommunityProfileImage = memo(
  ({ imageUrl, displayName, size, customSize, categoryId }: Props) => {
    const selectedSize = customSize ?? SIZES[size ?? "md"];

    const defaultProfileWhenFail = (
      <DefaultCommunityImage
        type={toProfileImages(categoryId ?? "0")}
        className={`overflow-hidden ${selectedSize.roundClassName}`}
        width={selectedSize.image}
        height={selectedSize.image}
      />
    );

    return (
      <div
        className={`community-profile-image flex-center isolate overflow-hidden ${selectedSize.roundClassName}`}
        style={{
          width: selectedSize.container,
          height: selectedSize.container,
          minWidth: selectedSize.container,
        }}
      >
        {imageUrl ? (
          <ResizedProfileImage
            className={`overflow-hidden object-cover ${selectedSize.roundClassName}`}
            style={{
              width: selectedSize.image,
              height: selectedSize.image,
            }}
            toResizeUrlProps={{
              url: imageUrl,
              width: remStrToPx(selectedSize.image),
            }}
            alt={`${displayName}'s profile`}
            fallback={defaultProfileWhenFail}
          />
        ) : (
          defaultProfileWhenFail
        )}
      </div>
    );
  },
  (oldProps, newProps) => {
    return (
      oldProps.imageUrl === newProps.imageUrl &&
      oldProps.categoryId === newProps.categoryId
    );
  },
);
