import { ReactNode } from "react";
import { Icon } from "~/modules/Common/Icons";
import { cn } from "~/_utils/converter/cn";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { EDevice } from "~/_enums/EDevice";

type TProps = {
  onClick: () => void;
  children?: ReactNode;
};

function MoreButton({ onClick, children }: TProps) {
  const { t } = useTranslation([ENameSpace.Home]);
  const { device } = useDaoHomeStore();
  const isDesktop = device === EDevice.Desktop;

  return (
    <button
      onClick={onClick}
      className={cn(
        "flex items-center gap-x-1 text-ciety-gy",
        isDesktop ? "typo-r1" : "typo-m3",
      )}
    >
      {children ?? t(`${ENameSpace.Home}:Root.Sections.Common.More`)}
      {isDesktop && (
        <Icon iconType="Prev" className="h-4 w-4 rotate-180 text-ciety-gy" />
      )}
    </button>
  );
}

export { MoreButton };
