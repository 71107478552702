const APP_SCHEME = "ciety://";

export const EMobileAppDeeplink = {
  Root: APP_SCHEME,
  AuthDeepLinkRedirect: `${APP_SCHEME}oauth/deeplink-redirect`,
  AuthSign: `${APP_SCHEME}auth/signIn`,
  CommunityJoin: `${APP_SCHEME}join/community/@{{SUBDOMAIN}}`,
} as const;

export type EMobileAppDeeplink =
  typeof EMobileAppDeeplink[keyof typeof EMobileAppDeeplink];
