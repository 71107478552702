import { cn } from "~/_utils/converter/cn";
import { ReactNode } from "react";

type TProps = {
  withBlur: boolean;
  children: ReactNode;
};

function BlurImageContainer({ withBlur, children }: TProps) {
  return (
    <div
      className={cn("h-full w-full", {
        "blur-[4px]": withBlur,
      })}
    >
      {children}
    </div>
  );
}

export { BlurImageContainer };
