import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { EmptySectionItem } from "~/_app/[dao]/home/components/common/EmptySectionItem";
import { EmptySectionTitle } from "~/_app/[dao]/home/components/common/EmptySectionTitle";

function EmptyBanner() {
  const { t } = useTranslation([ENameSpace.Home]);

  return (
    <EmptySectionItem isMobile height={"6.978rem"}>
      <EmptySectionTitle
        title={t(`${ENameSpace.Home}:Root.Sections.Banner.Empty.Title`)}
        className="typo-m1"
      />
    </EmptySectionItem>
  );
}

export { EmptyBanner };
