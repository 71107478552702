import React, { createContext, useContext, useReducer } from "react";

import { getUseContextError } from "~/utils/converter";
import reducer, { Actions, initialState, State } from "./reducer";

const EditorUtilsContext = createContext<{
  state: State;
  dispatch: React.Dispatch<Actions>;
} | null>(null);

function EditorUtilsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EditorUtilsContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </EditorUtilsContext.Provider>
  );
}

export const useEditorUtilsContext = () => {
  const storeContext = useContext(EditorUtilsContext);
  if (!storeContext)
    throw new Error(getUseContextError("EditorUtilsContext", "EditorUtils"));

  return storeContext;
};

export default EditorUtilsProvider;
