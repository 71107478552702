import { format } from "date-fns";
import { TMember } from "~/_types/TMember";
import { NicknameWithRoleColor } from "~/_app/[dao]/home/components/common/NicknameWithRoleColor";
import { ViewCount } from "~/_app/[dao]/home/components/common/ViewCount";
import { ReplyCount } from "~/_app/[dao]/home/components/common/ReplyCount";
import { HeartCount } from "~/_app/[dao]/home/components/common/HeartCount";
import { appNavigation } from "~/_api/mobile/toMobileNavigation";
import { getThreadScreenParams } from "~/_api/mobile/appScreenParams";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { useDaoSubdomainStore } from "~/_store/dao/useDaoSubdomainStore";
import { EChannelType } from "~/_enums/EChannelType";
import {
  TAttachmentAutolinkOutput,
  TAttachmentEmbedOutput,
  TAttachmentImageOutput,
  TAttachmentNftOutput,
  TAttachmentVideoOutput,
} from "~/_types/TAttachment";
import { remToPx } from "~/_utils/converter/remConverter";
import { ResizedImage } from "~/_components/Image/OptimalImage/ResizedImage";
import { assertIsDefined } from "~/_utils/assert";
import { getHomeImageUrlFromPost } from "~/_app/[dao]/home/utils/getHomeImageFromPost";
import { BlurImageContainer } from "~/_app/[dao]/home/components/common/BlurImageContainer";
import { EChannelState } from "~/_enums/EChannelState";
import { cn } from "~/_utils/converter/cn";

type TProps = {
  channel: {
    id: string;
    displayName: string;
    type: EChannelType;
    state: EChannelState;
  };
  post: {
    id: string;
    title: string;
    replyCount: number;
    author: TMember;
    createdAt: string;
    updatedAt: string;
    viewCount: number;
    attachments: (
      | TAttachmentImageOutput
      | TAttachmentAutolinkOutput
      | TAttachmentNftOutput
      | TAttachmentVideoOutput
      | TAttachmentEmbedOutput
    )[];
    heart: {
      count: number;
      selected: boolean;
    };
  };
};

function AllPostItem({ channel, post }: TProps) {
  const { daoSubdomain } = useDaoSubdomainStore();
  assertIsDefined(daoSubdomain);

  const handleMoveThread = () => {
    appNavigation(({ push }) => {
      push(
        getThreadScreenParams({
          path: generateUrl({
            type: "threadMobile",
            subdomain: daoSubdomain,
            channelId: channel.id,
            postId: post.id,
            channelType: EChannelType.Sns,
          }),
        }),
      );
    });
  };

  const imageUrl = getHomeImageUrlFromPost({ post });
  const isHiddenPost = channel.state === EChannelState.Hidden;

  return (
    <button
      type="button"
      onClick={handleMoveThread}
      className="flex w-full justify-between border-t-2 border-ciety-dk-95 py-4 last:border-b-2"
    >
      <div
        className={cn(
          "space-y-[0.75rem]",
          imageUrl ? "w-[calc(100%-4.125rem)]" : "w-full",
        )}
      >
        <div className="item-center flex gap-x-2 text-ciety-wh">
          <p className="typo-m2 max-w-full truncate text-left">{post.title}</p>
          <ReplyCount count={post.replyCount} />
        </div>
        <div className="typo-r3 flex gap-x-2 text-ciety-gy">
          <NicknameWithRoleColor
            nickname={post.author.nickname}
            roleColor={post.author.roles[0]?.displayColor}
          />
          <div>{format(new Date(post.createdAt), "yy.MM.dd")}</div>
          <ViewCount count={post.viewCount} />
          <HeartCount heart={post.heart} />
        </div>
      </div>
      {imageUrl && (
        <div className="isolate aspect-[1/1] w-[3.125rem] overflow-hidden rounded-lg">
          <BlurImageContainer withBlur={isHiddenPost}>
            <ResizedImage
              toResizeUrlParams={{
                url: imageUrl,
                width: remToPx(3.125),
                qualityMultiplier: 2,
              }}
              className="h-full w-full object-cover"
            />
          </BlurImageContainer>
        </div>
      )}
    </button>
  );
}

export { AllPostItem };
