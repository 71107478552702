import { Icon } from "~/modules/Common/Icons";

type TProps = {
  heart: {
    count: number;
    selected: boolean;
  };
};

function HeartCount({ heart: { count = 0, selected = false } }: TProps) {
  return (
    <div className="flex-center gap-x-1">
      {selected ? (
        <Icon iconType="Heart_fill" className="h-4 w-4 text-ciety-lp" />
      ) : (
        <Icon iconType="Heart" className="h-4 w-4 text-ciety-gy" />
      )}

      {count}
    </div>
  );
}

export { HeartCount };
