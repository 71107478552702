import { atom, WritableAtom } from "jotai";

const atomCache = new Map<
  string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  WritableAtom<unknown, [update: { value: any; isEvent: boolean }], void>
>();

/**
 * broadcast model 감싸서 사용
 *
 * reference
 * {@link https://jotai.org/docs/recipes/atom-with-broadcast}
 */
export function atomWithBroadcast<T>(key: string, initialValue: T) {
  const baseAtom = atom(initialValue);
  const listeners = new Set<(event: MessageEvent<T>) => void>();
  const channel = new BroadcastChannel(key);
  channel.onmessage = (event) => {
    listeners.forEach((l) => l(event));
  };

  if (!atomCache.has(key)) {
    atomCache.set(
      key,
      atom(
        (get) => get(baseAtom),
        (
          get,
          set,
          update: {
            value: T;
            isEvent: boolean;
          },
        ) => {
          set(baseAtom, update.value);

          if (!update.isEvent) {
            channel.postMessage(get(baseAtom));
          }
        },
      ),
    );

    atomCache.get(key)!.onMount = (setAtom) => {
      const listener = (event: MessageEvent<T>) => {
        setAtom({ isEvent: true, value: event.data });
      };
      listeners.add(listener);

      return () => {
        listeners.delete(listener);
      };
    };
  }

  return atom(
    (get) => get(atomCache.get(key)!),
    (get, set, update) => {
      set(atomCache.get(key)!, { isEvent: false, value: update });
    },
  ) as WritableAtom<T, [update: unknown], void>;
}
