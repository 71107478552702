import { ReactNode } from "react";
import { useDaoStore } from "~/_store/dao/useDaoStore";
import { cn } from "~/_utils/converter/cn";
import { useMemberStore } from "~/_store/member/useMemberStore";
import { assertIsDefined } from "~/_utils/assert";

type TProps = {
  height?: string;
  isMobile?: boolean;
  children:
    | ReactNode
    | ((params: { hasMerchStore: boolean; isAdmin: boolean }) => ReactNode);
};
function EmptySectionItem({
  children,
  isMobile = false,
  height = isMobile ? "9.5rem" : "16.4375rem",
}: TProps) {
  const { dao } = useDaoStore();
  assertIsDefined(dao);
  const { isAdmin } = useMemberStore();

  const hasMerchStore = Boolean(dao.merchstoreUrl);

  return (
    <section
      className={cn(
        `flex-center w-full flex-col rounded-xl border border-ciety-dk-80 px-5`,
        isMobile ? "py-8" : "py-12",
      )}
      style={{
        minHeight: height,
      }}
    >
      {typeof children === "function"
        ? children({ hasMerchStore, isAdmin })
        : children}
    </section>
  );
}

export { EmptySectionItem };
