export const ETextValidationAllow = {
  /** 국문 */
  Ko: "Ko",
  /** 영문 */
  En: "En",
  /** 영문 소문자 */
  EnLower: "EnLower",
  /** 숫자 */
  Number: "Number",
  /** 특수문자(-, _) */
  DashUnderscore: "DashUnderscore",
} as const;

export type ETextValidationAllow =
  typeof ETextValidationAllow[keyof typeof ETextValidationAllow];
