import { Web3Provider } from "@ethersproject/providers";

import { ADD_CHAIN_DATA } from "~/modules/BlockChain/Giftok/components/constants/addNetworkChainData";
import { ChainMetadata } from "~/modules/Market/types";
import { ellipseAddress, isAddressMatch } from "~/modules/Market/utils/address";
import { toHex } from "~/modules/Market/utils/conversions";
import { getMetamaskProvider } from "~/modules/Market/utils/provider";

type ChainErrorCases = {
  addChain: string;
  rejectSwitch: string;
};

export const checkConnectedChain = async ({
  userAddress,
  targetDesChainId,
  errorCases,
}: {
  userAddress?: string | null;
  targetDesChainId: ChainMetadata["desChainId"];
  errorCases: ChainErrorCases;
}) =>
  (await isTargetChainConnected(targetDesChainId, userAddress)) ||
  (await requestSwitchChain({
    targetDesChainId,
    errorCases,
  }));

const isTargetChainConnected = async (
  targetDesChainId: ChainMetadata["desChainId"],
  userAddress?: string | null,
) => {
  const { provider } = getMetamaskProvider();
  // Try metamask login again in case of logged out
  const connectedUser = await provider.send("eth_requestAccounts", []);

  // Check if userAddress and metamask connected user address is matched
  if (userAddress && connectedUser[0]) {
    if (!isAddressMatch(connectedUser[0], userAddress)) {
      throw new Error(
        `Please switch Metamask user to ${ellipseAddress(userAddress, 5)}`,
      );
    }
  }

  const connectedDesChainId = (await provider.getNetwork()).chainId;
  return targetDesChainId === "" + connectedDesChainId;
};

const requestSwitchChain = async ({
  targetDesChainId,
  errorCases,
}: {
  targetDesChainId: ChainMetadata["desChainId"];
  errorCases: ChainErrorCases;
}): Promise<boolean> => {
  const { metamaskEthereum } = getMetamaskProvider();
  const targetHexChainId = toHex(targetDesChainId);
  try {
    await metamaskEthereum.request({
      method: "wallet_switchEthereumChain",
      params: [{ chainId: targetHexChainId }],
    });
    return true;
  } catch (e) {
    if ((e as { code: number }).code === 4902) {
      try {
        await metamaskEthereum.request({
          method: "wallet_addEthereumChain",
          params: ADD_CHAIN_DATA(targetDesChainId),
        });
      } catch (e) {
        throw new Error(errorCases.addChain);
      }
      const isChainMatched = await isTargetChainConnected(targetDesChainId);
      if (!isChainMatched) {
        throw new Error(errorCases.rejectSwitch);
      } else {
        return true;
      }
    } else {
      throw new Error(errorCases.rejectSwitch);
    }
  }
};

const checkItemOwnerMatchWithConnectedAddress = async ({
  provider,
  itemOwnerAddress,
}: {
  provider: Web3Provider;
  itemOwnerAddress: string;
}) => {
  const connectedAddress = (await provider.send("eth_requestAccounts", []))[0];
  return connectedAddress.toLowerCase() === itemOwnerAddress.toLowerCase();
};

export const checkIsItemOwned = async ({
  provider,
  ownerAddress,
}: {
  provider: Web3Provider;
  ownerAddress?: string;
}): Promise<boolean> => {
  if (ownerAddress == null) {
    // Native Token or ERC20
    return true;
  } else {
    const result = await checkItemOwnerMatchWithConnectedAddress({
      provider,
      itemOwnerAddress: ownerAddress,
    });

    if (!result) {
      alert(
        `User connected to Metamask is not the owner of this item.\nPlease switch to ${ownerAddress} in Metamask who is the owner of the item.`,
      );
    }

    return result;
  }
};
