import { EChain } from "../_enums/EChain";

export const COOKIE_KEY = "c_ss_token";
export const APP_TOKEN_KEY = "x-ciety-token";
export const DAO_HEADER_KEY = "x-dao-sub-domain";
export const DAO_PREFIX = "%40"; // "@" 문자

export const SOCKET = {
  INIT_RETRY_TIME: 1000,
};

export const NEED_HYDRATION = Symbol("$$hydrate");

export const addressLinkEnum: { [key in EChain]: string } = {
  EthereumMainnet: "https://etherscan.io/address/",
  Goerli: "https://goerli.etherscan.io/address/",
  PolygonMainnet: "https://polygonscan.com/address/",
  Mumbai: "https://mumbai.polygonscan.com/address/",
};

export const DAO_HOME = {
  CONTENT_COUNT: {
    DESKTOP_SNS_SUMMARY: 3,
    DESKTOP_SNS_THUMBNAIL: 4,
    DESKTOP_SNS_DETAIL: 3,
    DESKTOP_HOT_POST: 5,
    DESKTOP_ALL_POST: 5,
    DESKTOP_BANNER: 8,
    DESKTOP_BANNER_VIEW: 2,
    DESKTOP_MERCH_STORE: 5,
    DESKTOP_MARKETPLACE: 5,

    MOBILE_SNS_SUMMARY: 3,
    MOBILE_SNS_THUMBNAIL: 8,
    MOBILE_SNS_DETAIL: 2,
    MOBILE_HOT_POST: 3,
    MOBILE_ALL_POST: 3,
    MOBILE_BANNER: 8,
    MOBILE_BANNER_VIEW: 1,
    MOBILE_MERCH_STORE: 5,
    MOBILE_MARKETPLACE: 5,
  },
};

export const APP_LINK = {
  GOOGLE_PLAY:
    "https://play.google.com/store/apps/details?id=com.ciety.xyz.prod&pli=1",
  APPLE_STORE:
    "https://apps.apple.com/kr/app/사이어티-ciety-커뮤니티-플랫폼/id6443814305",
};

export const ATTACHMENT_TIMEOUT = 100;
