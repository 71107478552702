import { ESnsType } from "~/_types/daoHome/TDaoHomeSectionProfile";
import { Icon } from "~/modules/Common/Icons";
import { sanitizeUrl } from "~/_utils/converter/sanitizeUrl";
import { EDevice } from "~/_enums/EDevice";
import { cn } from "~/_utils/converter/cn";
import { SNS_LIST } from "~/_app/[dao]/settings/home/components/desktop/sections/Profile/components/SnsItem";

type TProps = {
  type: ESnsType;
  url: string;
  device: EDevice;
};

function SnsItem({ type, url, device }: TProps) {
  const dimensionsClassName =
    device === EDevice.Desktop ? "h-6 w-6" : "h-7 w-7";

  return (
    <li key={type}>
      <a
        href={sanitizeUrl(url)}
        className={cn("flex-center", dimensionsClassName)}
        target="_blank"
      >
        <Icon
          iconType={SNS_LIST[type].iconName}
          className={cn("text-ciety-gy-30", dimensionsClassName)}
        />
      </a>
    </li>
  );
}

export { SnsItem };
