import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type NavigationReducerState = {
  daoListOpen: boolean;
};
export const initialState: NavigationReducerState = {
  daoListOpen: false,
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setDaoListOpen: (
      state: NavigationReducerState,
      action: PayloadAction<boolean>,
    ) => {
      state.daoListOpen = action.payload;
    },
  },
});

export const { setDaoListOpen } = navigationSlice.actions;

export default navigationSlice.reducer;
