import { BigNumberish } from "ethers";
import { formatUnits } from "ethers/lib/utils";

import { toTokenDisplayPriceFromEth } from "~/_utils/converter/toTokenDisplayPriceFromEth";

export const toTokenDisplayPrice = ({
  token,
  displayDecimals = 4,
  average = true,
}: {
  token: { amount: BigNumberish; decimals: number };
  displayDecimals?: number;
  average?: boolean;
}) => {
  const { amount, decimals } = token;
  if (typeof amount === "string") {
    if (amount.indexOf(".") >= 0) {
      throw new Error(
        "TokenAmount must be BigNumberish, but have float point.",
      );
    }
  }

  return toTokenDisplayPriceFromEth({
    eth: formatUnits(amount, decimals),
    displayDecimals,
    average,
  });
};
