export type TToResizeUrlParams = {
  url: string;
  width?: number | `${number}`;
  qualityMultiplier?: number;
  lastUpdatedTime?: string;
};

export const toResizeUrl = ({
  url,
  width,
  qualityMultiplier = 1,
  lastUpdatedTime,
}: TToResizeUrlParams) => {
  return `${process.env.REACT_APP_API_RESIZE_MEDIA_BASE_URL}?url=${encodeURI(
    url,
  )}&width=${Math.ceil(Number(width) * qualityMultiplier)}${
    lastUpdatedTime ? `&timeLastUpdated=${+new Date(lastUpdatedTime)}` : ""
  }`;
};
