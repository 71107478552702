import { atom } from "jotai";

import { EBroadcast } from "../../_enums/EBroadcast";
import { atomWithBroadcast } from "../atomWithBroadcast";

type BadgeCount = {
  notificationUnread: number;
  dmUnread: number;
  eventTs: number;
};

const badgeCountAtom = atomWithBroadcast<{
  [daoId: string]: BadgeCount;
}>(EBroadcast.Badge, {});

/**
 * badge 적용을 순서대로 적용
 */
let pending = Promise.resolve();

/**
 * badge count 업데이트
 */
const writeBadgeCountAtom = atom(
  null,
  (
    get,
    set,
    update: {
      daoId: string;
      badgeCount: BadgeCount;
    },
  ) => {
    pending = pending.then(() => {
      const badgeCount = get(badgeCountAtom);
      const daoBadge = badgeCount[update.daoId];

      if (daoBadge != null && daoBadge.eventTs >= update.badgeCount.eventTs) {
        return;
      }

      set(badgeCountAtom, {
        ...badgeCount,
        [update.daoId]: update.badgeCount,
      });
    });
  },
);

/**
 * badge count 전체 업데이트
 * */
const writeAllBadgeCountAtom = atom(
  null,
  (
    get,
    set,
    update: {
      [daoId: string]: BadgeCount;
    },
  ) => {
    set(badgeCountAtom, update);
  },
);

/**
 * badge count dm 값 변경
 */
const writeIncreaseDmBadgeCountAtom = atom(
  null,
  (
    get,
    set,
    update: {
      daoId: string;
      count: number;
      eventTs: number;
    },
  ) => {
    pending = pending.then(() => {
      const badgeCount = get(badgeCountAtom);
      const daoBadge = badgeCount[update.daoId];

      if (daoBadge != null && daoBadge.eventTs >= update.eventTs) {
        return;
      }

      set(badgeCountAtom, {
        ...badgeCount,
        [update.daoId]: {
          notificationUnread: badgeCount[update.daoId]?.notificationUnread ?? 0,
          dmUnread: Math.max((daoBadge?.dmUnread ?? 0) + update.count, 0),
          eventTs: update.eventTs,
        },
      });
    });
  },
);

/**
 * badge count notification 값 세팅
 * notification 개수는 안읽으면 데이터가 머지되어 지정된 값으로 세팅
 */
const writeNotificationBadgeCountAtom = atom(
  null,
  (
    get,
    set,
    update: {
      daoId: string;
      notificationUnread: number;
    },
  ) => {
    pending = pending.then(() => {
      const badgeCount = get(badgeCountAtom);
      const daoBadge = badgeCount[update.daoId] ?? {
        notificationUnread: 0,
        dmUnread: 0,
        eventTs: Date.now(),
      };

      set(badgeCountAtom, {
        ...badgeCount,
        [update.daoId]: {
          notificationUnread: update.notificationUnread,
          dmUnread: daoBadge.dmUnread,
          /**
           * notification 값은 지정된 값으로 오고 있어 보관하고 있는 값에 +1
           * 정확하게 비교하지 않음
           */
          eventTs: daoBadge.eventTs,
        },
      });
    });
  },
);

export {
  badgeCountAtom,
  writeBadgeCountAtom,
  writeAllBadgeCountAtom,
  writeIncreaseDmBadgeCountAtom,
  writeNotificationBadgeCountAtom,
};
