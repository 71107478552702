import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { Loading } from "~/modules/Common/Icons";

export const ConnectingButton = () => {
  const { t } = useTranslation([ENameSpace.Common]);

  return (
    <button
      type="button"
      disabled
      className="flex h-full w-full items-center justify-center gap-x-2 rounded-full bg-ciety-lg px-10"
    >
      <Loading className="h-6 w-6 animate-spin" />
      <span className="typo-m1 pt-[0.1rem] text-ciety-dk">
        {t(`${ENameSpace.Common}:TokenGating.Connecting`)}
      </span>
    </button>
  );
};
