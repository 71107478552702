import React, { createContext, useContext } from "react";
import {
  Location,
  NavigateFunction,
  Params,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { getUseContextError } from "~/utils/converter";

const LocationContext = createContext<Location | null>(null);
export const ParamsContext = createContext<Params<string> | null>(null);
const NavigateContext = createContext<NavigateFunction | null>(null);

export function ReactRouterProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <LocationContext.Provider value={location}>
      <NavigateContext.Provider value={navigate}>
        {children}
      </NavigateContext.Provider>
    </LocationContext.Provider>
  );
}

export const usePureLocation = () => {
  const locationContext = useContext(LocationContext);
  if (!locationContext)
    throw new Error(getUseContextError("PureLocation", "Location"));

  return locationContext;
};

export const usePureParams = function <
  ParamsOrKey extends string | Record<string, string | undefined> = string,
>(params?: {
  safe: boolean;
}): Readonly<
  [ParamsOrKey] extends [string] ? Params<ParamsOrKey> : Partial<ParamsOrKey>
> {
  const paramsContext = useContext(ParamsContext);

  if (!paramsContext && (!params || !params.safe))
    throw new Error(getUseContextError("PureParams", "Params"));

  return paramsContext as Readonly<
    [ParamsOrKey] extends [string] ? Params<ParamsOrKey> : Partial<ParamsOrKey>
  >;
};

export const usePureNavigate = () => {
  const navigateContext = useContext(NavigateContext);
  if (!navigateContext)
    throw new Error(getUseContextError("PureNavigate", "Navigate"));

  return navigateContext;
};
