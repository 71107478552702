import { providers as multicallProviders } from "@0xsequence/multicall";
import { BaseProvider } from "@ethersproject/providers";
import { MetaMaskInpageProvider } from "@metamask/providers";
import { ethers } from "ethers";

import { MarketSupportedChain } from "~/api/dto";
import {
  ChainToEthersNetworkName,
  PUBLIC_RPC_ENDPOINT,
} from "~/modules/Market/constants/provider";
import { checkConnectedChain } from "~/modules/Market/utils/blockchain";
import {
  convertChainNameToDecChainId,
  convertChainNameToDisplayName,
} from "~/modules/Market/utils/conversions";

export const getMetamaskProvider = () => {
  const ethereum = window.ethereum;
  if (!ethereum) {
    throw new Error("No MetaMask wallet. Please install the app.");
  } else {
    const ethereumProviders = ethereum.providers;
    if (Array.isArray(ethereumProviders)) {
      const metamaskProvider = ethereumProviders.find(
        (provider) => provider?.isMetaMask,
      );
      if (metamaskProvider != null) {
        return {
          metamaskEthereum: metamaskProvider as MetaMaskInpageProvider,
          provider: new ethers.providers.Web3Provider(metamaskProvider),
        };
      } else {
        throw new Error("Not found MetaMask wallet. Please install the app.");
      }
    } else {
      if (ethereum.isMetaMask) {
        return {
          metamaskEthereum: ethereum as MetaMaskInpageProvider,
          provider: new ethers.providers.Web3Provider(ethereum),
        };
      } else {
        throw new Error("Not found MetaMask wallet. Please install the app.");
      }
    }
  }
};

export const wrapToMulticallProvider = (provider: BaseProvider) =>
  new multicallProviders.MulticallProvider(provider);

export const getMulticallProvider = ({
  selectedChain,
}: {
  selectedChain?: MarketSupportedChain;
}) => {
  if (selectedChain) {
    return prepareMetamask({ selectedChain }).then(wrapToMulticallProvider);
  }

  return new multicallProviders.MulticallProvider(
    getMetamaskProvider().provider,
  );
};

export const prepareMetamask = async ({
  selectedChain,
  userAddress,
}: {
  selectedChain: MarketSupportedChain;
  userAddress?: string | null;
}) => {
  // Make sure user is connected to the correct chain
  //  - Request Login if logged out
  //  - Inject if metamask has no chain information
  //  - Request switching if connected to a different chain
  if (
    !(await checkConnectedChain({
      userAddress,
      targetDesChainId: convertChainNameToDecChainId({
        chainName: selectedChain,
      }),
      errorCases: {
        addChain: `Please add ${convertChainNameToDisplayName({
          chainName: selectedChain,
        })} to connection.`,
        rejectSwitch: `Please switch into ${convertChainNameToDisplayName({
          chainName: selectedChain,
        })}`,
      },
    }))
  ) {
    throw new Error("Connection to wrong chain");
  } else {
    const { provider } = getMetamaskProvider();
    return provider;
  }
};

export const getPublicProvider = ({
  chain,
}: {
  chain: MarketSupportedChain;
}) => {
  return new ethers.providers.JsonRpcProvider(
    PUBLIC_RPC_ENDPOINT[chain],
    ChainToEthersNetworkName[chain],
  );
  // const provider = new ethers.providers.FallbackProvider(
  //   [
  //     {
  //       provider: new ethers.providers.JsonRpcProvider(
  //         PUBLIC_RPC_ENDPOINT[chain],
  //         ChainToEthersNetworkName[chain],
  //       ),
  //       priority: 1,
  //       weight: 1,
  //       stallTimeout: 2000,
  //     },
  //     {
  //       provider: new ethers.providers.JsonRpcProvider(
  //         ALCHEMY_RPC_ENDPOINT[chain],
  //         ChainToEthersNetworkName[chain],
  //       ),
  //       priority: 2,
  //       weight: 1,
  //       stallTimeout: 2000,
  //     },
  //   ],
  //   1, // quorum
  // );
  //
  // return provider;
};
