import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type {
  TDmMessageGroup,
  TDmMessageGroupWithMembers,
  TNewMessageCountsByCommunity,
  TNewMessageCountsByMessageGroup,
} from "~/modules/Posting/Dm/Common/types";
import { INITIAL_MESSAGE_STATE } from "~/modules/Posting/Message/shared/utils/createMessageSlice";

interface DmState {
  currentMessageGroup: TDmMessageGroup | null;
  messageGroupsRefetchTrigger: boolean;
  currentMessageGroupWithMembers: TDmMessageGroupWithMembers | null;
  messageGroups: Array<TDmMessageGroup> | null;
  newMessageCountsByCommunity: TNewMessageCountsByCommunity | null;
  newMessageCountsByMessageGroup: TNewMessageCountsByMessageGroup | null;
  messagesScrollBottom: boolean;
}

const initialDmState: DmState = {
  currentMessageGroup: null,
  messageGroupsRefetchTrigger: false,
  currentMessageGroupWithMembers: null,
  messageGroups: null,
  newMessageCountsByCommunity: null,
  newMessageCountsByMessageGroup: null,
  messagesScrollBottom: INITIAL_MESSAGE_STATE.initialScrollBottom,
};

const dmSlice = createSlice({
  name: "dm",
  initialState: initialDmState,
  reducers: {
    setCurrentMessageGroup: (
      state,
      action: PayloadAction<DmState["currentMessageGroup"]>,
    ) => {
      state.currentMessageGroup = action.payload;
    },
    refetchMessageGroups(state) {
      state.messageGroupsRefetchTrigger = !state.messageGroupsRefetchTrigger;
    },
    setCurrentMessageGroupIsMuted(
      state,
      action: PayloadAction<TDmMessageGroup["isMuted"]>,
    ) {
      if (!state.currentMessageGroup) {
        throw new Error("currentMessageGroup is undefined.");
      } else {
        state.currentMessageGroup.isMuted = action.payload;
      }
    },
    setCurrentMessageGroupWithMembers: (
      state,
      action: PayloadAction<DmState["currentMessageGroupWithMembers"]>,
    ) => {
      state.currentMessageGroupWithMembers = action.payload;
    },
    setMessageGroups: (
      state,
      action: PayloadAction<DmState["messageGroups"]>,
    ) => {
      state.messageGroups = action.payload;
    },
    setMessageGroupIsMuted(
      state,
      action: PayloadAction<Pick<TDmMessageGroup, "id" | "isMuted">>,
    ) {
      const nextMessageGroup = action.payload;
      state.messageGroups?.forEach((messageGroup) => {
        if (messageGroup.id === nextMessageGroup.id) {
          messageGroup.isMuted = nextMessageGroup.isMuted;
        }
      });
    },
    setNewMessageCountsByCommunity: (
      state,
      action: PayloadAction<DmState["newMessageCountsByCommunity"]>,
    ) => {
      state.newMessageCountsByCommunity = action.payload;
    },
    setNewMessageCountsByMessageGroup: (
      state,
      action: PayloadAction<DmState["newMessageCountsByMessageGroup"]>,
    ) => {
      state.newMessageCountsByMessageGroup = action.payload;
    },
    setMessagesScrollBottom: (
      state,
      action: PayloadAction<DmState["messagesScrollBottom"]>,
    ) => {
      state.messagesScrollBottom = action.payload;
    },
  },
});

export const dmActions = dmSlice.actions;

export default dmSlice.reducer;
