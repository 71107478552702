import { useValidation } from "~/hooks/useValidation";
import { Input } from "~/modules/Common/Form";
import { GreenButton } from "~/modules/Router/Auth/components/Buttons";
import { useCommunityJoin } from "~/modules/Router/Auth/hooks/useCommunityJoin";
import { DaoInfoType } from "~/modules/Router/Auth/types";
import { ENameSpace } from "~/i18n/@types/types";
import { NavigationCancelCloseButton } from "~/_components/Button/NavigationCancelCloseButton";
import { VALIDATION } from "~/_constants/validation";

type Props = {
  dao: DaoInfoType;
  onClose: () => void;
} & Pick<
  ReturnType<typeof useCommunityJoin>,
  "nickNameForm" | "handleClickJoinButton" | "joinError"
>;

export const DesktopCommunityNicknameModal = ({
  dao,
  nickNameForm,
  handleClickJoinButton,
  joinError,
  onClose,
}: Props) => {
  let error;
  if (joinError) {
    error = joinError;
  } else if (nickNameForm.nickname) {
    error = nickNameForm.formState.errors.nickname;
  } else {
    error = undefined;
  }
  const { memberNickname: nicknameValidation } = VALIDATION;
  const { generateTextValidation, t } = useValidation();

  return (
    <article className="fixed left-0 top-0 flex h-full w-full items-center justify-center bg-ciety-bk bg-opacity-80 backdrop-blur-[1.25rem]">
      <NavigationCancelCloseButton
        onClick={onClose}
        className="fixed right-5 top-5"
      />

      <div className={"flex w-[31.25rem] flex-col gap-y-[2.5rem]"}>
        <p
          className={
            "typo-headline-r-22 whitespace-pre-line text-center text-ciety-wh"
          }
        >
          {t(`${ENameSpace.Common}:CommunityJoin.NicknameModal.Title`, {
            title: dao.displayName,
          })}
        </p>
        <form
          className="flex min-h-[10.5rem] w-full flex-col justify-between gap-y-[0.5rem]"
          onSubmit={nickNameForm.handleSubmit(handleClickJoinButton)}
        >
          <Input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus={true}
            type="text"
            label={t(`${ENameSpace.Common}:CommunityJoin.NicknameModal.Label`)}
            placeholder={t(
              `${ENameSpace.Common}:CommunityJoin.NicknameModal.Form.Placeholder`,
            )}
            className="w-full"
            minLength={nicknameValidation.minLength}
            maxLength={nicknameValidation.maxLength}
            watch={nickNameForm.nickname}
            autoComplete="off"
            error={error}
            {...nickNameForm.register("nickname", {
              required: generateTextValidation({
                type: "required",
                name: t(
                  `${ENameSpace.Common}:CommunityJoin.NicknameModal.Label`,
                ),
              }),
              minLength: {
                value: nicknameValidation.minLength,
                message: generateTextValidation({
                  type: "min",
                  length: nicknameValidation.minLength,
                }),
              },
              maxLength: {
                value: nicknameValidation.maxLength,
                message: generateTextValidation({
                  type: "max",
                  length: nicknameValidation.maxLength,
                }),
              },
              pattern: {
                value: nicknameValidation.regExp,
                message: generateTextValidation({
                  type: "regexp",
                  allows: nicknameValidation.allows,
                }),
              },
            })}
          />
          <div className={`h-[3.5rem] w-full`}>
            <GreenButton
              type="submit"
              disabled={!nickNameForm.formState.isValid}
              className={"h-full w-full !rounded-[0.75rem]"}
            >
              {t(
                `${ENameSpace.Common}:CommunityJoin.NicknameModal.Form.Finish`,
              )}
            </GreenButton>
          </div>
        </form>
      </div>
    </article>
  );
};
