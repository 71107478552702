import { HotPostItem } from "~/_app/[dao]/home/components/mobile/HotPosts/HotPostItem";
import { MobileSectionContainer } from "~/_app/[dao]/home/components/mobile/MobileSectionContainer";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { SectionHeader } from "~/_app/[dao]/home/components/common/SectionHeader";
import { MoreButton } from "~/_app/[dao]/home/components/common/MoreButton";
import { EmptyHotPosts } from "~/_app/[dao]/home/components/mobile/HotPosts/EmptyHotPosts";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { getFeedScreenParams } from "~/_api/mobile/appScreenParams";
import { appNavigation } from "~/_api/mobile/toMobileNavigation";
import { useDaoSubdomainStore } from "~/_store/dao/useDaoSubdomainStore";
import { EChannelType } from "~/_enums/EChannelType";
import { assertIsDefined } from "~/_utils/assert";
import { DAO_HOME } from "~/_constants";

type TProps = {
  sectionId: string;
};

export function HotPosts({ sectionId }: TProps) {
  const { daoSubdomain } = useDaoSubdomainStore();
  assertIsDefined(daoSubdomain);
  const { getSection } = useDaoHomeStore();
  const { title, posts: hotPosts } = getSection(
    EDaoHomeSection.HotPosts,
    sectionId,
  );

  const isEmptyItem = hotPosts.length === 0;

  const handleMove = () => {
    appNavigation(({ push }) => {
      push(
        getFeedScreenParams({
          path: generateUrl({
            type: "hotPosts",
            subdomain: daoSubdomain,
          }),
        }),
      );
    });
  };

  return (
    <MobileSectionContainer className="home-hot-posts px-5 py-6">
      <div className="mb-5">
        <SectionHeader
          title={title}
          typoClass="typo-headline-m-20"
          className="w-[calc(100%-4.5rem)] truncate"
        >
          <MoreButton onClick={handleMove} />
        </SectionHeader>
      </div>
      {isEmptyItem ? (
        <EmptyHotPosts />
      ) : (
        hotPosts
          .slice(0, DAO_HOME.CONTENT_COUNT.MOBILE_HOT_POST)
          .map(({ channel, post }) => {
            return (
              <HotPostItem
                channel={{
                  id: channel.id,
                  displayName: channel.displayName,
                  type: channel.type as EChannelType,
                  state: channel.state,
                }}
                post={{
                  id: post.id,
                  title: post.title,
                  replyCount: "replyCount" in post ? post.replyCount : 0,
                  author: post.author,
                  createdAt: post.createdAt,
                  updatedAt: post.updatedAt,
                  viewCount: "viewCount" in post ? post.viewCount : 0,
                  attachments: "attachments" in post ? post.attachments : [],
                  heart: post.heart,
                }}
                key={post.id}
              />
            );
          })
      )}
    </MobileSectionContainer>
  );
}
