import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import { ELanguage, ENameSpace } from "~/i18n/@types/types";
import ChannelsEn from "./locales/en/channels_en.json";
import CommonEn from "./locales/en/common_en.json";
import EntranceEn from "./locales/en/entrance_en.json";
import ExploreEn from "./locales/en/explore_en.json";
import CommunitySettingsEn from "./locales/en/communitySettings_en.json";
import CreateEn from "./locales/en/create_en.json";
import DmEn from "./locales/en/dm_en.json";
import FindEn from "./locales/en/find_en.json";
import HomeEn from "./locales/en/home_en.json";
import SettingsEn from "./locales/en/settings_en.json";
import InviteEn from "./locales/en/invite_en.json";
import LoginEn from "./locales/en/login_en.json";
import MarketEn from "./locales/en/market_en.json";
import MerchEn from "./locales/en/merch_en.json";
import NotificationsEn from "./locales/en/notifications_en.json";
import SearchEn from "./locales/en/search_en.json";
import SpaceEn from "./locales/en/space_en.json";
import OAuthEn from "./locales/en/oauth_en.json";
import ChannelsKo from "./locales/ko/channels_ko.json";
import CommonKo from "./locales/ko/common_ko.json";
import ExploreKo from "./locales/ko/explore_ko.json";
import CommunitySettingsKo from "./locales/ko/communitySettings_ko.json";
import CreateKo from "./locales/ko/create_ko.json";
import DmKo from "./locales/ko/dm_ko.json";
import FindKo from "./locales/ko/find_ko.json";
import HomeKo from "./locales/ko/home_ko.json";
import InviteKo from "./locales/ko/invite_ko.json";
import LoginKo from "./locales/ko/login_ko.json";
import MarketKo from "./locales/ko/market_ko.json";
import MerchKo from "./locales/ko/merch_ko.json";
import NotificationsKo from "./locales/ko/notifications_ko.json";
import SearchKo from "./locales/ko/search_ko.json";
import SpaceKo from "./locales/ko/space_ko.json";
import SettingsKo from "./locales/ko/settings_ko.json";
import OAuthKo from "./locales/ko/oauth_ko.json";
import GoogleConnectKo from "./locales/ko/googleConnect_ko.json";
import GoogleConnectEn from "./locales/en/googleConnect_en.json";
import EntranceKo from "./locales/ko/entrance_ko.json";

export const languages = [ELanguage.Ko, ELanguage.En];
export const defaultNS = "common";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ["localStorage", "navigator"],
    },
    ns: defaultNS,
    defaultNS,
    supportedLngs: languages,
    fallbackLng: ELanguage.Ko,
    interpolation: {
      /** Not needed for react. */
      escapeValue: false,
    },
    resources: {
      ko: {
        [ENameSpace.Common]: CommonKo,
        [ENameSpace.Login]: LoginKo,
        [ENameSpace.Create]: CreateKo,
        [ENameSpace.Find]: FindKo,
        [ENameSpace.Notifications]: NotificationsKo,
        [ENameSpace.Search]: SearchKo,
        [ENameSpace.Merch]: MerchKo,
        [ENameSpace.Market]: MarketKo,
        [ENameSpace.Channels]: ChannelsKo,
        [ENameSpace.CommunitySettings]: CommunitySettingsKo,
        [ENameSpace.Space]: SpaceKo,
        [ENameSpace.Dm]: DmKo,
        [ENameSpace.Invite]: InviteKo,
        [ENameSpace.Home]: HomeKo,
        [ENameSpace.Settings]: SettingsKo,
        [ENameSpace.Oauth]: OAuthKo,
        [ENameSpace.GoogleConnect]: GoogleConnectKo,
        [ENameSpace.Entrance]: EntranceKo,
        [ENameSpace.Explore]: ExploreEn,
      },
      en: {
        [ENameSpace.Common]: CommonEn,
        [ENameSpace.Login]: LoginEn,
        [ENameSpace.Create]: CreateEn,
        [ENameSpace.Find]: FindEn,
        [ENameSpace.Notifications]: NotificationsEn,
        [ENameSpace.Search]: SearchEn,
        [ENameSpace.Merch]: MerchEn,
        [ENameSpace.Market]: MarketEn,
        [ENameSpace.Channels]: ChannelsEn,
        [ENameSpace.CommunitySettings]: CommunitySettingsEn,
        [ENameSpace.Space]: SpaceEn,
        [ENameSpace.Dm]: DmEn,
        [ENameSpace.Invite]: InviteEn,
        [ENameSpace.Home]: HomeEn,
        [ENameSpace.Settings]: SettingsEn,
        [ENameSpace.Oauth]: OAuthEn,
        [ENameSpace.GoogleConnect]: GoogleConnectEn,
        [ENameSpace.Entrance]: EntranceEn,
        [ENameSpace.Explore]: ExploreKo,
      },
    },
  });

export default i18n;
