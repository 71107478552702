import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import { MarketSupportedChain } from "~/api/dto";
import { ContractData, Item } from "~/modules/BlockChain/Order/types";
import { isPolygonFriendlyDao } from "~/modules/Market/constants/polygonFriendlyDaos";
import { inventorySlice } from "~/store/inventory/slice";
import { InventoryState } from "~/store/inventory/types";

export const selectionController = {
  reset: ((state, { payload: { subdomain } }) => {
    state.selected.chain = isPolygonFriendlyDao({ subdomain })
      ? "PolygonMainnet"
      : "EthereumMainnet";
    state.selected.contract = null;
    state.selected.item = null;
  }) as InventoryReducer<{ subdomain?: string }>,

  // 유저가 선택한 Chain 상태변수 업데이트
  updateSelectedChain: ((state, { payload }) => {
    state.selected.chain = payload;
  }) as InventoryReducer<MarketSupportedChain>,

  // 유저가 선택한 Contract 상태변수 업데이트
  updateSelectedContract: ((state, { payload }) => {
    state.selected.contract = payload;
  }) as InventoryReducer<ContractData>,

  // 유저가 선택한 NFT item 상태변수 업데이트
  updateSelectedItem: ((state, { payload }) => {
    state.selected.item = payload;
  }) as InventoryReducer<Item>,

  // 유저가 선택한 컨트랙트, 아이템을 초기화 합니다.
  resetSelected: ((state, { payload: resetTarget }) => {
    if (!resetTarget || Object.keys(resetTarget).length === 0) {
      state.selected.item = null;
      state.selected.contract = null;
    } else {
      if (resetTarget.item) {
        state.selected.item = null;
      } else if (resetTarget.contract) {
        state.selected.contract = null;
      }
    }
  }) as InventoryReducer<{ item?: boolean; contract?: boolean } | undefined>,
};

export const pageKeyController = {
  resetPageKey: ((state) => {
    state.pageKey = inventorySlice.getInitialState().pageKey;
  }) as InventoryReducer,
  updateContractPageKey: ((state, { payload: { chain, newKey } }) => {
    state.pageKey.contract[chain] = newKey;
  }) as InventoryReducer<{
    chain: MarketSupportedChain;
    newKey: string | null;
  }>,
  updateItemPageKey: ((state, { payload: { contractAddress, newKey } }) => {
    state.pageKey.item[contractAddress] = newKey;
  }) as InventoryReducer<{
    contractAddress: string;
    newKey: string | null;
  }>,
};

export const scrollController = {
  resetScroll: ((state) => {
    state.scroll = inventorySlice.getInitialState().scroll;
  }) as InventoryReducer,
  updateContractScroll: ((state, { payload: { chain, scrollTo } }) => {
    state.scroll.contract[chain] = scrollTo;
  }) as InventoryReducer<{
    chain: MarketSupportedChain;
    scrollTo: number;
  }>,
  updateItemScroll: ((state, { payload: { contractAddress, scrollTo } }) => {
    state.scroll.item[contractAddress] = scrollTo;
  }) as InventoryReducer<{
    contractAddress: string;
    scrollTo: number;
  }>,
};

type InventoryReducer<PayloadType = void> = CaseReducer<
  InventoryState,
  PayloadAction<PayloadType>
>;
