import { MoreButton } from "~/_app/[dao]/home/components/common/MoreButton";
import { MobileSectionContainer } from "~/_app/[dao]/home/components/mobile/MobileSectionContainer";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { Swiper, SwiperSlide } from "swiper/react";
import { remToPx } from "~/_utils/converter/remConverter";
import {
  getCurrencySymbolByNationCode,
  getLocaleNumber,
} from "~/_utils/nation/getCurrencySymbolByNationCode";
import { SectionHeader } from "~/_app/[dao]/home/components/common/SectionHeader";
import { EmptyMerchStore } from "~/_app/[dao]/home/components/mobile/MerchStore/EmptyMerchStore";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { getMerchStoreScreenParams } from "~/_api/mobile/appScreenParams";
import { appNavigation } from "~/_api/mobile/toMobileNavigation";
import { useDaoSubdomainStore } from "~/_store/dao/useDaoSubdomainStore";
import { sanitizeUrl } from "~/_utils/converter/sanitizeUrl";
import { assertIsDefined } from "~/_utils/assert";
import { DAO_HOME } from "~/_constants";
import { useState } from "react";
import { filterMerchItemsByUserLanguage } from "~/_components/Merch/utils/filterMerchItemsByUserLanguage";

type TProps = {
  sectionId: string;
};

export function MerchStore({ sectionId }: TProps) {
  const [isLoading, setIsLoading] = useState(true);
  const { getSection } = useDaoHomeStore();
  const { daoSubdomain } = useDaoSubdomainStore();
  assertIsDefined(daoSubdomain);
  const { title, items } = getSection(EDaoHomeSection.MerchStore, sectionId);

  const isEmptyItem = items.length === 0;

  const handleMoveMerchStore = () => {
    appNavigation(({ push }) => {
      push(
        getMerchStoreScreenParams({
          path: generateUrl({
            type: "merch",
            subdomain: daoSubdomain,
          }),
        }),
      );
    });
  };

  const handleMoveMerchStoreItem = ({ link }: { link: string }) => {
    window.open(sanitizeUrl(link));
  };

  return (
    <MobileSectionContainer className="home-merchstore py-6">
      <div className="px-5">
        <SectionHeader
          title={title}
          typoClass="typo-headline-m-20"
          className="w-[calc(100%-4.5rem)] truncate"
        >
          <MoreButton onClick={handleMoveMerchStore} />
        </SectionHeader>
      </div>

      <div className="mt-4">
        {isEmptyItem ? (
          <div className="px-5">
            <EmptyMerchStore />
          </div>
        ) : (
          <Swiper
            slidesPerView={3}
            slidesPerGroupAuto={true}
            spaceBetween={remToPx(0.75)}
            slidesOffsetBefore={remToPx(1.25)}
            slidesOffsetAfter={remToPx(1.25)}
            onInit={() => setIsLoading(false)}
          >
            {filterMerchItemsByUserLanguage(items)
              .slice(0, DAO_HOME.CONTENT_COUNT.MOBILE_MERCH_STORE)
              .map((item) => {
                return (
                  <button
                    type="button"
                    key={item.id}
                    className="block w-[8.75rem]"
                    onClick={() =>
                      handleMoveMerchStoreItem({ link: item.link })
                    }
                  >
                    <SwiperSlide
                      key={item.id}
                      style={{ opacity: isLoading ? "0" : "1" }}
                    >
                      <div className="isolate flex aspect-[1/1] overflow-hidden rounded-lg">
                        <img
                          src={item.image}
                          className="h-full w-full object-cover"
                          alt=""
                        />
                      </div>
                      <div className="mt-3 flex flex-col">
                        <div className="flex flex-col">
                          <span className="typo-r3 truncate text-ciety-gy">
                            {item.base_product_name}
                          </span>
                          <span className="typo-m2 truncate text-ciety-wh">
                            {item.name === ""
                              ? item.base_product_name
                              : item.name}
                          </span>
                        </div>
                        <span className="typo-m2 mt-[0.25rem] truncate text-ciety-lg">
                          {getCurrencySymbolByNationCode(item.nationCode)}
                          {` ${getLocaleNumber(item.price, item.nationCode)}`}
                        </span>
                      </div>
                    </SwiperSlide>
                  </button>
                );
              })}
          </Swiper>
        )}
      </div>
    </MobileSectionContainer>
  );
}
