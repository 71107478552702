import { atom } from "jotai";
import { TInitialPipVideoPlayer } from "~/_types/TInitialPipVideoPlayer";
import { assertIsDefined } from "~/_utils/assert";

type TPipVideo = {
  id: string;
  initialPlayer: TInitialPipVideoPlayer;
  playing: boolean;
  video: {
    url: string;
    id: string;
    metadata: {
      duration: number;
      fileSize: number;
      height: number;
      rotation: number;
      width: number;
    };
  };
  title: string;
};

export const pipVideoAtom = atom<TPipVideo | null>(null);

export const writeUpdatePipVideoPlaying = atom(
  null,
  (get, set, update: boolean) => {
    const prev = get(pipVideoAtom);
    assertIsDefined(prev);

    set(pipVideoAtom, {
      ...prev,
      playing: update,
    });
  },
);
