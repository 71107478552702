import { useAtom } from "jotai";
import { NEED_HYDRATION } from "~/_constants";
import { EAtomName } from "~/_enums/EAtomName";

import { getHydrationErrorMessage } from "~/_utils/messageGetter/getHydrationErrorMessage";
import { memberAtom } from "~/_model/member/memberAtom";
import { ERolePolicyCode } from "~/_enums/ERolePolicyCode";

function useMemberStore() {
  const [member, setMember] = useAtom(memberAtom);

  if (member === NEED_HYDRATION) {
    throw new Error(getHydrationErrorMessage(EAtomName.Member));
  }

  return {
    member,
    setMember,
    isCommunityMember: member != null,
    isAdmin: member
      ? member.roles
          .flatMap((role) => role.policyCodeArray)
          .includes(ERolePolicyCode.Admin)
      : false,
  };
}
export { useMemberStore };
