import { BytesLike, ethers } from "ethers";
import { defaultAbiCoder } from "ethers/lib/utils";

import { GetUsersUseridAccountsControllerSuccessOutputDtoDataEvmCompatibleAccount } from "~/api/apiRouteTypes/UsersRoute";

export const isAddressMatch = (a: string, b: string) => {
  return a.toLowerCase() === b.toLowerCase();
};

export const decodeAddressFrom32Bytes = (bytes: BytesLike) => {
  try {
    return defaultAbiCoder.decode(["address"], bytes)[0];
  } catch (e) {
    return ADDRESS_ZERO;
  }
};

export const ellipseAddress = (address: string, limit?: number) =>
  // + 2 due to "0x"
  [
    address.slice(0, (limit ?? 6) + 2),
    "...",
    address.slice(-(limit ?? 6)),
  ].join("");

export const getEvmAccountAddress = (
  accounts?: GetUsersUseridAccountsControllerSuccessOutputDtoDataEvmCompatibleAccount[],
) => {
  /* Chain 정보를 받게 되면 chain 에 맞는 account address 를 리턴하도록 고도화 */
  /* 우선은 첫번째 evm account 를 wallet address 로 사용하는 것으로 가정함 */

  if (!accounts || accounts.length === 0) {
    return null;
  }
  return accounts[0].address;
};

export const ADDRESS_ZERO = ethers.constants.AddressZero;
