import React, { forwardRef, useMemo } from "react";
import type { FieldError } from "react-hook-form";

import { Icon } from "~/modules/Common/Icons";
import { LengthCheck } from "./LengthCheck";
import { cn } from "~/_utils/converter/cn";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement> & {
    label?: string;
    error?: FieldError | { message: string } | undefined;
    watch?: string | null;
    containerClassName?: string;
  },
  HTMLInputElement
>;

const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ error, label, className, containerClassName, ...props }, ref) => {
    const defaultStyle = useMemo(
      () =>
        `typo-r1 placeholder:typo-r1 h-12 w-full px-4 rounded-[0.75rem] bg-ciety-dk-95 text-ciety-wh placeholder:text-ciety-dk-60 ${
          error
            ? "border border-ciety-rd"
            : "border border-ciety-dk-95 hover:border hover:border-ciety-dk-60 focus:border focus:border-ciety-lg"
        }`,
      [error],
    );

    return (
      <label
        className={cn("flex flex-col gap-y-2", containerClassName ?? "w-full")}
      >
        {label && (
          <div className="typo-r2 flex w-full justify-between">
            <span className="text-ciety-dk-60">{label}</span>
            {props.maxLength && (
              <LengthCheck
                watch={props.watch}
                minLength={props.minLength}
                maxLength={props.maxLength}
              />
            )}
          </div>
        )}
        <input
          ref={ref}
          type="text"
          {...props}
          className={`${defaultStyle} ${className}`}
          minLength={undefined}
          maxLength={undefined}
        />
        {error && (
          <div className="break-word flex w-full items-center gap-x-1 text-ciety-rd">
            <Icon iconType="Alert Sign Circle" className="h-4 w-4" />
            <span className="typo-r2">{error.message}</span>
          </div>
        )}
      </label>
    );
  },
);

export { Input };
