import { getFeedScreenParams } from "~/_api/mobile/appScreenParams";
import { appNavigation } from "~/_api/mobile/toMobileNavigation";
import { MoreButton } from "~/_app/[dao]/home/components/common/MoreButton";
import { SectionHeader } from "~/_app/[dao]/home/components/common/SectionHeader";
import { MobileSectionContainer } from "~/_app/[dao]/home/components/mobile/MobileSectionContainer";
import { EmptySns } from "~/_app/[dao]/home/components/mobile/Sns/EmptySns";
import { SnsDetailType } from "~/_app/[dao]/home/components/mobile/Sns/SnsDetailType";
import { SnsSummaryType } from "~/_app/[dao]/home/components/mobile/Sns/SnsSummaryType";
import { SnsThumbnailType } from "~/_app/[dao]/home/components/mobile/Sns/SnsThumbnailType";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { useDaoSubdomainStore } from "~/_store/dao/useDaoSubdomainStore";
import { EChannelLayout } from "~/_types/daoHome/TDaoHomeSectionSns";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { assertIsDefined } from "~/_utils/assert";

function SnsContent({ sectionId }: { sectionId: string }) {
  const { getSection } = useDaoHomeStore();

  const { posts } = getSection(EDaoHomeSection.Sns, sectionId);
  const isEmptyItem = posts.length === 0;

  const sns = getSection(EDaoHomeSection.Sns, sectionId);

  if (isEmptyItem) {
    return <EmptySns sectionId={sectionId} />;
  } else if (sns.layout === EChannelLayout.Summary) {
    return <SnsSummaryType sectionId={sectionId} />;
  } else if (sns.layout === EChannelLayout.Detail) {
    return <SnsDetailType sectionId={sectionId} />;
  } else if (sns.layout === EChannelLayout.Thumbnail) {
    return <SnsThumbnailType sectionId={sectionId} />;
  } else {
    throw new Error(`Does not exist sns layout: ${sns.layout}`);
  }
}

type TProps = {
  sectionId: string;
};

function Sns({ sectionId }: TProps) {
  const { getSection } = useDaoHomeStore();
  const { daoSubdomain } = useDaoSubdomainStore();
  assertIsDefined(daoSubdomain);

  const { title, channel } = getSection(EDaoHomeSection.Sns, sectionId);

  const handleMoveSns = () => {
    appNavigation(({ push }) => {
      push(
        getFeedScreenParams({
          path: generateUrl({
            type: "feed",
            subdomain: daoSubdomain,
            channelId: channel.id,
            channelType: channel.type,
          }),
        }),
      );
    });
  };

  return (
    <MobileSectionContainer className="home-sns-summary py-6">
      <div className="px-5">
        <SectionHeader
          title={title}
          typoClass="typo-headline-m-20"
          className="w-[calc(100%-4.5rem)] truncate"
        >
          <MoreButton onClick={handleMoveSns} />
        </SectionHeader>
      </div>
      <div className="pt-1.5">
        <SnsContent sectionId={sectionId} />
      </div>
    </MobileSectionContainer>
  );
}

export { Sns };
