import { useHydrateAtoms } from "jotai/utils";
import { PropsWithChildren, useEffect, useLayoutEffect } from "react";
import { useMemberStore } from "~/_store/member/useMemberStore";
import { daoAtom } from "../../../../_model/dao/daoAtom";
import { daoSubdomainAtom } from "../../../../_model/dao/daoSubdomainAtom";
import { memberAtom } from "../../../../_model/member/memberAtom";
import { useDaoStore } from "../../../../_store/dao/useDaoStore";
import { useDaoSubdomainStore } from "../../../../_store/dao/useDaoSubdomainStore";
import { TDao } from "../../../../_types/TDao";
import { TMember } from "../../../../_types/TMember";
import {
  AuthDispatch,
  AuthDispatchContext,
} from "../contexts/AuthDispatchContext";
import { AuthState, AuthStateContext } from "../contexts/AuthStateContext";

export function MainModule({
  children,
  ...params
}: PropsWithChildren<{
  dao: TDao | null;
  member: TMember | null;
  subdomain: string | null;
  authState: AuthState;
  authDispatch: AuthDispatch;
}>) {
  useHydrateAtoms([
    [daoAtom, params.dao],
    [memberAtom, params.member],
    [daoSubdomainAtom, params.subdomain],
  ]);
  const { setDao } = useDaoStore();
  const { setMember } = useMemberStore();
  const { setDaoSubdomain } = useDaoSubdomainStore();

  useLayoutEffect(() => {
    setDao(params.dao);
    setDaoSubdomain(params.dao?.subdomain ?? null);
  }, [params.dao, setDao, setDaoSubdomain]);

  useEffect(() => {
    setMember(params.member);
  }, [params.member, setMember]);

  return (
    <AuthDispatchContext.Provider value={params.authDispatch}>
      <AuthStateContext.Provider value={params.authState}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
}
