import { createApi } from "@reduxjs/toolkit/query/react";

import { getBaseQuery } from "~/api/getBaseQuery";
import { GetMember } from "~/api/Member/types";

export const memberApi = createApi({
  reducerPath: "memberApi",
  baseQuery: getBaseQuery({}),
  tagTypes: ["Member"],
  keepUnusedDataFor: 30,
  endpoints: (builder) => ({
    /* 멤버 정보 조회 */
    // https://api-dev.ciety.dev/api#/members/GetMembersMemberidController_handle
    getMember: builder.query<GetMember["data"], GetMember["request"]>({
      query: ({ memberId }) => ({
        url: `members/${memberId}`,
        method: "GET",
      }),
      providesTags: (result, error, { memberId }) => {
        if (!result || error) {
          return [];
        }
        return ["Member", { type: "Member", id: memberId }];
      },
      transformResponse: (response: GetMember["response"]) => {
        return response.data;
      },
    }),
  }),
});
