import { useAtom } from "jotai";

import { userAtom } from "~/_model/users/userAtom";

export const useUserStore = () => {
  const [user, setUser] = useAtom(userAtom);
  // @TODO: SSR에서 need_hydration 처리 필요함
  // if (user === NEED_HYDRATION) {
  //   throw new Error(getHydrationErrorMessage(EAtomName.User));
  // }

  return { user, setUser };
};
