import { Icon } from "~/modules/Common/Icons";
import { ENameSpace } from "~/i18n/@types/types";
import { useTranslation } from "~/hooks/useTranslation";
import { ErrorLayout } from "~/_components/Fallback/ErrorLayout";

function Oops() {
  const { t } = useTranslation([ENameSpace.Common]);

  return (
    <ErrorLayout
      renderIcon={
        <Icon
          className="mb-5 h-[7.75rem] w-[17.763rem]"
          iconType="Error-oops"
        />
      }
      renderSubText={
        <>
          <span>{t(`${ENameSpace.Common}:Error.*.SubText1`)}</span>
          <span>{t(`${ENameSpace.Common}:Error.*.SubText2`)}</span>
        </>
      }
      title={t(`${ENameSpace.Common}:Error.*.MainText`)}
    >
      <button
        type="button"
        className="typo-m1 flex h-10 w-[9.625rem] items-center justify-center gap-x-1 rounded-full bg-ciety-wh text-ciety-dk"
        onClick={() => location.reload()}
      >
        {t(`${ENameSpace.Common}:Error.*.ButtonText`)}
      </button>
    </ErrorLayout>
  );
}

export { Oops };
