import { defaultAbiCoder } from "ethers/lib/utils";

import { MarketSupportedChain } from "~/api/dto";
import { TokenType } from "~/modules/Market/MarketItemDetail/types";
import {
  decodeAddressFrom32Bytes,
  isAddressMatch,
} from "~/modules/Market/utils/address";
import { decodeUint256From32Bytes } from "~/modules/Market/utils/decode";
import { getPublicProvider } from "~/modules/Market/utils/provider";

type Props = {
  chain: MarketSupportedChain;
  tokenType: TokenType;
  ownerAddress: string;
  tokenId: string;
  contractAddress: string;
};

export const getItemBalanceToRPC = async ({
  chain,
  tokenType,
  ownerAddress,
  tokenId,
  contractAddress,
}: Props) => {
  const provider = getPublicProvider({ chain });

  let sig: string;
  let paramEncoded: string;

  switch (tokenType) {
    case "ERC721": {
      sig = "0x6352211e"; // ownerOf(uint256)
      paramEncoded = defaultAbiCoder.encode(["uint256"], [tokenId]);
      return provider
        .call({
          to: contractAddress,
          data: sig + paramEncoded.substring(2),
        })
        .then((result) =>
          Number(
            isAddressMatch(decodeAddressFrom32Bytes(result), ownerAddress),
          ),
        );
    }
    case "ERC1155": {
      sig = "0x00fdd58e"; // balanceOf(address,uint256)
      paramEncoded = defaultAbiCoder.encode(
        ["address", "uint256"],
        [ownerAddress, tokenId],
      );
      return provider
        .call({
          to: contractAddress,
          data: sig + paramEncoded.substring(2),
        })
        .then((result) => decodeUint256From32Bytes(result).toNumber());
    }
  }
};
