import { TPostSummary } from "~/_types/daoHome/TPostSummary";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { EChannelType } from "~/_enums/EChannelType";
import { EChannelVisibility } from "~/_enums/EChannelVisibility";
import { EChannelState } from "~/_enums/EChannelState";
import { TChannelMemberPolicy } from "~/_types/TChannelMemberPolicy";

export const EChannelLayout = {
  Summary: "summary",
  Detail: "detail",
  Thumbnail: "thumbnail",
} as const;
export type EChannelLayout = typeof EChannelLayout[keyof typeof EChannelLayout];

export type TDaoHomeSectionSns = {
  type: typeof EDaoHomeSection.Sns;
  id: string;
  title: string | null;
  layout: EChannelLayout;
  channel: {
    id: string;
    displayName: string;
    description: string;
    state: EChannelState;
    type: typeof EChannelType.Sns;
    visibility: EChannelVisibility;
  };
  channelMemberPolicy: TChannelMemberPolicy;
  posts: TPostSummary[];
};
