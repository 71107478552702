import { MarketSupportedChain } from "~/api/dto";
import { TMarketComment } from "~/api/Market/getMarketComments";
import {
  GetMarketOrdersForItem,
  GetMarketProposalsForItem,
  GetRecommendContracts,
  GetTopMembers,
  MarketItemWithFloorPrice,
} from "~/api/Market/types";
import { ArrayEl } from "~/utils/types";

export type ChainMetadata = {
  chainName: MarketSupportedChain;
  displayName: string;
  desChainId: CietySupportedChainIdsOutput;
  currencyName: string;
  decimals: number;
  viewDecimals: number;
  isTestNet: boolean;
};

export const marketSupportedChain = (isPolygonPriority?: boolean) =>
  isPolygonPriority
    ? (["PolygonMainnet", "Mumbai", "EthereumMainnet", "Goerli"] as const)
    : (["EthereumMainnet", "Goerli", "PolygonMainnet", "Mumbai"] as const);

export const testChainsForMarketplace = ["Goerli", "Mumbai"] as const;

export type TestChainsForMarketplace = ArrayEl<typeof testChainsForMarketplace>;

export type CietySupportedChainIdsOutput = "1" | "5" | "137" | "80001";

export type MarketSupportedERCInterface = "ERC20" | "ERC721" | "ERC1155";

export type CommentCallback = (comment: TMarketComment) => void;

export type TMarketItemIdentifier = Pick<
  MarketItemWithFloorPrice["nft"],
  "chain" | "tokenId" | "contractAddress"
>;

export type TopMembersOutput = GetTopMembers["data"]["members"];

export type MarketOrder = ArrayEl<GetMarketOrdersForItem["data"]>;
export type MarketProposal = ArrayEl<GetMarketProposalsForItem["data"]>;
export type OrderOrProposal = MarketOrder | MarketProposal;

export type RecommendContract = ArrayEl<
  GetRecommendContracts["data"]["daoRecommendedContractList"]
>;

export type SocketUpdatedDataProps = {
  commentId: string;
};

export type SocketNewDataProps = {
  chainAddressTokenId: string;
  recentCommentId: string;
};

export type MarketplaceDaoNftSocket = {
  subscribeType: "MARKETPLACE_DAO_NFT_SUB";
  unsubscribeType: "MARKETPLACE_DAO_NFT_UNSUB";
  subscribePayloadProps: {
    subscriptionId: string;
    daoId: string;
    chainAddressTokenId: string;
  };
  listeners: {
    MARKETPLACE_DAO_NFT_NEW_COMMENT: Listener<SocketNewDataProps>;
    MARKETPLACE_DAO_NFT_UPDATE_COMMENT: Listener<SocketUpdatedDataProps>;
    MARKETPLACE_DAO_NFT_DELETE_COMMENT: Listener<SocketUpdatedDataProps>;
  };
};
