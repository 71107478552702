import { ETextValidationAllow } from "~/_enums/ETextValidationAllow";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { capitalizeFirstLetter } from "~/utils/converter";

type RequiredValidation = {
  type: "required";
  name: string;
};

type LengthValidation = {
  type: "max" | "min" | "line";
  length: number;
};

type RegExpValidation = {
  type: "regexp";
  allows: Array<ETextValidationAllow>;
};

type DuplicatedValidation = {
  type: "duplicate";
  name: string;
};

type DefaultValidation = {
  type: "default";
  name: string;
};

/**
 * @description
 * - generateTextValidation 함수의 사용이 필요할 때만 훅을 사용해주세요.
 * - 기본적으로 ENamespace에 common이 포함 되니 작성하실 때 특정 네임스페이스를 꼭 붙여서 사용해주세요 ex) login:Root.Title
 * */
export const useValidation = <T extends ENameSpace[]>(
  ns = [ENameSpace.Common] as T,
) => {
  if (!ns.includes(ENameSpace.Common)) {
    ns.push(ENameSpace.Common);
  }
  const { t } = useTranslation<T | [typeof ENameSpace.Common]>(ns);

  const getAllows = (allows: ETextValidationAllow[]) =>
    allows
      .map((allowedToken) =>
        t(`${ENameSpace.Common}:Form.RegExpValidationAllow.${allowedToken}`),
      )
      .join(", ");

  const generateTextValidation = (
    props:
      | RequiredValidation
      | LengthValidation
      | RegExpValidation
      | DuplicatedValidation
      | DefaultValidation,
  ): string => {
    switch (props.type) {
      case "required":
        return t("common:Form.ErrorMessage.Required", {
          label: capitalizeFirstLetter(props.name),
        });
      case "max":
        return t("common:Form.ErrorMessage.Max", {
          length: props.length,
        });
      case "min":
        return t("common:Form.ErrorMessage.Min", {
          length: props.length,
        });
      case "line":
        return t("common:Form.ErrorMessage.Line", {
          length: props.length,
        });
      case "regexp":
        return t("common:Form.ErrorMessage.Regexp", {
          allows: getAllows(props.allows),
        });
      case "duplicate":
        return t("common:Form.ErrorMessage.Duplicate", {
          something: capitalizeFirstLetter(props.name),
        });
      case "default":
        return t("common:Form.ErrorMessage.Default", { label: props.name });
    }
  };

  return {
    t,
    generateTextValidation,
  };
};
