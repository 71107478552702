import {
  ERROR_BAD_REQUEST,
  ERROR_FORBIDDEN,
  ERROR_INTERNAL_SERVER_ERROR,
  ERROR_NOT_FOUND,
  ERROR_OOPS,
  ERROR_UNAUTHORIZED,
} from "~/_constants/errors";

const defaultErrorHandle = async (res: Response): Promise<never> => {
  switch (res.status) {
    case 400:
      throw new Error(ERROR_BAD_REQUEST);
    case 401:
      throw new Error(ERROR_UNAUTHORIZED);
    case 403: {
      const data = await res.json();
      throw new Error(
        `${ERROR_FORBIDDEN}${
          data.message === "NO_SUBDOMAIN" ? "message=NO_SUBDOMAIN" : ""
        }`,
      );
    }
    case 404: {
      const data = await res.json();
      throw new Error(
        `${ERROR_NOT_FOUND}${
          data.message === "NO_SUBDOMAIN" ? "message=NO_SUBDOMAIN" : ""
        }`,
      );
    }
    case 500:
      throw new Error(ERROR_INTERNAL_SERVER_ERROR);
    default:
      throw new Error(ERROR_OOPS);
  }
};

export { defaultErrorHandle };
