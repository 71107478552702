import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { EThreadType } from "~/_enums/common/EThreadType";

import { RootState } from "~/store";

type ThreadLayoutControlState = {
  type: EThreadType | null;
  prevType: EThreadType | null;
  isReadyOpenDm: boolean;
};

const initialState: ThreadLayoutControlState = {
  type: null,
  prevType: null,
  isReadyOpenDm: false,
};

export const threadLayoutControlSlice = createSlice({
  name: "threadLayoutControl",
  initialState,
  reducers: {
    openThread: (
      state: ThreadLayoutControlState,
      action: PayloadAction<EThreadType>,
    ) => {
      if (state.type == action.payload) return;
      state.prevType = state.type;
      state.type = action.payload;
    },
    closeThread: (
      state: ThreadLayoutControlState,
      action: PayloadAction<EThreadType | undefined>,
    ) => {
      if (state.type == null) return;
      if (action.payload !== undefined && action.payload != state.type) return;
      state.prevType = state.type;
      state.type = null;
    },
    setIsOpenReadyDm: (
      state: ThreadLayoutControlState,
      action: PayloadAction<boolean>,
    ) => {
      state.isReadyOpenDm = action.payload;
    },
  },
});

export const { openThread, closeThread, setIsOpenReadyDm } =
  threadLayoutControlSlice.actions;

export const selectThreadType = (state: RootState) =>
  state.threadLayoutControl.type;

export default threadLayoutControlSlice.reducer;
