import { CSSProperties, ReactNode } from "react";
import { cn } from "~/_utils/converter/cn";

type TProps = {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
};

export function MobileSectionContainer({ children, className, style }: TProps) {
  return (
    <section className={cn("w-full flex-1 flex-col", className)} style={style}>
      {children}
    </section>
  );
}
