import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export type RecentEnterReducerState = {
  daoIds: {
    [userId: string]: string;
  };
  channelIds: {
    [daoId: string]: string;
  };
  isOnboarding: boolean;
};

export const initialState: RecentEnterReducerState = {
  daoIds: {},
  channelIds: {},
  isOnboarding: false,
};

export const recentEnterSlice = createSlice({
  name: "recentEnter",
  initialState,
  reducers: {
    setDaoId: (
      state: RecentEnterReducerState,
      action: PayloadAction<{ userId: string; daoId: string }>,
    ) => {
      state.daoIds[action.payload.userId] = action.payload.daoId;
    },
    setChannelId: (
      state: RecentEnterReducerState,
      action: PayloadAction<{ daoId: string; channelId: string }>,
    ) => {
      state.channelIds[action.payload.daoId] = action.payload.channelId;
    },
    reset: (state: RecentEnterReducerState) => {
      state.daoIds = {};
      state.channelIds = {};
      state.isOnboarding = false;
    },
    setIsOnboarding: (
      state: RecentEnterReducerState,
      action: PayloadAction<boolean>,
    ) => {
      state.isOnboarding = action.payload;
    },
  },
});

export const { setDaoId, setChannelId, reset, setIsOnboarding } =
  recentEnterSlice.actions;

export default recentEnterSlice.reducer;
