import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from ".";

interface TNotificationState {
  notification: {
    hasNewNotifications: boolean;
    hasUnreadPost: boolean;
  };
}

const initialState: TNotificationState = {
  notification: {
    hasNewNotifications: false,
    hasUnreadPost: false,
  },
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setHasNewNotification: (state, action: PayloadAction<boolean>) => {
      state.notification.hasNewNotifications = action.payload;
    },
    setHasUnreadPost: (state, action: PayloadAction<boolean>) => {
      state.notification.hasUnreadPost = action.payload;
    },
  },
});

export const { setHasNewNotification, setHasUnreadPost } =
  notificationSlice.actions;

export const selectNotification = (state: RootState) =>
  state.notification.notification;

export default notificationSlice.reducer;
