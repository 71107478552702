import { ECode } from "~/_enums/ECode";
import { TApiConfig } from "~/_libs/fetch/fetchConfig";
import { fetchPostJson } from "~/_libs/fetch/fetchPostJson";
import { ERROR_UNKNOWN } from "~/_constants/errors";
import * as Sentry from "@sentry/react";

type TPostAuthConnectRequestsOutputDto = {
  code: ECode;
  data: {
    id: string;
    key: string;
  };
};

/** 인증 요청 생성 */
export const postAuthConnectRequests = (config: TApiConfig) =>
  fetchPostJson<TPostAuthConnectRequestsOutputDto>(
    "/auth/connect/requests",
    {},
    config,
  ).then((result) => {
    switch (result.code) {
      case "000":
      case "001":
      case "002":
      case "003":
      case "004":
      case "009":
      case "010":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postAuthConnectRequests",
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
