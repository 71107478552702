import { find, pipe } from "@fxts/core";
import {
  TAttachmentAutolinkOutput,
  TAttachmentEmbedOutput,
  TAttachmentImageOutput,
  TAttachmentNftOutput,
  TAttachmentVideoOutput,
} from "~/_types/TAttachment";

export function getHomeImageUrlFromPost<
  T extends
    | {
        // sns
        attachments?: (
          | TAttachmentImageOutput
          | TAttachmentAutolinkOutput
          | TAttachmentNftOutput
          | TAttachmentVideoOutput
          | TAttachmentEmbedOutput
        )[];
      }
    | {
        // video
        streamUrl: string | null;
      },
>({ post }: { post: T }) {
  const image =
    "attachments" in post
      ? (pipe(
          post.attachments ?? [],
          find(
            (attachment) =>
              attachment.type === "image" || attachment.type === "video",
          ),
        ) as TAttachmentImageOutput | TAttachmentVideoOutput)
      : null;

  return (image?.type === "video" ? image.thumbnail : image?.url) ?? null;
}
