import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AttachmentVideoOutputDto } from "~/api/apiRouteTypes/PostsRoute";
import { TVodJobState } from "~/modules/Posting/Video/Vod/types/vodJob";
import { RootState } from "..";

type TAttachment = Pick<
  AttachmentVideoOutputDto,
  "id" | "thumbnail" | "videoUuid"
>;

export interface TVod {
  fileName: string | null;
  videoUrl: string | null;
  isUploading: boolean;
  uploadProgress: number;
  attachment: TAttachment | null;
  vodJobState: TVodJobState;
}

interface TVodState {
  vod: TVod;
}

export const initialVod: TVodState = {
  vod: {
    fileName: null,
    videoUrl: null,
    isUploading: false,
    uploadProgress: 0,
    attachment: null,
    vodJobState: {
      videoUuid: "",
      status: null,
      transcodingPercent: "",
      error: null,
      vodResourceId: null,
    },
  },
};

export const vodSlice = createSlice({
  name: "vod",
  initialState: initialVod,
  reducers: {
    setVod: (state, action: PayloadAction<TVod>) => {
      state.vod = action.payload;
    },
    resetVod: (state) => {
      state.vod = initialVod.vod;
    },
    //
    setVideoFileName: (state, action: PayloadAction<string>) => {
      state.vod.fileName = action.payload;
    },
    setVideoUrl: (state, action: PayloadAction<string>) => {
      state.vod.videoUrl = action.payload;
    },
    setVideoIsUploading: (state, action: PayloadAction<boolean>) => {
      state.vod.isUploading = action.payload;
    },
    setVideoUploadProgress: (state, action: PayloadAction<number>) => {
      state.vod.uploadProgress = action.payload;
    },
    setVideoAttachment: (state, action: PayloadAction<TAttachment>) => {
      state.vod.attachment = action.payload;
    },
    //
    setVodJobState: (state, action: PayloadAction<TVodJobState>) => {
      const { videoUuid, status, vodResourceId } = action.payload;
      if (state.vod.attachment?.videoUuid !== videoUuid) return;
      if (status === "MEDIA_CONVERT_JOB_COMPLETE" && vodResourceId) {
        state.vod.attachment.id = vodResourceId;
      }
      state.vod.vodJobState = action.payload;
    },
    setVodJobStateError: (
      state,
      action: PayloadAction<Pick<TVodJobState, "error">>,
    ) => {
      state.vod.vodJobState.error = action.payload.error;
    },
    setVodJobStateStatus: (
      state,
      action: PayloadAction<Pick<TVodJobState, "status">>,
    ) => {
      state.vod.vodJobState.status = action.payload.status;
    },
  },
});

export const {
  setVod,
  resetVod,
  //
  setVideoFileName,
  setVideoUrl,
  setVideoIsUploading,
  setVideoUploadProgress,
  setVideoAttachment,
  //
  setVodJobState,
  setVodJobStateError,
  setVodJobStateStatus,
} = vodSlice.actions;

export const selectVod = (state: RootState) => state.vod.vod;

export default vodSlice.reducer;
