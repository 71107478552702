type TProps = {
  count: number;
  color?: `text-ciety-${string}`;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ViewCount(props: TProps) {
  return null;

  /*
  ** @TODO yjk: 추후 viewCount 개발되면 적용 예정
  return (
    <div className={`flex-center gap-x-1 ${color}`}>
      <Icon iconType="Eye-Line" className="h-4 w-4" />
      {count}
    </div>
  );*/
}

export { ViewCount };
