import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { DaosOfUserOutput } from "~/api-versioned";
import { RootState } from ".";

interface TDaoListState {
  daoList: DaosOfUserOutput | null;
}

const initialState: TDaoListState = {
  daoList: null,
};

export const daoListSlice = createSlice({
  name: "daoList",
  initialState,
  reducers: {
    setDaoList: (state, action: PayloadAction<DaosOfUserOutput>) => {
      state.daoList = action.payload;
    },
  },
});

export const { setDaoList } = daoListSlice.actions;

export const selectDaoList = (state: RootState) => state.daoList.daoList;

export default daoListSlice.reducer;
