import { ControllerDtoDaoOutputDto } from "~/api/apiRouteTypes/DaosRoute";
import { ContractInterfaceOutput, MarketSupportedChain } from "~/api/dto";

export type ConditionStatus =
  | "loading"
  | "succeed"
  | "failed"
  | "initialized"
  | "question"
  | "empty";

export type Currency = "ETH" | "MATIC";

export type Status =
  | "notConnected"
  | "connected"
  | "needWallet"
  | "notSatisfied"
  | "canJoin"
  | "verified"
  | "alreadyJoined"
  | "loading";

export type ContractCondition = {
  id: string;
  contract: {
    chain: MarketSupportedChain;
    contractInterface: ContractInterfaceOutput;
    contractAddress: string;
    contractName: string | null;
    symbol: string | null;
  };
  amount: string;
  status: ConditionStatus;
};

export type CurrencyCondition = {
  id: string;
  currency: Currency;
  amount: string;
  status: ConditionStatus;
  isZero?: boolean;
};

export type ResultType<TSuccess, TFail = unknown> =
  | { ok: true; value: TSuccess }
  | { ok: false; value: TFail };

export enum AccountEnum {
  EvmCompatibleAccount = `EvmCompatibleAccount`,
  EmailAccount = `EmailAccount`,
}

export enum ChainEnum {
  EthereumMainnet = `EthereumMainnet`,
  Goerli = `Goerli`,
  PolygonMainnet = `PolygonMainnet`,
  Mumbai = `Mumbai`,
}

export type DaoMemberInfoType = {
  id: string;
  imageUrl: string | null;
  nftProfile: { chain: ChainEnum; imageUrl: string } | null;
  nickname: string;
  bio: string;
};

export enum ErrorTypes {
  DaoJoinDeficientChainsError = "DaoJoinDeficientChainsError",
  NotExistingDaoError = "NotExistingDaoError",
  InvalidSignatureError = "InvalidSignatureError",
  UnCategorizedError = "UnCategorizedError",
  InvalidAddressError = "InvalidAddressError",
  DismissFromWalletError = "DismissFromWalletError",
  AlreadyProcessingFromWalletError = "AlreadyProcessingFromWalletError",
  AlreadyUsedAccountError = "AlreadyUsedAccountError",
  CantGoToAuthStatusError = "CantGoToAuthStatusError",
  ReJoinDaoNotAllowedError = "ReJoinDaoNotAllowedError",
  AlreadyJoinedDaoError = "AlreadyJoinedDaoError",
  AlreadyRegisteredError = "AlreadyRegisteredError",
  NotSupportedChainError = "NotSupportedChainError",
  NotDetectedWalletError = "NotDetectedWalletError",
  TooManyVerifyRequestError = "TooManyVerifyRequestError",
  DuplicateMemberNicknameError = "DuplicateMemberNicknameError",
}

export type ConnectCommandParamsType = {
  requestId: { id: string; key: string };
  signIn?: boolean;
};

export type ActionPropsType = {
  action: "connect" | "signIn";
  userId: string;
};

export interface DaoInfoType
  extends Pick<
    ControllerDtoDaoOutputDto,
    "merchstoreUrl" | "marketplaceVisibility" | "mode"
  > {
  id: string;
  categoryId: string | null;
  subdomain: string;
  displayName: string;
  description: string;
  profileImageUrl: string | null;
  coverImageUrl: string | null;
  inviteBackgroundImageUrl: string | null;
  memberCount: number;
}

export type VerificationJson = {
  id: string;
  verificationType: `JOIN`;
  contractConditions: Array<{
    id: string;
    contract: {
      chain: `EthereumMainnet` | `Goerli` | `PolygonMainnet` | `Mumbai`;
      contractInterface: `ERC20` | `ERC721` | `ERC777` | `ERC1155`;
      contractAddress: string;
      contractName: string | null;
      symbol: string | null;
      decimals: number;
    };
    amount: string;
  }>;
  currencyConditions: Array<{
    id: string;
    currency: `ETH` | `MATIC`;
    amount: string;
  }>;
};
