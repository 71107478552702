import { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { detectMobile } from "~/_utils/mobile/detectMobile";
import { MobileBrowserNotSupported } from "~/modules/Router/Browser/MobileBrowserNotSupported";
import { CommonFallbackRoutes } from "~/router/CommonFallbackRoutes";
import { RouteParamWrapper } from "~/router/routes/wrapper/RouteParamWrapper";

const GoogleSigninSetup = lazy(
  () =>
    import(
      /*webpackChunkName: "routes-GoogleSigninSetup" */ "~/_app/oauth/google/signin/setup"
    ),
);
const GoogleSigninCallback = lazy(
  () =>
    import(
      /*webpackChunkName: "routes-GoogleSigninCallback" */ "~/_app/oauth/google/signin/callback"
    ),
);
const GoogleConnectSetup = lazy(
  () =>
    import(
      /*webpackChunkName: "routes-GoogleConnectSetup" */ "~/_app/oauth/google/connect/setup"
    ),
);
const GoogleConnectCallback = lazy(
  () =>
    import(
      /*webpackChunkName: "routes-GoogleConnectCallback" */ "~/_app/oauth/google/connect/callback"
    ),
);
const AppleSigninSetup = lazy(
  () =>
    import(
      /*webpackChunkName: "routes-AppleSigninSetup" */ "~/_app/oauth/apple/signin/setup"
    ),
);
const AppleSigninCallback = lazy(
  () =>
    import(
      /*webpackChunkName: "routes-AppleSigninCallback" */ "~/_app/oauth/apple/signin/callback"
    ),
);
const AppleConnectSetup = lazy(
  () =>
    import(
      /*webpackChunkName: "routes-AppleConnectSetup" */ "~/_app/oauth/apple/connect/setup"
    ),
);
const AppleConnectCallback = lazy(
  () =>
    import(
      /*webpackChunkName: "routes-AppleConnectCallback" */ "~/_app/oauth/apple/connect/callback"
    ),
);

const IS_MOBILE = detectMobile();

export function OAuthRoutes() {
  return (
    <Routes>
      <Route
        path="google/signin/setup"
        element={
          <RouteParamWrapper>
            <GoogleSigninSetup />
          </RouteParamWrapper>
        }
      />
      <Route
        path="google/signin/callback"
        element={
          <RouteParamWrapper>
            <GoogleSigninCallback />
          </RouteParamWrapper>
        }
      />
      <Route
        path="google/connect/setup"
        element={
          <RouteParamWrapper>
            <GoogleConnectSetup />
          </RouteParamWrapper>
        }
      />
      <Route
        path="google/connect/callback"
        element={
          <RouteParamWrapper>
            <GoogleConnectCallback />
          </RouteParamWrapper>
        }
      />

      <Route
        path="apple/signin/setup"
        element={
          <RouteParamWrapper>
            <AppleSigninSetup />
          </RouteParamWrapper>
        }
      />
      <Route
        path="apple/signin/callback"
        element={
          <RouteParamWrapper>
            <AppleSigninCallback />
          </RouteParamWrapper>
        }
      />
      <Route
        path="apple/connect/setup"
        element={
          <RouteParamWrapper>
            <AppleConnectSetup />
          </RouteParamWrapper>
        }
      />
      <Route
        path="apple/connect/callback"
        element={
          <RouteParamWrapper>
            <AppleConnectCallback />
          </RouteParamWrapper>
        }
      />

      <Route
        path="*"
        element={
          IS_MOBILE ? <MobileBrowserNotSupported /> : <CommonFallbackRoutes />
        }
      />
    </Routes>
  );
}
