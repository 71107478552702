import { useTranslation as usei18nTranslation } from "react-i18next";

import { ENameSpace } from "../i18n/@types/types";

type TTranslateFunctionKey<T extends ENameSpace | Array<ENameSpace>> =
  T extends ENameSpace
    ? T extends keyof TI18nKeys
      ? TI18nKeys[T]
      : never
    : T extends Array<ENameSpace>
    ? T["length"] extends 1
      ? T[0] extends keyof TI18nKeys
        ? TI18nKeys[T[0]] | TI18nPrefixedKeys[T[0]]
        : never
      : T[number] extends keyof TI18nKeys
      ? TI18nPrefixedKeys[T[number]]
      : never
    : never;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TTranslateFunctionParams = Record<any, any>;

export type TTranslateFunction<T extends ENameSpace | ENameSpace[]> = (
  key: TTranslateFunctionKey<T>,
  options?: TTranslateFunctionParams,
) => string;

export function useTranslation<
  T extends ENameSpace | ENameSpace[] = typeof ENameSpace.Common,
>(
  ns?: T,
): Omit<ReturnType<typeof usei18nTranslation<T, undefined>>, "t"> & {
  t: TTranslateFunction<T>;
} {
  const ret = usei18nTranslation(ns);

  return ret as unknown as Omit<
    ReturnType<typeof usei18nTranslation<T, undefined>>,
    "t"
  > & {
    t: TTranslateFunction<T>;
  };
}
