import { ReactNode } from "react";
import { EDevice } from "~/_enums/EDevice";

type TProps = {
  device?: EDevice;
  children: ReactNode;
};

function UtilList({ device, children }: TProps) {
  if (device === EDevice.Mobile) {
    return (
      <div className="typo-r3 flex gap-x-[0.375rem] text-ciety-gy">
        {children}
      </div>
    );
  }

  return <div className="typo-r3 flex gap-x-2 text-ciety-gy">{children}</div>;
}

export { UtilList };
