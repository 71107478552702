export const Empty = ({
  className,
  width,
  height,
}: {
  className: string;
  width?: `${number}rem` | `${number}%`;
  height?: `${number}rem` | `${number}%`;
}) => {
  return <div className={className} style={{ width, height }}></div>;
};
