import { Dialog } from "@headlessui/react";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import { postDoOnboarding } from "~/api";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { Icon } from "~/modules/Common/Icons";
import { CommunityProfileImage } from "~/modules/Common/Image";
import { CommunityDescription } from "~/modules/Router/Auth/components/CommunityInfo/CommunityDescription";
import { CommunityDisplayName } from "~/modules/Router/Auth/components/CommunityInfo/CommunityDisplayName";
import { CommunityMemberCount } from "~/modules/Router/Auth/components/CommunityInfo/CommunityMemberCount";
import { DesktopCommunityJoinProps } from "~/modules/Router/Auth/components/DesktopCommunityJoin";
import { DesktopCommunityNicknameModal } from "~/modules/Router/Auth/components/DesktopCommunityNicknameModal";
import { VerificationTitle } from "~/modules/Router/Auth/components/VerificationTitle";
import { useCommunityJoin } from "~/modules/Router/Auth/hooks/useCommunityJoin";
import { setDaoId } from "~/store/recentEnterReducer";
import {
  ConnectingButton,
  DesktopJoinButton,
  GreenButton,
  ReloadButton,
} from "./Buttons";
import { ContractConditions, CurrencyConditions } from "./Condition";
import { ConditionBox } from "./ConditionBox";
import { useDaoStore } from "../../../../_store/dao/useDaoStore";
import { useErrorBoundary } from "react-error-boundary";

export type Props = {
  isOpen: boolean;
  onClose: () => void;
} & DesktopCommunityJoinProps;

export const DesktopCommunityJoinModal = ({
  isEmailAccount,
  isOpen,
  onClose,
  userId,
}: Props) => {
  const initialFocusRef = useRef(null);
  const dispatch = useDispatch();
  const { dao } = useDaoStore();

  const {
    handleClickJoinButton,
    handleClickVerifyButton,
    handleClickConnectWalletButton,
    verifications,
    isSimplifiedLayout,
    hasCurrencyConditions,
    hasContractConditions,
    currencyConditions,
    contractConditions,
    message,
    status,
    showNicknameModal,
    needConnectWallet,
    nickNameForm,
    joinError,
    handleClickCancelButton,
  } = useCommunityJoin({ isEmailAccount });

  const Button = ({ className }: { className?: string }) => {
    switch (true) {
      case status === "connected":
        return (
          <div className={className}>
            {needConnectWallet ? (
              <GreenButton
                className={"w-[13.938rem]"}
                onClick={handleClickConnectWalletButton}
                icon={<Icon className="h-5 w-5" iconType="Chains-METAMASK" />}
              >
                Connect Wallet
              </GreenButton>
            ) : (
              <DesktopJoinButton onClick={handleClickVerifyButton} />
            )}
          </div>
        );
      case status === "notSatisfied":
        return (
          <div className={className}>
            <ReloadButton onClick={handleClickVerifyButton} />
          </div>
        );
      default:
        return (
          <div className={className}>
            <ConnectingButton />
          </div>
        );
    }
  };

  const { showBoundary } = useErrorBoundary();
  useEffect(() => {
    if (status === "verified" && dao) {
      dispatch(setDaoId({ userId, daoId: dao.id }));
      postDoOnboarding()
        .catch(showBoundary)
        .finally(() => {
          window.location.reload();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, showBoundary]);

  const { t } = useTranslation([ENameSpace.Common]);

  if (!dao || !verifications) return null;

  return (
    <>
      <Dialog initialFocus={initialFocusRef} open={isOpen} onClose={onClose}>
        <Dialog.Overlay
          className={`fixed inset-0 bg-ciety-bk bg-opacity-80 backdrop-blur-[20px]`}
        />
        <div
          ref={initialFocusRef}
          className={`fixed left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2`}
        >
          <article
            className={`${
              isSimplifiedLayout ? "w-[31.25rem]" : "w-[38.25rem]"
            } relative flex flex-col items-center justify-between rounded-[2.5rem] bg-ciety-dk p-10`}
          >
            <div className="flex h-full w-full flex-col items-center justify-between gap-y-4">
              <div className="flex flex-col items-center justify-center gap-y-4">
                <CommunityProfileImage
                  displayName={dao.displayName}
                  categoryId={dao.categoryId ?? "0"}
                  size="xl"
                  imageUrl={dao.profileImageUrl}
                />

                <section className="flex flex-col gap-y-2">
                  <CommunityDisplayName dao={dao} />
                  <CommunityMemberCount
                    dao={dao}
                    fontClassName={"typo-r2-auto"}
                  />
                </section>

                <CommunityDescription dao={dao} fontClassName={"typo-r1"} />
              </div>
              {!isSimplifiedLayout && (
                <>
                  <div className="flex w-full flex-col gap-y-4">
                    <VerificationTitle>
                      {t(
                        `${ENameSpace.Common}:TokenGating.VerificationRequirementsToJoinCommunity`,
                      )}
                    </VerificationTitle>
                    <ConditionBox>
                      <CurrencyConditions
                        currencyConditions={currencyConditions}
                        hidden={!hasCurrencyConditions}
                      />
                      <ContractConditions
                        contractConditions={contractConditions}
                        hidden={!hasContractConditions}
                      />
                    </ConditionBox>
                    <div className="flex items-center gap-x-2 text-ciety-gy">
                      <Icon iconType="Alert Sign Circle" className="h-4 w-4" />
                      <span className="typo-r2">
                        {t(
                          `${ENameSpace.Common}:TokenGating.WeWillOnlyVerifyYouAndYouWontBeChargedForAnyCost`,
                        )}
                      </span>
                    </div>
                  </div>
                  {message.length > 0 && (
                    <p className="typo-r2 whitespace-pre-wrap break-words text-center text-ciety-rd">
                      {message}
                    </p>
                  )}
                </>
              )}
              <Button className="h-10" />
            </div>
            <button
              type="button"
              className="absolute right-5 top-5 h-6 w-6"
              onClick={onClose}
            >
              <Icon
                iconType="Cancel Close"
                className="h-full w-full text-ciety-dk-60"
              />
            </button>
          </article>
        </div>
        {showNicknameModal && (
          <DesktopCommunityNicknameModal
            dao={dao}
            nickNameForm={nickNameForm}
            joinError={joinError}
            handleClickJoinButton={handleClickJoinButton}
            onClose={handleClickCancelButton}
          />
        )}
      </Dialog>
    </>
  );
};
