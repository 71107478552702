import { DaoInfoType } from "~/modules/Router/Auth/types";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { cn } from "~/_utils/converter/cn";

type Props = {
  fontClassName?: string;
  dao: Pick<DaoInfoType, "description" | "displayName">;
};
export const CommunityDescription = ({ fontClassName, dao }: Props) => {
  const { t } = useTranslation([ENameSpace.Common]);

  const isDescription = dao.description && dao.description.length > 0;

  return (
    <p
      className={cn(
        fontClassName,
        `break-words text-center text-ciety-wh line-clamp-3`,
        isDescription ? "whitespace-pre-wrap" : "whitespace-pre-line",
      )}
    >
      {isDescription ? (
        dao.description
      ) : (
        <>
          {t(`${ENameSpace.Common}:CommunityJoin.Description`, {
            title: dao.displayName,
          })}
        </>
      )}
    </p>
  );
};
