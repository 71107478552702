import { UtilList } from "~/_app/[dao]/home/components/common/UtilList";
import { ViewCount } from "~/_app/[dao]/home/components/common/ViewCount";
import { NicknameWithRoleColor } from "~/_app/[dao]/home/components/common/NicknameWithRoleColor";
import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { ReplyCount } from "~/_app/[dao]/home/components/common/ReplyCount";
import { HeartCount } from "~/_app/[dao]/home/components/common/HeartCount";
import { EDevice } from "~/_enums/EDevice";
import { useRelativeTimeFormat } from "~/_hooks/time/useRelativeTimeFormat";
import { commonTimeDiffOptions } from "~/_utils/converter/timeConverter";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { appNavigation } from "~/_api/mobile/toMobileNavigation";
import { getThreadScreenParams } from "~/_api/mobile/appScreenParams";
import { useDaoSubdomainStore } from "~/_store/dao/useDaoSubdomainStore";
import { remToPx } from "~/_utils/converter/remConverter";
import { ResizedImage } from "~/_components/Image/OptimalImage/ResizedImage";
import { assertIsDefined } from "~/_utils/assert";
import { DAO_HOME } from "~/_constants";
import { getHomeImageUrlFromPost } from "~/_app/[dao]/home/utils/getHomeImageFromPost";
import { BlurImageContainer } from "~/_app/[dao]/home/components/common/BlurImageContainer";
import { EChannelState } from "~/_enums/EChannelState";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { cn } from "~/_utils/converter/cn";
import { getHomeTextFromPost } from "~/_app/[dao]/home/utils/getHomeTextFromPost";

type TProps = {
  sectionId: string;
};

function SnsDetailType({ sectionId }: TProps) {
  const { getSection } = useDaoHomeStore();
  const { posts, channel } = getSection(EDaoHomeSection.Sns, sectionId);
  const { daoSubdomain } = useDaoSubdomainStore();
  assertIsDefined(daoSubdomain);
  const { formatRelativeTimeWithTranslation } = useRelativeTimeFormat();
  const { t } = useTranslation([ENameSpace.Home]);

  const handleMoveThread = ({ postId }: { postId: string }) => {
    appNavigation(({ push }) => {
      push(
        getThreadScreenParams({
          path: generateUrl({
            type: "thread",
            subdomain: daoSubdomain,
            channelId: channel.id,
            channelType: channel.type,
            postId: postId,
          }),
        }),
      );
    });
  };

  const isHiddenPost = channel.state === EChannelState.Hidden;

  return (
    <div className="mt-5 px-5">
      {posts.slice(0, DAO_HOME.CONTENT_COUNT.MOBILE_SNS_DETAIL).map((post) => {
        const imageUrl = getHomeImageUrlFromPost({ post });

        return (
          <button
            type="button"
            key={post.id}
            className="flex w-full flex-col border-t border-ciety-dk-95 py-4 last:border-b"
            onClick={() => handleMoveThread({ postId: post.id })}
          >
            <div className="flex w-full justify-between">
              <div
                className={cn({
                  "w-full": imageUrl == null,
                  "w-[calc(100%-4.125rem)]": imageUrl != null,
                })}
              >
                <div className="mb-[0.375rem] flex items-center gap-x-1 text-ciety-wh">
                  <h2 className="typo-m2 truncate">{post.title}</h2>
                  <ReplyCount
                    count={"replyCount" in post ? post.replyCount : 0}
                  />
                </div>

                <UtilList device={EDevice.Mobile}>
                  <NicknameWithRoleColor
                    nickname={post.author.nickname}
                    roleColor={post.author.roles?.[0]?.displayColor}
                  />
                  <div>
                    {formatRelativeTimeWithTranslation(
                      new Date(),
                      new Date(post.createdAt),
                      commonTimeDiffOptions.basic,
                    )}
                  </div>
                  <ViewCount count={0} />
                  <HeartCount heart={post.heart} />
                </UtilList>
              </div>

              {imageUrl && (
                <div className="isolate flex aspect-[1/1] w-[3.125rem] overflow-hidden rounded-lg">
                  <BlurImageContainer withBlur={isHiddenPost}>
                    <ResizedImage
                      toResizeUrlParams={{
                        url: imageUrl,
                        width: remToPx(3.125),
                        qualityMultiplier: 2,
                      }}
                      className="h-full w-full object-cover"
                    />
                  </BlurImageContainer>
                </div>
              )}
            </div>

            {!isHiddenPost && "text" in post && (
              <p className="typo-r3 mt-4 line-clamp-2 w-full text-left text-ciety-gy-30">
                {getHomeTextFromPost({ post })}
              </p>
            )}
            {isHiddenPost && (
              <p className="typo-r3 mt-4 line-clamp-2 w-full text-left text-ciety-gy">
                {t(`${ENameSpace.Home}:Root.RestrictPost.Description`)}
              </p>
            )}
          </button>
        );
      })}
    </div>
  );
}

export { SnsDetailType };
