import { capitalizeFirstLetter } from "./capitalizeFirstLetter";

/** @return `use${hookName} is only available within ${contextName}Context.Provider.` */
export const getUseContextError = (hookName: string, contextName?: string) => {
  const firstLetterCapitalizedHookName = capitalizeFirstLetter(hookName);
  const firstLetterCapitalizedContextName = capitalizeFirstLetter(
    contextName ?? hookName,
  );

  return `use${firstLetterCapitalizedHookName} is only available within ${firstLetterCapitalizedContextName}Context.Provider.`;
};
