import { ContractInterfaceOutput } from "~/api/dto";

export const simplifyAddress = ({
  address,
  crop = 3,
}: {
  address: string;
  crop?: number;
}) => address.slice(0, crop) + "..." + address.slice(-crop);

export const parseContractInterface = (params: {
  interface: ContractInterfaceOutput;
}) => params.interface.slice(0, 3) + "-" + params.interface.slice(3);
