import {
  flip,
  FloatingPortal,
  shift,
  useFloating,
} from "@floating-ui/react-dom-interactions";
import { ReactNode, useState } from "react";

import { Z_LEVELS } from "~/constants";
import { Icon } from "~/modules/Common/Icons";

export const VerificationTitle = ({ children }: { children: ReactNode }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const { reference, x, y, floating } = useFloating({
    strategy: "absolute",
    placement: "bottom-start",
    middleware: [shift(), flip()],
  });

  return (
    <>
      <h2 className="flex w-full items-center gap-x-1 text-ciety-wh typo-m1">
        {children}
        <button
          type="button"
          onFocus={() => setIsActive(true)}
          onMouseOver={() => setIsActive(true)}
          onMouseLeave={() => setIsActive(false)}
          onBlur={() => setIsActive(false)}
          ref={reference}
        >
          <Icon iconType={"Light Bulb"} className={"h-5 w-5 text-ciety-gy"} />
        </button>
      </h2>
      <FloatingPortal id={"gatingInfo"}>
        {isActive && (
          <div
            ref={floating}
            style={{
              position: "absolute",
              top: y ?? 0,
              left: x ?? 0,
              zIndex: Z_LEVELS.GLOBAL.HEADLESS_UI_PORTAL_40,
            }}
            className="w-[22.5rem] rounded-[1rem] border border-ciety-dk-90 bg-ciety-dk-90 bg-opacity-50 px-5 py-4 backdrop-blur-[2.5rem]"
          >
            <span className="typo-m2 mb-1 text-ciety-wh">Token Gating</span>
            <p className="typo-r2 text-ciety-wh">
              Use the Token Gating to provide exclusive space for specific token
              holders, enabling the community to create a sense of exclusivity
              and value for its members.
            </p>
          </div>
        )}
      </FloatingPortal>
    </>
  );
};
