import { ECode } from "~/_enums/ECode";
import { TApiConfig } from "~/_libs/fetch/fetchConfig";
import { fetchPostJson } from "~/_libs/fetch/fetchPostJson";
import { TDao } from "~/_types/TDao";
import { TDaosEnters } from "~/_types/TDaosEnters";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

type TInputDto = {
  daoSubdomain: string;
  /** 토큰 게이팅 검사 여부, 기본값 "exclude". */
  tokenGating?: "include" | "exclude";
};

type TSuccessPendingData = {
  canEnter: true;
  isEntered: false;
  dao: TDao;
};

type TReasonDeleteMember = {
  type: "DELETE_MEMBER";
};

type TReasonNotFound = {
  type: "NOT_FOUND";
};

type TReasonNotAuth = {
  type: "NOT_AUTH";
};

type TReasonKickMember = {
  type: "KICK_MEMBER";
};

type TReasonTokenGating = {
  type: "TOKEN_GATING";
  nativeTokenConditionIdList: string[];
  contractConditionIdList: string[];
};

type TCode = typeof ECode.Success | typeof ECode.NotFound | typeof ECode.Error;

type TSuccessRejectedData = {
  canEnter: false;
  isEntered: false;
  dao: TDao | null;
  reason:
    | TReasonDeleteMember
    | TReasonKickMember
    | TReasonTokenGating
    | TReasonNotFound
    | TReasonNotAuth
    | null;
};

type TOutputDto = {
  code: TCode;
  data: TDaosEnters | TSuccessPendingData | TSuccessRejectedData;
};

export const postCommonEnterDao = (body: TInputDto, config: TApiConfig) =>
  fetchPostJson<TOutputDto>(
    "/common-enter-dao",
    {
      body: body,
    },
    config,
  )
    .then(convertSpaceMemberToMember)
    .then((result) => {
      switch (result.code) {
        case "000":
        case "004":
        case "009":
          return result;
        default:
          Sentry.captureException(ERROR_UNKNOWN, {
            extra: {
              apiName: "postCommonEnterDao",
              payload: body,
              res: result,
            },
          });

          throw new Error(ERROR_UNKNOWN);
      }
    });

type TConvertParams = {
  code: TCode;
  data: TSuccessPendingData | TDaosEnters | TSuccessRejectedData;
};

type TConvertReturn = {
  code: TCode;
  data: TSuccessPendingData | TDaosEnters | TSuccessRejectedData;
};

const convertSpaceMemberToMember = (
  res: TConvertParams | { statusCode: number },
): TConvertReturn => {
  if ((res as { statusCode: number }).statusCode == 404) {
    return {
      code: ECode.NotFound,
      data: {
        isEntered: false,
        canEnter: false,
        reason: null,
        dao: null,
      },
    };
  }

  if ((res as { statusCode: number }).statusCode === 500) {
    return {
      code: ECode.Error,
      data: {
        isEntered: false,
        canEnter: false,
        reason: null,
        dao: null,
      },
    };
  }

  const { code, data } = res as TConvertParams;

  return {
    code,
    data,
  };
};
