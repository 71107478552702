import classNames from "classnames";

/**
 * @description A simple JavaScript utility for conditionally joining classNames together.
 *
 * @example
 * cn("foo", "bar"); // => 'foo bar'
 * cn("foo", { bar: true }); // => 'foo bar'
 * cn({ "foo-bar": true }); // => 'foo-bar'
 * cn({ "foo-bar": false }); // => ''
 * cn({ foo: true }, { bar: true }); // => 'foo bar'
 * cn({ foo: true, bar: true }); // => 'foo bar'
 *
 * // lots of arguments of various types
 * cn("foo", { bar: true, duck: false }, "baz", { quux: true }); // => 'foo bar baz quux'
 *
 * // other falsy values are just ignored
 * cn(null, false, "bar", undefined, 0, 1, { baz: null }, ""); // => 'bar 1'
 *
 * @readme https://github.com/JedWatson/classnames#readme
 */
export const cn = classNames;

export const caseCss = ({
  condition,
  css,
}: {
  condition: boolean;
  css: string; // TailwindCss strings
}) => `${condition ? css : ""}`;

/** @return dividend / divisor */
export const calcRatio = (dividend: number, divisor: number) =>
  dividend / divisor;

/** @return `(dividend / divisor) * 100 */
export const calcPercent = (dividend: number, divisor: number) =>
  calcRatio(dividend, divisor) * 100;

/** @return `${(dividend / divisor) * 100}%` */
export const calcPercentWithSymbol = (dividend: number, divisor: number) =>
  `${calcRatio(dividend, divisor) * 100}%`;

/** @return px / 16 */
export const calcRem = (px: number) => calcRatio(px, 16);

/** @return `${px / 16}rem` */
export const calcRemWithSymbol = (px: number) => `${calcRatio(px, 16)}rem`;
