import { ComponentType, useCallback } from "react";

import { useOverlayActions } from "~/context/OverlayProvider";
import { getUuid } from "~/utils/getUuid";

/**
 * @description
 * - useOverlay는 Overlay를 선언(전역)적으로 다루기 위한 유틸리티 입니다.
 * - cf. Overlay는 Dialog, Toast 등 UI를 별도의 레이어에 띄우는 컴포넌트의 상위집합 입니다.
 * - Callback 패턴 대신 Promise와 함께 사용할 수도 있습니다.
 * - onClose, onSubmit 시 close 부수동작이 추상화되어 있습니다. (ref. `OverlayController.tsx`)
 * - open된 Overlay는 배열로 관리되기에 동시에 다수의 Overlay를 open할 수 있습니다.
 *
 * @recommendation Overlay 추가 시 `OverlayController.tsx`에 해당 컴포넌트를 추가해주세요.
 *
 * @example
 * const { open, close } = useOverlay();
 *
 * const openAlert = (alertProps: ComponentProps<typeof Alert>) =>
 *   open(overlays.alert, alertProps);
 *
 * const openAlertAsync = (alertProps: ComponentProps<typeof Alert>) => {
 *   return new Promise<void>((resolve) => {
 *     const { onClose, ...restProps } = alertProps;
 *     open(overlays.alert, {
 *       ...restProps,
 *       onClose: () => {
 *         onClose?.();
 *         return resolve();
 *       },
 *     });
 *   });
 * };
 *
 * const closeAlert = () => close(overlays.alert);
 */
export const useOverlay = () => {
  const overlayActions = useOverlayActions();

  const open = useCallback(
    <TProps>(Component: ComponentType<TProps>, props?: TProps) => {
      overlayActions.open(getUuid(), Component, props);
    },
    [overlayActions],
  );
  const close = useCallback(
    <TProps>(Component: ComponentType<TProps>) => {
      overlayActions.close(Component);
    },
    [overlayActions],
  );

  return {
    open,
    close,
  };
};
