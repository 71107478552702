import "swiper/css";

import { UtilList } from "~/_app/[dao]/home/components/common/UtilList";
import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { Swiper, SwiperSlide } from "swiper/react";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { useRelativeTimeFormat } from "~/_hooks/time/useRelativeTimeFormat";
import { commonTimeDiffOptions } from "~/_utils/converter/timeConverter";
import { EDevice } from "~/_enums/EDevice";
import { NicknameWithRoleColor } from "~/_app/[dao]/home/components/common/NicknameWithRoleColor";
import { remToPx } from "~/_utils/converter/remConverter";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { useDaoSubdomainStore } from "~/_store/dao/useDaoSubdomainStore";
import { appNavigation } from "~/_api/mobile/toMobileNavigation";
import { getThreadScreenParams } from "~/_api/mobile/appScreenParams";
import { ResizedImage } from "~/_components/Image/OptimalImage/ResizedImage";
import { assertIsDefined } from "~/_utils/assert";
import { DAO_HOME } from "~/_constants";
import { getHomeImageUrlFromPost } from "~/_app/[dao]/home/utils/getHomeImageFromPost";
import { BlurImageContainer } from "~/_app/[dao]/home/components/common/BlurImageContainer";
import { EChannelState } from "~/_enums/EChannelState";
import { flatMap, pipe, take, toArray } from "@fxts/core";
import { useState } from "react";

type TProps = {
  sectionId: string;
};

function SnsThumbnailType({ sectionId }: TProps) {
  const [isLoading, setIsLoading] = useState(true);
  const { getSection } = useDaoHomeStore();
  const { daoSubdomain } = useDaoSubdomainStore();
  assertIsDefined(daoSubdomain);
  const { posts, channel } = getSection(EDaoHomeSection.Sns, sectionId);
  const { formatRelativeTimeWithTranslation } = useRelativeTimeFormat();

  const handleMoveThread = ({ postId }: { postId: string }) => {
    appNavigation(({ push }) => {
      push(
        getThreadScreenParams({
          path: generateUrl({
            type: "thread",
            subdomain: daoSubdomain,
            channelId: channel.id,
            channelType: channel.type,
            postId: postId,
          }),
        }),
      );
    });
  };

  const isHiddenPost = channel.state === EChannelState.Hidden;

  return (
    <div className="mt-4">
      <Swiper
        slidesPerView={"auto"}
        slidesPerGroupAuto={true}
        spaceBetween={remToPx(0.75)}
        slidesOffsetBefore={remToPx(1.25)}
        slidesOffsetAfter={remToPx(1.25)}
        onInit={() => setIsLoading(false)}
        className="sns-thumbnail-type-swiper"
      >
        {pipe(
          posts,
          flatMap((post) => {
            const imageUrl = getHomeImageUrlFromPost({ post });

            /**
             * 커뮤니티 홈에서는 서버에서 이미지 존재하는 경우만 전달함
             * 커뮤니티 세팅에서는 이미지 없는 경우도 같이 전달하여 필터
             */
            if (imageUrl == null) {
              return [];
            }

            return (
              <SwiperSlide
                key={post.id}
                style={{ opacity: isLoading ? "0" : "1" }}
              >
                <button
                  type="button"
                  className="w-full"
                  onClick={() => handleMoveThread({ postId: post.id })}
                >
                  {imageUrl && (
                    <div className="isolate flex aspect-video overflow-hidden rounded-lg">
                      <BlurImageContainer withBlur={isHiddenPost}>
                        <ResizedImage
                          toResizeUrlParams={{
                            url: imageUrl,
                            width: remToPx(13.625),
                            qualityMultiplier: 2,
                          }}
                          className="h-full w-full object-cover"
                        />
                      </BlurImageContainer>
                    </div>
                  )}

                  <div className="mt-3">
                    <div className="flex items-center gap-x-1 text-ciety-wh">
                      <h2 className="typo-m1 truncate">{post.title}</h2>
                    </div>

                    <UtilList device={EDevice.Mobile}>
                      <NicknameWithRoleColor
                        nickname={post.author.nickname}
                        roleColor={post.author.roles?.[0]?.displayColor}
                      />
                      {formatRelativeTimeWithTranslation(
                        new Date(),
                        new Date(post.createdAt),
                        commonTimeDiffOptions.basic,
                      )}
                    </UtilList>
                  </div>
                </button>
              </SwiperSlide>
            );
          }),
          take(DAO_HOME.CONTENT_COUNT.MOBILE_SNS_THUMBNAIL),
          toArray,
        )}
      </Swiper>
    </div>
  );
}

export { SnsThumbnailType };
