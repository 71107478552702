import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

import type {
  CommentData,
  CommentsFilter,
  FilterType,
  MemberData,
  MembersFilter,
  OrderType,
  PostData,
  PostsFilter,
  SearchType,
  Status,
} from "~/modules/Search/types";
import {
  DEFAULT_PERIOD,
  FILTER_DEFAULT_LABEL,
} from "~/modules/Search/types/constant";

export type SearchReducerState = {
  status: Status;
  isKeywordInputActive: boolean;
  searchType: SearchType;
  defaultFilterType: FilterType | null;
  keyword: string;
  searchedKeyword: string;
  posts: {
    order: OrderType;
    isLoading: boolean;
    data: Array<PostData>;
    totalCount: number;
    filters: PostsFilter;
  };
  comments: {
    order: OrderType;
    isLoading: boolean;
    data: Array<CommentData>;
    totalCount: number;
    filters: CommentsFilter;
  };
  members: {
    data: Array<MemberData>;
    isLoading: boolean;
    totalCount: number;
    filters: MembersFilter;
  };
};

export const initialState: SearchReducerState = {
  status: "initialized",
  isKeywordInputActive: false,
  searchType: "posts",
  defaultFilterType: null,
  keyword: "",
  searchedKeyword: "",
  posts: {
    order: "relevant",
    data: [],
    isLoading: true,
    totalCount: 0,
    filters: {
      type: "posts",
      author: {
        label: FILTER_DEFAULT_LABEL.author,
        selectedIds: [],
      },
      channel: {
        label: FILTER_DEFAULT_LABEL.channel,
        selectedIds: [],
      },
      periods: {
        label: FILTER_DEFAULT_LABEL.periods,
        selected: DEFAULT_PERIOD,
      },
    },
  },
  comments: {
    order: "relevant",
    data: [],
    isLoading: false,
    totalCount: 0,
    filters: {
      type: "comments",
      author: {
        label: FILTER_DEFAULT_LABEL.author,
        selectedIds: [],
      },
      channel: {
        label: FILTER_DEFAULT_LABEL.channel,
        selectedIds: [],
      },
      periods: {
        label: FILTER_DEFAULT_LABEL.periods,
        selected: DEFAULT_PERIOD,
      },
    },
  },
  members: {
    data: [],
    isLoading: false,
    totalCount: 0,
    filters: {
      type: "members",
      roles: {
        label: FILTER_DEFAULT_LABEL.role,
        selected: [],
      },
    },
  },
};

export const searchSlice = createSlice({
  name: "search",
  initialState,
  reducers: {
    resetSearchedData: (state: SearchReducerState) => {
      Object.assign(state, initialState);
    },
    setStatus: (state: SearchReducerState, action: PayloadAction<Status>) => {
      state.status = action.payload;
    },
    setIsKeywordInputActive: (
      state: SearchReducerState,
      action: PayloadAction<boolean>,
    ) => {
      state.isKeywordInputActive = action.payload;
    },
    setSearchType: (
      state: SearchReducerState,
      action: PayloadAction<SearchType>,
    ) => {
      state.searchType = action.payload;
    },
    setDefaultFilterType: (
      state: SearchReducerState,
      action: PayloadAction<FilterType | null>,
    ) => {
      state.defaultFilterType = action.payload;
    },
    setKeyword: (state: SearchReducerState, action: PayloadAction<string>) => {
      state.keyword = action.payload;
    },
    resetKeyword: (state: SearchReducerState) => {
      state.keyword = initialState.keyword;
    },
    setSearchedKeyword: (
      state: SearchReducerState,
      action: PayloadAction<string>,
    ) => {
      state.searchedKeyword = action.payload;
      state.keyword = action.payload;
    },
    setPostsData: (
      state: SearchReducerState,
      action: PayloadAction<Array<PostData>>,
    ) => {
      state.posts.data = action.payload;
    },
    addPostsData: (
      state: SearchReducerState,
      action: PayloadAction<Array<PostData>>,
    ) => {
      state.posts.data = [...state.posts.data, ...action.payload];
    },
    setPostsIsLoading: (
      state: SearchReducerState,
      action: PayloadAction<boolean>,
    ) => {
      state.posts.isLoading = action.payload;
    },
    setPostsTotalCount: (
      state: SearchReducerState,
      action: PayloadAction<number>,
    ) => {
      state.posts.totalCount = action.payload;
    },
    setPostsOrder: (
      state: SearchReducerState,
      action: PayloadAction<OrderType>,
    ) => {
      state.posts.order = action.payload;
    },
    setPostsFilter: (
      state: SearchReducerState,
      action: PayloadAction<PostsFilter>,
    ) => {
      state.posts.filters = action.payload;
    },
    resetPostsFilter: (state: SearchReducerState) => {
      state.posts.filters = initialState.posts.filters;
    },
    setCommentsData: (
      state: SearchReducerState,
      action: PayloadAction<Array<CommentData>>,
    ) => {
      state.comments.data = action.payload;
    },
    addCommentsData: (
      state: SearchReducerState,
      action: PayloadAction<Array<CommentData>>,
    ) => {
      state.comments.data = [...state.comments.data, ...action.payload];
    },
    setCommentsIsLoading: (
      state: SearchReducerState,
      action: PayloadAction<boolean>,
    ) => {
      state.comments.isLoading = action.payload;
    },
    setCommentsTotalCount: (
      state: SearchReducerState,
      action: PayloadAction<number>,
    ) => {
      state.comments.totalCount = action.payload;
    },
    setCommentsOrder: (
      state: SearchReducerState,
      action: PayloadAction<OrderType>,
    ) => {
      state.comments.order = action.payload;
    },
    setCommentsFilter: (
      state: SearchReducerState,
      action: PayloadAction<CommentsFilter>,
    ) => {
      state.comments.filters = action.payload;
    },
    resetCommentsFilter: (state: SearchReducerState) => {
      state.comments.filters = initialState.comments.filters;
    },
    setMembersData: (
      state: SearchReducerState,
      action: PayloadAction<Array<MemberData>>,
    ) => {
      state.members.data = action.payload;
    },
    addMembersData: (
      state: SearchReducerState,
      action: PayloadAction<Array<MemberData>>,
    ) => {
      state.members.data = [...state.members.data, ...action.payload];
    },
    setMembersIsLoading: (
      state: SearchReducerState,
      action: PayloadAction<boolean>,
    ) => {
      state.members.isLoading = action.payload;
    },
    setMembersTotalCount: (
      state: SearchReducerState,
      action: PayloadAction<number>,
    ) => {
      state.members.totalCount = action.payload;
    },
    setMembersFilter: (
      state: SearchReducerState,
      action: PayloadAction<MembersFilter>,
    ) => {
      state.members.filters = action.payload;
    },
    resetMembersFilter: (state: SearchReducerState) => {
      state.members.filters = initialState.members.filters;
    },
  },
});

export const {
  resetSearchedData,
  setStatus,
  setIsKeywordInputActive,
  setSearchType,
  setDefaultFilterType,
  setKeyword,
  resetKeyword,
  setSearchedKeyword,
  setPostsData,
  addPostsData,
  setPostsIsLoading,
  setPostsTotalCount,
  setPostsOrder,
  setPostsFilter,
  resetPostsFilter,
  setCommentsData,
  addCommentsData,
  setCommentsIsLoading,
  setCommentsTotalCount,
  setCommentsOrder,
  setCommentsFilter,
  resetCommentsFilter,
  setMembersData,
  addMembersData,
  setMembersIsLoading,
  setMembersTotalCount,
  setMembersFilter,
  resetMembersFilter,
} = searchSlice.actions;

export default searchSlice.reducer;
