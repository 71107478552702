import { Users } from "~/api/apiRouteTypes/UsersRoute";
import { Unwrap } from "~/api/types";
import { fetchData } from "~/utils/fetch";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

export type TGetUserAccountsOutputDto =
  Users.GetUsersUseridAccountsControllerHandle.ResponseBody;
export type TGetUserAccountsOutputDtoData = TGetUserAccountsOutputDto["data"];
export type TDtoGetUserIdToEvmCompatibleAccountOutput = Unwrap<
  TGetUserAccountsOutputDtoData["evmCompatibleAccounts"]
>;

type TGetUserAccountsInputDto =
  Users.GetUsersUseridAccountsControllerHandle.RequestParams &
    Users.GetUsersUseridAccountsControllerHandle.RequestQuery;

export const getUserAccounts = (body: TGetUserAccountsInputDto) =>
  fetchData<TGetUserAccountsOutputDto>({
    url: `/users/${body.userId}/accounts`,
    queries: { emailAccount: body.emailAccount },
  }).then((result) => {
    switch (result.code) {
      case "000":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "getUserAccounts",
            payload: body,
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
