export const EAtomName = {
  // auth
  AuthStatus: "authStatus",

  // badge
  BadgeCount: "badgeCount",

  // blockchain
  Nft: "nft",

  // channels
  Channel: "channel",
  ChannelGroupList: "channelGroupList",
  ChannelInfo: "channelInfo",
  ChannelMemberPolicies: "channelMemberPolicies",

  // chat
  ChatList: "chatList",

  // communityCategory
  Categories: "categories",
  CategorizedDaos: "categorizedDaos",

  // dao
  Dao: "dao",
  DaoAsset: "daoAsset",
  DaoSubdomain: "daoSubdomain",
  DaoEnters: "daoEnters",

  // dm
  DmGroupS: "dmGroups",

  // env
  AppEnv: "appEnv",
  Env: "env",

  // live
  LiveCommentList: "liveCommentList",
  LivePostList: "livePostList",
  LivePost: "livePost",

  // member
  Member: "member",
  MemberList: "memberList",
  MemberSetting: "memberSetting",
  MembersOfChannel: "membersOfChannel",

  // merch
  MerchList: "merchList",

  // message
  MessageGroup: "messageGroup",
  MessageGroups: "messageGroups",
  MessgeList: "messgeList",

  // nftAsset
  NftContracts: "nftContracts",
  Nfts: "nfts",
  NftAsset: "nftAsset",

  // notification
  MobileSearchFilter: "mobileSearchFilter",
  Notification: "notification",

  // post
  LiveChannel: "liveChannel",
  PostRender: "postRender",
  PostList: "postList",
  Post: "post",
  PostCommentList: "postCommentList",
  PostThread: "postThread",

  // space
  SpaceMember: "spaceMember",

  // textEditor
  PlainTextEditor: "plainTextEditor",

  // users
  User: "user",
  UserAccounts: "userAccounts",

  // video
  VideoGlobalMuted: "videoGlobalMuted",
  VideoPlayback: "videoPlayback",

  // websocket
  MemberSubscriptionId: "memberSubscriptionId",

  // dm
  DmDrawer: "dmDrawer",
  InviteOrCreateSearchParams: "inviteOrCreateSearchParams",

  //report
  Report: "report",

  // market
  MarketRecommendedContractList: "marketRecommendedContractList",
  MarketTopMemberList: "marketTopMemberList",
  MarketLiveItemList: "marketLiveItemList",
  MarketContractNFTList: "marketContractNFTList",
  MarketContract: "marketContract",
  MarketNft: "marketNft",
  MarketOrderList: "marketOrderList",
  MarketProposalList: "marketProposalList",

  // search
  PostSearchResult: "postSearchResult",
  CommentSearchResult: "commentSearchResult",
  MemberSearchResult: "memberSearchResult",
  GiftokDetail: "giftokDetail",

  // youtube
  GoogleAccounts: "googleAccounts",

  //home
  DaoHome: "daoHome",
  DaoHomeMetadata: "daoHomeMetadata",
  DaosOfUser: "daosOfUser",

  //settings
  SettingsHomeChannelGroups: "settingsHomeChannelGroups",
  SettingsChannelsAtom: "settingsChannelsAtom",
  SettingsMemberRoles: "settingsMemberRoles",
  SettingsDaosRoles: "settingsDaosRoles",

  Bookmark: "bookmark",
  SettingsChannels: "settingsChannels",
  SettingDao: "settingDao",
} as const;

export type EAtomName = typeof EAtomName[keyof typeof EAtomName];
