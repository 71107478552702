import { isBoolean } from "@fxts/core";
import {
  differenceInCalendarYears,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  differenceInWeeks,
} from "date-fns";
import { format as fnsFormat } from "date-fns-tz";

type DateType = number | Date;

// https://github.com/heineiuo/date-fns-format-zone/blob/master/timezone.json
export const format = (date: DateType, formatStr = "PP") => {
  return fnsFormat(date, formatStr, {
    timeZone: "Asia/Seoul",
  });
};

type CommonTimeUnitOption = { limit?: number };

export type RelativeTimeOption = {
  seconds?: boolean | CommonTimeUnitOption;
  minutes?: boolean | CommonTimeUnitOption;
  hours?: boolean | CommonTimeUnitOption;
  days?: boolean | CommonTimeUnitOption;
  weeks?: boolean | CommonTimeUnitOption;
  absolute?: boolean | { template: string; templateWithYear: string }; // date-fns format string
};

export const getOptionLimit = (
  options: RelativeTimeOption,
  key: keyof RelativeTimeOption,
) => {
  const option = options[key] as CommonTimeUnitOption;
  if (key == "absolute") {
    return Infinity;
  }

  return option?.limit || Infinity;
};

type timeDiffOptionPreset = "basic" | "absolute";
export const commonTimeDiffOptions: Record<
  timeDiffOptionPreset,
  Partial<RelativeTimeOption>
> = {
  basic: {
    seconds: { limit: 60 },
    minutes: { limit: 60 },
    hours: { limit: 24 },
    days: { limit: 7 },
    absolute: true,
  },
  absolute: {
    absolute: true,
  },
};

export const formatRelativeTime = (
  leftDate: Date,
  rightDate: Date,
  options: RelativeTimeOption,
  unitConstants: {
    ago: string;
    now: string;
    m: string;
    h: string;
    d: string;
    y: string;
  },
) => {
  const replaceTemplate = (diff: number, unit: string) => {
    const template = `{n}{u} ${unitConstants.ago}`;

    return template.replace("{n}", diff.toString()).replace("{u}", unit);
  };

  if (options.seconds) {
    const limit = getOptionLimit(options, "seconds");
    const diff = differenceInSeconds(leftDate, rightDate);

    if (diff <= limit) {
      return unitConstants.now;
    }
  }

  if (options.minutes) {
    const limit = getOptionLimit(options, "minutes");
    const diff = differenceInMinutes(leftDate, rightDate);

    if (diff <= limit) {
      return replaceTemplate(diff, unitConstants.m);
    }
  }

  if (options.hours) {
    const limit = getOptionLimit(options, "hours");
    const diff = differenceInHours(leftDate, rightDate);

    if (diff <= limit) {
      return replaceTemplate(diff, unitConstants.h);
    }
  }

  if (options.days) {
    const limit = getOptionLimit(options, "days");
    const diff = differenceInDays(leftDate, rightDate);

    if (diff <= limit) {
      return replaceTemplate(diff, unitConstants.d);
    }
  }

  if (options.weeks) {
    const limit = getOptionLimit(options, "weeks");
    const diff = differenceInWeeks(leftDate, rightDate);

    if (diff <= limit) {
      return replaceTemplate(diff, unitConstants.y);
    }
  }

  if (options.absolute) {
    const isSameCalendarYear =
      differenceInCalendarYears(leftDate, rightDate) < 1;

    const absolute_option = options.absolute;

    if (isBoolean(absolute_option)) {
      return format(rightDate, isSameCalendarYear ? "MM.dd" : "yyyy.MM.dd");
    }

    return format(
      rightDate,
      isSameCalendarYear
        ? absolute_option.template
        : absolute_option.templateWithYear,
    );
  }

  return "";
};
