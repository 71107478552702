import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { listController } from "~/store/market/reducers";
import { MarketState } from "~/store/market/types";
import { RootState } from "..";

const initialState: MarketState = {
  liveItems: {
    resetCounter: 0,
    originLiveItems: null,
  },
  marketplaceVisibility: "PUBLIC",
};

export const marketSlice = createSlice({
  name: "market",
  initialState,
  reducers: {
    // Live items list control
    liveItemsResetCounterUp: listController.updateResetCounter.liveItems,
    liveItemsReset: listController.reset.liveItems,
    liveItemsSet: listController.set.liveItems,
    liveItemsMerge: listController.merge.liveItems,
    setMarketplaceVisibility: (
      state,
      action: PayloadAction<MarketState["marketplaceVisibility"]>,
    ) => {
      state.marketplaceVisibility = action.payload;
    },
  },
});

export const { setMarketplaceVisibility } = marketSlice.actions;

export const selectMarket = (state: RootState) => state.market;
