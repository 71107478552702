"use client";

import * as Sentry from "@sentry/react";

import { TMember } from "~/_types/TMember";
import { postAuthSignOut } from "../auth/postAuthSignOut";

/**
 * @TODO mobile action 추가
 * https://github.com/marpple/ciety-fe/blob/develop/src/utils/mobile/toMobile.ts
 */

type THaptic =
  | "impactLight"
  | "impactMedium"
  | "impactHeavy"
  | "rigid"
  | "soft"
  | "notificationSuccess"
  | "notificationWarning"
  | "notificationError";

const EAction = {
  StopLoadingWebview: "stopLoadingWebview",
  GestureEnabled: "gestureEnabled",
  IsOpenEmojiPicker: "isOpenEmojiPicker",
  HapticFeedback: "hapticFeedback",
  OpenDrawer: "openDrawer",
  ReloadApp: "reloadApp",
  RecentRTM: "recentRTM",
  RecentDM: "recentDM",
  OpenActionSheet: "actionSheet",
  GoBack: "goBack",
  Logout: "logout",
  copyClipboard: "copyClipboard",
  UpdateProfile: "updateProfile",
  OpenedModal: "openedModal",
  ClosedModal: "closedModal",
  OpenNotificationSettings: "openNotificationSettings",
  GetNotificationSettingStatus: "getNotificationSettingStatus",
  UpdateSpaceProfile: "updateSpaceProfile",
  OpenShareForInviteMember: "openShareForInviteMember",
  AuthLogin: "auth/login",
  NeedUpdate: "needUpdate",
} as const;

type EAction = typeof EAction[keyof typeof EAction];

type TMemberForSpaceProfileUpdate = {
  nickname?: string | null;
  bio?: string | null;
  imageUrl?: string;
  nftProfileId?: string;
};

type TActionParams<T extends EAction> = T extends
  | typeof EAction.StopLoadingWebview
  | typeof EAction.GestureEnabled
  | typeof EAction.IsOpenEmojiPicker
  ? boolean
  : T extends typeof EAction.HapticFeedback
  ? THaptic
  : T extends typeof EAction.OpenDrawer
  ? { error: "404" } | boolean
  : T extends typeof EAction.ReloadApp | typeof EAction.GoBack
  ? object
  : T extends typeof EAction.RecentDM
  ? number
  : T extends typeof EAction.RecentRTM
  ? { count: number }
  : T extends typeof EAction.OpenActionSheet
  ? {
      type: "list";
      /** name[] */
      divide?: string[];
      data: [
        /** actionName @ciety-app */
        string,
        {
          name: string;
          /** @default name */
          iconName?: string;
          [key: string]: unknown;
        },
      ][];
    }
  : T extends
      | typeof EAction.GoBack
      | typeof EAction.GetNotificationSettingStatus
  ? object
  : T extends typeof EAction.Logout
  ? { reason: string }
  : T extends typeof EAction.copyClipboard
  ? string
  : T extends typeof EAction.UpdateProfile
  ? TMember | TMemberForSpaceProfileUpdate
  : T extends typeof EAction.OpenedModal | typeof EAction.ClosedModal
  ? null
  : T extends typeof EAction.OpenNotificationSettings
  ? true
  : T extends typeof EAction.OpenShareForInviteMember
  ? {
      type: "member" | "leader";
      /** categoryId */
      category: string;
      dao: {
        id: string;
        displayName: string;
        subdomain: string;
      };
      member: { nickname: string };
    }
  : T extends typeof EAction.AuthLogin
  ? { token: string }
  : T extends typeof EAction.NeedUpdate
  ? { type: "needUpdate" }
  : never;

export const mobileAction = <T extends EAction>(params: {
  type: T;
  params: TActionParams<T>;
}) => {
  if (typeof window === "undefined") {
    return;
  }

  window?.ReactNativeWebView?.postMessage(JSON.stringify(params));
};

/**
 * 앱 로딩바 제거
 */
export const mobileStopLoadingWebview = () => {
  mobileAction({ type: "stopLoadingWebview", params: true });
};

export const mobileGestureEnabled = (
  params: TActionParams<"gestureEnabled">,
) => {
  mobileAction({ type: "gestureEnabled", params });
};

export const mobileIsOpenEmojiPicker = (
  params: TActionParams<"isOpenEmojiPicker">,
) => {
  mobileAction({ type: "isOpenEmojiPicker", params });
};

export const mobileHaptic = (params: TActionParams<"hapticFeedback">) => {
  mobileAction({
    type: "hapticFeedback",
    params,
  });
};

/**
 * 앱 리로드
 */
export const mobileReloadApp = () => {
  mobileAction({ type: EAction.ReloadApp, params: {} });
};

/**
 * drawer 열기
 */
export const mobileOpenDrawer = (params: { error: "404" } | boolean) => {
  mobileAction({ type: EAction.OpenDrawer, params });
};

/**
 * 모바일 하단 알림 뱃지카운트 세팅
 */
export const mobileSetNotificationCount = (
  params: TActionParams<typeof EAction.RecentRTM>,
) => {
  mobileAction({
    type: EAction.RecentRTM,
    params,
  });
};

/**
 * 모바일 하단 DM 뱃지카운트 세팅
 */
export const mobileSetDmCount = (params: { count: number }) => {
  mobileAction({
    type: EAction.RecentDM,
    params: params.count,
  });
};

/**
 * 모바일 액션 시트
 * */
export const mobileListActionSheet = (
  params: Omit<TActionParams<"actionSheet">, "type">,
) => {
  mobileHaptic("impactHeavy");
  mobileAction({
    type: EAction.OpenActionSheet,
    params: {
      type: "list",
      ...params,
    },
  });
};

/**
 * 뒤로가기
 */
export const mobileGoBack = () => {
  mobileAction({
    type: EAction.GoBack,
    params: {},
  });
};

/**
 * 웹에서 열린 모달의 열림 닫힘 여부를 앱에 전달
 * */
export const mobileOpenModal = () => {
  mobileAction({ type: "openedModal", params: null });
};
export const mobileCloseModal = () => {
  mobileAction({ type: "closedModal", params: null });
};

/** 비정상적인 상황의 로그아웃 발생의 원인 파악을 위해 이유를 필수로 작성합니다. */
export const mobileLogOut = async (params: TActionParams<"logout">) => {
  try {
    await postAuthSignOut({});
  } catch (err) {
    Sentry.captureException(err);
  } finally {
    mobileAction({ type: EAction.Logout, params });
  }
};

export const mobileCopyToClipboard = (
  params: TActionParams<"copyClipboard">,
) => {
  mobileAction({ type: EAction.copyClipboard, params });
};

export const mobileUpdateProfile = (params: TActionParams<"updateProfile">) => {
  mobileAction({ type: EAction.UpdateProfile, params });
};

export const mobileOpenNotificationSettings = () => {
  mobileAction({ type: EAction.OpenNotificationSettings, params: true });
};

export const mobileGetNotificationSettingStatus = () => {
  mobileAction({ type: EAction.GetNotificationSettingStatus, params: {} });
};

export const mobileOpenShareForInviteMember = (
  params: TActionParams<"openShareForInviteMember">,
) =>
  mobileAction({
    type: EAction.OpenShareForInviteMember,
    params,
  });

export const mobileOpenAppUpdatePage = () => {
  mobileAction({ type: "needUpdate", params: { type: "needUpdate" } });
};
