import { useRef, useState } from "react";
import { Swiper, type SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/css";

import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { cn } from "~/utils/css";
import { range, toArray } from "@fxts/core";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { MobileSectionContainer } from "~/_app/[dao]/home/components/mobile/MobileSectionContainer";
import { EmptyBanner } from "~/_app/[dao]/home/components/mobile/Banner/EmptyBanner";
import { useMemberStore } from "~/_store/member/useMemberStore";
import { DAO_HOME } from "~/_constants";

type TProps = {
  sectionId: string;
};

function Banner({ sectionId }: TProps) {
  const [isLoading, setIsLoading] = useState(true);
  const { getSection } = useDaoHomeStore();
  const { isAdmin } = useMemberStore();
  const banner = getSection(EDaoHomeSection.Banner, sectionId);

  const banners = banner.banners.slice(0, DAO_HOME.CONTENT_COUNT.MOBILE_BANNER);

  const bannerLength = banners.length;
  const swiperRef = useRef<SwiperRef | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const pagination = toArray(range(bannerLength));

  const isEmptyItem = bannerLength === 0;

  if (isEmptyItem && !isAdmin) {
    return null;
  }

  return (
    <MobileSectionContainer className="home-banner px-5 py-6">
      <h1 className="home-banner__title hidden">{banner.title}</h1>

      {isEmptyItem ? (
        <EmptyBanner />
      ) : (
        <>
          <Swiper
            slidesPerView={DAO_HOME.CONTENT_COUNT.MOBILE_BANNER_VIEW}
            slidesPerGroupAuto={true}
            spaceBetween={0}
            ref={swiperRef}
            onSlideChange={(swiper) => {
              setActiveIndex(swiper.activeIndex);
            }}
            onInit={() => setIsLoading(false)}
          >
            {banners.map((banner, index) => (
              <SwiperSlide
                key={`${banner.url}${index}`}
                style={{ opacity: isLoading ? "0" : "1" }}
              >
                <a
                  href={banner.url}
                  className="isolate block h-[6.979rem] w-full overflow-hidden rounded-md"
                  target="_blank"
                >
                  <img
                    // @TODO resize url 변경
                    src={banner.imageUrl}
                    className="max-h-full w-full object-cover"
                    alt=""
                  />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>

          {pagination.length > 1 && (
            <div className="pagination flex-center mt-5 gap-x-1">
              {pagination.map((index) => {
                return (
                  <div
                    className={cn(
                      "h-2 w-2 rounded-full",
                      activeIndex === index ? "bg-ciety-wh" : "bg-ciety-dk-90",
                    )}
                    key={index}
                  >
                    <span className="sr-only">{index}</span>
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </MobileSectionContainer>
  );
}

export { Banner };
