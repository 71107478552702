import { cn } from "~/_utils/converter/cn";

type TProps = {
  className?: string;
  description: string;
};

function EmptySectionDescription({ description, className }: TProps) {
  return (
    <p
      className={cn(
        `typo-r1 mt-3 whitespace-pre-line text-center text-ciety-gy`,
        className,
      )}
    >
      {description}
    </p>
  );
}

export { EmptySectionDescription };
