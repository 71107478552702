import { BigNumber } from "ethers";
import { formatUnits } from "ethers/lib/utils";

import { GetErc20BalanceToRpc } from "~/api/Market/types";
import { AllCryptoCurrencyTypes } from "~/modules/BlockChain/Order/types";
import { isAddressMatch } from "~/modules/Market/utils/address";

export const removeTrailingZeroBelowPoint = (value: string) => {
  return value.replace(/\.?0+$/, "");
};

export const splitNumberPart = (val: BigNumber | number | string) => {
  if (val instanceof BigNumber) {
    val = val.toString();
  }
  if (typeof val === "number") {
    val = "" + val;
  }

  const pointIdx = val.indexOf(".");
  const sanitizedInteger = removeStartingZeros(val.substring(0, pointIdx));
  const sanitizedFraction = removeEndingZeros(
    val.substring(pointIdx + 1, val.length),
  );

  return { integerPart: sanitizedInteger, fractionalPart: sanitizedFraction };
};

const removeStartingZeros = (value: string) =>
  handleNullStringToZero(value.replace(/^(?!0\.)(?!0$)0+/, ""));

const removeEndingZeros = (value: string) =>
  handleNullStringToZero(value.replace(/0+$/, ""));

export const handleNullStringToZero = (str: string) => {
  return str === "" ? "0" : str;
};

export const toFixed = (val: BigNumber | number | string, fixTo: number) => {
  if (fixTo >= 17 || fixTo < 0) {
    throw new Error("Max decimal is limited to 0 ~ 17 for javascript.");
  }
  const { integerPart, fractionalPart } = splitNumberPart(val);

  if (fractionalPart === "0") {
    return integerPart;
  } else {
    const resFraction = removeEndingZeros(fractionalPart.substring(0, fixTo));

    if (resFraction === "0") {
      return integerPart;
    } else {
      return integerPart + "." + resFraction;
    }
  }
};

export const limitStringInputAsNumber = (
  value: string,
  limitDecimalDigit?: number,
  limitIntegerDigit?: number,
) =>
  value
    .replace(new RegExp(`(\\d{0,${limitIntegerDigit ?? 16}})\\d*`), "$1") // MAX javascript integer limit to 16 digits
    .replace(/(^0)\d/g, "$1") // cannot input successive starting 0 (ex. 000.23, 09.23)
    .replace(/[^0-9.]/g, "") // only Number
    .replace(/(\..*)\./g, "$1") // Cannot input . more than once (ex. 32.33.1.1)
    .replace(
      new RegExp(`(\\d+\\.\\d{0,${limitDecimalDigit ?? 18}})\\d*`),
      "$1",
    );

export const limitStringInputAsNaturalNumber = (value: string) =>
  value
    .replace(/^0/, "") // limit starting 0
    .replace(/[^0-9]/g, "") // limit only number
    .replace(/(\d{0,16})\d*/, "$1"); // MAX javascript integer limit to 16 digits

export const getBalanceStringAppliedDecimal = ({
  currencyData,
  cryptoBalances,
  toFixDecimal = 5,
  divider = "1",
}: {
  currencyData: AllCryptoCurrencyTypes;
  cryptoBalances: GetErc20BalanceToRpc["response"][];
  toFixDecimal?: number;
  divider?: string;
}) => {
  const matchedCrypto = cryptoBalances.find((cryptoBalance) =>
    isAddressMatch(
      currencyData.tokenContractAddress,
      cryptoBalance.contractAddress,
    ),
  );

  if (matchedCrypto) {
    const { balance, decimal } = matchedCrypto;
    return toFixed(
      formatUnits(BigNumber.from(balance).div(divider).toString(), decimal),
      toFixDecimal,
    );
  } else {
    return null;
  }
};
