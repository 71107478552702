import { DefaultCommunityImageType } from "./types.gen";

// 수동 작업 X, ./scripts/gen-img-comp.mjs 파일을 참고해주세요.
export const loader: Map<DefaultCommunityImageType, string> = new Map()
  .set("C-Default-Cover-0-Skip", "community-image-c_default_cover_0_skip")
  .set("C-Default-Cover-1-Game", "community-image-c_default_cover_1_game")
  .set("C-Default-Cover-10-Food", "community-image-c_default_cover_10_food")
  .set("C-Default-Cover-11-Pet", "community-image-c_default_cover_11_pet")
  .set("C-Default-Cover-12-Music", "community-image-c_default_cover_12_music")
  .set("C-Default-Cover-13-Sports", "community-image-c_default_cover_13_sports")
  .set("C-Default-Cover-14-IT", "community-image-c_default_cover_14_it")
  .set(
    "C-Default-Cover-15-Writing",
    "community-image-c_default_cover_15_writing",
  )
  .set("C-Default-Cover-16-Travel", "community-image-c_default_cover_16_travel")
  .set("C-Default-Cover-17-Art", "community-image-c_default_cover_17_art")
  .set("C-Default-Cover-18-Faith", "community-image-c_default_cover_18_faith")
  .set(
    "C-Default-Cover-19-Fashion",
    "community-image-c_default_cover_19_fashion",
  )
  .set(
    "C-Default-Cover-2-Photography",
    "community-image-c_default_cover_2_photography",
  )
  .set(
    "C-Default-Cover-3-Lifestyle",
    "community-image-c_default_cover_3_lifestyle",
  )
  .set("C-Default-Cover-4-Movie", "community-image-c_default_cover_4_movie")
  .set("C-Default-Cover-5-Economy", "community-image-c_default_cover_5_economy")
  .set(
    "C-Default-Cover-6-Education",
    "community-image-c_default_cover_6_education",
  )
  .set("C-Default-Cover-7-Kpop", "community-image-c_default_cover_7_kpop")
  .set("C-Default-Cover-8-Politic", "community-image-c_default_cover_8_politic")
  .set("C-Default-Cover-9-Science", "community-image-c_default_cover_9_science")
  .set("C-Default-GateBG-0-Skip", "community-image-c_default_gatebg_0_skip")
  .set("C-Default-GateBG-1-Game", "community-image-c_default_gatebg_1_game")
  .set("C-Default-GateBG-10-Food", "community-image-c_default_gatebg_10_food")
  .set("C-Default-GateBG-11-Pet", "community-image-c_default_gatebg_11_pet")
  .set("C-Default-GateBG-12-Music", "community-image-c_default_gatebg_12_music")
  .set(
    "C-Default-GateBG-13-Sports",
    "community-image-c_default_gatebg_13_sports",
  )
  .set("C-Default-GateBG-14-IT", "community-image-c_default_gatebg_14_it")
  .set(
    "C-Default-GateBG-15-Writing",
    "community-image-c_default_gatebg_15_writing",
  )
  .set(
    "C-Default-GateBG-16-Travel",
    "community-image-c_default_gatebg_16_travel",
  )
  .set("C-Default-GateBG-17-Art", "community-image-c_default_gatebg_17_art")
  .set("C-Default-GateBG-18-Faith", "community-image-c_default_gatebg_18_faith")
  .set(
    "C-Default-GateBG-19-Fashion",
    "community-image-c_default_gatebg_19_fashion",
  )
  .set(
    "C-Default-GateBG-2-Photgraphy",
    "community-image-c_default_gatebg_2_photgraphy",
  )
  .set(
    "C-Default-GateBG-3-Lifestyle",
    "community-image-c_default_gatebg_3_lifestyle",
  )
  .set("C-Default-GateBG-4-Movie", "community-image-c_default_gatebg_4_movie")
  .set(
    "C-Default-GateBG-5-Economy",
    "community-image-c_default_gatebg_5_economy",
  )
  .set(
    "C-Default-GateBG-6-Education",
    "community-image-c_default_gatebg_6_education",
  )
  .set("C-Default-GateBG-7-Kpop", "community-image-c_default_gatebg_7_kpop")
  .set(
    "C-Default-GateBG-8-Politic",
    "community-image-c_default_gatebg_8_politic",
  )
  .set(
    "C-Default-GateBG-9-Science",
    "community-image-c_default_gatebg_9_science",
  )
  .set("C-Default-Profile-0-Skip", "community-image-c_default_profile_0_skip")
  .set("C-Default-Profile-1-Game", "community-image-c_default_profile_1_game")
  .set("C-Default-Profile-10-Food", "community-image-c_default_profile_10_food")
  .set("C-Default-Profile-11-Pet", "community-image-c_default_profile_11_pet")
  .set(
    "C-Default-Profile-12-Music",
    "community-image-c_default_profile_12_music",
  )
  .set(
    "C-Default-Profile-13-Sports",
    "community-image-c_default_profile_13_sports",
  )
  .set("C-Default-Profile-14-IT", "community-image-c_default_profile_14_it")
  .set(
    "C-Default-Profile-15-Writing",
    "community-image-c_default_profile_15_writing",
  )
  .set(
    "C-Default-Profile-16-Travel",
    "community-image-c_default_profile_16_travel",
  )
  .set("C-Default-Profile-17-Art", "community-image-c_default_profile_17_art")
  .set(
    "C-Default-Profile-18-Faith",
    "community-image-c_default_profile_18_faith",
  )
  .set(
    "C-Default-Profile-19-Fashion",
    "community-image-c_default_profile_19_fashion",
  )
  .set(
    "C-Default-Profile-2-Photography",
    "community-image-c_default_profile_2_photography",
  )
  .set(
    "C-Default-Profile-3-Lifestyle",
    "community-image-c_default_profile_3_lifestyle",
  )
  .set("C-Default-Profile-4-Movie", "community-image-c_default_profile_4_movie")
  .set(
    "C-Default-Profile-5-Economy",
    "community-image-c_default_profile_5_economy",
  )
  .set(
    "C-Default-Profile-6-Education",
    "community-image-c_default_profile_6_education",
  )
  .set("C-Default-Profile-7-Kpop", "community-image-c_default_profile_7_kpop")
  .set(
    "C-Default-Profile-8-Politic",
    "community-image-c_default_profile_8_politic",
  )
  .set(
    "C-Default-Profile-9-Science",
    "community-image-c_default_profile_9_science",
  );
