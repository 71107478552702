import { EAccount } from "~/_enums/EAccount";
import { ECode } from "~/_enums/ECode";
import { EErrorTypes } from "~/_enums/EErrorTypes";
import { ResultType } from "~/_utils/auth/types";

export const consumeConnectRequestId = async (params: {
  id: string;
  key: string;
}): Promise<
  ResultType<
    {
      token: string;
      user: {
        id: string;
        currentAccount: {
          accountType: EAccount;
          account: { id: string; address: string };
        };
      };
    },
    EErrorTypes
  >
> => {
  try {
    const { id, key } = params;
    const body = {
      key,
    };
    const url = `${process.env.REACT_APP_API_BASE_URL}/auth/connect/requests/${id}/consume`;
    const fetchResult = await fetch(url, {
      method: `POST`,
      headers: {
        [`Content-Type`]: `application/json`,
      },
      referrerPolicy: `no-referrer-when-downgrade`,
      body: JSON.stringify(body),
      credentials: `include`,
    })
      .then((res) => {
        return { ok: true as const, value: res };
      })
      .catch(() => {
        return {
          ok: false as const,
          value: EErrorTypes.UnCategorizedError,
        };
      });
    if (!fetchResult.ok) {
      return fetchResult;
    }
    const { value: res } = fetchResult;

    if (res.status !== 200) {
      return {
        ok: false,
        value: EErrorTypes.UnCategorizedError,
      };
    }

    const json = await res.json();
    if (json?.code === `002`) {
      return {
        ok: false,
        value: EErrorTypes.AlreadyUsedAccountError,
      };
    } else if (json.code === "101") {
      // 이미 등록된 인증 수단
      return {
        ok: false,
        value: EErrorTypes.AlreadyRegisteredError,
      };
    }
    if (json?.code !== ECode.Success) {
      return {
        ok: false,
        value: EErrorTypes.UnCategorizedError,
      };
    }

    const { token, user } = json.data;

    return { ok: true, value: { token, user } };
  } catch (error) {
    return {
      ok: false,
      value: EErrorTypes.UnCategorizedError,
    };
  }
};
