import { ResultType } from "../../types";
import { TMember } from "~/_types/TMember";
import { ECode } from "~/_enums/ECode";
import { TDao } from "~/_types/TDao";
import { postCommonEnterDao } from "~/_api/dao/postCommonEnterDao";

export const getIsJoinedDao = async (params: {
  subdomain: string;
  tokenGating?: boolean;
}): Promise<
  ResultType<
    | {
        isJoined: true;
        canEnter: boolean;
        kicked: false;
        member: TMember;
        dao: TDao;
        rejectedContractConditionIds: string[];
        rejectedCurrencyConditionIds: string[];
      }
    | {
        isJoined: false;
        canEnter: boolean;
        member: null;
        kicked: boolean;
        dao: TDao;
        rejectedContractConditionIds: string[];
        rejectedCurrencyConditionIds: string[];
      },
    typeof ECode.NotFound | typeof ECode.Error | typeof ECode.NotAuthenticated
  >
> => {
  try {
    const { code, data } = await postCommonEnterDao(
      {
        daoSubdomain: params.subdomain,
        tokenGating: params.tokenGating ? "include" : "exclude",
      },
      {},
    );
    if (code !== ECode.Success) {
      return {
        ok: false,
        value: ECode.Error,
      };
    }

    // 가입한 경우
    if (data.isEntered) {
      return {
        ok: true,
        value: {
          isJoined: true,
          canEnter: true,
          kicked: false,
          member: data.member,
          dao: data.dao,
          rejectedContractConditionIds: [],
          rejectedCurrencyConditionIds: [],
        },
      };
    }

    // 가입 대기
    if (data.canEnter) {
      return {
        ok: true,
        value: {
          isJoined: false,
          canEnter: true,
          kicked: false,
          member: null,
          dao: data.dao,
          rejectedContractConditionIds: [],
          rejectedCurrencyConditionIds: [],
        },
      };
    }

    const reason = data.reason;
    const dao = data.dao ?? null;
    if (dao == null || reason?.type === "NOT_FOUND") {
      return {
        ok: false,
        value: ECode.NotFound,
      };
    }

    if (reason?.type === "DELETE_MEMBER" || reason?.type === "KICK_MEMBER") {
      return {
        ok: true,
        value: {
          isJoined: false,
          canEnter: false,
          kicked: true,
          member: null,
          dao: dao,
          rejectedContractConditionIds: [],
          rejectedCurrencyConditionIds: [],
        },
      };
    }

    if (reason?.type === "TOKEN_GATING") {
      return {
        ok: true,
        value: {
          isJoined: false,
          canEnter: false,
          dao: dao,
          kicked: false,
          member: null,
          rejectedContractConditionIds: reason.contractConditionIdList,
          rejectedCurrencyConditionIds: reason.nativeTokenConditionIdList,
        },
      };
    }

    if (dao != null) {
      return {
        ok: true,
        value: {
          isJoined: false,
          canEnter: false,
          dao: dao,
          kicked: false,
          member: null,
          rejectedContractConditionIds: [],
          rejectedCurrencyConditionIds: [],
        },
      };
    }

    return {
      ok: false,
      value: ECode.Error,
    };
  } catch (error) {
    return {
      ok: false,
      value: ECode.Error,
    };
  }
};
