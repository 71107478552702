import { useCallback } from "react";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import {
  formatRelativeTime,
  RelativeTimeOption,
} from "~/_utils/converter/timeConverter";

export const useRelativeTimeFormat = () => {
  const { t } = useTranslation([ENameSpace.Common]);

  const formatRelativeTimeWithTranslation = useCallback(
    (leftDate: Date, rightDate: Date, options: RelativeTimeOption) =>
      formatRelativeTime(leftDate, rightDate, options, {
        ago: t(`${ENameSpace.Common}:hooks.time.Ago`),
        now: t(`${ENameSpace.Common}:hooks.time.Now`),
        m: t(`${ENameSpace.Common}:hooks.time.M`),
        h: t(`${ENameSpace.Common}:hooks.time.H`),
        d: t(`${ENameSpace.Common}:hooks.time.D`),
        y: t(`${ENameSpace.Common}:hooks.time.Y`),
      }),
    [t],
  );

  return { formatRelativeTimeWithTranslation };
};
