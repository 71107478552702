import deepEqual from "fast-deep-equal";
import { memo, ReactNode, useRef } from "react";
import { useParams } from "react-router-dom";

import { ParamsContext } from "~/context/reactRouter";

export const RouteParamWrapper = memo(function RouteParamWrapper({
  children,
}: {
  children: ReactNode;
}) {
  const params = useParams();

  const paramsRef = useRef(params);
  const paramsHistoryRef = useRef(params);

  // useEffect 를 흉내내지만 동기적으로 실행
  if (params != paramsHistoryRef.current) {
    paramsHistoryRef.current = params;
    if (!deepEqual(params, paramsRef.current)) {
      paramsRef.current = params;
    }
  }

  return (
    <ParamsContext.Provider value={paramsRef.current}>
      {children}
    </ParamsContext.Provider>
  );
});

/**
 * 이사 전까지 사용 예정
 */
export const RouteParamSubdomainWrapper = memo(function RouteParamWrapper({
  children,
}: {
  children: (params: { subdomain: string }) => ReactNode;
}) {
  const params = useParams() as { subdomain: string };

  const paramsRef = useRef(params);
  const paramsHistoryRef = useRef(params);

  // useEffect 를 흉내내지만 동기적으로 실행
  if (params != paramsHistoryRef.current) {
    paramsHistoryRef.current = params;
    if (!deepEqual(params, paramsRef.current)) {
      paramsRef.current = params;
    }
  }

  return (
    <ParamsContext.Provider value={paramsRef.current}>
      {children(params)}
    </ParamsContext.Provider>
  );
});
