import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { MarketItemNft } from "~/api/Market/types";

export type ThreadMarketState = {
  marketItem: MarketItemNft | null;
};

export const initialThreadMarketState: ThreadMarketState = {
  marketItem: null,
};

export const threadMarketSlice = createSlice({
  name: "threadMarket",
  initialState: initialThreadMarketState,
  reducers: {
    setMarketItem: (
      state,
      action: PayloadAction<ThreadMarketState["marketItem"]>,
    ) => {
      state.marketItem = action.payload;
    },
  },
});

export const threadMarketActions = threadMarketSlice.actions;
export default threadMarketSlice.reducer;
