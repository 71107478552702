import { CietyButton } from "~/_components/Button/CietyButton";
import { CoverImageWithGradient } from "~/_components/CoverImageWithGradient/CoverImageWithGradient";
import { ResizedImage } from "~/_components/Image/OptimalImage/ResizedImage";
import { Z_LEVELS } from "~/_constants/zIndexer";
import { EDevice } from "~/_enums/EDevice";
import { useDaoStore } from "~/_store/dao/useDaoStore";
import { assertIsDefined } from "~/_utils/assert";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { remToPx } from "~/_utils/converter/remConverter";
import { downloadApp } from "~/_utils/mobile/downloadApp";
import { usePureNavigate } from "~/context/reactRouter";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { Icon } from "~/modules/Common/Icons";
import { DefaultCommunityImage } from "~/modules/Common/Image";
import { toProfileImages } from "~/utils/converter";

export function EntranceContent() {
  const { t } = useTranslation([ENameSpace.Common, ENameSpace.Entrance]);
  const { dao } = useDaoStore();
  const navigate = usePureNavigate();
  assertIsDefined(dao);

  const handleDownloadApp = () => {
    downloadApp(dao.subdomain);
  };

  const handleExploreCommunity = () => {
    navigate(
      generateUrl({
        type: "exploreMain",
        subdomain: dao.subdomain,
      }),
    );
  };

  return (
    <div className="absolute inset-0">
      <section className="fixed inset-0 aspect-[23.44/31.25]">
        <CoverImageWithGradient
          variant={EDevice.Mobile}
          image={dao.mobileCoverImageUrl!}
        />
      </section>

      <div className="relative flex h-full w-full flex-col justify-between">
        <div className="relative mx-auto flex h-[100%-12.42rem] w-full flex-col justify-center pt-[6.21rem] text-center text-ciety-wh">
          <header>
            <h1 className="typo-headline-m w-full whitespace-pre-line break-words px-8 text-ciety-wh">
              {t(`${ENameSpace.Entrance}:Root.Title`, {
                displayName: `${dao.displayName}`,
              })}
            </h1>

            <section className="relative mx-8 mt-[2.938rem] rounded-[2.5rem] bg-gradient-to-b from-ciety-dk to-ciety-dk pb-[1.5rem] pt-[6.25rem]">
              {dao.profileImageUrl ? (
                <ResizedImage
                  alt=""
                  className="absolute left-0 right-0 top-[-1.375rem] mx-auto aspect-square w-[6.75rem] rounded-full"
                  toResizeUrlParams={{
                    url: dao.profileImageUrl,
                    width: remToPx(6.75),
                    qualityMultiplier: 2,
                  }}
                />
              ) : (
                <DefaultCommunityImage
                  type={toProfileImages(dao.categoryId ?? "0")}
                  className="absolute left-0 right-0 top-[-1.375rem] mx-auto aspect-square w-[6.75rem] rounded-full object-cover"
                />
              )}

              <div className="flex flex-col gap-y-2">
                <h3 className="typo-m1 mx-auto flex w-full items-center justify-center">
                  {dao.displayName}
                  {/* @TODO ppeeou: 뱃지 위치 정해지면 추가 */}
                  {/* {dao.isYoutubeCommunity && (
                <Icon iconType="Badge-Red" className="h-5 text-ciety-rd" />
              )} */}
                </h3>
                <div className="typo-r3 flex w-full items-center justify-center">
                  {t(`${ENameSpace.Common}:CommunityJoin.Members`)}
                  <div className="ml-[0.5rem] mr-[0.34rem] h-[0.625rem] w-[0.625rem] rounded-full bg-ciety-wh" />
                  {dao.memberCount.toLocaleString()}
                </div>
                {dao.description?.length > 0 && (
                  <p className="typo-r3 break-all px-6">{dao.description}</p>
                )}
              </div>
              <div className="w mt mx-auto mt-10 flex w-full flex-col gap-y-3 px-6">
                <CietyButton
                  form={{
                    variant: "LgFill",
                    theme: "lg",
                  }}
                  onClick={handleDownloadApp}
                >
                  {t(`${ENameSpace.Entrance}:Root.AcceptBtn`)}
                </CietyButton>

                <CietyButton
                  form={{
                    variant: "LgLine",
                    theme: "dk-60",
                  }}
                  onClick={handleExploreCommunity}
                >
                  {t(`${ENameSpace.Entrance}:Root.ExploreBtn`)}
                </CietyButton>
              </div>
            </section>
          </header>
        </div>
        <footer
          className="relative flex w-full flex-col gap-y-[0.5rem] py-[4.63rem] text-center text-ciety-bright-60"
          style={{
            zIndex: Z_LEVELS.GLOBAL.FOOTER,
          }}
        >
          <p className="typo-book-14">Your World, Your CIETY</p>
          <Icon iconType="CIETY-Logo" className="h-5 w-full" />
        </footer>
      </div>
    </div>
  );
}
