import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { DaoInfoType } from "~/modules/Router/Auth/types";

type Props = {
  fontClassName?: string;
  dao: Pick<DaoInfoType, "memberCount">;
};
export const CommunityMemberCount = ({ fontClassName, dao }: Props) => {
  const { t } = useTranslation([ENameSpace.Common] as const);

  return (
    <div className="flex items-center gap-x-2">
      <span className={`${fontClassName} text-ciety-wh`}>
        {t(`${ENameSpace.Common}:CommunityJoin.Members`)}
      </span>

      <div className="flex items-center gap-x-1">
        <div className="h-2.5 w-2.5 shrink-0 rounded-full bg-ciety-wh" />
        <span className={`${fontClassName} text-ciety-wh`}>
          {dao.memberCount.toLocaleString()}
        </span>
      </div>
    </div>
  );
};
