export const CIETY_MARKET_INFO = {
  NAME: "CIETY",
  VERSION: "1.0",
  SEAPORT: "0x00000000006c3852cbEf3e08E8dF289169EdE581",
  FEES: {
    EthereumMainnet: {
      address: "0x077711aD10d38C86FBD63aC9Ea87d3D6b1dee285",
      fee: "1.25", // 1.25 %
    },
    PolygonMainnet: {
      address: "0x9e8ed9194810d81C6D69035F792Ff820031339b5",
      fee: "1.25", // 1.25 %
    },
    Goerli: {
      address: "0x5B8De46fD6DB85A19Dac7A732FdA48240e2e0b46",
      fee: "1.25", // 1.25 %
    },
    Mumbai: {
      address: "0xe40536b09bedbe0f86e89d98d13da9d579eb859f",
      fee: "1.25", // 1.25 %
    },
  },
  ZONE: {
    // https://www.notion.so/marpple/ZONE-41fa7f603b3d4129b5592011208cbd75?pvs=4
    membersOnly: "0x2fe9CAf29F5667dC79425cDAb206E62aA01703BD",
  },
};

export const DOMAIN_SIG = `${CIETY_MARKET_INFO.NAME}_${CIETY_MARKET_INFO.VERSION}`;

export const INITIAL_FETCH_CTN = {
  topMembers: 15,
  liveItems: 15,
};
