import { ethers } from "ethers";

import { ErrorTypes, ResultType } from "../../types";

export const detectWallet = async (): Promise<
  ResultType<
    { isDetected: boolean; provider: ethers.providers.Web3Provider | null },
    ErrorTypes
  >
> => {
  try {
    const ethereum = window.ethereum;
    if (ethereum == null) {
      return { ok: true, value: { isDetected: false, provider: null } };
    }

    const ethereumProviders = ethereum.providers;
    if (Array.isArray(ethereumProviders)) {
      const ethereumProvider = ethereumProviders.find((provider) => {
        return provider?.isMetaMask ?? false;
      });
      if (ethereumProvider != null) {
        const provider = new ethers.providers.Web3Provider(ethereumProvider);
        return { ok: true, value: { isDetected: true, provider } };
      }
    }

    if (!(ethereum.isMetaMask ?? false)) {
      return { ok: true, value: { isDetected: false, provider: null } };
    }

    const provider = new ethers.providers.Web3Provider(ethereum);
    return { ok: true, value: { isDetected: true, provider } };
  } catch (error) {
    return {
      ok: false,
      value: ErrorTypes.UnCategorizedError,
    };
  }
};
