import React, { lazy, Suspense } from "react";
import { usePipVideoStore } from "~/_store/video/usePipVideoStore";

const PipVideo = lazy(() => import("~/_components/PipVideo/PipVideo"));

type TProps = {
  children: React.ReactNode;
};

export function PipVideoProvider({ children }: TProps) {
  const { pipVideo } = usePipVideoStore();

  return (
    <>
      {children}
      {pipVideo && (
        <Suspense>
          <PipVideo key={pipVideo.id} />
        </Suspense>
      )}
    </>
  );
}
