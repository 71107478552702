import { useContext, useEffect } from "react";

import { AuthStateContext } from "~/modules/Router/Auth/contexts/AuthStateContext";
import { useAppDispatch } from "~/store";
import { setUserId } from "~/store/globalSlice";

export const useUpdateGlobal = () => {
  const { userId } = useContext(AuthStateContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setUserId(userId));
  }, [dispatch, userId]);
};

/** @reference https://github.com/marpple/ciety-fe/blob/53fc06e8e2d38d9f2ef0e7137ea6754dd72033b3/src/context/global/index.tsx */
