import { createContext } from "react";

import { GetUsersUseridAccountsControllerSuccessOutputDtoData } from "~/api/apiRouteTypes/UsersRoute";

export type UserAccounts =
  GetUsersUseridAccountsControllerSuccessOutputDtoData | null;

export type AuthState = {
  userId: string;
  address: string;
  daoId: string;
  isEmailAccount: boolean;
  categoryId: string;
  accounts: UserAccounts;
  needWalletConnect: boolean;
  subdomain: string;
};
export const AuthStateContext = createContext<AuthState>({
  userId: ``,
  address: ``,
  daoId: ``,
  isEmailAccount: false,
  categoryId: "",
  accounts: null,
  needWalletConnect: false,
  subdomain: ``,
});
