import { forwardRef, ImgHTMLAttributes, useState } from "react";

type Props = ImgHTMLAttributes<HTMLImageElement>;

export const SmoothLoadableImage = forwardRef<HTMLImageElement, Props>(
  ({ alt, onLoad, className, ...restProps }, ref) => {
    const [isLoaded, setIsLoaded] = useState(false);

    const cssByIsLoaded = isLoaded ? "opacity-100" : "opacity-0";
    const fadeInCss = "transition-opacity duration-[250ms] ease-in";
    return (
      <img
        {...restProps}
        ref={ref}
        onLoad={(e) => {
          onLoad?.(e);
          setIsLoaded(true);
        }}
        className={`${cssByIsLoaded} ${fadeInCss} ${className} pointer-events-none`}
        alt={alt ?? ""}
      />
    );
  },
);
