import { cn } from "~/_utils/converter/cn";

const MAX_COUNT = 99;

type TProps = {
  count: number;
  className?: string;
};

function RedDotWithCount({ count, className }: TProps) {
  return (
    <span
      className={cn(
        "flex-center typo-m3 h-4 min-w-[1rem] rounded-full bg-ciety-rd px-[0.19rem] text-ciety-wh",
        className,
      )}
    >
      {count > MAX_COUNT ? `${MAX_COUNT}+` : count}
    </span>
  );
}

export { RedDotWithCount };
