import { apiBaseUrl } from "~/modules/Router/Auth/constant";
import {
  ErrorTypes,
  ResultType,
  VerificationJson,
} from "~/modules/Router/Auth/types";
import { fetchAdapter } from "~/utils/fetch";

export const getDaoAssetVerificationConditions = async (params: {
  id: string;
}): Promise<
  ResultType<
    Pick<VerificationJson, "contractConditions" | "currencyConditions">,
    ErrorTypes
  >
> => {
  try {
    const url = `${apiBaseUrl}/daos/${params.id}/asset-verifications`;

    const fetchResult = await fetchAdapter(url, {
      method: `GET`,
      credentials: `include`,
      referrerPolicy: `no-referrer-when-downgrade`,
    })
      .then((res) => {
        return { ok: true as const, value: res };
      })
      .catch(() => {
        return {
          ok: false as const,
          value: ErrorTypes.UnCategorizedError,
        };
      });
    if (!fetchResult.ok) {
      return fetchResult;
    }
    const { value: res } = fetchResult;

    const json = await res.json();
    if (json?.code !== `000`) {
      return {
        ok: false,
        value: ErrorTypes.UnCategorizedError,
      };
    }

    const verification = (json.data as Array<VerificationJson>).find(
      (verification) => {
        return verification.verificationType === `JOIN`;
      },
    );
    if (verification == null) {
      return {
        ok: true,
        value: { contractConditions: [], currencyConditions: [] },
      };
    }

    const { contractConditions, currencyConditions } = verification;

    return {
      ok: true,
      value: { contractConditions, currencyConditions },
    };
  } catch (error) {
    return {
      ok: false,
      value: ErrorTypes.UnCategorizedError,
    };
  }
};
