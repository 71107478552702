import classNames from "classnames";

/**
 * @description A simple JavaScript utility for conditionally joining classNames together.
 *
 * @example
 * cn("foo", "bar"); // => 'foo bar'
 * cn("foo", { bar: true }); // => 'foo bar'
 * cn({ "foo-bar": true }); // => 'foo-bar'
 * cn({ "foo-bar": false }); // => ''
 * cn({ foo: true }, { bar: true }); // => 'foo bar'
 * cn({ foo: true, bar: true }); // => 'foo bar'
 *
 * // lots of arguments of various types
 * cn("foo", { bar: true, duck: false }, "baz", { quux: true }); // => 'foo bar baz quux'
 *
 * // other falsy values are just ignored
 * cn(null, false, "bar", undefined, 0, 1, { baz: null }, ""); // => 'bar 1'
 *
 * @readme https://github.com/JedWatson/classnames#readme
 */
export const cn = classNames;
