import { createAddressVo } from "~/modules/Router/Auth/utils/auth/createAddressVo";
import { detectWallet } from "~/modules/Router/Auth/utils/auth/detectWallet";
import { ErrorTypes, ResultType } from "../../types";
import { getCodeFromWalletError } from "../getCodeFromWalletError";

export const getAddress = async (params: {
  isRequest: boolean;
}): Promise<ResultType<{ address: string }, ErrorTypes>> => {
  try {
    const result = await detectWallet();
    if (
      !result.ok ||
      !result.value.isDetected ||
      result.value.provider === null
    ) {
      return {
        ok: false,
        value: ErrorTypes.NotDetectedWalletError,
      };
    }

    const provider = result.value.provider;
    const method = params.isRequest
      ? (`eth_requestAccounts` as const)
      : (`eth_accounts` as const);
    let rawAddresses: unknown;
    try {
      rawAddresses = await provider.send(method, []);
    } catch (error) {
      const code = getCodeFromWalletError(error);
      if (code === 4001) {
        return {
          ok: false,
          value: ErrorTypes.DismissFromWalletError,
        };
      }
      if (code === -32002) {
        return {
          ok: false,
          value: ErrorTypes.AlreadyProcessingFromWalletError,
        };
      }
      return {
        ok: false,
        value: ErrorTypes.UnCategorizedError,
      };
    }
    if (!Array.isArray(rawAddresses)) {
      return {
        ok: false,
        value: ErrorTypes.InvalidAddressError,
      };
    }
    if (rawAddresses.length <= 0) {
      return {
        ok: false,
        value: ErrorTypes.DismissFromWalletError,
      };
    }
    const rawAddress = rawAddresses[0];
    if (typeof rawAddress !== `string`) {
      return {
        ok: false,
        value: ErrorTypes.InvalidAddressError,
      };
    }

    const addressVoCreateResult = createAddressVo({
      address: rawAddress,
    });
    if (!addressVoCreateResult.ok) {
      return addressVoCreateResult;
    }
    return {
      ok: true,
      value: { address: addressVoCreateResult.value.address },
    };
  } catch (error) {
    return {
      ok: false,
      value: ErrorTypes.UnCategorizedError,
    };
  }
};
