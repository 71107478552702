import { EDevice } from "~/_enums/EDevice";
import { ReactNode } from "react";

type TProps = {
  image: string | ReactNode | null;
  variant: EDevice;
};

function CoverImageWithGradient({ image, variant }: TProps) {
  return (
    <div className="absolute inset-0">
      {typeof image === "string" ? (
        <img src={image} className="h-full w-full object-cover" alt="" />
      ) : (
        image
      )}
      <div className="absolute inset-0 bg-ciety-bk/50" />
      {variant === "Desktop" ? (
        <div className="absolute inset-0 bg-gradient-to-t from-ciety-bk" />
      ) : (
        <div className="absolute left-0 bottom-0 aspect-[375/295] w-full bg-gradient-to-t from-ciety-bk" />
      )}
    </div>
  );
}

export { CoverImageWithGradient };
