import { Navigate, Route, Routes } from "react-router-dom";

import { env } from "~/env";
import { Error } from "~/router/routes";

export const CommonFallbackRoutes = () => (
  <Routes>
    <Route path={env.ERROR_PATH}>
      <Route path="404" element={<Error code="404" />} />
      <Route path="406" element={<Error code="406" />} />
      <Route path="500" element={<Error code="500" />} />
      <Route path="*" element={<Error code="*" />} />
    </Route>
    <Route
      path="*"
      element={<Navigate replace to={`${env.ERROR_PATH}/404`} />}
    />
  </Routes>
);
