import { TChannelChatMessage } from "~/modules/Posting/Message/shared/types/message";
import { createMessageSlice } from "~/modules/Posting/Message/shared/utils/createMessageSlice";

const searchParams = new URLSearchParams(document.location.search);
const currentChannelType = searchParams.get("channelType");
const initialHighlightId = searchParams.get("highlightId");

const isChatChannel = currentChannelType === "CHAT";

const channelChatMessageSlice = createMessageSlice<TChannelChatMessage>({
  name: "channelChatMessage",
  initialState: {
    highlightId: (isChatChannel && initialHighlightId) || null,
    initialScrollBottom: true,
  },
});

export const channelChatMessageActions = channelChatMessageSlice.actions;

export default channelChatMessageSlice.reducer;
