export const extractNumFromStr = ({
  str,
  withSign,
}: {
  str: string;
  withSign: boolean;
}) => {
  const res = withSign ? /[-+]?\d*\.?\d+/.exec(str) : /\d*\.?\d+/.exec(str);
  if (res == null) {
    return 0;
  } else {
    return Number(res[0]);
  }
};

export const parseSizeStringInsideBracket = ({ str }: { str: string }) => {
  const regex = /\[(\d*?)(\D*?)\]/;
  const res = regex.exec(str);

  if (res == null) {
    return null;
  } else {
    return { number: Number(res[1]), unit: res[2] };
  }
};
