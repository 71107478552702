import { useMemo } from "react";

import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { VerificationJson, type Status } from "../types";
import { isZero } from "../utils/isZero";

export const useWalletConnect = (params: {
  verifications: Pick<
    VerificationJson,
    "currencyConditions" | "contractConditions"
  > | null;
  status: Status;
}) => {
  const { verifications, status } = params;

  const currencyConditions = useMemo(
    () => verifications?.currencyConditions ?? [],
    [verifications],
  );

  const hasCurrencyConditions = useMemo<boolean>(
    () =>
      currencyConditions.length > 0 &&
      currencyConditions.some(({ amount }) => !isZero(amount)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currencyConditions.length],
  );

  const contractConditions = useMemo(
    () => verifications?.contractConditions ?? [],
    [verifications],
  );

  const hasContractConditions = useMemo<boolean>(
    () => contractConditions.length > 0,
    [contractConditions.length],
  );

  const isSimplifiedLayout = useMemo<boolean>(() => {
    return !hasCurrencyConditions && !hasContractConditions;
  }, [hasCurrencyConditions, hasContractConditions]);

  const messageTextColor = useMemo<"text-ciety-yl" | "text-ciety-rd">(
    () => (status === "notSatisfied" ? "text-ciety-rd" : "text-ciety-yl"),
    [status],
  );

  const { t } = useTranslation([ENameSpace.Common]);

  const message = useMemo<string>(() => {
    switch (status) {
      case "notSatisfied":
        return t(`${ENameSpace.Common}:TokenGating.ErrorMessage.Required`);
      default:
        return "";
    }
  }, [status, t]);

  return {
    hasCurrencyConditions,
    hasContractConditions,
    isSimplifiedLayout,
    messageTextColor,
    message,
  };
};
