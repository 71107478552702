import { ECode } from "~/_enums/ECode";
import { TApiConfig } from "~/_libs/fetch/fetchConfig";
import { fetchPostJson } from "~/_libs/fetch/fetchPostJson";
import { TGoogleAccount } from "~/_types/TGoogleAccount";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

type TOutputDto = {
  code: typeof ECode.Success;
  data: TGoogleAccount[];
};

export async function postCommonGoogleAccounts(config: TApiConfig) {
  return fetchPostJson<TOutputDto>(
    `/v5draft/common-google-accounts`,
    {},
    config,
  ).then((result) => {
    switch (result.code) {
      case "000":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postCommonGoogleAccounts",
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
}
