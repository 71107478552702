import { detectWallet } from "~/modules/Router/Auth/utils/auth/detectWallet";
import { ErrorTypes, ResultType } from "../../types";
import { getCodeFromWalletError } from "../getCodeFromWalletError";

export const signConnect = async (params: {
  address: string;
  message: string;
}): Promise<ResultType<{ signature: string }, ErrorTypes>> => {
  try {
    const result = await detectWallet();
    if (
      !result.ok ||
      !result.value.isDetected ||
      result.value.provider === null
    ) {
      return {
        ok: false,
        value: ErrorTypes.NotDetectedWalletError,
      };
    }

    const provider = result.value.provider;
    const address = params.address;
    const message = params.message;

    let signature: unknown;
    try {
      signature = await provider.send(`eth_signTypedData_v4`, [
        address,
        message,
      ]);
    } catch (error) {
      const code = getCodeFromWalletError(error);
      if (code === 4001) {
        return {
          ok: false,
          value: ErrorTypes.DismissFromWalletError,
        };
      }
      return {
        ok: false,
        value: ErrorTypes.UnCategorizedError,
      };
    }
    if (typeof signature !== `string`) {
      return {
        ok: false,
        value: ErrorTypes.InvalidSignatureError,
      };
    }

    return { ok: true, value: { signature } };
  } catch (error) {
    return {
      ok: false,
      value: ErrorTypes.UnCategorizedError,
    };
  }
};
