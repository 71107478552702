import { DefaultCommunityImageType } from "~/modules/Common/Image/components/DefaultCommunityImage/types.gen";
import { DefaultProfileImageType } from "~/modules/Common/Image/components/DefaultProfileImage/types.gen";

const COVER_IMAGES: Record<string, DefaultCommunityImageType> = {
  "0": "C-Default-Cover-0-Skip",
  "1": "C-Default-Cover-1-Game",
  "10": "C-Default-Cover-10-Food",
  "11": "C-Default-Cover-11-Pet",
  "12": "C-Default-Cover-12-Music",
  "13": "C-Default-Cover-13-Sports",
  "14": "C-Default-Cover-14-IT",
  "15": "C-Default-Cover-15-Writing",
  "16": "C-Default-Cover-16-Travel",
  "17": "C-Default-Cover-17-Art",
  "18": "C-Default-Cover-18-Faith",
  "19": "C-Default-Cover-19-Fashion",
  "2": "C-Default-Cover-2-Photography",
  "3": "C-Default-Cover-3-Lifestyle",
  "4": "C-Default-Cover-4-Movie",
  "5": "C-Default-Cover-5-Economy",
  "6": "C-Default-Cover-6-Education",
  "7": "C-Default-Cover-7-Kpop",
  "8": "C-Default-Cover-8-Politic",
  "9": "C-Default-Cover-9-Science",
};

const BACKGROUND_IMAGES: Record<string, DefaultCommunityImageType> = {
  "0": "C-Default-GateBG-0-Skip",
  "1": "C-Default-GateBG-1-Game",
  "10": "C-Default-GateBG-10-Food",
  "11": "C-Default-GateBG-11-Pet",
  "12": "C-Default-GateBG-12-Music",
  "13": "C-Default-GateBG-13-Sports",
  "14": "C-Default-GateBG-14-IT",
  "15": "C-Default-GateBG-15-Writing",
  "16": "C-Default-GateBG-16-Travel",
  "17": "C-Default-GateBG-17-Art",
  "18": "C-Default-GateBG-18-Faith",
  "19": "C-Default-GateBG-19-Fashion",
  "2": "C-Default-GateBG-2-Photgraphy",
  "3": "C-Default-GateBG-3-Lifestyle",
  "4": "C-Default-GateBG-4-Movie",
  "5": "C-Default-GateBG-5-Economy",
  "6": "C-Default-GateBG-6-Education",
  "7": "C-Default-GateBG-7-Kpop",
  "8": "C-Default-GateBG-8-Politic",
  "9": "C-Default-GateBG-9-Science",
};

const PROFILE_IMAGES: Record<string, DefaultCommunityImageType> = {
  "0": "C-Default-Profile-0-Skip",
  "1": "C-Default-Profile-1-Game",
  "10": "C-Default-Profile-10-Food",
  "11": "C-Default-Profile-11-Pet",
  "12": "C-Default-Profile-12-Music",
  "13": "C-Default-Profile-13-Sports",
  "14": "C-Default-Profile-14-IT",
  "15": "C-Default-Profile-15-Writing",
  "16": "C-Default-Profile-16-Travel",
  "17": "C-Default-Profile-17-Art",
  "18": "C-Default-Profile-18-Faith",
  "19": "C-Default-Profile-19-Fashion",
  "2": "C-Default-Profile-2-Photography",
  "3": "C-Default-Profile-3-Lifestyle",
  "4": "C-Default-Profile-4-Movie",
  "5": "C-Default-Profile-5-Economy",
  "6": "C-Default-Profile-6-Education",
  "7": "C-Default-Profile-7-Kpop",
  "8": "C-Default-Profile-8-Politic",
  "9": "C-Default-Profile-9-Science",
};

const LEADER_PROFILE_IMAGES: Record<string, DefaultProfileImageType> = {
  "0": "C-Leader-Profile-0-Skip",
  "1": "C-Leader-Profile-1-Game",
  "10": "C-Leader-Profile-10-Food",
  "11": "C-Leader-Profile-11-Pet",
  "12": "C-Leader-Profile-12-Music",
  "13": "C-Leader-Profile-13-Sports",
  "14": "C-Leader-Profile-14-IT",
  "15": "C-Leader-Profile-15-Writing",
  "16": "C-Leader-Profile-16-Travel",
  "17": "C-Leader-Profile-17-Art",
  "18": "C-Leader-Profile-18-Faith",
  "19": "C-Leader-Profile-19-Fashion",
  "2": "C-Leader-Profile-2-Photography",
  "3": "C-Leader-Profile-3-Lifestyle",
  "4": "C-Leader-Profile-4-Movie",
  "5": "C-Leader-Profile-5-Economy",
  "6": "C-Leader-Profile-6-Education",
  "7": "C-Leader-Profile-7-Kpop",
  "8": "C-Leader-Profile-8-Politic",
  "9": "C-Leader-Profile-9-Science",
};

export const toCoverImages = (categoryId: string) => COVER_IMAGES[categoryId];
export const toBackgroundImages = (categoryId: string) =>
  BACKGROUND_IMAGES[categoryId];
export const toProfileImages = (categoryId: string) =>
  PROFILE_IMAGES[categoryId];
export const toLeaderProfileImages = (categoryId: string) =>
  LEADER_PROFILE_IMAGES[categoryId];
