import React, { createContext, useContext } from "react";

import { getUseContextError } from "~/utils/converter";

type InitialProps = {
  uuidForEditor: () => string;
};

export const uuid = (prefix?: string) => {
  let uuid = Math.floor(Math.random() * 10000);
  return () => (prefix ? `${prefix}-${uuid++}` : `${uuid++}`);
};

const UuidContext = createContext<InitialProps | null>(null);

function UuidProvider({ children }: { children: React.ReactNode }) {
  return (
    <UuidContext.Provider
      value={{
        uuidForEditor: uuid("editor"),
      }}
    >
      {children}
    </UuidContext.Provider>
  );
}

export const useUuidContext = () => {
  const uuidContext = useContext(UuidContext);

  if (!uuidContext) throw new Error(getUseContextError("UuidContext", "Uuid"));

  return uuidContext;
};

export default UuidProvider;
