import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ControllerDtoDaoOutputDto } from "~/api/apiRouteTypes/UsersRoute";
import { RootState } from ".";

export interface TGlobal {
  joinedDaoSubdomains: string[];
  memberSubscriptionId: string | null;
  mode: ControllerDtoDaoOutputDto["mode"] | null;
  userId: string | null;
}

interface TGlobalState {
  global: TGlobal;
}

const initialState: TGlobalState = {
  global: {
    joinedDaoSubdomains: [],
    memberSubscriptionId: null,
    mode: null,
    userId: null,
  },
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    setGlobalJoinedDaoSubdomains: (
      state,
      action: PayloadAction<TGlobal["joinedDaoSubdomains"]>,
    ) => {
      state.global.joinedDaoSubdomains = action.payload;
    },
    setGlobalMemberSubscriptionId: (
      state,
      action: PayloadAction<TGlobal["memberSubscriptionId"]>,
    ) => {
      state.global.memberSubscriptionId = action.payload;
    },
    setGlobalMode: (state, action: PayloadAction<TGlobal["mode"]>) => {
      state.global.mode = action.payload;
    },
    setUserId: (state, action: PayloadAction<TGlobal["userId"]>) => {
      state.global.userId = action.payload;
    },
  },
});

export const {
  setGlobalJoinedDaoSubdomains,
  setGlobalMemberSubscriptionId,
  setGlobalMode,
  setUserId,
} = globalSlice.actions;

export const selectGlobal = (state: RootState) => state.global.global;

export default globalSlice.reducer;
