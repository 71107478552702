import { Icon, IconType } from "~/modules/Common/Icons/Icon";

type Props = {
  title: "Oops!" | "404" | "500" | "401" | "403";
  mainText: string;
  subText: string | Array<string>;
  buttonText?: string;
  onClickButton?: () => void;
};

export const ErrorLayout = ({
  title,
  mainText,
  subText,
  buttonText,
  onClickButton,
}: Props) => {
  const ICONS_BY_TITLE: {
    [key in Props["title"]]: {
      width: `${number}rem`;
      iconType: IconType;
    };
  } = {
    "Oops!": {
      width: "20.188rem",
      iconType: "Error-oops",
    },
    "401": {
      width: "11.5rem",
      iconType: "Error-401",
    },
    "403": {
      width: "12.875rem",
      iconType: "Error-403",
    },
    "404": {
      width: "13.5rem",
      iconType: "Error-404",
    },
    "500": {
      width: "12.375rem",
      iconType: "Error-500",
    },
  };

  return (
    <div className="flex-center h-fit w-fit flex-col">
      <Icon
        className="mb-5"
        iconType={ICONS_BY_TITLE[title].iconType}
        width={ICONS_BY_TITLE[title].width}
        height="8.753rem"
      />
      <h1 className="typo-headline-m mb-2.5 text-ciety-wh">{mainText}</h1>
      <p className="typo-r2 text-center text-ciety-gy">
        {Array.isArray(subText)
          ? subText.map((text, index) => (
              <span key={index}>
                {text}
                <br />
              </span>
            ))
          : subText}
      </p>
      <div className="mt-10 flex items-center space-x-4">
        {onClickButton && (
          <button
            type="button"
            className="flex h-10 w-[9.625rem] items-center justify-center gap-x-1 rounded-full bg-ciety-wh text-ciety-dk"
            onClick={onClickButton}
          >
            <span className="typo-m1">{buttonText}</span>
          </button>
        )}
        {title === "500" && (
          <button
            type="button"
            className="flex h-10 w-[9.625rem] items-center justify-center gap-x-1 rounded-full bg-ciety-wh text-ciety-dk"
            onClick={() => {
              history.back();
            }}
          >
            <span className="typo-m1">Previous</span>
          </button>
        )}
      </div>
    </div>
  );
};
