export const isPolygonFriendlyDao = ({ subdomain }: { subdomain?: string }) => {
  return !!subdomain && POLYGON_FRIENDLY_DAO_LIST.includes(subdomain);
};

const POLYGON_FRIENDLY_DAO_LIST = [
  "ciety",
  "aavegotchi",
  "lemontreeguild",
  "maplestory",
  "session",
];
