import { ECode } from "~/_enums/ECode";
import { TApiConfig } from "~/_libs/fetch/fetchConfig";
import { fetchPostJson } from "~/_libs/fetch/fetchPostJson";
import { TAuthConnectRequestsConsumeSuccessData } from "~/_types/TAuthRequestsConsumeSuccessData";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

type TInputDto = {
  authRequestId: string;

  /**
   * @default "a9c98c03-858e-4dd6-ae7e-a7d20a5fdf96"
   * 인증 요청 검증 키
   */
  key: string;
};

type TSuccessOutputDto = {
  code: typeof ECode.Success;
  data: TAuthConnectRequestsConsumeSuccessData;
};

type TErrMaximumOutputDto = {
  /** 최대 요청 횟수 초과 or 유효시간 초과 or 이미 사용한 토큰 */
  code: "010";
  data: null;
};

type TErrBadRequestOutputDto = {
  /** 잘못된 요청 */
  code: "003";
  data: string;
};

type TErrAlreadyUsedOutputDto = {
  code: "101";
  data: string;
};

type TOutputDto =
  | TSuccessOutputDto
  | TErrMaximumOutputDto
  | TErrBadRequestOutputDto
  | TErrAlreadyUsedOutputDto;

export const postAuthConnectRequestsConsume = (
  { authRequestId, ...body }: TInputDto,
  config: TApiConfig,
) => {
  return fetchPostJson<TOutputDto>(
    `/auth/connect/requests/${authRequestId}/consume`,
    {
      body,
    },
    config,
  ).then((result) => {
    switch (result.code) {
      case "000":
      case "003":
      case "010":
      case "101":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postAuthConnectRequestsConsume",
            payload: body,
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
};
