import { ethers } from "ethers";

import { ErrorTypes, ResultType } from "~/modules/Router/Auth/types";

export const createAddressVo = (params: {
  address: string;
}): ResultType<{ address: string }, ErrorTypes> => {
  try {
    if (!ethers.utils.isAddress(params.address)) {
      return {
        ok: false,
        value: ErrorTypes.InvalidAddressError,
      };
    }
    return {
      ok: true,
      value: { address: params.address.toLowerCase() },
    };
  } catch {
    return {
      ok: false,
      value: ErrorTypes.InvalidAddressError,
    };
  }
};
