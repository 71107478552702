import { ControllerDtoDaoOutputDto } from "~/api/apiRouteTypes/DaosRoute";
import { fetchData } from "~/utils/fetch";
import { ECode } from "~/_enums/ECode";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

type TSuccessResult = {
  code: typeof ECode.Success;
  data: ControllerDtoDaoOutputDto;
};

/** @description 권한이 없을 경우 */
type TNoPermissionResult = {
  code: "001";
  data: null;
};

/** @description 존재하지 않을 경우 */
type TNotFoundResult = {
  code: "100";
  data: null;
};

export type TGetDaoOutputDto =
  | TSuccessResult
  | TNoPermissionResult
  | TNotFoundResult;

type Props = { daoId: string };

/** @description DAO ID로 정보 조회 */
export const getDao = ({ daoId }: Props) =>
  fetchData<TGetDaoOutputDto>({
    url: `/daos/${daoId}`,
  }).then((result) => {
    switch (result.code) {
      case "000":
      case "001":
      case "100":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "getDao",
            payload: {
              daoId,
            },
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
