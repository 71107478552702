"use client";

import { escapeHtml } from "~/_utils/converter/escapeHtml";
import { renderToString } from "../converter/renderToString";
import { ReactNode } from "react";
import i18next from "i18next";
import { ELanguage } from "~/i18n/@types/types";

type TConfirmButton = {
  text: string;
  color: "green" | "red" | "white" | "gray";
  value?: boolean;
};

type Props = {
  body: ReactNode;
  left?: TConfirmButton;
  right?: TConfirmButton;
};

export async function cietyConfirm({
  left = {
    color: "white",
    text: i18next.language === ELanguage.Ko ? "취소" : "Cancel",
    value: false,
  },
  right = {
    color: "green",
    text: i18next.language === ELanguage.Ko ? "확인" : "Ok",
    value: true,
  },
  body,
}: Props): Promise<boolean> {
  const _body =
    typeof body == "string" ? escapeHtml(body) : await renderToString(body);

  return new Promise((resolve) => {
    const html = `
      <div id="confirm-modal-container" class="modal-container">
        <div id="confirm-modal" class="modal-wrapper">
          <div id="confirm-modal-content" class="modal-content">${_body}</div>
          <div class="modal-button-wrapper">
            <button
              id="confirm-modal-left-button"
              class="modal-button text-${left.color}"
              type="button"
            >${escapeHtml(left.text)}</button>
            <button
              id="confirm-modal-right-button"
              class="modal-button text-${right.color}"
              type="button"
            >${escapeHtml(right.text)}</button>
          </div>
        </div>
      </div>
    `;

    const wrapper = document.createElement("div");
    wrapper.innerHTML = html;
    const confirmContainerNode = wrapper.children[0];

    const closeModal = () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.querySelector("html")?.classList.remove("overflow-hidden");
      document.body.removeChild(confirmContainerNode);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" || event.key === "Escape") {
        event.preventDefault();
        closeModal();
        if (event.key === "Enter") {
          resolve(right.value ?? true);
        } else {
          resolve(left.value ?? false);
        }
      }
    };

    const cancelBtn = wrapper.querySelector(
      "#confirm-modal-left-button",
    ) as HTMLButtonElement;
    cancelBtn?.addEventListener("click", () => {
      closeModal();
      resolve(left.value ?? false);
    });
    wrapper
      .querySelector("#confirm-modal-right-button")
      ?.addEventListener("click", () => {
        closeModal();
        resolve(right.value ?? true);
      });

    document.addEventListener("keydown", handleKeyDown);
    document.querySelector("html")?.classList.add("overflow-hidden");
    document.body.appendChild(confirmContainerNode);
    cancelBtn?.focus();
  });
}
