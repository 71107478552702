import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TSocketResDaoLiveStreamingJobSubPayload } from "~/modules/Posting/Video/Live/types/liveStreamingJob";
import type { RootState } from "..";

export interface TLiveStreaming {
  liveChannelIds: Array<string> /** 현재 라이브 중인 채널 Id */;
  liveStreamingJobState: Pick<
    TSocketResDaoLiveStreamingJobSubPayload,
    "liveStreamingResourceId" | "channelId" | "status"
  > | null;
}

interface TLiveStreamingState {
  liveStreaming: TLiveStreaming;
}

export const initialLiveStreaming: TLiveStreamingState = {
  liveStreaming: {
    liveChannelIds: [],
    liveStreamingJobState: null,
  },
};

export const liveStreamingSlice = createSlice({
  name: "liveStreaming",
  initialState: initialLiveStreaming,
  reducers: {
    setLiveChannelIds: (
      state,
      action: PayloadAction<TLiveStreaming["liveChannelIds"]>,
    ) => {
      state.liveStreaming.liveChannelIds = action.payload;
    },
    setLiveStreamingJobState: (
      state,
      action: PayloadAction<TLiveStreaming["liveStreamingJobState"]>,
    ) => {
      state.liveStreaming.liveStreamingJobState = action.payload;
    },
  },
});

export const { setLiveChannelIds, setLiveStreamingJobState } =
  liveStreamingSlice.actions;

export const selectLiveStreaming = (state: RootState) =>
  state.liveStreaming.liveStreaming;

export default liveStreamingSlice.reducer;
