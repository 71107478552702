import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";

import { env } from "~/env";
import { buildQuery } from "~/utils/calibrator";

type Props = {
  rootPath?: `/${string}`;
};

export const getBaseQuery = ({ rootPath }: Props) => {
  return fetchBaseQuery({
    baseUrl: `${env.API_BASE_URL}${rootPath ?? ""}`,
    referrerPolicy: "no-referrer-when-downgrade",
    credentials: "include",
    paramsSerializer: buildQuery,
    timeout: 60000,
  });
};
