import { MarketSupportedChain } from "~/api/dto";
import {
  ellipseItemName,
  ellipseTokenId,
} from "~/modules/BlockChain/Order/utils/OrderHandling";
import {
  testChainsForMarketplace,
  TestChainsForMarketplace,
} from "~/modules/Market/types";

export const getNftNameWithTokenId = (
  nftName: string | null,
  tokenId: string,
) => {
  return `${ellipseItemName(sanitizeNftName(nftName))} #${ellipseTokenId(
    tokenId,
  )}`;
};

export const sanitizeNftName = (nftName: string | null) =>
  nftName ? nftName.replace(/#[\s0-9]+/, "").trim() : "Item";

export const nameSanitizer = {
  contract: (name: string | null) => name ?? "Collection",
  item: (name: string | null, tokenId: string) =>
    getNftNameWithTokenId(name, tokenId),
};

export const isPrevNextArrayJsonEqual =
  <T, R extends keyof T>(key: R) =>
  (prev: T, next: T) => {
    return JSON.stringify(prev[key]) === JSON.stringify(next[key]);
  };

const excludes = <T extends U, U>(arr: ReadonlyArray<T>, el: U): el is T => {
  return !arr.includes(el as T);
};

export const filterOutTestnets = (
  chain: MarketSupportedChain,
): chain is Exclude<MarketSupportedChain, TestChainsForMarketplace> =>
  excludes(testChainsForMarketplace, chain);

export const isPlural = (value: number) => value > 1;
export const chainToDisplayName: Record<MarketSupportedChain, string> = {
  EthereumMainnet: "Ethereum",
  Goerli: "Goerli",
  PolygonMainnet: "Polygon",
  Mumbai: "Mumbai",
};
