import numbro from "numbro";

import { extractInteger } from "~/_utils/converter/numberConverter";

export const toTokenDisplayPriceFromEth = ({
  eth,
  displayDecimals = 5,
  average = true,
}: {
  eth: string;
  displayDecimals?: number;
  average?: boolean;
}) => {
  const isOverKilo = extractInteger(eth).length > 3;

  /* k m b t 표현에서는 최대 소수 2자리까지, 그 외에는 최대 displayDecimal 까지 */
  const formatNumStr = numbro(eth).format({
    average,
    mantissa: isOverKilo ? Math.min(2, displayDecimals) : displayDecimals,
  });

  if (formatNumStr === "NaN") {
    return "0.0";
  }

  // removing tailing zeros
  return formatNumStr.replace(/(\.\d+?)0*(\D)?$|(\D$)/, "$1$2");
};
