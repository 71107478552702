import { defaultAbiCoder } from "ethers/lib/utils";

import { MarketSupportedChain } from "~/api/dto";
import { getPublicProvider } from "~/modules/Market/utils/provider";

type Props = {
  chain: MarketSupportedChain;
  tokenId: string;
  contractAddress: string;
};

export const getNftOwner = async ({
  chain,
  tokenId,
  contractAddress,
}: Props) => {
  const provider = getPublicProvider({ chain });

  const sig = "0x6352211e"; // ownerOf(uint256)
  const paramEncoded = defaultAbiCoder.encode(["uint256"], [tokenId]);
  const data = sig + paramEncoded.substring(2);

  return provider.call({
    to: contractAddress,
    data,
  });
};
