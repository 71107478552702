import { useAtom, useAtomValue, useSetAtom } from "jotai";

import {
  readIndexedSectionByIdAtom,
  readIsPreviewModeAtom,
  readOriginalDaoHomeAtom,
  readRevisionAtom,
  readWriteCopiedDaoHome,
  readWriteDeviceAtom,
  readWriteDirtySectionIdsAtom,
  readWriteOpenAddSectionModal,
  readWriteSelectedSectionAtom,
  readWriteViewModeAtom,
  writeAddSectionAtom,
  writeChangeSecionTypeAtom,
  writeRemoveSectionAtom,
  writeRemoveSectionProfileCoverImageAtom,
  writeSectionBannerAtom,
  writeSectionMarketplaceTitleAtom,
  writeSectionMerchStoreTitleAtom,
  writeSectionPostTitleAtom,
  writeSectionProfileAddSnsAtom,
  writeSectionProfileCoverImageAtom,
  writeSectionProfileRemoveSnsAtom,
  writeSectionProfileSnsAtom,
  writeSelectedSectionMoveAtom,
  writeSetSectionsAtom,
  writeUpdateSnsLayoutAtom,
} from "~/_model/daoHome/daoHomeSettingsAtom";
import { useMemo } from "react";
import { assertIsDefined } from "~/_utils/assert";

const useSettingsHomeStore = () => {
  const [viewMode, setViewMode] = useAtom(readWriteViewModeAtom);
  const [selectedSection, setSelectedSection] = useAtom(
    readWriteSelectedSectionAtom,
  );
  const [dirtySectionIds, setDirtSectionIds] = useAtom(
    readWriteDirtySectionIdsAtom,
  );

  // Getter
  const originalDaoHome = useAtomValue(readOriginalDaoHomeAtom);
  const copiedDaoHome = useAtomValue(readWriteCopiedDaoHome);
  const indexedSectionById = useAtomValue(readIndexedSectionByIdAtom);
  const isPreviewMode = useAtomValue(readIsPreviewModeAtom);
  const revision = useAtomValue(readRevisionAtom);

  // 섹션
  const removeSection = useSetAtom(writeRemoveSectionAtom);
  const addSection = useSetAtom(writeAddSectionAtom);
  const setSections = useSetAtom(writeSetSectionsAtom);
  const setSelectedSectionMove = useSetAtom(writeSelectedSectionMoveAtom);
  const [openAddSectionModal, setOpenAddSectionModal] = useAtom(
    readWriteOpenAddSectionModal,
  );

  // 프로필
  const setSectionProfileCoverImage = useSetAtom(
    writeSectionProfileCoverImageAtom,
  );
  const setSectionProfileRemoveCoverImageAtom = useSetAtom(
    writeRemoveSectionProfileCoverImageAtom,
  );
  const setSectionProfileSns = useSetAtom(writeSectionProfileSnsAtom);
  const setSectionProfileRemoveSns = useSetAtom(
    writeSectionProfileRemoveSnsAtom,
  );
  const setSectionProfileAddSns = useSetAtom(writeSectionProfileAddSnsAtom);
  // 배너
  const setSectionBanner = useSetAtom(writeSectionBannerAtom);

  // 커뮤니티샵
  const setSectionMerchStoreTitle = useSetAtom(writeSectionMerchStoreTitleAtom);

  // 마켓플레이스
  const setSectionMarketplaceTitle = useSetAtom(
    writeSectionMarketplaceTitleAtom,
  );
  // 포스트 섹션
  const setSectionChannelTitle = useSetAtom(writeSectionPostTitleAtom);
  const setChangeSectionType = useSetAtom(writeChangeSecionTypeAtom);
  const setUpdateSnsLayout = useSetAtom(writeUpdateSnsLayoutAtom);

  const [device, setDevice] = useAtom(readWriteDeviceAtom);

  // 섹션 별
  const toHashedSectionMap = useMemo(() => {
    return new Map(
      originalDaoHome.sections.map((section) => [
        section.id,
        createHash(JSON.stringify(section)),
      ]),
    );
  }, [originalDaoHome]);
  const getIsDirtySection = (sectionId: string) => {
    const prevHash = toHashedSectionMap.get(sectionId);

    const currentSection = indexedSectionById[sectionId];
    assertIsDefined(currentSection);

    const nextHash = createHash(JSON.stringify(currentSection));

    return prevHash !== nextHash;
  };

  // 섹션 전체
  const toHashedSections = useMemo(() => {
    return createHash(JSON.stringify({ sections: originalDaoHome.sections }));
  }, [originalDaoHome]);
  const isDirty = useMemo(
    () =>
      toHashedSections !==
      createHash(JSON.stringify({ sections: copiedDaoHome.sections })),
    [toHashedSections, copiedDaoHome],
  );

  // 미리보기, 편집 모드 관리
  const handleToggleViewMode = () => {
    setViewMode({ viewMode: viewMode === "Preview" ? "Edit" : "Preview" });
  };

  return {
    // 리비전
    revision,

    // 모드 관리
    viewMode,
    setViewMode,
    isPreviewMode,
    handleToggleViewMode,

    // 섹션 관리
    selectedSection,
    setSelectedSection,
    addSection,
    removeSection,
    setSections,
    setSelectedSectionMove,
    openAddSectionModal,
    setOpenAddSectionModal,

    // 디바이스 관리
    device,
    setDevice,

    // 변경사항 관리
    dirtySectionIds,
    setDirtSectionIds,
    getIsDirtySection,
    isDirty,

    // 프로필 설정
    setSectionProfileCoverImage,
    setSectionProfileRemoveCoverImageAtom,
    setSectionProfileSns,
    setSectionProfileRemoveSns,
    setSectionProfileAddSns,

    // 배너 설정
    setSectionBanner,

    // MerchStore 설정
    setSectionMerchStoreTitle,

    // Marketplace 설정
    setSectionMarketplaceTitle,

    // 채널 설정
    setSectionChannelTitle,
    setChangeSectionType,
    setUpdateSnsLayout,
  };
};

function createHash(str: string) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash |= 0;
  }
  return hash;
}

export { useSettingsHomeStore };
