import { APP_LINK } from "~/_constants";
import { EMobileAppDeeplink } from "~/_enums/EMobileAppDeeplink";
import { EPlatform } from "~/_enums/EPlatform";
import { detectMobilePlatform } from "~/_utils/mobile/detectMobilePlatform";

/**
 * 앱 다운로드 이동 / 앱 다운되어있으면 이동
 */
export function downloadApp(subdomain?: string) {
  setTimeout(function () {
    const platform = detectMobilePlatform();
    const link =
      platform === EPlatform.Ios ? APP_LINK.APPLE_STORE : APP_LINK.GOOGLE_PLAY;

    /**
     * safri window open
     * https://stackoverflow.com/a/63170977
     */
    const a = document.createElement("a") as HTMLAnchorElement;
    a.style.setProperty("display", "none");
    a.setAttribute("href", link);
    a.setAttribute("target", "_blank");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }, 300);

  const locationPath =
    subdomain == null
      ? EMobileAppDeeplink.Root
      : EMobileAppDeeplink.CommunityJoin.replace("{{SUBDOMAIN}}", subdomain);

  (window as unknown as { location: string }).location = locationPath;
}
