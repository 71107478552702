import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { Icon } from "~/modules/Common/Icons";

type Props = {
  onClick?: () => void;
};

export const ReloadButton = ({ onClick }: Props) => {
  const { t } = useTranslation([ENameSpace.Common]);
  return (
    <button
      type="button"
      onClick={onClick}
      className="flex h-full w-full items-center justify-center gap-x-2 rounded-full bg-ciety-wh px-10"
    >
      <Icon iconType="Refresh" className="h-6 w-6 text-ciety-dk" />
      <span className="typo-m1 pt-[0.1rem] text-ciety-dk">
        {t(`${ENameSpace.Common}:TokenGating.Retry`)}
      </span>
    </button>
  );
};
