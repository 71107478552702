import { ItemType } from "@opensea/seaport-js/lib/constants";
import {
  ApprovalAction,
  BasicErc721Item,
  BasicErc1155Item,
  CurrencyItem,
  Fee,
} from "@opensea/seaport-js/lib/types";
import { ethers } from "ethers";
import { keccak256, randomBytes, toUtf8Bytes } from "ethers/lib/utils";

import { GetMarketFees } from "~/api/Market/types";
import { ORDER_CREATION_STEPS } from "~/modules/BlockChain/Order/constants";
import { OrderCreateStep } from "~/modules/BlockChain/Order/types";
import { handleNullStringToZero } from "~/modules/BlockChain/Order/utils/NumberHandling";
import { DOMAIN_SIG } from "~/modules/Market/constants/cietyMarket";
import { arrayBufferToHexStr } from "~/modules/Market/utils/conversions";

export const prepareProposalCreation = ({
  cryptoAddress,
  proposalPrice,
  buyItemAmount,
  tokenType,
  tokenId,
  buyItemAddress,
  fees,
  offererAddress,
}: {
  cryptoAddress: string;
  proposalPrice: string;
  buyItemAmount: string;
  tokenType: "ERC721" | "ERC1155";
  tokenId: string;
  buyItemAddress: string;
  fees: GetMarketFees["data"];
  offererAddress: string;
}) => {
  const offerItemList: CurrencyItem[] = [
    {
      token: cryptoAddress,
      amount: proposalPrice,
    },
  ];

  const considerationItemList: (BasicErc721Item | BasicErc1155Item)[] = [
    singleItemToConsideration({
      tokenType,
      tokenId,
      contractAddress: buyItemAddress,
      amount: buyItemAmount,
      recipient: offererAddress,
    }),
  ];
  const feeList: Fee[] = marketFeesToOrderFee(fees);

  return { offerItemList, considerationItemList, feeList };
};

export const prepareOrderCreation = ({
  sellItemAmount,
  totalPriceAmount,
  tokenType,
  tokenId,
  itemAddress,
  currencyAddress,
  ownerAddress,
  fees,
}: {
  sellItemAmount: string;
  totalPriceAmount: string;
  tokenType: "ERC721" | "ERC1155";
  tokenId: string;
  itemAddress: string;
  currencyAddress: string;
  ownerAddress: string;
  fees: GetMarketFees["data"];
}) => {
  const offerItemList: (BasicErc721Item | BasicErc1155Item)[] = [
    singleItemToOffer({
      tokenType,
      tokenId,
      contractAddress: itemAddress,
      amount: sellItemAmount,
    }),
  ];
  const considerationItemList: CurrencyItem[] = [
    singleCryptoToConsideration({
      token: currencyAddress,
      recipient: ownerAddress,
      amount: totalPriceAmount,
    }),
  ];

  const feeList: Fee[] = marketFeesToOrderFee(fees);

  return { offerItemList, considerationItemList, feeList };
};

export const getCietySalt = () => {
  const combinationBytesLengths = {
    domainBytes: 4,
    randomBytes: 8,
  };

  const totalCombinationBytesLen = Object.values(
    combinationBytesLengths,
  ).reduce((a, b) => a + b);
  if (totalCombinationBytesLen > 32) {
    throw new Error("Salts cannot by over 32 bytes");
  }

  return (
    keccak256(toUtf8Bytes(DOMAIN_SIG)).slice(
      0,
      2 + combinationBytesLengths.domainBytes * 2,
    ) +
    Array(2 * (32 - totalCombinationBytesLen))
      .fill(0)
      .join("") +
    arrayBufferToHexStr(randomBytes(combinationBytesLengths.randomBytes))
  );
};

export const singleItemToConsideration = ({
  tokenType,
  tokenId,
  contractAddress,
  amount,
  recipient,
}: {
  tokenType: "ERC721" | "ERC1155";
  contractAddress: string;
  tokenId: string;
  amount: string;
  recipient: string;
}): (BasicErc721Item | BasicErc1155Item) & { recipient: string } => {
  switch (tokenType) {
    case "ERC721": {
      return {
        itemType: ItemType.ERC721,
        token: contractAddress,
        identifier: tokenId,
        recipient,
      };
    }
    case "ERC1155": {
      return {
        itemType: ItemType.ERC1155,
        token: contractAddress,
        identifier: tokenId,
        amount: "" + amount,
        recipient,
      };
    }
    default:
      throw new Error("Unidentified token type to create order");
  }
};
export const singleItemToOffer = ({
  tokenType,
  tokenId,
  contractAddress,
  amount,
}: {
  tokenType: "ERC721" | "ERC1155";
  contractAddress: string;
  tokenId: string;
  amount: string;
}): BasicErc721Item | BasicErc1155Item => {
  switch (tokenType) {
    case "ERC721": {
      return {
        itemType: ItemType.ERC721,
        token: contractAddress,
        identifier: tokenId,
      };
    }
    case "ERC1155": {
      return {
        itemType: ItemType.ERC1155,
        token: contractAddress,
        identifier: tokenId,
        amount: "" + amount,
      };
    }
    default:
      throw new Error("Unidentified token type to create order");
  }
};

export const singleCryptoToConsideration = ({
  token,
  amount,
  recipient,
}: {
  token: string;
  amount: string;
  recipient?: string;
}): CurrencyItem & {
  recipient?: string;
} => {
  return {
    token,
    amount,
    recipient,
  };
};

export const marketFeesToOrderFee = (fees: GetMarketFees["data"]): Fee[] =>
  Object.values(fees)
    .filter(filterNull)
    .map(({ fee, address }) => {
      return {
        recipient: address,
        basisPoints: Math.round(Number(fee) * 100 * 100),
      };
    });

const filterNull = (
  _: { fee: number | string | null; address: string } | null,
): _ is { fee: number; address: string } =>
  _ != null && _.fee !== 0 && _.fee !== "0" && _.fee != null;

export const getUsdPrice = ({
  price,
  currency,
}: {
  price: string | number;
  currency: string | number;
}) => {
  return Number(currency) * Number(price);
};

export const getDurationValidity = (startTime: Date, endTime: Date) => {
  const start = startTime.getTime();
  const end = endTime.getTime();

  if (start === end) {
    return { type: "isSame", message: "Start and end time cannot be the same" };
  } else if (start > end) {
    return {
      type: "isReverse",
      message: "Start time cannot be later than the end time",
    };
  } else if (end - start < 3600) {
    return {
      type: "isTooShort",
      message: "Too short. Duration must be over 1 hour",
    };
  } else {
    return null;
  }
};

export const getProgressStepIdx = (status: OrderCreateStep) =>
  ORDER_CREATION_STEPS.indexOf(status);

export const displayAddressCompact = (address: string) =>
  address.slice(0, 10) + "..." + address.slice(33);

export const isTrxStepBefore = (
  currentStep: OrderCreateStep,
  until: OrderCreateStep,
  include: boolean,
) =>
  include
    ? getProgressStepIdx(currentStep) <= getProgressStepIdx(until)
    : getProgressStepIdx(currentStep) < getProgressStepIdx(until);

export const isStepAt = (
  currentStep: OrderCreateStep,
  target: OrderCreateStep,
) => currentStep === target;

export const getTotalPrice = (unitPrice: string, amount: string) =>
  sanitizeValueToNumber(unitPrice) * sanitizeValueToNumber(amount);

const sanitizeValueToNumber = (value: string | number) => {
  if (value == null) {
    return 0;
  } else {
    if (typeof value === "string") {
      return value === "" ? 0 : Number(value);
    } else {
      return isNaN(value) ? 0 : value;
    }
  }
};

export const getTotalPriceBigNumber = ({
  decimals,
  unitPrice,
  amount,
}: {
  decimals: number;
  unitPrice: string;
  amount: string;
}) => {
  return ethers.utils
    .parseUnits(handleNullStringToZero(unitPrice), decimals)
    .mul(handleNullStringToZero(amount));
};

export const isApprovalTypeErc20 = (
  approvalAction: ApprovalAction | undefined,
) => {
  if (approvalAction == null) {
    return false;
  } else {
    return approvalAction?.itemType === ItemType.ERC20;
  }
};

export const getFirstSignatureFromData = (data: string) => {
  return data.slice(2, 2 + 8);
};

export const ellipseTokenId = (tokenId: string) => {
  if (tokenId.length > 10) {
    return [tokenId.slice(0, 3), "...", tokenId.slice(-5)].join("");
  } else {
    return tokenId;
  }
};

export const ellipseItemName = (itemName: string, limit?: number) => {
  limit = limit ?? 25;
  if (itemName.length > limit) {
    return [itemName.slice(0, limit), "..."].join("");
  } else {
    return itemName;
  }
};

export const getSafeTrxData = async (approvalAction: ApprovalAction) => {
  const populatedTrx =
    await approvalAction.transactionMethods.buildTransaction();

  if (populatedTrx && populatedTrx.data && populatedTrx.data !== "0x") {
    /*
     * Erc20 트랜젝션이고,
     * approve 메소드 호출인 경우,
     * seaport-js 에서 주입된 transaction data 의 trailing domain tag 를 제거함.
     * */
    const isErc20Trx = isApprovalTypeErc20(approvalAction);

    if (isErc20Trx) {
      const sig = getFirstSignatureFromData(populatedTrx.data);
      const isErc20ApproveMethod = sig === "095ea7b3";
      if (isErc20ApproveMethod) {
        populatedTrx.data = populatedTrx.data.slice(0, -8);
      }
    }
  }

  return populatedTrx;
};
