import { pastDays } from "../utils";
import { PeriodType } from "./enum";
import type { FilterType, Period, SearchType } from "./index";

export const SEARCH_TYPES: Array<SearchType> = ["posts", "comments", "members"];

export const FILTERS: Array<FilterType> = [
  "author",
  "channel",
  "periods",
  "role",
];

export const FILTER_DEFAULT_LABEL: Record<FilterType, string> = {
  author: "From",
  channel: "Channel",
  periods: "Date",
  role: "Role",
};

export const INITIAL_PERIODS: Array<Period> = [
  {
    type: PeriodType.AllTimes,
    startDate: null,
    endDate: null,
    changeType: "none",
  },
  {
    type: PeriodType.Today,
    startDate: pastDays(0),
    endDate: pastDays(0),
    changeType: "none",
  },
  {
    type: PeriodType.Yesterday,
    startDate: pastDays(1),
    endDate: pastDays(1),
    changeType: "none",
  },
  {
    type: PeriodType.Last7Days,
    startDate: pastDays(7),
    endDate: null,
    changeType: "none",
  },
  {
    type: PeriodType.Last30Days,
    startDate: pastDays(30),
    endDate: null,
    changeType: "none",
  },
  {
    type: PeriodType.On,
    startDate: pastDays(0),
    endDate: pastDays(0),
    changeType: "same all",
  },
  {
    type: PeriodType.Before,
    startDate: null,
    endDate: pastDays(0),
    changeType: "end",
  },
  {
    type: PeriodType.After,
    startDate: pastDays(0),
    endDate: null,
    changeType: "start",
  },
  {
    type: PeriodType.Range,
    startDate: pastDays(0),
    endDate: pastDays(0),
    changeType: "different all",
  },
];

export const DEFAULT_PERIOD = INITIAL_PERIODS[0];

export const RESULT_PAGING_COUNT = 50;

export const MAX_PAGE_ROW_LENGTH = 5;
