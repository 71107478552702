import { useAtom } from "jotai";

import { NEED_HYDRATION } from "~/_constants";
import { EAtomName } from "~/_enums/EAtomName";
import { daoAtom, writeUpdateDaoBySettingDao } from "~/_model/dao/daoAtom";
import { getHydrationErrorMessage } from "~/_utils/messageGetter/getHydrationErrorMessage";

const useDaoStore = () => {
  const [dao, setDao] = useAtom(daoAtom);
  const [, setUpdateDaoBySettingDao] = useAtom(writeUpdateDaoBySettingDao);

  if (dao === NEED_HYDRATION) {
    throw new Error(getHydrationErrorMessage(EAtomName.Dao));
  }

  return {
    dao,
    setDao,
    setUpdateDaoBySettingDao,
  };
};

export { useDaoStore };
