import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { EmptySectionItem } from "~/_app/[dao]/home/components/common/EmptySectionItem";
import { EmptySectionTitle } from "~/_app/[dao]/home/components/common/EmptySectionTitle";
import { useDaoStore } from "~/_store/dao/useDaoStore";
import { EmptySectionDescription } from "~/_app/[dao]/home/components/common/EmptySectionDescription";
import { assertIsDefined } from "~/_utils/assert";

function EmptyMarketplace() {
  const { dao } = useDaoStore();
  assertIsDefined(dao);
  const { t } = useTranslation([ENameSpace.Home]);

  if (dao.mode === "WEB3") {
    return (
      <EmptySectionItem isMobile>
        <EmptySectionTitle
          title={t(
            `${ENameSpace.Home}:Root.Sections.Marketplace.Empty.WEB3.Title`,
          )}
          className="typo-m1"
        />
        {/* @TODO 모바일에서 판매할수 없음 */}
        {/* <EmptySectionButton className="typo-m2 mt-[1.5rem]">
          <Icon iconType="NFT" className="h-[1rem] w-[1rem] text-ciety-dk" />
          {t(`${ENameSpace.Home}:Root.Sections.Marketplace.Empty.Button`)}
        </EmptySectionButton> */}
      </EmptySectionItem>
    );
  } else {
    return (
      <EmptySectionItem isMobile>
        <EmptySectionTitle
          title={t(
            `${ENameSpace.Home}:Root.Sections.Marketplace.Empty.WEB2.Title`,
            {
              displayName: dao.displayName,
            },
          )}
          className="typo-m1"
        />
        <EmptySectionDescription
          description={t(
            `${ENameSpace.Home}:Root.Sections.Marketplace.Empty.WEB2.DescriptionMobile`,
          )}
          className="typo-r2 mt-[1rem]"
        />
      </EmptySectionItem>
    );
  }
}

export { EmptyMarketplace };
