import { curry, reduce } from "@fxts/core";
import Decimal from "decimal.js";
import numeral from "numeral";

type numStr = number | string;

export const handleNumber = (
  digit: number,
  n: numStr,
  handleFn: (n: numStr) => numStr,
  decimalInstance?: typeof Decimal,
): numStr => {
  if (digit == 0) return handleFn(n);

  const mul = (a: numStr, b: numStr) =>
    decimalInstance ? decimalInstance.mul(a, b).toString() : +a * +b;

  const div = (a: numStr, b: numStr) =>
    decimalInstance ? decimalInstance.div(a, b).toString() : +a / +b;

  const positive = digit > 0;

  const unit = 10 ** Math.abs(digit);
  const [f1, f2] = positive ? [div, mul] : [mul, div];
  return f2(handleFn(f1(n, unit)), unit);
};

// 큰 숫자는 오차 발생 가능, Decimal.js 활용한 별도 함수 만들어서 사용 필요
export const roundTo = curry((digit: number, n: numStr) =>
  handleNumber(digit, n, (arg) => Math.round(+arg)),
);

export const floorTo = curry((digit: number, n: numStr) =>
  handleNumber(digit, n, (arg) => Math.floor(+arg)),
);

export const ceilTo = curry((digit: number, n: numStr) =>
  handleNumber(digit, n, (arg) => Math.ceil(+arg)),
);

/**
 * input 값으로 입력한 string 을 number 와 호환되는 형태로 변환, 단 입력 중인 것도 보존해야 함
 * @param numberString 전처리 할 대상 수량, 숫자에 호환되는 스트링이어야 함
 * @returns trim 된 number string
 */
export const trimTypingNumber = (numberString: string): string => {
  const first = numberString[0];
  const sign = ["+", "-"].includes(first) ? first : "";

  return (
    (sign || "") +
      (sign ? numberString.slice(1) : numberString)
        .replace(/[^\d.]*/g, "") // 숫자, 마침표(.) 제외 제거
        .replace(/^0*(?!\.)/, "") // 앞에 붙은 0 제거, 단 소수점을 위한 0은 보존
        .replace(/\./, "d")
        .replace(/\./g, "")
        .replace("d", ".") || // 최초 마침표(.) 빼고는 다 제거
    "0"
  );
};

/**
 * input 값으로 입력한 string number 에 세자리 수마다 콤마
 * @param num 콤마 찍을 숫자 스트링, 숫자에 호환되는 스트링이어야 함
 * @returns 콤마 찍힌 스트링
 */
export const commaThousands = (num: string | number) => {
  // Convert the number to a string and split it into integer and decimal parts
  const parts = num.toString().split(".");
  let integerPart = parts[0];
  const decimalPart = parts.length > 1 ? "." + parts[1] : "";

  // Add commas to the integer part
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  // Add the decimal part back in and return the result
  return (Number(num) < 0 ? "-" : "") + integerPart + decimalPart;
};

const numberUnit: Record<number, string> = {
  1: "K",
  2: "M",
  3: "B",
  4: "T",
};
export const shortenNumUnit = (
  num: string | number,
  options: {
    decimal?: number;
    formatter?: (shorten: string, unit: string) => string;
  } = {},
) => {
  const [integerPart] = num.toString().replaceAll(",", "").split(".");
  const unitLevel = Math.floor(integerPart.length / 4);
  const unit = Math.min(4, unitLevel);

  if (unit == 0) return num;

  const shortenIntegerLen = integerPart.length - unit * 3;

  const shortenIntegerPart = integerPart.substring(0, shortenIntegerLen);
  const shortenDecimalPart = !options.decimal
    ? ""
    : "." +
      integerPart.substring(
        shortenIntegerLen,
        shortenIntegerLen + options.decimal,
      );

  const formatter =
    options.formatter ||
    ((shorten: string, unit: string) => `${shorten}${unit}`);

  return unitLevel == 0
    ? num
    : formatter(`${shortenIntegerPart}${shortenDecimalPart}`, numberUnit[unit]);
};

export const isNumber = (num: string) => {
  if (!num) {
    return false;
  }
  return num.match(/^\d+\.\d+$|^\d+$/) !== null;
};

export const formatLongNumber = (number: number): string => {
  const convertedToString = `${number}`;

  if (convertedToString.length < 5) return convertedToString;

  return numeral(number).format("0.0a").toUpperCase();
};

type DecimalMethods = "plus" | "minus" | "mul" | "div";
const makeDecimalCalc = (
  method: DecimalMethods,
  ...args: Decimal.Value[]
): number => {
  const initial_value = new Decimal(args[0]);
  return reduce(
    (acc, arg) => acc[method](arg),
    initial_value,
    args.slice(1),
  ).toNumber();
};

export const exactAdd = (...args: Decimal.Value[]) =>
  makeDecimalCalc("plus", ...args);

export const exactSub = (...args: Decimal.Value[]) =>
  makeDecimalCalc("minus", ...args);

export const exactMul = (...args: Decimal.Value[]) =>
  makeDecimalCalc("mul", ...args);

export const exactDiv = (...args: Decimal.Value[]) =>
  makeDecimalCalc("div", ...args);

export const extractInteger = (numStr: string) =>
  numStr.substring(0, numStr.indexOf("."));
