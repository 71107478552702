import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface TState {
  updatedChannelId: string | null;
  isMuted: boolean | null;
}

const initialState: TState = {
  updatedChannelId: null,
  isMuted: null,
};

const channelNotiSocketSlice = createSlice({
  name: "channelNotiSocket",
  initialState,
  reducers: {
    setChannelNotiSocket: (state, action: PayloadAction<TState>) =>
      action.payload,
  },
});

export const channelNotiSocketActions = channelNotiSocketSlice.actions;

export default channelNotiSocketSlice.reducer;
