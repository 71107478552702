import type { ContractCondition as TContractCondition } from "../../types";
import { Contract } from "./Contract";

type Props = {
  contractConditions: Array<TContractCondition>;
  hidden?: boolean;
};

export const ContractConditions = ({
  contractConditions,
  hidden = false,
}: Props) => {
  return !hidden ? (
    <>
      {contractConditions.map((contractCondition) => (
        <Contract
          key={contractCondition.id}
          contractCondition={contractCondition}
        />
      ))}
    </>
  ) : null;
};
