export enum PeriodType {
  AllTimes = "All Times",
  Today = "Today",
  Yesterday = "Yesterday",
  Last7Days = "Last 7 days",
  Last30Days = "Last 30 days",
  On = "On",
  Before = "Before",
  After = "After",
  Range = "Range",
}
