import { env } from "~/env";
import { TApiConfig } from "./fetchConfig";
import { defaultErrorHandle } from "~/_libs/fetch/defaultErrorHandle";

type TFetchPostInputDto = {
  body?: Record<string, unknown>;
  init?: Omit<RequestInit, "method">;
};

async function fetchPostJson<T>(
  url: string,
  { body, init }: TFetchPostInputDto,
  config: TApiConfig,
): Promise<T | never> {
  const headers: Record<string, string> = {};
  let token;
  if (config.token) {
    token = config.token; // for ssr
  } else if (typeof window == "undefined") {
    token = null;
  } else {
    token = window.__APP_STATE__?.token; // for app
  }

  if (token) {
    headers["authorization"] = `Bearer ${token}`;
  }
  if (config.daoSubdomain) {
    headers["x-dao-sub-domain"] = config.daoSubdomain;
  }

  const requestInit: RequestInit = {
    method: "POST",
    referrerPolicy: "no-referrer-when-downgrade",
    credentials: "include",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    cache: "no-store",
  };

  const API_URL = config.baseUrl ? config.baseUrl : env.API_BASE_URL;

  return fetch(`${API_URL}${url}`, {
    ...requestInit,
    ...init,
    body: JSON.stringify(body),
  }).then(async (res) => {
    if (res.ok) {
      return res.json();
    }

    await defaultErrorHandle(res);
  });
}
export { fetchPostJson };
