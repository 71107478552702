import type { TDmMessage } from "~/modules/Posting/Dm/Common/types";
import { createMessageSlice } from "~/modules/Posting/Message/shared/utils/createMessageSlice";

const { search, pathname } = document.location;
const params = new URLSearchParams(search);
const highlightId = params.get("highlightId");
const isDm = pathname.split("/")[2] === "dm";

const dmMessageSlice = createMessageSlice<TDmMessage>({
  name: "dmMessage",
  initialState: {
    highlightId: isDm && highlightId ? highlightId : null,
    initialScrollBottom: false,
  },
});

export const dmMessageActions = dmMessageSlice.actions;

export default dmMessageSlice.reducer;
