import { EPlatform } from "~/_enums/EPlatform";

/**
 * android 인지 ios인지 판단
 */
export function detectMobilePlatform(): EPlatform | null {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return EPlatform.Android;
  } else if (/iPad|iPhone|iPod/i.test(userAgent)) {
    return EPlatform.Ios;
  } else {
    return null;
  }
}
