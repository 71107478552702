import { lazy, Suspense } from "react";

import { useOverlayValue } from "~/context/OverlayProvider";
import { useOverlay } from "../hooks/useOverlay";

const Alert = lazy(() => import("./Alert"));
const Confirm = lazy(() => import("./Confirm"));
const Toast = lazy(() => import("./Toast"));

const LiveDashboardModal = lazy(
  () => import("~/modules/Posting/Video/Live/components/LiveDashboardModal"),
);
const DmMembersModal = lazy(
  () => import("~/modules/Posting/Dm/DmMembersModal/components"),
);
const WithdrawalModal = lazy(
  () => import("~/modules/Space/components/WithdrawalModal"),
);
const ReportModal = lazy(() => import("./ReportModal"));
const JoinFunnelModal = lazy(
  () => import("~/_components/OAuth/components/JoinFunnel/JoinFunnelModal"),
);

export const overlays = {
  alert: Alert,
  confirm: Confirm,
  toast: Toast,

  liveDashboardModal: LiveDashboardModal,
  dmMembersModal: DmMembersModal,
  withdrawalModal: WithdrawalModal,
  reportModal: ReportModal,
  joinFunnelModal: JoinFunnelModal,
};

export function OverlayController() {
  const { openedOverlays } = useOverlayValue();
  const overlay = useOverlay();

  return (
    <>
      {openedOverlays.map((openedOverlay) => {
        const { id, Component, props } = openedOverlay;
        const { onClose, onSubmit, ...restProps } = props;

        const handleClose = async (param: unknown) => {
          await onClose?.(param);
          overlay.close(Component);
        };
        const handleSubmit = async (param: unknown) => {
          await onSubmit?.(param);
          overlay.close(Component);
        };

        return (
          <Suspense key={id}>
            <Component
              {...restProps}
              onClose={handleClose}
              onSubmit={handleSubmit}
            />
          </Suspense>
        );
      })}
    </>
  );
}
