import type { CurrencyCondition as TCurrencyCondition } from "../../types";
import { Currency } from "./Currency";

type Props = {
  currencyConditions: Array<TCurrencyCondition>;
  hidden?: boolean;
};

export const CurrencyConditions = ({
  currencyConditions,
  hidden = false,
}: Props) => {
  return !hidden ? (
    <>
      {currencyConditions.map((currencyCondition) => (
        <Currency
          key={currencyCondition.id}
          currencyCondition={currencyCondition}
        />
      ))}
    </>
  ) : null;
};
