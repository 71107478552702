import { ImgHTMLAttributes, SyntheticEvent, useState } from "react";

import { SmoothLoadableImage } from "./SmoothLoadableImage";
import {
  toResizeUrl,
  TToResizeUrlParams,
} from "~/_utils/converter/toResizeUrl";

export type TResizedImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  toResizeUrlParams: TToResizeUrlParams;
  loadSmooth?: boolean;
  fallback?: JSX.Element | null;
};

/** @caution Using `<img>` could result in slower LCP and higher bandwidth. Consider using `<Image />` from `next/image` to automatically optimize images. This may incur additional usage or cost from your provider. See: https://nextjs.org/docs/messages/no-img-element */
function ResizedImage({
  toResizeUrlParams: { url, ...restToResizeUrlParams },
  onError,
  loadSmooth = false,
  fallback = null,
  ...restProps
}: TResizedImageProps): JSX.Element | null {
  const [isResizeError, setIsResizeError] = useState(false);
  const [isImageError, setIsImageError] = useState(false);

  const getSrc = () =>
    isResizeError
      ? url
      : toResizeUrl({
          url,
          ...restToResizeUrlParams,
        });

  const handleError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    if (isResizeError) {
      onError?.(e);
      setIsImageError(true);
    } else {
      setIsResizeError(true);
    }
  };

  const commonProps: ImgHTMLAttributes<HTMLImageElement> = {
    ...restProps,
    src: getSrc(),
    onError: handleError,
  };

  if (isImageError) {
    return fallback;
  }

  return loadSmooth ? (
    <SmoothLoadableImage {...commonProps} />
  ) : (
    <img {...commonProps} alt="" />
  );
}

export { ResizedImage };

/**
 * @reference
 * - https://github.com/marpple/ciety-fe/commit/e5e197f9dc248aca71bfbb11a1e2036bbda2d0c4#diff-e3b4b0166b30f612eb6a808ec8e3907c9b2ac3d09762ad0b6482042cecc5b54a
 * - https://github.com/marpple/ciety-fe/commit/302ff165473fa44717975438e84e4656de58abd4#diff-e3b4b0166b30f612eb6a808ec8e3907c9b2ac3d09762ad0b6482042cecc5b54a
 */
