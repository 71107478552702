const GLOBAL_CSS = {
  MAX_WIDTH_1440: 1.1111,
  MAX_WIDTH_1360: 1.1765,
};

const getRemSize = () => {
  // @FIXME 임시값
  if (typeof window === "undefined") {
    return 16;
  }

  const windowWidth = window.innerWidth ?? 3000;

  let remSize;
  if (windowWidth > 1440 || windowWidth < 560) {
    remSize = 16;
  } else if (windowWidth > 1360) {
    remSize = (windowWidth / 100) * GLOBAL_CSS.MAX_WIDTH_1440;
  } else {
    remSize = (windowWidth / 100) * GLOBAL_CSS.MAX_WIDTH_1360;
  }

  return remSize;
};

export const remToPx = (rem: number) => rem * getRemSize();
export const pxToRem = (px: number) => px / getRemSize();

const isCssUnit = (unit: string) => (target: string) =>
  new RegExp(`${unit}$`).test(target.replace(/\s/g, ""));
const isRemStr = (remStr: string) => isCssUnit("rem")(remStr);
const isPxStr = (pxStr: string) => isCssUnit("px")(pxStr);
const remConverter = (remStr: string) => {
  if (!isRemStr(remStr)) {
    throw new Error("error: convert not rem to px");
  }

  return +remStr.replace("rem", "") * getRemSize();
};

/** @return dividend / divisor */
const calcRatio = (dividend: number, divisor: number) => dividend / divisor;

/** @return dividend / divisor * 100 */
const calcPercent = (dividend: number, divisor: number) =>
  calcRatio(dividend, divisor) * 100;

/** @return `${dividend / divisor * 100}%` */
const calcPercentWithSymbol = (dividend: number, divisor: number) =>
  `${calcRatio(dividend, divisor) * 100}%`;

const remStrToPx = (remStr: string) => {
  if (!isRemStr(remStr)) {
    throw new Error("error: convert not rem to px");
  }

  return +remStr.replace("rem", "") * getRemSize();
};

export {
  remStrToPx,
  getRemSize,
  isCssUnit,
  isRemStr,
  isPxStr,
  remConverter,
  //
  calcRatio,
  calcPercent,
  calcPercentWithSymbol,
};
