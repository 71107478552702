import { sortBy, toArray, uniq, uniqBy } from "@fxts/core";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TMember } from "~/_types/TMember";

export type FeedReducerState = {
  posts: TPost[];
  newPosts: TPost[];
  recentPostId: string | null;
  updateFeed: boolean;
  showUpdateFeedButton: boolean;
};
export const initialState: FeedReducerState = {
  posts: [],
  newPosts: [],
  recentPostId: null,
  updateFeed: false,
  showUpdateFeedButton: false,
};

export const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    setPosts: (
      state: FeedReducerState,
      action: PayloadAction<Array<TPost>>,
    ) => {
      state.posts = action.payload;
    },
    addPosts: (
      state: FeedReducerState,
      action: PayloadAction<TPost | Array<TPost>>,
    ) => {
      state.posts = toArray(
        uniq(
          (Array.isArray(action.payload)
            ? action.payload
            : [action.payload]
          ).concat(state.posts),
        ),
      );
    },
    addNewPosts: (state: FeedReducerState, action: PayloadAction<TPost>) => {
      state.newPosts = sortBy(
        ({ id }) => id,
        uniqBy(({ id }) => id, [action.payload, ...state.newPosts]),
      );
    },
    updatePost: (state: FeedReducerState, action: PayloadAction<TPost>) => {
      state.posts = state.posts.map((post) =>
        post.id === action.payload.id ? action.payload : post,
      );
    },
    removePost: (state: FeedReducerState, action: PayloadAction<string>) => {
      state.posts = state.posts.filter((post) => post.id !== action.payload);
      state.newPosts = state.newPosts.filter(
        (post) => post.id !== action.payload,
      );
    },
    setNewPosts: (
      state: FeedReducerState,
      action: PayloadAction<Array<TPost>>,
    ) => {
      state.newPosts = action.payload;
    },
    setRecentPostId: (
      state: FeedReducerState,
      action: PayloadAction<string | null>,
    ) => {
      state.recentPostId = action.payload;
    },
    setUpdateFeed: (
      state: FeedReducerState,
      action: PayloadAction<boolean>,
    ) => {
      if (action.payload) {
        state.posts = state.newPosts;
        state.updateFeed = false;
      } else {
        state.updateFeed = action.payload;
      }
      state.newPosts = [];
      state.showUpdateFeedButton = false;
    },
    setShowUpdateFeedButton: (
      state: FeedReducerState,
      action: PayloadAction<boolean>,
    ) => {
      state.showUpdateFeedButton = action.payload;
    },
    addPostsLast: (
      state: FeedReducerState,
      action: PayloadAction<TPost | Array<TPost>>,
    ) => {
      state.posts = state.posts.concat(
        Array.isArray(action.payload) ? action.payload : [action.payload],
      );
    },
    onChangeChannel: (state: FeedReducerState) => {
      state.showUpdateFeedButton = false;
      state.posts = [];
      state.newPosts = [];
      state.updateFeed = false;
    },
    setBlockedMember: (
      state: FeedReducerState,
      action: PayloadAction<{ memberId: string; closeThread?: boolean }>,
    ) => {
      const { memberId } = action.payload;
      state.posts = state.posts.map((post) => {
        if (post.author.id === memberId) {
          return {
            ...post,
            state: "Hidden",
          };
        }
        return post;
      });
    },
    removeBlockedMember: (
      state: FeedReducerState,
      action: PayloadAction<{ memberId: string; closeThread?: boolean }>,
    ) => {
      state.posts = state.posts.map((post) => {
        if (post.author.id === action.payload.memberId) {
          return {
            ...post,
            state: "Created",
          };
        }
        return post;
      });
    },
    updateProfileInPost: (
      state: FeedReducerState,
      action: PayloadAction<TMember>,
    ) => {
      state.posts = state.posts.map((post) => {
        if (post.author.id === action.payload.id) {
          return {
            ...post,
            author: action.payload,
          };
        }
        return post;
      });
    },
  },
});

export const {
  setPosts,
  addPosts,
  addNewPosts,
  updatePost,
  removePost,
  setNewPosts,
  setRecentPostId,
  setUpdateFeed,
  setShowUpdateFeedButton,
  addPostsLast,
  onChangeChannel,
  setBlockedMember,
  removeBlockedMember,
  updateProfileInPost,
} = feedSlice.actions;

export default feedSlice.reducer;
