import { DoOnboarding } from "~/api/apiRouteTypes/DoOnboardingRoute";
import { fetchPost } from "~/utils/fetch";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

export type TPostDoOnboardingOutputDto =
  DoOnboarding.PostDoOnboardingControllerHandle.ResponseBody;

/**@description 온보딩 진행 이력 기록 */
export const postDoOnboarding = () =>
  fetchPost<TPostDoOnboardingOutputDto>({
    url: `/do-onboarding`,
  }).then((result) => {
    switch (result.code) {
      case "000":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postDoOnboarding",
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
