import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NftOutputWithMetadataDto } from "~/api/apiRouteTypes/NftAssetsRoute";
import { RootState } from ".";

interface TNft {
  refreshedNft: NftOutputWithMetadataDto | null;
}

interface TNftState {
  nft: TNft;
}

const initialState: TNftState = {
  nft: {
    refreshedNft: null,
  },
};

export const nftSlice = createSlice({
  name: "nft",
  initialState,
  reducers: {
    setRefreshedNft: (state, action: PayloadAction<TNft["refreshedNft"]>) => {
      state.nft.refreshedNft = action.payload;
    },
  },
});

export const { setRefreshedNft } = nftSlice.actions;

export const selectNft = (state: RootState) => state.nft.nft;

export default nftSlice.reducer;

/** @reference https://github.com/marpple/ciety-fe/blob/a8ad641538f98733adc2b55ab1db7b43302bde18/src/context/NFT/index.tsx */
