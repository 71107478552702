import { ImgHTMLAttributes, useEffect, useState } from "react";
import { IntersectionOptions, useInView } from "react-intersection-observer";

import { SmoothLoadableImage } from "./SmoothLoadableImage";

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  intersectionOptions?: IntersectionOptions;
  loadSmooth: boolean;
}

/** @reference https://github.com/thebuilder/react-intersection-observer/blob/main/docs/Recipes.md#lazy-image-load */
export function LazyImage({
  src,
  intersectionOptions,
  loadSmooth,
  ...restProps
}: Props) {
  const [isImageViewed, setIsImageViewed] = useState(false);
  const { ref, inView } = useInView({
    ...intersectionOptions,
    triggerOnce: true,
  });

  useEffect(() => {
    if (!isImageViewed && inView) {
      setIsImageViewed(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView]);

  return (
    <>
      {loadSmooth ? (
        <SmoothLoadableImage
          {...restProps}
          ref={ref}
          src={isImageViewed ? src : undefined}
          alt=""
        />
      ) : (
        <img
          {...restProps}
          ref={ref}
          src={isImageViewed ? src : undefined}
          alt=""
        />
      )}
    </>
  );
}
