import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";

import { useUserAccounts } from "~/hooks/api";
import { AuthDispatchContext } from "~/modules/Router/Auth/contexts/AuthDispatchContext";
import { useAuthContext } from "~/modules/Router/Auth/hooks/index";
import { useWalletConnect } from "~/modules/Router/Auth/hooks/useWalletConnect";
import {
  ConditionStatus,
  ErrorTypes,
  Status,
  VerificationJson,
} from "~/modules/Router/Auth/types";
import { connectWalletAccount } from "~/modules/Router/Auth/utils/account/connectWalletAccount";
import { getDaoAssetVerificationConditions } from "~/modules/Router/Auth/utils/join/getDaoAssetVerificationConditions";
import { getIsJoinedDao } from "~/modules/Router/Auth/utils/join/getIsJoinedDao";
import { joinDao } from "~/modules/Router/Auth/utils/join/joinDao";
import { toSubdomainPolicy } from "~/utils/converter";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { useDaoStore } from "../../../../_store/dao/useDaoStore";
import { toConnectCommandErrorMessage } from "~/_utils/messageGetter/toConnectCommandErrorMessage";
import { TGoogleAccount } from "~/_types/TGoogleAccount";
import { assertIsDefined } from "~/_utils/assert";
import { getDao } from "~/api";
import { ECode } from "~/_enums/ECode";
import { cietyAlert } from "~/_utils/overlay/cietyAlert";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { useOverlay } from "~/modules/Common/Overlay";
import { GoogleAccountListToJoinModal } from "~/_components/OAuth/components/GoogleAccountListToJoinModal";
import { ERROR_OOPS } from "~/_constants/errors";
import { useErrorBoundary } from "react-error-boundary";

export type CommunityJoinProps = {
  isEmailAccount: boolean;
};

export const useCommunityJoin = ({ isEmailAccount }: CommunityJoinProps) => {
  const { updateAuthState, updateDaoMemberInfo } =
    useContext(AuthDispatchContext);
  const { userId } = useAuthContext();
  const { userAccounts } = useUserAccounts();
  const { t } = useTranslation([ENameSpace.Common]);
  const { dao } = useDaoStore();
  const { open } = useOverlay();
  const { showBoundary } = useErrorBoundary();

  const selectedGoogleAccountRef = useRef<TGoogleAccount | null>(null);

  const updateUserInfo = () => {
    assertIsDefined(dao);

    updateAuthState();
    updateDaoMemberInfo({ subdomain: dao.subdomain });
  };

  const { register, formState, watch, handleSubmit } = useForm<{
    nickname: string;
  }>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      nickname: "",
    },
  });
  const nickname = watch("nickname");

  const [status, setStatus] = useState<Status>("connected");
  const [joinError, setJoinError] = useState<{ message: string } | null>(null);

  const showNicknameModal = useMemo(() => {
    return status === "canJoin" || status === "verified";
  }, [status]);

  const [verifications, setVerifications] = useState<Pick<
    VerificationJson,
    "currencyConditions" | "contractConditions"
  > | null>(null);
  const [rejectedConditionIds, setRejectedConditionIds] = useState<{
    rejectedContractConditionIds: string[];
    rejectedCurrencyConditionIds: string[];
  }>({
    rejectedContractConditionIds: [],
    rejectedCurrencyConditionIds: [],
  });

  const toConditionStatus = useCallback(
    ({
      id,
      rejectedConditionIds,
    }: {
      id: string;
      rejectedConditionIds: string[];
    }): ConditionStatus => {
      let conditionStatus: ConditionStatus;
      if (status === "loading") {
        conditionStatus = "loading";
      } else if (status === "notSatisfied") {
        conditionStatus = rejectedConditionIds.includes(id)
          ? "failed"
          : "succeed";
      } else {
        conditionStatus = "initialized";
      }

      return conditionStatus;
    },
    [status],
  );

  const currencyConditions = useMemo(() => {
    if (!verifications) return [];

    return verifications.currencyConditions.map((condition) => {
      return {
        ...condition,
        status: toConditionStatus({
          id: condition.id,
          rejectedConditionIds:
            rejectedConditionIds.rejectedCurrencyConditionIds,
        }),
      };
    });
  }, [
    verifications,
    rejectedConditionIds.rejectedCurrencyConditionIds,
    toConditionStatus,
  ]);

  const contractConditions = useMemo(() => {
    if (!verifications) return [];

    return verifications.contractConditions.map((condition) => {
      return {
        ...condition,
        status: toConditionStatus({
          id: condition.id,
          rejectedConditionIds:
            rejectedConditionIds.rejectedContractConditionIds,
        }),
      };
    });
  }, [
    verifications,
    rejectedConditionIds.rejectedContractConditionIds,
    toConditionStatus,
  ]);

  useEffect(() => {
    if (!dao) return;

    getDaoAssetVerificationConditions({ id: dao.id }).then((result) => {
      if (result.ok) {
        setVerifications(result.value);
      }
    });
  }, [dao]);

  const {
    hasCurrencyConditions,
    hasContractConditions,
    isSimplifiedLayout,
    messageTextColor,
    message,
  } = useWalletConnect({ status, verifications });

  const needConnectWallet = useMemo(() => {
    return (
      isEmailAccount &&
      userAccounts?.evmCompatibleAccounts.length === 0 &&
      (hasCurrencyConditions || hasContractConditions)
    );
  }, [
    isEmailAccount,
    userAccounts,
    hasCurrencyConditions,
    hasContractConditions,
  ]);

  const joinDaoRequestRef = useRef(false);
  const handleClickJoinButton = async () => {
    if (!dao || joinDaoRequestRef.current) return;

    if (status !== "canJoin") {
      setStatus("loading");
    }

    setJoinError(null);
    joinDaoRequestRef.current = true;
    const result = await joinDao({
      daoId: dao.id,
      nickname: nickname || undefined,
      googleAccountId: selectedGoogleAccountRef.current?.id,
    });
    joinDaoRequestRef.current = false;

    setRejectedConditionIds({
      rejectedContractConditionIds: result.ok
        ? result.value.rejectedContractConditionIds
        : [],
      rejectedCurrencyConditionIds: result.ok
        ? result.value.rejectedCurrencyConditionIds
        : [],
    });

    if (result.ok) {
      if (result.value.isJoined) {
        setStatus("verified");
      } else {
        setStatus("notSatisfied");
      }
    } else {
      if (result.value === ErrorTypes.DuplicateMemberNicknameError) {
        setStatus("canJoin");
        setJoinError({
          message: t(
            `${ENameSpace.Common}:Auth.useCommunityJoin.ThisNicknameIsAlreadyInUseByAnotherUser`,
          ),
        });
        return;
      }
      const message = toConnectCommandErrorMessage(result.value);

      message && alert(message);

      if (result.value === ErrorTypes.DismissFromWalletError) {
        setStatus("connected");
      } else {
        setStatus("notSatisfied");
      }
    }
  };

  const openGoogleAccountListToJoinModal = (displayName: string) =>
    new Promise<null | TGoogleAccount>((resolve) => {
      open(GoogleAccountListToJoinModal, {
        onSubmit: resolve,
        onClose: () => resolve(null),
        displayName,
      });
    });

  const handleClickVerifyButton = async () => {
    if (!dao) {
      return;
    }

    setStatus("loading");
    const result = await getIsJoinedDao({
      subdomain: dao.subdomain,
      tokenGating: true,
    });

    setRejectedConditionIds({
      rejectedContractConditionIds: result.ok
        ? result.value.rejectedContractConditionIds
        : [],
      rejectedCurrencyConditionIds: result.ok
        ? result.value.rejectedCurrencyConditionIds
        : [],
    });

    if (result.ok) {
      if (result.value.isJoined) {
        updateUserInfo();
        setStatus("alreadyJoined");
      } else if (result.value.canEnter) {
        if (dao.isYoutubeCommunity) {
          const selectedGoogleAccount = await openGoogleAccountListToJoinModal(
            dao.displayName,
          );
          const isCanceled = selectedGoogleAccount === null;
          if (isCanceled) {
            return handleClickCancelButton();
          }

          selectedGoogleAccountRef.current = selectedGoogleAccount;
        }

        setStatus("canJoin");
      } else {
        if (result.value.kicked) {
          await cietyAlert({
            body: t(
              `${ENameSpace.Common}:CommunityJoin.Error.ReJoinDaoNotAllowed`,
            ),
            buttonText: t(`${ENameSpace.Common}:CommunityJoin.Error.Ok`),
          });
          setStatus("connected");
        } else {
          setStatus("notSatisfied");
        }
      }
    } else {
      if (result.value === ECode.NotFound) {
        await cietyAlert({
          body: t(`${ENameSpace.Common}:CommunityJoin.Error.ChangedSubdomain`),
          buttonText: t(`${ENameSpace.Common}:CommunityJoin.Error.Ok`),
        });

        try {
          const nextDao = await getDao({ daoId: dao.id });
          if (nextDao.code !== ECode.Success) {
            throw new Error(ERROR_OOPS);
          }

          location.href = generateUrl({
            type: "main",
            subdomain: nextDao.data.subdomain,
          });
        } catch (err) {
          showBoundary(err);
          setStatus("notSatisfied");
        }
      } else {
        setStatus("notSatisfied");
      }
    }
  };

  const handleClickReloadButton = () => {
    window.location.reload();
  };

  const handleClickCancelButton = () => {
    setStatus("connected");
  };

  const handleClickConnectWalletButton = async () => {
    if (!dao) return;

    setStatus("loading");

    const result = await connectWalletAccount(userId);

    if (result.ok) {
      if (dao.isYoutubeCommunity) {
        const selectedGoogleAccount = await openGoogleAccountListToJoinModal(
          dao.displayName,
        );
        const isCanceled = selectedGoogleAccount === null;
        if (isCanceled) {
          return handleClickCancelButton();
        }

        selectedGoogleAccountRef.current = selectedGoogleAccount;
      }

      setStatus("connected");
    } else {
      const message = toConnectCommandErrorMessage(result.value);

      message && alert(message);

      if (
        result.value === ErrorTypes.DismissFromWalletError ||
        result.value === ErrorTypes.AlreadyUsedAccountError
      ) {
        setStatus("connected");
      } else {
        setStatus("notSatisfied");
      }
    }
  };

  const handleClickExplore = () => {
    if (!dao) {
      alert(t(`${ENameSpace.Common}:ApiAlert.TryAgainLater`));
      return;
    }

    const subdomainPolicy = toSubdomainPolicy(dao.subdomain);

    location.replace(`/${subdomainPolicy}/explore`);
  };

  return {
    handleClickJoinButton,
    handleClickVerifyButton,
    handleClickCancelButton,
    handleClickReloadButton,
    handleClickConnectWalletButton,
    handleClickExplore,
    verifications,
    isSimplifiedLayout,
    hasCurrencyConditions,
    hasContractConditions,
    hasConditions: hasCurrencyConditions || hasContractConditions,
    currencyConditions,
    contractConditions,
    message,
    messageTextColor,
    status,
    showNicknameModal,
    needConnectWallet,
    nickNameForm: {
      nickname,
      register,
      formState,
      watch,
      handleSubmit,
    },
    joinError,
  };
};
