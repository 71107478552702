import type { CurrencyCondition as TCurrencyCondition } from "../../types";
import { ConditionContainer } from "./ConditionContainer";

type Props = {
  currencyCondition: TCurrencyCondition;
};

export const Currency = ({ currencyCondition }: Props) => (
  <ConditionContainer status={currencyCondition.status} type="Currency">
    <span className="typo-m2 w-0 grow text-start text-ciety-sk">
      {currencyCondition.amount} {currencyCondition.currency}
      {" or more"}
    </span>
  </ConditionContainer>
);
