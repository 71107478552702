import { useHydrateAtoms } from "jotai/utils";
import { ExploreSection } from "~/_app/[dao]/explore/components/mobile/ExploreSection";
import { daoHomeAtom } from "~/_model/daoHome/daoHomeAtom";
import { TDaoHome } from "~/_types/daoHome/TDaoHome";

type TProps = {
  daoHome: TDaoHome;
};

export function ExploreContainer({ daoHome }: TProps) {
  useHydrateAtoms([
    [
      daoHomeAtom,
      {
        data: daoHome,
        copiedData: daoHome,
      },
    ],
  ]);

  return <ExploreSection />;
}
