import { ReactNode } from "react";
import { cn } from "~/_utils/converter/cn";
import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { EDevice } from "~/_enums/EDevice";

type TProps = {
  title: string | null;
  typoClass?: `typo-${string | number}`;
  className?: string;
  children: ReactNode;
};

function SectionHeader({
  title,
  children,
  typoClass = "typo-headline-m",
  className,
}: TProps) {
  const { device } = useDaoHomeStore();

  return (
    <div
      className={cn(
        "flex",
        title ? "justify-between" : "justify-end",
        device == EDevice.Desktop ? "items-center" : "items-end",
      )}
    >
      {title && (
        <h1 className={cn("text-ciety-wh", typoClass, className)}>{title}</h1>
      )}
      {children}
    </div>
  );
}

export { SectionHeader };
