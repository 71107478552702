import { apiBaseUrl } from "~/modules/Router/Auth/constant";
import { fetchAdapter } from "~/utils/fetch";
import { ErrorTypes, ResultType } from "../../types";

export const joinDao = async (params: {
  daoId: string;
  nickname?: string;
  googleAccountId?: string;
}): Promise<
  ResultType<
    {
      isJoined: boolean;
      rejectedContractConditionIds: string[];
      rejectedCurrencyConditionIds: string[];
    },
    ErrorTypes
  >
> => {
  try {
    const url = `${apiBaseUrl}/daos/${encodeURIComponent(
      params.daoId,
    )}/members`;

    const fetchResult = await fetchAdapter(url, {
      method: `POST`,
      credentials: `include`,
      referrerPolicy: `no-referrer-when-downgrade`,
      headers: {
        [`Content-Type`]: `application/json`,
      },
      body: JSON.stringify({
        nickname: params?.nickname ?? null,
        googleAccountId: params.googleAccountId,
      }),
    })
      .then((res) => {
        return { ok: true as const, value: res };
      })
      .catch(() => {
        return {
          ok: false as const,
          value: ErrorTypes.UnCategorizedError,
        };
      });
    if (!fetchResult.ok) {
      return fetchResult;
    }
    const { value: res } = fetchResult;

    if ("status" in res && res.status !== 200) {
      return {
        ok: false,
        value: ErrorTypes.UnCategorizedError,
      };
    }

    const json = await res.json();
    if (json?.code === `104`) {
      return {
        ok: false,
        value: ErrorTypes.ReJoinDaoNotAllowedError,
      };
    }
    if (json?.code === `122`) {
      const contractConditionIds: Array<string> =
        json.data.contractConditions.map((c: { id: string }) => {
          return c.id;
        });
      const currencyConditionIds: Array<string> =
        json.data.currencyConditions.map((c: { id: string }) => {
          return c.id;
        });
      return {
        ok: true,
        value: {
          isJoined: false,
          rejectedContractConditionIds: contractConditionIds,
          rejectedCurrencyConditionIds: currencyConditionIds,
        },
      };
    }
    if (json?.code === `123`) {
      return {
        ok: false,
        value: ErrorTypes.DaoJoinDeficientChainsError,
      };
    }
    if (json?.code === `101`) {
      return {
        ok: false,
        value: ErrorTypes.DuplicateMemberNicknameError,
      };
    }
    if (json?.code !== `000`) {
      return {
        ok: false,
        value: ErrorTypes.UnCategorizedError,
      };
    }

    return {
      ok: true,
      value: {
        isJoined: true,
        rejectedContractConditionIds: [],
        rejectedCurrencyConditionIds: [],
      },
    };
  } catch (error) {
    return {
      ok: false,
      value: ErrorTypes.UnCategorizedError,
    };
  }
};
