/**
 * 공통 사용될 코드
 */
export const ECode = {
  Success: "000",
  NotAuthenticated: "001",
  NotAuthorized: "002",
  BadRequest: "003",
  NotFound: "004",
  Error: "009",
  MaxAttempt: "010",
} as const;

export type ECode = typeof ECode[keyof typeof ECode];
