import { HTMLProps, ReactNode } from "react";
import { cn } from "~/_utils/converter/cn";

type TProps = {
  className?: string;
  children: ReactNode;
} & Omit<HTMLProps<HTMLButtonElement>, "className" | "children" | "type">;

function EmptySectionButton({ className, children, ...props }: TProps) {
  return (
    <button
      type="button"
      className={cn(
        "flex-center typo-m1 rounded-full bg-ciety-lg px-10 py-2 text-ciety-dk",
        className,
      )}
      {...props}
    >
      {children}
    </button>
  );
}

export { EmptySectionButton };
