/**
 * mobile 인지 확인
 * https://stackoverflow.com/a/11381730
 */
export function detectMobile(): boolean {
  if (typeof navigator === "undefined") {
    return false;
  }

  if (navigator.userAgentData?.mobile) {
    return true;
  }

  const toMatch = [
    /Android/i,
    /webOS/i,
    /iPhone/i,
    /iPad/i,
    /iPod/i,
    /BlackBerry/i,
    /Windows Phone/i,
  ];

  return toMatch.some((toMatchItem) => {
    return navigator.userAgent.match(toMatchItem);
  });
}
