import { ENationCode } from "~/_enums/ENationCode";
import { TMerchItem } from "~/_types/TMerchItem";
// import { getUserNationCode } from "~/_utils/checker/getUserNationCode";

/**
 * 마플샵에서 넘어오는 상품을 kr, jp, en... 브라우저 언어에 따라 필터되서 반환
 */
export function filterMerchItemsByUserLanguage(merchItems: TMerchItem[]) {
  // const userNationCode = getUserNationCode();
  /**
   * 현재 국문으로 지원하여 서비스가 언어지원 하게 될 경우 nation code다시 지원
   */
  const userNationCode = "kr";

  const merchsByUserNationCode = merchItems.filter(
    (item) => item.nationCode === userNationCode,
  );

  let merchsToRender = merchsByUserNationCode;
  if (merchsByUserNationCode.length === 0) {
    // fallback
    const merchsWithEnglish = merchItems.filter(
      (item) => item.nationCode === ENationCode.En,
    );
    const canSurvEnglish = merchsWithEnglish.length;
    const merchsWithKorean = merchItems.filter(
      (item) => item.nationCode === ENationCode.Kr,
    );
    const canSurvKorean = merchsWithKorean.length;
    const merchsWithJapanese = merchItems.filter(
      (item) => item.nationCode === ENationCode.Jp,
    );

    if (userNationCode === ENationCode.Kr) {
      if (canSurvEnglish) {
        merchsToRender = merchsWithEnglish;
      } else {
        merchsToRender = merchsWithJapanese;
      }
    } else if (userNationCode === ENationCode.Jp) {
      if (canSurvEnglish) {
        merchsToRender = merchsWithEnglish;
      } else {
        merchsToRender = merchsWithKorean;
      }
    } else {
      if (canSurvKorean) {
        merchsToRender = merchsWithKorean;
      } else {
        merchsToRender = merchsWithJapanese;
      }
    }
  }

  return merchsToRender;
}
