import React, { ReactNode } from "react";

import { Icon, IconType, Loading } from "~/modules/Common/Icons";
import type { ConditionStatus } from "../../types";

type ConditionProps = {
  status: ConditionStatus;
  children?: ReactNode;
  type?: "Currency" | "Digital collection" | "Role";
  oneLine?: boolean;
};

export const ConditionContainer = ({
  status,
  children,
  type,
  oneLine = false,
}: ConditionProps) => {
  let iconType: IconType;
  if (type === "Currency") {
    iconType = "Blockchain";
  } else if (type === "Role") {
    iconType = "User Profile 1";
  } else {
    iconType = "Contract";
  }

  let icon;
  if (status === "loading") {
    icon = <LoadingIcon />;
  } else if (status === "succeed") {
    icon = <SucceedIcon />;
  } else if (status === "failed") {
    icon = <FailedIcon />;
  } else {
    icon = <KeyIcon />;
  }

  return (
    <div
      className={`flex w-full flex-grow ${
        oneLine ? "items-start" : "items-center"
      } justify-between gap-x-4`}
    >
      {type ? (
        <div className="flex w-[8.125rem] items-center gap-x-2 text-ciety-gy">
          <Icon iconType={iconType} className="w-4 h-4" />
          <span className="truncate typo-r2">{type}</span>
        </div>
      ) : null}
      {children}
      {icon}
    </div>
  );
};

const LoadingIcon = () => (
  <Loading color="gray" className="w-6 h-6 animate-spin" />
);

const SucceedIcon = () => (
  <Icon
    iconType="Checkmark Circle"
    className="w-6 h-6 shrink-0 text-ciety-lg"
  />
);

const FailedIcon = () => (
  <Icon
    iconType="Cancel Close Circle"
    className="w-6 h-6 shrink-0 text-ciety-rd"
  />
);

const KeyIcon = () => (
  <Icon iconType="Key-Line" className="w-6 h-6 shrink-0 text-ciety-sk" />
);
