import { ReactNode } from "react";
import { cn } from "~/_utils/converter/cn";

const BUTTON_CLASS_NAME =
  "flex items-center justify-center rounded-full gap-x-2";

type ButtonProps = {
  type?: "button" | "reset" | "submit";
  onClick?: () => void;
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
};

export const BaseButton = ({
  type = "button",
  onClick,
  className,
  children,
  disabled,
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={cn(BUTTON_CLASS_NAME, className)}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
