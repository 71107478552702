import { ReactNode } from "react";

type TProps = {
  children: ReactNode;
  href: string;
};

export function DownloadButton({ children, href }: TProps) {
  return (
    <a
      href={href}
      target="_blank"
      className="flex-center typo-m1 h-12 w-[25rem] gap-x-2 rounded-xl bg-ciety-dim-80 text-ciety-wh transition-colors duration-300 hover:border hover:border-ciety-bk hover:bg-transparent hover:text-ciety-bk"
    >
      {children}
    </a>
  );
}
