import { MarketSupportedChain } from "~/api/dto";
import { env } from "~/env";
import { ChainEnum as ChainEnumType } from "../types";

export const ChainEnum: { [key in MarketSupportedChain]: string } = {
  EthereumMainnet: "Ethereum",
  Goerli: "Goerli",
  PolygonMainnet: "Polygon",
  Mumbai: "Mumbai",
};

export const addressLinkEnum: { [key in MarketSupportedChain]: string } = {
  EthereumMainnet: "https://etherscan.io/address/",
  Goerli: "https://goerli.etherscan.io/address/",
  PolygonMainnet: "https://polygonscan.com/address/",
  Mumbai: "https://mumbai.polygonscan.com/address/",
};

export const apiBaseUrl = env.API_BASE_URL || "";
export const supportedChainEnums: Set<ChainEnumType> = new Set();

env.SUPPORT_CHAINS.split(`,`)
  .map((chainId) => {
    return chainId.trim();
  })
  .forEach((chainId) => {
    if (chainId === `1`) {
      supportedChainEnums.add(ChainEnumType.EthereumMainnet);
    } else if (chainId === `137`) {
      supportedChainEnums.add(ChainEnumType.PolygonMainnet);
    } else if (chainId === `5`) {
      supportedChainEnums.add(ChainEnumType.Goerli);
    } else if (chainId === `80001`) {
      supportedChainEnums.add(ChainEnumType.Mumbai);
    }
  });
