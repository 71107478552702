import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { TPostChannelsOutputDtoChannelList } from "~/api-versioned";
import { TPostChannelsOutputDtoChannelGroupList } from "~/api-versioned";
import type { RootState } from "..";

export type TMobileSelectedChannel = {
  id: string;
  displayName: string;
  channelType: "SNS" | "LIVE_STREAMING";
  visibility: "PUBLIC" | "RESTRICTED" | "PRIVATE";
};

export interface TChannelGroup {
  channelGroups: TPostChannelsOutputDtoChannelGroupList | null;
  loading: boolean;
  error: Error | null;
  channels: TPostChannelsOutputDtoChannelList[];
  selectedChannel: TPostChannelsOutputDtoChannelList | null;
  mobileSelectedChannel: TMobileSelectedChannel | null;
}

interface TChannelGroupState {
  channelGroup: TChannelGroup;
}

const initialState: TChannelGroupState = {
  channelGroup: {
    channelGroups: [],
    loading: false,
    error: null,
    //
    channels: [],
    selectedChannel: null,
    mobileSelectedChannel: null,
  },
};

export const channelGroupSlice = createSlice({
  name: "channelGroup",
  initialState,
  reducers: {
    setChannels: (state, action: PayloadAction<TChannelGroup["channels"]>) => {
      state.channelGroup.channels = action.payload;
    },

    setChannelGroupMobileSelectedChannel: (
      state,
      action: PayloadAction<TChannelGroup["mobileSelectedChannel"]>,
    ) => {
      state.channelGroup.mobileSelectedChannel = action.payload;
    },
    setSelectedChannel: (
      state,
      action: PayloadAction<TChannelGroup["selectedChannel"]>,
    ) => {
      state.channelGroup.selectedChannel = action.payload;
    },
  },
});

export const {
  setChannels,
  setChannelGroupMobileSelectedChannel,
  setSelectedChannel,
} = channelGroupSlice.actions;

export const selectChannelGroup = (state: RootState) =>
  state.channelGroup.channelGroup;

export default channelGroupSlice.reducer;
