import React, { useCallback, useEffect, useState } from "react";

import {
  filterSizeClass,
  hasSizeClass,
  removeSizeClass,
} from "~/utils/checker";
import { Empty } from "../../Layout/components/Empty";
import { loader } from "./loader";
import type { IconType } from "./types";

type Props = {
  className?: string;
  width?: `${number}rem`;
  height?: `${number}rem`;
  iconType: IconType;
};

export const Icon = ({ className = "", iconType, width, height }: Props) => {
  const [IconComponent, setIconComponent] = useState<React.FC<
    React.SVGProps<SVGSVGElement>
  > | null>(null);

  const loadLazyComponent = useCallback(
    <SvgFile extends typeof import("*.svg")>(
      lazyComponent: () => Promise<SvgFile>,
    ) => {
      return new Promise<SvgFile>((resolve, reject) => {
        lazyComponent().then(resolve).catch(reject);
      });
    },
    [],
  );

  useEffect(() => {
    const iconLoader = loader.get(iconType);
    if (iconLoader) {
      loadLazyComponent(iconLoader)
        .then((result) => {
          setIconComponent(() => result.ReactComponent);
        })
        .catch(() => {
          location.reload();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iconType]);

  return IconComponent ? (
    <IconComponent
      className={`${
        hasSizeClass(className) ? filterSizeClass(className) : "h-6 w-6"
      } ${removeSizeClass(className)}`}
      style={{ width, height }}
    />
  ) : (
    <Empty
      className={`${
        hasSizeClass(className) ? filterSizeClass(className) : "h-6 w-6"
      } ${removeSizeClass(className)}`}
      width={width}
      height={height}
    />
  );
};
