import { MarketSupportedChain } from "~/api/dto";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createTagList = <R extends Record<string, any>, T extends string>({
  results,
  idFn,
  tagType,
  groupIds,
}: {
  results: R[] | undefined;
  idFn: (args: R) => string;
  tagType: T;
  groupIds: string[];
}) => {
  return results
    ? [
        ...results.map((args) => ({ type: tagType, id: idFn(args) })),
        ...groupIds.map((groupId) => ({ type: tagType, id: groupId })),
        { type: tagType },
      ]
    : [{ type: tagType }];
};

export const getCacheTag = ({
  chain,
  predicates,
}: {
  chain: MarketSupportedChain;
  predicates: string | string[];
}) => [chain, predicates].flat().join("_");
