import { useAtom, useSetAtom } from "jotai";

import {
  badgeCountAtom,
  writeAllBadgeCountAtom,
  writeBadgeCountAtom,
  writeIncreaseDmBadgeCountAtom,
  writeNotificationBadgeCountAtom,
} from "~/_model/badge/badgeCountAtom";

const useBadgeCountStore = () => {
  const [badgeCount] = useAtom(badgeCountAtom);
  const setBadgeCount = useSetAtom(writeBadgeCountAtom);
  const setAllBadgeCount = useSetAtom(writeAllBadgeCountAtom);
  const setIncreaseDmBadgeCount = useSetAtom(writeIncreaseDmBadgeCountAtom);
  const setNotificationBadgeCount = useSetAtom(writeNotificationBadgeCountAtom);

  return {
    badgeCount,
    setBadgeCount,
    setAllBadgeCount,
    setIncreaseDmBadgeCount,
    setNotificationBadgeCount,
  };
};

export { useBadgeCountStore };
