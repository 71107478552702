import { EChain } from "~/_enums/EChain";
import { EChannelType } from "~/_enums/EChannelType";
import { EReportScreenType } from "~/_enums/EReportScreenType";
import { TMarketSupportedChain } from "~/_types/market/TMarketSupportedChain";
import { buildQuery } from "../calibrator/urlCalibrator";
import { toSubdomainPolicy } from "./toSubdomainPolicy";
import { EDaoHomeViewMode } from "~/_enums/daoHome/EDaoHomeViewMode";
import { toDec } from "~/_utils/blockchain/convertsions";
import { ESettingsMenu } from "~/_enums/ESettingsMenu";

/** @description useNavigation 또는 mobileNavigate 사용 시 필요한 URL 빌딩 */
export const generateUrl = (props: Props): `/${string}` => {
  const addedSubdomain = ("/" +
    toSubdomainPolicy(props.subdomain)) as `/${string}`;

  switch (props.type) {
    case "home":
      return props.explore ? `${addedSubdomain}/explore` : `${addedSubdomain}`;
    case "main":
      return `${addedSubdomain}?${buildQuery({
        created: props.created,
      })}`;

    case "exploreMain":
      return `${addedSubdomain}/explore`;
    case "exploreFeed":
      return `${addedSubdomain}/explore/channels/${
        props.channelId
      }?${buildQuery({
        channelType: props.channelType,
      })}`;
    case "feed":
      return props.explore
        ? `${addedSubdomain}/explore/channels/${props.channelId}?${buildQuery({
            created: props.created,
            channelType: props.channelType,
          })}`
        : `${addedSubdomain}/channels/${props.channelId}?${buildQuery({
            created: props.created,
            channelType: props.channelType,
          })}`;
    case "exploreThread":
      return `${addedSubdomain}/explore/channels/${props.channelId}/posts/${
        props.postId
      }?${buildQuery({
        channelType: props.channelType,
      })}`;
    case "thread":
      return `${addedSubdomain}${
        props.explore ? "/explore/channels" : "/channels"
      }/${props.channelId}/posts/${props.postId}?${buildQuery({
        channelType: props.channelType,
      })}`;
    case "notification":
      return `${addedSubdomain}/notifications`;
    // case "search":
    // case "searchMobile":
    //   return `${addedSubdomain}/search?${buildQuery({
    //     q: props.keyword,
    //     defaultSearchType: props.defaultSearchType,
    //   })}`;
    case "mainMobile":
      return `${addedSubdomain}`;
    case "feedMobile":
      return `${addedSubdomain}/channels/${props.channelId}?${buildQuery({
        channelType: props.channelType,
      })}`;
    case "chatFeedMobile":
      return `${addedSubdomain}/channels/${props.channelId}?${buildQuery({
        channelType: "CHAT",
        highlightId: props.highlightId,
      })}`;
    case "threadMobile":
      return `${addedSubdomain}/channels/${props.channelId}/posts/${
        props.postId
      }?${buildQuery({
        scrollBottom: props.scrollBottom,
        highlightId: props.highlightId,
        channelType: props.channelType,
      })}`;
    case "channelMembersMobile":
      return `${addedSubdomain}/channels/${props.channelId}/members`;
    case "memberProfileMobile":
      return `${addedSubdomain}/members/${props.memberId}/profile`;
    // case "searchFilterMobile":
    //   return `${addedSubdomain}/search?${buildQuery({
    //     filters: props.filters,
    //     searchType: props.searchType,
    //     searchedKeyword: props.searchedKeyword,
    //     defaultFilterType: props.defaultFilterType,
    //   })}`;
    case "postEditorMobile":
      return `${addedSubdomain}/posts/editor?${buildQuery({
        postId: props.postId,
        channelId: props.channelId,
      })}`;
    case "nftDetailMobile":
      return `${addedSubdomain}/nft/${props.assetId}`;
    case "giftokDetailMobile":
      return `${addedSubdomain}/giftok/${props.commentId}?${buildQuery({
        assetId: props.assetId,
        channelType: props.channelType,
      })}`;
    case "reportMobile":
      return `${addedSubdomain}/report?${buildQuery({
        id: props.id,
        screenType: props.reportType,
      })}`;
    case "spaceMobile":
      return `${addedSubdomain}/space/${props.memberId}?${buildQuery({
        isInnerTab: props.isInnerTab,
      })}`;
    case "profileEditMobile":
      return `${addedSubdomain}/space/${props.memberId}/edit-profile`;
    case "digitalCollectiblesMobile":
      return `${addedSubdomain}/space/${
        props.memberId
      }/digital-collectibles?${buildQuery({
        isFromMarket: props.isFromMarket,
      })}`;
    case "digitalCollectiblesCollectionMobile":
      return `${addedSubdomain}/space/${
        props.memberId
      }/digital-collectibles/collection?${buildQuery({
        chain: props.chain,
        address: props.address,
        name: props.name,
        numDistinctTokensOwned: props.numDistinctTokensOwned,
      })}`;
    case "digitalCollectiblesItemMobile":
      return `${addedSubdomain}/space/${
        props.memberId
      }/digital-collectibles/item?${buildQuery({
        nftId: props.nftId,
      })}`;
    case "myAccountMobile":
      return `${addedSubdomain}/space/${props.memberId}/my-account`;
    case "settingsMobile":
      return `${addedSubdomain}/space/${props.memberId}/settings`;
    case "myNftsMobile":
      return `${addedSubdomain}/space/${props.memberId}/my-nfts`;
    case "myNftsCollectionMobile":
      return `${addedSubdomain}/space/${
        props.memberId
      }/my-nfts/collection?${buildQuery({
        chain: props.chain,
        address: props.address,
        name: props.name,
        numDistinctTokensOwned: props.numDistinctTokensOwned,
      })}`;
    case "marketItem":
      return `${addedSubdomain}/market?${buildQuery({
        chain: props.chain,
        address: props.contractAddress,
        tokenId: toDec(props.tokenId),
      })}`;
    case "marketContractNftDetail":
      return `${addedSubdomain}/market/assets/${props.chain}/${props.address}/${props.tokenId}`;
    case "communityInfoSettingMobile":
      return `${addedSubdomain}/setting/info`;
    case "communityChannelSettingMobile":
      return `${addedSubdomain}/setting/channels`;
    case "leaveCommunityMobile":
      return `${addedSubdomain}/leave?${buildQuery({
        displayName: props.displayName,
      })}`;
    case "dm":
      return `${addedSubdomain}/dm`;
    case "dmDetail":
      return `${addedSubdomain}/dm/${props.messageGroupId}`;
    case "dmDetailMobile":
      return `${addedSubdomain}/dm/${props.messageGroupId}`;
    case "dmDrawerMobile":
      return `${addedSubdomain}/dm/${props.messageGroupId}/drawer`;
    case "dmMembersMobile":
      return `${addedSubdomain}/dm/members?${buildQuery({
        selectedMembers: JSON.stringify(props.selectedMembers),
        messageGroupId: props.messageGroupId,
        invite: props.invite,
      })}`;
    case "marketContractAssetList":
      return `${addedSubdomain}/market/assets/${props.chain}/${
        props.address
      }?${buildQuery({
        pageKey: props.pageKey,
      })}`;
    case "marketTopMemberList":
      return `${addedSubdomain}/market/members`;
    case "marketLiveItemList":
      return `${addedSubdomain}/market/live-items`;
    case "join":
      return `${addedSubdomain}/join`;
    case "allPosts":
      return `${addedSubdomain}${
        props.explore ? "/explore/all-posts" : "/all-posts"
      }`;
    case "hotPosts":
      return `${addedSubdomain}${
        props.explore ? "/explore/hot-posts" : "/hot-posts"
      }`;
    case "market":
      return `${addedSubdomain}/market`;
    case "merch":
      return props.explore
        ? `${addedSubdomain}/explore/merch`
        : `${addedSubdomain}/merch`;
    case "daoHomeSettings":
      return `${addedSubdomain}/settings/home?${buildQuery({
        viewMode: props.viewMode ?? EDaoHomeViewMode.Edit,
      })}`;
    case "gate":
      return `${addedSubdomain}/gate`;
    case "settings":
      return `${addedSubdomain}/settings${props.menu ? `/${props.menu}` : ""}`;
    case "bookmark":
      return `${addedSubdomain}/bookmark`;
  }
};

type Props =
  | MainProps
  | FeedProps
  | ThreadProps
  | NotificationProps
  // | SearchProps
  | MainMobileProps
  | FeedMobileProps
  | ChatFeedMobileProps
  | ThreadMobileProps
  | ChannelMembersMobileProps
  | MemberProfileMobileProps
  // | SearchMobileProps
  // | SearchFilterMobileProps
  | PostEditorMobileProps
  | NFTDetailMobileProps
  | GIFTOKDetailMobileProps
  // | ReportMobileProps
  | SpaceMobileProps
  | ProfileEditMobileProps
  | SettingsMobileProps
  | DigitalCollectiblesMobileProps
  | DigitalCollectiblesContractMobileProps
  | DigitalCollectiblesItemMobileProps
  | MyAccountMobileProps
  | MyNftsMobileProps
  | MyNftsCollectionMobileProps
  | MarketItem
  | MarketContractAssetListProps
  | MarketContractNftDetailProps
  | MarketTopMemberListProps
  | MarketLiveItemListProps
  | CommunityInfoSettingMobileProps
  | CommunityChannelSettingMobileProps
  | LeaveCommunityMobileProps
  | DmProps
  | DmDetailProps
  | DmDetailMobileProps
  | DmDrawerMobileProps
  | DmMembersMobileProps
  | ReportMobileProps
  | JoinMobileProps
  | DaoHomeSettings
  | DaoHomeAllPostsProps
  | DaoHomeHotPostsProps
  | MarketProps
  | MerchProps
  | ExploreMainProps
  | ExploreFeedProps
  | ExploreThreadProps
  | HomeProps
  | TGateProps
  | TBookmarkProps
  | TSettingsProps;

type MarketItem = {
  type: "marketItem";
  subdomain: string;
  chain: EChain;
  contractAddress: string;
  tokenId: string;
};

type MainProps = {
  type: "main";
  subdomain: string;
  created?: boolean;
};
type ExploreMainProps = {
  type: "exploreMain";
  subdomain: string;
};

type ExploreFeedProps = {
  type: "exploreFeed";
  subdomain: string;
  channelId: string;
  channelType: EChannelType;
};

type FeedProps = {
  type: "feed";
  subdomain: string;
  channelId: string;
  channelType: EChannelType;
  created?: boolean;
  explore?: boolean;
};

type ExploreThreadProps = {
  type: "exploreThread";
  subdomain: string;
  postId: string;
  channelId: string;
  channelType: EChannelType;
};

type ThreadProps = {
  type: "thread";
  subdomain: string;
  postId: string;
  channelId: string;
  channelType: EChannelType;
  explore?: boolean;
};

type NotificationProps = {
  type: "notification";
  subdomain: string;
};

// type SearchProps = {
//   type: "search";
//   subdomain: string;
//   keyword?: string;
//   defaultSearchType?: SearchType;
// };

type MainMobileProps = {
  type: "mainMobile";
  subdomain: string;
};

type FeedMobileProps = {
  type: "feedMobile";
  subdomain: string;
  channelId: string;
  channelType: EChannelType;
};

type ChatFeedMobileProps = {
  type: "chatFeedMobile";
  subdomain: string;
  channelId: string;
  highlightId?: string;
};

type ThreadMobileProps = {
  type: "threadMobile";
  subdomain: string;
  postId: string;
  channelId: string;
  highlightId?: string;
  scrollBottom?: boolean;
  channelType: EChannelType;
};

type ChannelMembersMobileProps = {
  type: "channelMembersMobile";
  subdomain: string;
  channelId: string;
};

type MemberProfileMobileProps = {
  type: "memberProfileMobile";
  subdomain: string;
  memberId: string;
};

// type SearchMobileProps = {
//   type: "searchMobile";
//   subdomain: string;
//   keyword?: string;
//   defaultSearchType?: SearchType;
// };

// type SearchFilterMobileProps = {
//   type: "searchFilterMobile";
//   subdomain: string;
//   filters: { [key in FilterType]?: string };
//   searchType: SearchType;
//   searchedKeyword: string;
//   defaultFilterType?: FilterType;
// };

type PostEditorMobileProps = {
  type: "postEditorMobile";
  subdomain: string;
  postId?: string;
  channelId: string;
};

type NFTDetailMobileProps = {
  type: "nftDetailMobile";
  subdomain: string;
  assetId: string;
};

type GIFTOKDetailMobileProps = {
  type: "giftokDetailMobile";
  subdomain: string;
  commentId: string;
  assetId: string | null;
  channelType: EChannelType;
};

// type ReportMobileProps = {
//   type: "reportMobile";
//   subdomain: string;
//   id: string;
//   reportType: CreateReportType;
// };

type SpaceMobileProps = {
  type: "spaceMobile";
  subdomain: string;
  memberId: string;
  isInnerTab?: boolean;
};

type ProfileEditMobileProps = {
  type: "profileEditMobile";
  subdomain: string;
  memberId: string;
};

type SettingsMobileProps = {
  type: "settingsMobile";
  subdomain: string;
  memberId: string;
};

type DigitalCollectiblesMobileProps = {
  type: "digitalCollectiblesMobile";
  subdomain: string;
  memberId: string;
  isFromMarket?: boolean;
};

type DigitalCollectiblesContractMobileProps = {
  type: "digitalCollectiblesCollectionMobile";
  subdomain: string;
  memberId: string;
  chain: TMarketSupportedChain;
  address: string;
  name: string | null;
  numDistinctTokensOwned: number;
};

type DigitalCollectiblesItemMobileProps = {
  type: "digitalCollectiblesItemMobile";
  subdomain: string;
  memberId: string;
  nftId: string;
};

type MyAccountMobileProps = {
  type: "myAccountMobile";
  subdomain: string;
  memberId: string;
};

type MyNftsMobileProps = {
  type: "myNftsMobile";
  subdomain: string;
  memberId: string;
};

type MyNftsCollectionMobileProps = {
  type: "myNftsCollectionMobile";
  subdomain: string;
  memberId: string;
  chain: TMarketSupportedChain;
  address: string;
  name: string | null;
  numDistinctTokensOwned: number;
};

type CommunityInfoSettingMobileProps = {
  type: "communityInfoSettingMobile";
  subdomain: string;
};

type CommunityChannelSettingMobileProps = {
  type: "communityChannelSettingMobile";
  subdomain: string;
};

type LeaveCommunityMobileProps = {
  type: "leaveCommunityMobile";
  subdomain: string;
  displayName: string;
};

type DmProps = {
  type: "dm";
  subdomain: string;
};

type DmDetailProps = {
  type: "dmDetail";
  subdomain: string;
  messageGroupId: string;
};

type DmDetailMobileProps = {
  type: "dmDetailMobile";
  subdomain: string;
  messageGroupId: string;
};

type DmDrawerMobileProps = {
  type: "dmDrawerMobile";
  subdomain: string;
  messageGroupId: string;
};

type DmMembersMobileProps = {
  type: "dmMembersMobile";
  subdomain: string;
  selectedMembers?: string[];
  messageGroupId?: string;
  invite?: boolean;
};

type ReportMobileProps = {
  type: "reportMobile";
  subdomain: string;
  id: string;
  reportType: EReportScreenType;
};

type MarketContractAssetListProps = {
  type: "marketContractAssetList";
  subdomain: string;
  chain: EChain;
  address: string;
  pageKey?: string;
};

type MarketContractNftDetailProps = {
  type: "marketContractNftDetail";
  subdomain: string;
  chain: EChain;
  address: string;
  tokenId: string;
};

type MarketTopMemberListProps = {
  type: "marketTopMemberList";
  subdomain: string;
};

type MarketLiveItemListProps = {
  type: "marketLiveItemList";
  subdomain: string;
};

type JoinMobileProps = {
  type: "join";
  subdomain: string;
};

type DaoHomeAllPostsProps = {
  type: "allPosts";
  subdomain: string;
  explore?: boolean;
};

type DaoHomeHotPostsProps = {
  type: "hotPosts";
  subdomain: string;
  explore?: boolean;
};

type MarketProps = {
  type: "market";
  subdomain: string;
};

type MerchProps = {
  type: "merch";
  subdomain: string;
  explore?: boolean;
};

type DaoHomeSettings = {
  type: "daoHomeSettings";
  subdomain: string;
  viewMode?: EDaoHomeViewMode;
};

type HomeProps = {
  type: "home";
  subdomain: string;
  explore?: boolean;
};

type TGateProps = {
  type: "gate";
  subdomain: string;
};

type TSettingsProps = {
  type: "settings";
  subdomain: string;
  menu?: ESettingsMenu;
};

type TBookmarkProps = {
    type: "bookmark";
    subdomain: string;
};