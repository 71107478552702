import { MarketSupportedChain } from "~/api/dto";
import { env } from "~/env";
import { filterOutTestnets } from "~/modules/BlockChain/Inventory/utils";
import { marketSupportedChain } from "~/modules/Market/types";

const handleTestnets = (chain: MarketSupportedChain) =>
  env.IS_PROD ? filterOutTestnets(chain) : chain;

export const MARKET_CHAINS = (isPolygonPriority?: boolean) =>
  marketSupportedChain(isPolygonPriority).filter(handleTestnets);
