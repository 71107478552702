export const EErrorTypes = {
  DaoJoinDeficientChainsError: "DaoJoinDeficientChainsError",
  NotExistingDaoError: "NotExistingDaoError",
  InvalidSignatureError: "InvalidSignatureError",
  UnCategorizedError: "UnCategorizedError",
  InvalidAddressError: "InvalidAddressError",
  DismissFromWalletError: "DismissFromWalletError",
  AlreadyProcessingFromWalletError: "AlreadyProcessingFromWalletError",
  AlreadyUsedAccountError: "AlreadyUsedAccountError",
  CantGoToAuthStatusError: "CantGoToAuthStatusError",
  ReJoinDaoNotAllowedError: "ReJoinDaoNotAllowedError",
  AlreadyJoinedDaoError: "AlreadyJoinedDaoError",
  NotSupportedChainError: "NotSupportedChainError",
  NotDetectedWalletError: "NotDetectedWalletError",
  TooManyVerifyRequestError: "TooManyVerifyRequestError",
  DuplicateMemberNicknameError: "DuplicateMemberNicknameError",
  AlreadyRegisteredError: "AlreadyRegisteredError",
} as const;

export type EErrorTypes = typeof EErrorTypes[keyof typeof EErrorTypes];
