import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PostEditorReducerState = {
  title: string;
  isPosting: boolean;
  isUploadingPost: boolean;
  initialEditorState: Pick<
    TPost,
    "id" | "richText" | "text" | "attachments" | "mentions"
  > | null;
  editPostId: string | null;
  isOpenPostEditor: boolean;
};
export const initialState: PostEditorReducerState = {
  title: "",
  isPosting: false,
  isUploadingPost: false,
  initialEditorState: null,
  editPostId: null,
  isOpenPostEditor: false,
};

export const postEditorSlice = createSlice({
  name: "postEditor",
  initialState,
  reducers: {
    setTitle: (
      state: PostEditorReducerState,
      action: PayloadAction<string>,
    ) => {
      state.title = action.payload;
    },
    setIsPosting: (
      state: PostEditorReducerState,
      action: PayloadAction<boolean>,
    ) => {
      state.isPosting = action.payload;
    },
    setIsUploadPost: (
      state: PostEditorReducerState,
      action: PayloadAction<boolean>,
    ) => {
      state.isUploadingPost = action.payload;
    },
    setInitialEditorState: (
      state: PostEditorReducerState,
      action: PayloadAction<Pick<
        TPost,
        "id" | "richText" | "text" | "attachments" | "mentions"
      > | null>,
    ) => {
      state.initialEditorState = action.payload;
    },
    setEditPostId: (
      state: PostEditorReducerState,
      action: PayloadAction<string | null>,
    ) => {
      state.editPostId = action.payload;
    },
    setIsOpenPostEditor: (
      state: PostEditorReducerState,
      action: PayloadAction<boolean>,
    ) => {
      state.isOpenPostEditor = action.payload;
    },
    resetPostEditor: (state: PostEditorReducerState) => {
      state.title = "";
      state.isPosting = false;
      state.isUploadingPost = false;
      state.initialEditorState = null;
      state.editPostId = null;
    },
  },
});

export const {
  setTitle,
  setIsPosting,
  setIsUploadPost,
  setInitialEditorState,
  setEditPostId,
  resetPostEditor,
  setIsOpenPostEditor,
} = postEditorSlice.actions;

export default postEditorSlice.reducer;
