import { concurrent, map, pipe, toArray, toAsync } from "@fxts/core";
import {
  createApi,
  defaultSerializeQueryArgs,
} from "@reduxjs/toolkit/dist/query/react";

import { MarketSupportedChain } from "~/api/dto";
import { getBaseQuery } from "~/api/getBaseQuery";
import { createTagList, getCacheTag } from "~/api/Inventory/utils";
import {
  GetCheckDaoMember,
  GetContractOwner,
  GetCreatorFees,
  GetCryptoCurrencies,
  GetErc20BalancesToRpc,
  GetErc20BalanceToRpc,
  GetItemBalance,
  GetItemBalanceToRpc,
  GetLiveItems,
  GetMarketFees,
  GetMarketOrdersForItem,
  GetMarketProposalsForItem,
  GetNftOwnerToRpc,
  GetRecommendContracts,
  GetTopMembers,
  GetTransactionActivities,
  PostOrderCreation,
  PostOrderFulfill,
  PostOrderInvalidate,
  PostProposalCreation,
  PutCreatorFee,
  PutMarketSetting,
} from "~/api/Market/types";
import { decodeAddressFrom32Bytes } from "~/modules/Market/utils/address";
import { getErc20BalanceToRPC } from "~/modules/Market/utils/getErc20BalanceToRPC";
import { getItemBalanceToRPC } from "~/modules/Market/utils/getItemBalanceToRPC";
import { getNftOwner } from "~/modules/Market/utils/getNftOwner";

export const marketApi = createApi({
  reducerPath: "marketApi",
  baseQuery: getBaseQuery({}),
  tagTypes: [
    "LiveItems",
    "TopMembers",
    "RecommendContracts",
    "Order",
    "Proposal",
    "CreatorFee",
    "ItemBalance",
    "NftOwner",
    "TransactionActivity",
  ],
  keepUnusedDataFor: 30,
  endpoints: (builder) => ({
    // 다오의 마켓플레이스 우선순위 높은 멤버 목록 조회
    // https://api-dev.ciety.dev/api#/Marketplace/GetMarketplaceDaosDaoidTopmembersController_handle
    getTopMembers: builder.query<
      GetTopMembers["data"],
      GetTopMembers["request"]
    >({
      query: ({ daoId, ...params }) => ({
        url: `marketplace/daos/${daoId}/top-members`,
        method: "GET",
        params,
      }),
      providesTags: (result, error, { daoId }) => [
        "TopMembers",
        { type: "TopMembers", id: daoId },
      ],
      transformResponse: (response: GetTopMembers["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.getTopMembers.name}`,
            );
          }
        }
      },
    }),

    // 다오의 마켓플레이스 라이브 목록 조회
    // https://api-dev.ciety.dev/api#/Marketplace/GetMarketplaceLiveitemsController_handle
    getLiveItems: builder.query<
      GetLiveItems["data"],
      GetLiveItems["request"] & { daoId: string }
    >({
      query: (params) => ({
        url: `marketplace/live-items`,
        method: "GET",
        params,
      }),
      providesTags: (result, error, { daoId }) => [
        "LiveItems",
        { type: "LiveItems", id: daoId },
      ],
      transformResponse: (response: GetLiveItems["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.getLiveItems.name}`,
            );
          }
        }
      },
    }),

    // 다오의 마켓플레이스 추천 컨트랙트 목록 조회
    // https://api-dev.ciety.dev/api#/Marketplace/GetMarketplaceDaosDaoidContractsController_handle
    getRecommendContracts: builder.query<
      GetRecommendContracts["data"],
      GetRecommendContracts["request"]
    >({
      query: ({ daoId }) => ({
        url: `marketplace/daos/${daoId}/contracts`,
        method: "GET",
      }),
      providesTags: (result, error, { daoId }) => [
        "RecommendContracts",
        { type: "RecommendContracts", id: daoId },
      ],
      transformResponse: (response: GetRecommendContracts["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.getRecommendContracts.name}`,
            );
          }
        }
      },
    }),

    // 아이템에 등록된 오더 목록 조회
    // https://api-dev.ciety.dev/api#/Marketplace/GetMarketplaceOrdersController_handle
    getMarketOrdersForItem: builder.query<
      GetMarketOrdersForItem["data"],
      GetMarketOrdersForItem["request"]
    >({
      query: ({ chain, address, tokenId }) => ({
        url: `marketplace/orders`,
        method: "GET",
        params: { chain, address, tokenId },
      }),
      providesTags: (result, error, { chain, address, tokenId }) => {
        return createTagList({
          results: result,
          idFn: (args) => args.id,
          tagType: "Order",
          groupIds: [
            chain,
            getCacheTag({ chain, predicates: address }),
            getCacheTag({ chain, predicates: [address, tokenId] }),
          ],
        });
      },
      transformResponse: (response: GetMarketOrdersForItem["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data?.orders;
          }
          case "050": {
            throw new Error("This item does not exist in the blockchain");
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.getMarketOrdersForItem.name}`,
            );
          }
        }
      },
    }),

    // 아이템에 등록된 프로포절 목록 조회
    // https://api-dev.ciety.dev/api#/Marketplace/GetMarketplaceProposalsController_handle
    getMarketProposalsForItem: builder.query<
      GetMarketProposalsForItem["data"],
      GetMarketProposalsForItem["request"]
    >({
      query: ({ chain, address, tokenId }) => ({
        url: `marketplace/proposals`,
        method: "GET",
        params: { chain, address, tokenId },
      }),
      providesTags: (result, error, { chain, address, tokenId }) => {
        return createTagList({
          results: result,
          idFn: (args) => args.id,
          tagType: "Proposal",
          groupIds: [
            chain,
            getCacheTag({ chain, predicates: address }),
            getCacheTag({ chain, predicates: [address, tokenId] }),
          ],
        });
      },
      transformResponse: (response: GetMarketProposalsForItem["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data?.proposals;
          }
          case "050": {
            throw new Error("This item does not exist in the blockchain");
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.getMarketProposalsForItem.name}`,
            );
          }
        }
      },
    }),

    // 아이템에 오더 등록 요청
    // https://api-dev.ciety.dev/api#/Marketplace/PostMarketplaceOrdersController_handle
    postOrderCreation: builder.mutation<
      PostOrderCreation["data"],
      PostOrderCreation["request"] & { cacheInvalidateIds: string[] }
    >({
      query: ({ order }) => ({
        url: `marketplace/orders`,
        method: "POST",
        body: { order },
      }),
      transformResponse: (response: PostOrderCreation["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          case "005": {
            throw new Error("Already registered order");
          }
          case "050": {
            throw new Error("This item does not exist in the blockchain");
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.postOrderCreation.name}`,
            );
          }
        }
      },
      invalidatesTags: (result, error, { cacheInvalidateIds }) => {
        if (error || !result) {
          return [];
        } else {
          const { daoId, marketplaceOrderId } = result;
          return [
            ...cacheInvalidateIds.map((id) => ({
              type: "Order" as const,
              id,
            })),
            { type: "Order" as const, id: marketplaceOrderId },
            { type: "LiveItems" as const, id: daoId },
            { type: "TopMembers" as const, id: daoId },
          ];
        }
      },
    }),

    // 아이템에 프로포절 등록 요청
    // https://api-dev.ciety.dev/api#/Marketplace/PostMarketplaceProposalsController_handle
    postProposalCreation: builder.mutation<
      PostProposalCreation["data"],
      PostProposalCreation["request"] & { cacheInvalidateIds: string[] }
    >({
      query: ({ proposal }) => ({
        url: `marketplace/proposals`,
        method: "POST",
        body: { proposal },
      }),
      transformResponse: (response: PostProposalCreation["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          case "005": {
            throw new Error("Already registered order");
          }
          case "050": {
            throw new Error("This item does not exist in the blockchain");
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.postProposalCreation.name}`,
            );
          }
        }
      },
      invalidatesTags: (result, error, { cacheInvalidateIds }) => {
        if (error || !result) {
          return [];
        } else {
          const { daoId, marketplaceProposalId } = result;
          return [
            ...cacheInvalidateIds.map((id) => ({
              type: "Proposal" as const,
              id,
            })),
            { type: "Proposal" as const, id: marketplaceProposalId },
            { type: "LiveItems" as const, id: daoId },
            { type: "TopMembers" as const, id: daoId },
          ];
        }
      },
    }),

    // 멤버의 마켓 활동 내역
    // https://api-dev.ciety.dev/api#/Marketplace/GetMarketplaceDaosDaoidActivities_handle
    getTransactionActivities: builder.query<
      GetTransactionActivities["data"],
      GetTransactionActivities["request"]
    >({
      query: ({ daoId, count = "100", sinceId }) => ({
        url: `marketplace/daos/${daoId}/activities`,
        method: "GET",
        params: { sinceId, count },
      }),
      providesTags: (result, error, { daoId }) => {
        if (error) {
          return [];
        }
        return [
          {
            type: "TransactionActivity",
            id: daoId,
          },
        ];
      },
      serializeQueryArgs: ({ endpointName, queryArgs, endpointDefinition }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { sinceId, ...cachedArgs } = queryArgs;
        return defaultSerializeQueryArgs({
          endpointName,
          queryArgs: cachedArgs,
          endpointDefinition,
        });
      },
      transformResponse: (response: GetTransactionActivities["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.getTransactionActivities.name}`,
            );
          }
        }
      },
      merge: (currentCache, newItems, { arg }) => {
        // 요청한 count 수 만큼 목록이 응답되지 않으면 다음 페이지가 없는 것으로 판단
        // 마지막 페이지인데 요청 개수가 딱 떨어지는 경우 miss 되는 로직이라서 개선 필요.
        const hasNextPage = newItems.items.length === Number(arg.count ?? 100);

        if (currentCache.lastId != null) {
          currentCache.items.push(...newItems.items);
        }

        if (hasNextPage) {
          currentCache.lastId = newItems.lastId;
        } else {
          currentCache.lastId = null;
        }
      },
      forceRefetch({ currentArg, previousArg }) {
        return JSON.stringify(currentArg) !== JSON.stringify(previousArg);
      },
    }),
    // 다오에 가입된 멤버인지 검사
    // https://api-dev.ciety.dev/api#/Marketplace/GetMarketplaceMembersController_handle
    getCheckDaoMember: builder.query<
      GetCheckDaoMember["data"],
      GetCheckDaoMember["request"]
    >({
      query: ({ chain, address }) => ({
        url: `marketplace/members`,
        method: "GET",
        params: { chain, address },
      }),
      keepUnusedDataFor: 0,
      transformResponse: (response: GetCheckDaoMember["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          case "004": {
            throw new Error("Not a member in this community");
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.getCheckDaoMember.name}`,
            );
          }
        }
      },
    }),

    // 오더 무효화
    // https://www.notion.so/marpple/caa402343b85487b9689662385955365?pvs=4
    // https://api-dev.ciety.dev/api#/Marketplace/PostMarketplaceOrdersInvalidateController_handle
    postOrderInvalidate: builder.mutation<
      PostOrderInvalidate["data"],
      PostOrderInvalidate["request"] & {
        chain: MarketSupportedChain;
        address: string;
        tokenId: string;
      }
    >({
      query: ({ orders }) => {
        return {
          url: `marketplace/orders/invalidate`,
          method: "POST",
          body: {
            orders,
          },
        };
      },
      transformResponse: (response: PostOrderInvalidate["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data.orders;
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.postOrderInvalidate.name}`,
            );
          }
        }
      },
      invalidatesTags: (result, error, { chain, address, tokenId }) => {
        if (!result) {
          return [];
        } else {
          const invalidateTag = getCacheTag({
            chain,
            predicates: [address, tokenId],
          });
          return result
            .filter(({ success }) => success)
            .flatMap(({ id }) => [
              { type: "Order" as const, id },
              { type: "Proposal" as const, id },
              { type: "ItemBalance" as const, id: invalidateTag },
              { type: "NftOwner" as const, id: invalidateTag },
            ]);
        }
      },
      onQueryStarted: (
        { orders, chain, address, tokenId },
        { dispatch, queryFulfilled },
      ) => {
        const invalidateOrderIds = orders.map((o) => o.id);
        /* Optimistic Cache update
         *  - 에러인 경우에만 rollback 함
         *  - 정상 응답이라도 server 가 invalidate 안된다고 판단했을 시에도 optimistic 하게 둠.
         *    - 해당 유저의 cache 유지 기간이 종료되거나 다른 유저가 invalidate 을 재요청 할 것이기 때문
         *    - FE 화면은 optimistic 하게 이미 반영되어 있기 때문에 굳이 불필요하게 invalidate 요청을 자주 하지 않기 위함.
         * */
        const ordersPatchResult = dispatch(
          marketApi.util.updateQueryData(
            "getMarketOrdersForItem",
            { chain, address, tokenId },
            (draft) =>
              draft.filter((item) => !invalidateOrderIds.includes(item.id)),
          ),
        );
        const proposalsPatchResult = dispatch(
          marketApi.util.updateQueryData(
            "getMarketProposalsForItem",
            { chain, address, tokenId },
            (draft) =>
              draft.filter((item) => !invalidateOrderIds.includes(item.id)),
          ),
        );
        queryFulfilled.catch(ordersPatchResult.undo);
        queryFulfilled.catch(proposalsPatchResult.undo);
      },
    }),

    // 블록체인 오더 체결 후 서버에 sync 요청
    // https://api-dev.ciety.dev/api#/Marketplace/PostMarketplaceOrdersOrderidFulfillController_handle
    postOrderFulfill: builder.mutation<
      PostOrderFulfill["data"],
      PostOrderFulfill["request"]
    >({
      query: ({ orderId, chain, trxHash }) => {
        return {
          url: `marketplace/orders/${orderId}/fulfill`,
          method: "POST",
          body: { chain, trxHash },
        };
      },
      transformResponse: (response: PostOrderFulfill["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          case "100": {
            return response.data; // null
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.postOrderFulfill.name}`,
            );
          }
        }
      },
      invalidatesTags: (result, error) => {
        if (error || !result) return [];
        const { chain, address, tokenId, daoId } = result;
        const cacheTagId = getCacheTag({
          chain,
          predicates: [address, tokenId],
        });
        return [
          { type: "Order" as const, id: result.orderId },
          { type: "Proposal" as const, id: result.orderId },
          { type: "ItemBalance" as const, id: cacheTagId },
          { type: "NftOwner" as const, id: cacheTagId },
          { type: "LiveItems" as const, id: daoId },
          { type: "TopMembers" as const, id: daoId },
          {
            type: "TransactionActivity" as const,
            id: daoId,
          },
        ];
      },
    }),

    // 거래 가능한 native / erc20 / 다오 커스텀 토큰 목록 조회
    // https://api-dev.ciety.dev/api#/Marketplace/GetMarketplaceDaosDaoidCryptocurrenciesController_handle
    getCryptoCurrencies: builder.query<
      GetCryptoCurrencies["data"],
      GetCryptoCurrencies["request"]
    >({
      query: ({ daoId, memberId }) => ({
        url: `marketplace/daos/${daoId}/cryptocurrencies`,
        method: "GET",
        params: { memberId },
      }),
      transformResponse: (response: GetCryptoCurrencies["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.getCryptoCurrencies.name}`,
            );
          }
        }
      },
    }),

    // 마켓에 등록된 수수료 정보 조회 (CIETY + DAO + CREATOR)
    // https://api-dev.ciety.dev/api#/marketplace/GetMarketplaceDaosDaoidChainContractaddressFeesController_handle
    getMarketFees: builder.query<
      GetMarketFees["data"],
      GetMarketFees["request"]
    >({
      query: ({ daoId, chain, contractAddress }) => ({
        url: `marketplace/daos/${daoId}/${chain}/${contractAddress}/fees`,
        method: "GET",
      }),
      transformResponse: (response: GetMarketFees["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.getMarketFees.name}`,
            );
          }
        }
      },
    }),

    // erc20 토큰 밸런스 조회 (through BE -> ALCHEMY)
    // https://api-dev.ciety.dev/api#/Token/GetUsersUseridTokensController_handle
    getItemBalance: builder.query<
      GetItemBalance["data"],
      GetItemBalance["request"]
    >({
      query: ({ userId, ...params }) => ({
        url: `users/${userId}/tokens`,
        method: "GET",
        params,
      }),
      providesTags: (result, error) => {
        if (error) {
          return [];
        }
        return [
          {
            type: "ItemBalance",
          },
        ];
      },
      transformResponse: (response: GetItemBalance["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          case "050": {
            throw new Error(`Cannot retrieve data from blockchain`);
          }
          case "006": {
            throw new Error(`Not supported token type`);
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.getItemBalance.name}`,
            );
          }
        }
      },
    }),

    // erc20 토큰 밸런스 조회 (direct to public RPC)
    getErc20BalanceToRpc: builder.query<
      GetErc20BalanceToRpc["response"],
      GetErc20BalanceToRpc["request"]
    >({
      queryFn: async ({ chain, ownerAddress, contractAddress }) => {
        return getErc20BalanceToRPC({ chain, contractAddress, ownerAddress })
          .then((result) => {
            return {
              data: result,
            };
          })
          .catch((error) => {
            return {
              error: {
                status: error?.status,
                data:
                  error?.message ??
                  `Cannot retrieve erc20 balance from RPC: ${contractAddress} to chain ${chain}`,
              },
            };
          });
      },
    }),

    // erc20 토큰 밸런스 목록 조회 (direct to public RPC)
    getErc20BalancesToRpc: builder.query<
      GetErc20BalancesToRpc["response"],
      GetErc20BalancesToRpc["request"]
    >({
      queryFn: async ({ chain, ownerAddress, contractAddresses }) => {
        return pipe(
          contractAddresses,
          toAsync,
          map((contractAddress) => {
            return getErc20BalanceToRPC({
              chain,
              contractAddress,
              ownerAddress,
            });
          }),
          concurrent(contractAddresses.length),
          toArray,
        )
          .then((result) => {
            return {
              data: result,
            };
          })
          .catch((error) => {
            return {
              error: {
                status: error?.status,
                data:
                  error?.message ??
                  `Cannot retrieve erc20 balance from RPC: ${contractAddresses.join(
                    ", ",
                  )} to chain ${chain}`,
              },
            };
          });
      },
    }),

    // 아이템 밸런스 조회 (direct to public RPC)
    getItemBalanceToRpc: builder.query<
      GetItemBalanceToRpc["response"],
      GetItemBalanceToRpc["request"]
    >({
      queryFn: (params) => {
        return getItemBalanceToRPC(params)
          .then((result) => {
            return { data: result };
          })
          .catch((error) => {
            return {
              error: {
                status: error?.status,
                data: error?.message ?? "Cannot retrieve item balance from RPC",
              },
            };
          });
      },
      providesTags: (result, error, { chain, contractAddress, tokenId }) => {
        return [
          {
            type: "ItemBalance",
            id: getCacheTag({
              chain,
              predicates: [contractAddress, tokenId],
            }),
          },
        ];
      },
    }),

    // NFT 소유자 정보 조회 (direct to public RPC)
    getNftOwnerToRpc: builder.query<
      GetNftOwnerToRpc["response"],
      GetNftOwnerToRpc["request"]
    >({
      providesTags: (result, error, { chain, contractAddress, tokenId }) => {
        return [
          {
            type: "NftOwner",
            id: getCacheTag({
              chain,
              predicates: [contractAddress, tokenId],
            }),
          },
        ];
      },
      queryFn: (params) => {
        return getNftOwner(params)
          .then((result) => {
            return {
              data: decodeAddressFrom32Bytes(result),
            };
          })
          .catch((error) => {
            return {
              error: {
                status: error?.status ?? "RPC-ERROR",
                error:
                  error?.message ??
                  "Cannot retrieve NFT owner address from RPC",
              },
            };
          });
      },
    }),

    // 온체인 ownable interface 이용해서 owner address 조회
    // https://api-dev.ciety.dev/api#/settings%20%2F%20marketplace/GetContractsChainContractaddressCreatorController_handle
    getContractOwner: builder.query<
      GetContractOwner["data"],
      GetContractOwner["request"]
    >({
      query: ({ chain, contractAddress }) => ({
        url: `contracts/${chain}/${contractAddress}/creator`,
        method: "GET",
      }),
      transformResponse: (response: GetContractOwner["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data.ownerAddress;
          }
          default: {
            const unhandledCodeCase = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.getContractOwner.name}`,
            );
          }
        }
      },
    }),

    // Market creator fees 목록 불러오기
    // https://api-dev.ciety.dev/api#/settings%20%2F%20marketplace/GetSettingsUsersUseridCreatorfeesController_handle
    getCreatorFees: builder.query<
      GetCreatorFees["data"],
      GetCreatorFees["request"]
    >({
      query: ({ userId }) => ({
        url: `settings/users/${userId}/creator-fees`,
        method: "GET",
      }),
      providesTags: ["CreatorFee"],
      transformResponse: (response: GetCreatorFees["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.getCreatorFees.name}`,
            );
          }
        }
      },
    }),

    // Market creator fee 설정
    // https://api-dev.ciety.dev/api#/settings%20%2F%20marketplace/PutSettingsUsersUseridCreatorfeesController_handle
    putCreateFee: builder.mutation<
      PutCreatorFee["data"],
      PutCreatorFee["request"]
    >({
      query: ({ userId, creatorFeeList }) => ({
        url: `settings/users/${userId}/creator-fees`,
        method: "PUT",
        body: { creatorFeeList },
      }),
      transformResponse: (response: PutCreatorFee["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          case "100": {
            throw new Error("Invalid owner");
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.putCreateFee.name}`,
            );
          }
        }
      },
      invalidatesTags: (result, error) => {
        if (error) {
          return [];
        } else {
          return ["CreatorFee"];
        }
      },
    }),

    // DAO 의 마켓플레이스 추천 컨트랙트 설정 & DAO 수수료 설정
    // https://api-dev.ciety.dev/api#/settings%20%2F%20marketplace/PutSettingsDaosDaoidMarketplaceController_handle
    putMarketSettings: builder.mutation<
      PutMarketSetting["data"],
      PutMarketSetting["request"]
    >({
      query: ({ daoId, ...params }) => ({
        url: `settings/daos/${daoId}/marketplace`,
        method: "PUT",
        body: params,
      }),
      transformResponse: (response: PutMarketSetting["response"]) => {
        const { code } = response;
        switch (code) {
          case "000": {
            return response.data;
          }
          case "101": {
            throw new Error(
              "Shared marketplace smart contracts are not applicable",
            );
          }
          case "102": {
            throw new Error(
              "Dao's tradable custom tokens cannot duplicate the default supported token entries.",
            );
          }
          default: {
            const unhandledCodeCase: never = code;
            throw new Error(
              `Unhandled ${unhandledCodeCase} at ${marketApi.endpoints.putCreateFee.name}`,
            );
          }
        }
      },
      invalidatesTags: (result, error, { daoId }) => {
        if (error) {
          return [];
        } else {
          return [{ type: "RecommendContracts", id: daoId }];
        }
      },
    }),
  }),
});
