import { compactObject } from "@fxts/core";

export function getParamsFromErrorMessage(
  errorMessage: string,
): Record<string, string> {
  const [, queryString = ""] = errorMessage.split("$");

  return compactObject(
    Object.fromEntries(queryString.split(",").map((param) => param.split("="))),
  );
}
