import { EAccount } from "~/_enums/EAccount";
import { ECode } from "~/_enums/ECode";
import { TApiConfig } from "~/_libs/fetch/fetchConfig";
import { fetchPostJson } from "~/_libs/fetch/fetchPostJson";
import * as Sentry from "@sentry/react";
import { ERROR_UNKNOWN } from "~/_constants/errors";

type TInputDto = {
  authRequestId: string;

  key: string;
  payload:
    | TPayloadForEvnCompatibleAccount
    | TPayloadForEmailAccount
    | TPayloadForGoogleAccount
    | TPayloadForAppleAccount;
};

type TPayloadForEvnCompatibleAccount = {
  account: typeof EAccount.EvmCompatibleAccount;
  validation: {
    signature: string;
  };
};

type TPayloadForEmailAccount = {
  account: typeof EAccount.EmailAccount;
  validation: {
    code: string;
  };
};

type TPayloadForGoogleAccount = {
  account: typeof EAccount.Google;
  validation: {
    code: string;
  };
  asLoginAccount?: boolean;
};

type TPayloadForAppleAccount = {
  account: typeof EAccount.Apple;
  validation: {
    code: string;
    /** app에서 사용할 경우 true */
    useBundleId?: boolean;
  };
  /**
   * null인 경우 기존
   * 2인 경우 새로운 버전
   */
  version: 2 | null;
};

type TOutputDto =
  | TAuthVerifyOutputDto
  | TAuthVerifyErrMaximumOutputDto
  | TAuthVerifyBadRequestOutputDto
  | TAuthVerifyErrUnmatchedCodeOutputDto
  | TAuthVerifyErrInvalidScopeOutputDto
  | TAuthVerifyErrAlreadyUsedAccountOutputDto;

type TAuthVerifyOutputDto = {
  code: typeof ECode.Success;
  data: null;
};

type TAuthVerifyErrMaximumOutputDto = {
  code: typeof ECode.MaxAttempt;
  data: null;
};

type TAuthVerifyBadRequestOutputDto = {
  code: typeof ECode.BadRequest;
  /** 에러 메시지 ex) not registered auth request not supported account */
  data: string;
};

type TAuthVerifyErrUnmatchedCodeOutputDto = {
  /** 인증 코드가 다름 (여러번 실패시 최대 요청 횟수 초과로 변경) */
  code: "101";
  data: null;
};

type TAuthVerifyErrInvalidScopeOutputDto = {
  /** oauth scope 추가 필요 */
  code: "102";
  data: {
    scope: ["https://www.googleapis.com/auth/youtube.readonly"];
  };
};

type TAuthVerifyErrAlreadyUsedAccountOutputDto = {
  /** 이미 연동된  계정 */
  code: "103";
  data: {
    address: string;
  };
};

export const postAuthConnectRequestsVerify = (
  { authRequestId, ...body }: TInputDto,
  config: TApiConfig,
) => {
  return fetchPostJson<TOutputDto>(
    `/auth/connect/requests/${authRequestId}/verify`,
    {
      body,
    },
    config,
  ).then((result) => {
    switch (result.code) {
      case "000":
      case "003":
      case "010":
      case "101":
      case "102":
      case "103":
        return result;
      default:
        Sentry.captureException(ERROR_UNKNOWN, {
          extra: {
            apiName: "postAuthConnectRequestsVerify",
            payload: body,
            res: result,
          },
        });

        throw new Error(ERROR_UNKNOWN);
    }
  });
};
