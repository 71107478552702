import { env } from "~/env";
import { Icon } from "~/modules/Common/Icons";
import { cn } from "~/utils/css";

type TProps = {
  className: string;
};

export function AppIconButton({ className }: TProps) {
  return (
    <a
      href={env.ROOT_REDIRECT_URL}
      target="_blank"
      rel="noopener noreferrer"
      className={cn(className, "flex-center h-14 w-14 shrink-0")}
    >
      <Icon
        iconType="Ciety_app version"
        className="h-[3.2669rem] w-[3.2669rem]"
      />
    </a>
  );
}
