import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { INITIAL_MESSAGE_STATE } from "~/modules/Posting/Message/shared/utils/createMessageSlice";

interface ChannelChatState {
  isChannelChatScrollBottom: boolean;
  channelId: string | null;
}

const initialChannelChatState: ChannelChatState = {
  channelId: null,
  isChannelChatScrollBottom: INITIAL_MESSAGE_STATE.initialScrollBottom,
};

const channelChatSlice = createSlice({
  name: "channelChat",
  initialState: initialChannelChatState,
  reducers: {
    resetToInitialState: () => initialChannelChatState,
    setIsChannelChatScrollBottom(
      state,
      action: PayloadAction<ChannelChatState["isChannelChatScrollBottom"]>,
    ) {
      state.isChannelChatScrollBottom = action.payload;
    },
    setChannelId(state, action: PayloadAction<ChannelChatState["channelId"]>) {
      state.channelId = action.payload;
    },
  },
});

export const channelChatActions = channelChatSlice.actions;

export default channelChatSlice.reducer;
