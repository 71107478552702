import { add } from "date-fns";

import type { SearchType } from "~/modules/Search/types";
import { toSubdomainPolicy } from "~/utils/converter";

export const pastDays = (day: number) =>
  add(new Date(), { days: day * -1 }).getTime();

export const toSearchURL = (params: {
  subdomain: string;
  keyword: string;
  searchType?: SearchType;
}) =>
  `/${toSubdomainPolicy(params.subdomain)}/search${
    params.keyword.length > 0
      ? `?q=${params.keyword}&defaultSearchType=${params.searchType ?? "posts"}`
      : ""
  }`;

export const highlightQuery = (text: string) =>
  text
    .split(/[\ue000\ue000]/)
    .map((character, index) =>
      index % 2 === 0 ? (
        character
      ) : (
        <mark key={`text-${character}-${index}`}>{character}</mark>
      ),
    );

export const deleteHighlightQuery = (text: string) =>
  text.split(/[\ue000\ue000]/).map((character) => character);

export const removeHighlight = (text: string) => text.replaceAll("\ue000", "");
