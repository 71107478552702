import { TGfitokNativeTokenMetadatas } from "~/modules/BlockChain/Giftok/types/module.types";

export const ADD_CHAIN_DATA = (
  desChainId: TGfitokNativeTokenMetadatas["desChainId"],
) => {
  switch (desChainId) {
    case "1": {
      return [
        {
          chainId: "0x01",
          chainName: "Ethereum Mainnet",
          nativeCurrency: {
            name: "ether",
            symbol: "ETH",
            decimals: 18,
          },
          rpcUrls: ["https://mainnet.infura.io/v3/"],
          blockExplorerUrls: ["https://etherscan.io"],
        },
      ];
    }
    case "137": {
      return [
        {
          chainId: "0x89",
          chainName: "Polygon Mainnet",
          nativeCurrency: {
            name: "matic",
            symbol: "MATIC",
            decimals: 18,
          },
          rpcUrls: ["https://polygon-rpc.com"],
          blockExplorerUrls: ["https://polygonscan.com"],
        },
      ];
    }
  }
};
