import { createApi } from "@reduxjs/toolkit/query/react";

import { GetDao } from "~/api/Dao/types";
import { getBaseQuery } from "~/api/getBaseQuery";

export const daoApi = createApi({
  reducerPath: "daoApi",
  baseQuery: getBaseQuery({ rootPath: "/daos" }),
  refetchOnFocus: true,
  refetchOnReconnect: true,

  endpoints: (builder) => ({
    getDao: builder.query<GetDao["data"], GetDao["request"]>({
      query: ({ daoId }) => ({
        url: daoId,
        method: "GET",
        params: {},
      }),
      transformResponse: (response: GetDao["response"]) => {
        return response.data;
      },
    }),
  }),
});
