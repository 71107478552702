export const EDaoHomeSection = {
  HotPosts: "HotPosts",
  AllPosts: "AllPosts",
  Profile: "Profile",
  Banner: "Banner",
  LiveStreaming: "LiveStreaming",
  Sns: "Sns",
  Marketplace: "Marketplace",
  MerchStore: "MerchStore",
} as const;

export type EDaoHomeSection =
  typeof EDaoHomeSection[keyof typeof EDaoHomeSection];
