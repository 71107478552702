import { ComponentPropsWithoutRef, ElementType, ReactNode } from "react";

export interface Props<T extends ElementType> {
  children?: ReactNode;
  as?: T;
}

/**
 * @example
 * <PolymorphicButton as="a" href="https://google.com">
 *   Polymorphic
 * </PolymorphicButton>
 */
export const PolymorphicButton = <T extends ElementType = "button">({
  children,
  as,
  ...restProps
}: Props<T> & Omit<ComponentPropsWithoutRef<T>, keyof Props<T>>) => {
  const Component = as || "button";

  return <Component {...restProps}>{children}</Component>;
};
