import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { getThreadScreenParams } from "~/_api/mobile/appScreenParams";
import { appNavigation } from "~/_api/mobile/toMobileNavigation";
import { useDaoSubdomainStore } from "~/_store/dao/useDaoSubdomainStore";
import { assertIsDefined } from "~/_utils/assert";
import { DAO_HOME } from "~/_constants";

type TProps = {
  sectionId: string;
};

function SnsSummaryType({ sectionId }: TProps) {
  const { getSection } = useDaoHomeStore();
  const { daoSubdomain } = useDaoSubdomainStore();
  assertIsDefined(daoSubdomain);
  const { posts, channel } = getSection(EDaoHomeSection.Sns, sectionId);

  const handleMoveThread = ({ postId }: { postId: string }) => {
    appNavigation(({ push }) => {
      push(
        getThreadScreenParams({
          path: generateUrl({
            type: "thread",
            subdomain: daoSubdomain,
            channelId: channel.id,
            channelType: channel.type,
            postId: postId,
          }),
        }),
      );
    });
  };

  return (
    <div className="px-5 pt-4">
      {posts.slice(0, DAO_HOME.CONTENT_COUNT.MOBILE_SNS_SUMMARY).map((post) => (
        <button
          type="button"
          className="flex w-full items-center justify-between border-t border-ciety-dk-95 py-4 text-ciety-wh last:border-b"
          key={post.id}
          onClick={() => handleMoveThread({ postId: post.id })}
        >
          <h1 className="typo-m2 truncate">{post.title}</h1>
        </button>
      ))}
    </div>
  );
}

export { SnsSummaryType };
