const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;

const SUPPORTED_URL_PROTOCOLS = new Set(["http:", "https:"]);

function sanitizeUrl(url: string): string {
  try {
    const parsedUrl = new URL(url); // eslint-disable-next-line no-script-url

    if (!SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)) {
      return "about:blank";
    }
  } catch {
    const matched = url.match(URL_MATCHER);
    if (!matched) {
      return "about:blank";
    }

    return addProtocolToUrl(url);
  }

  return url;
}

const addProtocolToUrl = (url: string, protocol = "https://") => {
  const matched = /^(?:http(?:s)?:\/\/)/g.exec(url);

  if (matched === null) {
    return `${protocol}${url}`;
  }
  return url;
};

export { sanitizeUrl, addProtocolToUrl };
