import { supportedChainEnums } from "~/modules/Router/Auth/constant";
import { createChainVo } from "~/modules/Router/Auth/utils/auth/createChainVo";
import { detectWallet } from "~/modules/Router/Auth/utils/auth/detectWallet";
import { ChainEnum, ErrorTypes, ResultType } from "../../types";

export const getChain = async (): Promise<
  ResultType<{ chain: ChainEnum }, ErrorTypes>
> => {
  try {
    const result = await detectWallet();
    if (
      !result.ok ||
      !result.value.isDetected ||
      result.value.provider === null
    ) {
      return {
        ok: false,
        value: ErrorTypes.NotDetectedWalletError,
      };
    }

    const provider = result.value.provider;
    const hexStrChainId = await provider.send(`eth_chainId`, []);
    const chainId = parseInt(hexStrChainId, 16);
    const chainVoCreateResult = createChainVo({
      chainId,
      supportedChainEnums,
    });
    if (!chainVoCreateResult.ok) {
      return chainVoCreateResult;
    }
    const {
      value: { chain },
    } = chainVoCreateResult;
    return { ok: true, value: { chain } };
  } catch (error) {
    return {
      ok: false,
      value: ErrorTypes.UnCategorizedError,
    };
  }
};
