import type { TThreadComment } from "~/modules/Posting/Message/shared/types/message";
import {
  createMessageSlice,
  GenericMessageState,
} from "~/modules/Posting/Message/shared/utils/createMessageSlice";

const params = new URLSearchParams(document.location.search);
const channelType = params.get("channelType");
const isLiveSteaming = channelType === "LIVE_STREAMING";
const isSns = channelType === "SNS";

const initialChannelThreadMessageState: Partial<
  GenericMessageState<TThreadComment>
> = {
  initialScrollBottom: isLiveSteaming ? false : params.get("scroll") === "true",
  highlightId: ((isLiveSteaming || isSns) && params.get("highlightId")) || null,
};

const channelThreadMessageSlice = createMessageSlice<TThreadComment>({
  name: "channelThreadMessage",
  initialState: initialChannelThreadMessageState,
});

export const channelThreadMessageActions = channelThreadMessageSlice.actions;

export default channelThreadMessageSlice.reducer;
