import { DaoInfoType } from "~/modules/Router/Auth/types";

type Props = {
  dao: Pick<DaoInfoType, "displayName">;
};
export const CommunityDisplayName = ({ dao }: Props) => {
  return (
    <h1 className="typo-m0 text-center text-ciety-wh">{dao.displayName}</h1>
  );
};
