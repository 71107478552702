import { useAtom, useSetAtom } from "jotai";
import {
  pipVideoAtom,
  writeUpdatePipVideoPlaying,
} from "~/_model/video/pipVideoAtom";

export const usePipVideoStore = () => {
  const [pipVideo, setPipVideo] = useAtom(pipVideoAtom);

  const setUpdatePipVideoPlaying = useSetAtom(writeUpdatePipVideoPlaying);

  return {
    pipVideo,
    setPipVideo,
    setUpdatePipVideoPlaying,
  };
};
