import { forwardRef, ImgHTMLAttributes, useState } from "react";
import { cn } from "~/_utils/converter/cn";

type TProps = ImgHTMLAttributes<HTMLImageElement>;

/** @caution Using `<img>` could result in slower LCP and higher bandwidth. Consider using `<Image />` from `next/image` to automatically optimize images. This may incur additional usage or cost from your provider. See: https://nextjs.org/docs/messages/no-img-element */
export const SmoothLoadableImage = forwardRef<HTMLImageElement, TProps>(
  ({ onLoad, className, ...restProps }, ref) => {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
      <img
        {...restProps}
        ref={ref}
        onLoad={(e) => {
          onLoad?.(e);
          setIsLoaded(true);
        }}
        className={cn(
          className,
          `${isLoaded ? "opacity-100" : "opacity-0"}`,
          "pointer-events-none transition-opacity",
        )}
        alt=""
      />
    );
  },
);

SmoothLoadableImage.displayName = "SmoothLoadableImage";
