import { useMemo, useRef } from "react";

import { env } from "~/env";

const AUTH_EXCLUDED_PATHS = [
  "error",
  "dao-list",
  "new-dao",
  "update",
  "infinite",
  "explore",
  "withdrawal",
  "oauth",

  "login",
  "create-or-find",
  "google-connect",
  "create",
  "invite",
  "find",
];

export const usePathRef = () => {
  const rawPathSegmentsRef = useRef<Array<string>>(
    new URL(location.href).pathname.split(`/`).slice(1),
  );
  const rawPath1Ref = useRef<string>(rawPathSegmentsRef.current[0] ?? ``);
  const rawPath2Ref = useRef<string>(rawPathSegmentsRef.current[1] ?? ``);
  const decodedPath1Ref = useRef<string>(
    decodeURIComponent(rawPath1Ref.current),
  );
  const decodedPath2Ref = useRef<string>(
    decodeURIComponent(rawPath2Ref.current),
  );
  const subdomainRef = useRef<string | null>(
    /^@/.test(decodedPath1Ref.current)
      ? decodedPath1Ref.current.replace(/^@/, ``)
      : null,
  );
  const isLoginPath = useMemo(() => {
    return rawPath1Ref.current === "";
  }, [rawPath1Ref]);
  const isAuthExcludedPath = useMemo(() => {
    return AUTH_EXCLUDED_PATHS.some((path) =>
      location.pathname.includes(`/${path}`),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [decodedPath1Ref]);
  const isNotFoundPath = useMemo(() => {
    return (
      decodedPath1Ref.current &&
      subdomainRef.current === null &&
      !isAuthExcludedPath
    );
  }, [decodedPath1Ref, subdomainRef, isAuthExcludedPath]);
  const isErrorUrlRef = useRef<boolean>(
    isNotFoundPath || `/${decodedPath1Ref.current}` === env.ERROR_PATH,
  );

  return {
    decodedPath2Ref,
    subdomainRef,
    isLoginPath,
    isAuthExcludedPath,
    isNotFoundPath,
    isErrorUrlRef,
  };
};
