import { useTranslation } from "react-i18next";

import { ENameSpace } from "~/i18n/@types/types";

export const LengthCheck = ({
  watch,
  maxLength,
  minLength = 0,
  className,
}: {
  watch?: string | null;
  maxLength: number;
  minLength?: number;
  className?: string;
}) => {
  const { t } = useTranslation([ENameSpace.Common]);
  return (
    <div className={className}>
      {watch && watch.length > 0 ? (
        <>
          <span
            className={(() => {
              let textStyle;
              if (watch.length >= minLength && watch.length <= maxLength) {
                textStyle = "text-ciety-wh";
              } else if (watch.length === maxLength) {
                textStyle = "text-ciety-dk-60";
              } else {
                textStyle = "text-ciety-rd";
              }

              return textStyle;
            })()}
          >
            {watch.length}
          </span>
          <span className="text-ciety-dk-60">
            {t(`${ENameSpace.Common}:Form.GuideMessage.Characters`, {
              count: maxLength,
            })}
          </span>
        </>
      ) : (
        <span className="text-ciety-dk-60">
          {t(`${ENameSpace.Common}:Form.GuideMessage.Max`, {
            count: maxLength,
          })}
        </span>
      )}
    </div>
  );
};
