import React, { useEffect, useState } from "react";

import "./styles.gen.css";

import { loader } from "./loader.gen";
import type { DefaultCommunityImageType } from "./types.gen";

type Props = {
  className?: string;
  width?: `${number}rem`;
  height?: `${number}rem`;
  type: DefaultCommunityImageType;
};

export const DefaultCommunityImage = ({
  className = "",
  type,
  width,
  height,
}: Props) => {
  const [imageClassName, setImageClassName] = useState<string | null>(null);

  useEffect(() => {
    const className = loader.get(type);
    if (className) {
      setImageClassName(className);
    }
  }, [type]);

  return (
    <div
      className={`${imageClassName} ${className}`}
      style={{
        width,
        height,
      }}
    />
  );
};
