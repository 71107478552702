import { Icon } from "~/modules/Common/Icons";

type TProps = {
  count: number;
};

function ReplyCount({ count }: TProps) {
  return (
    <div className="flex-center typo-m1 gap-x-1">
      <Icon iconType="Chat-Line" className="h-4 w-4" />
      {count}
    </div>
  );
}

export { ReplyCount };
