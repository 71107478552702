import React, { CSSProperties, ReactNode } from "react";
import { SwitchTransition, Transition } from "react-transition-group";

import { extractNumFromStr } from "~/utils/converter";
import Loading_blue from "./assets/Loading_blue.png";
import Loading_gray from "./assets/Loading_gray.png";
import Loading_lightgreen from "./assets/Loading_lightgreen.png";
import Loading_orange from "./assets/Loading_orange.png";
import Loading_pink from "./assets/Loading_pink.png";
import Loading_red from "./assets/Loading_red.png";
import Loading_white from "./assets/Loading_white.png";

type Props = {
  color?: "pink" | "gray" | "blue" | "white" | "orange" | "lightgreen" | "red";
  className?: string;
};

export const Loading = ({ color = "gray", className }: Props) => {
  let src;
  if (color === "blue") {
    src = Loading_blue;
  } else if (color === "pink") {
    src = Loading_pink;
  } else if (color === "white") {
    src = Loading_white;
  } else if (color === "lightgreen") {
    src = Loading_lightgreen;
  } else if (color === "orange") {
    src = Loading_orange;
  } else if (color === "red") {
    src = Loading_red;
  } else {
    src = Loading_gray;
  }

  return <img alt="loading" src={src} className={className} />;
};

export const DotLoader = ({
  className,
  dotSize = "0.7rem",
  spacing = "0.3rem",
  color = "bg-ciety-dk-90",
}: {
  className?: string;
  dotSize?: `${string}rem`;
  spacing?: `${string}rem`;
  color?: `bg-${string}`;
}) => {
  const defaultClassName = `${color} rounded-full animate-[dotLoader_0.8s_infinite]`;
  const Dot = ({ delay }: { delay: `${string}s` }) => {
    return (
      <div
        className={`${defaultClassName}`}
        style={{
          animationDelay: `${delay}`,
          width: `${dotSize}`,
          height: `${dotSize}`,
          margin: `0 ${spacing}`,
        }}
      ></div>
    );
  };
  return (
    <div className={`${className ?? ""} flex`}>
      <Dot delay={"0s"} />
      <Dot delay={"0.2s"} />
      <Dot delay={"0.4s"} />
    </div>
  );
};

export const SmoothLoadable = ({
  className = "",
  isLoading,
  Loader,
  ComponentWhenLoaded,
  transitionDuration = "duration-200",
  style,
}: {
  className?: string;
  isLoading: boolean;
  Loader: ReactNode;
  ComponentWhenLoaded: ReactNode | null;
  transitionDuration?: `duration-${number}`;
  style?: CSSProperties;
}) => {
  return (
    <SwitchTransition mode="out-in">
      <Transition
        key={isLoading ? "loading" : "loaded"}
        timeout={
          extractNumFromStr({ str: transitionDuration, withSign: false }) / 2
        }
        unmountOnExit
        mountOnEnter
      >
        {(status) => {
          const cssWhenEntered =
            status === "entered" ? "opacity-100 " : "opacity-0";
          return (
            <div
              style={style}
              className={`${
                className ?? ""
              } ${cssWhenEntered} transition-opacity ${transitionDuration}`}
            >
              {isLoading ? Loader : ComponentWhenLoaded}
            </div>
          );
        }}
      </Transition>
    </SwitchTransition>
  );
};
