import { ethers } from "ethers";

import { TMarketSupportedChain } from "~/_types/market/TMarketSupportedChain";
import { TGfitokNativeTokenMetadatas } from "~/_types/TGiftok";

type CietySupportedChainIdsOutput = "1" | "5" | "137" | "80001";

type Props = {
  chainId: CietySupportedChainIdsOutput;
};

export const convertGiftokChainNameToDesChainId = ({
  chainName,
}: {
  chainName: TGfitokNativeTokenMetadatas["chainName"];
}): TGfitokNativeTokenMetadatas["desChainId"] => {
  switch (chainName) {
    case "EthereumMainnet":
      return "1";
    case "Goerli":
      return "5";
    case "PolygonMainnet":
      return "137";
    case "Mumbai":
      return "80001";
  }
};

const blockExplorerBaseUrl = (chain: TMarketSupportedChain) => {
  switch (chain) {
    case "EthereumMainnet":
      return `https://etherscan.io`;
    case "Goerli":
      return `https://goerli.etherscan.io`;
    case "PolygonMainnet":
      return `https://polygonscan.com`;
    case "Mumbai":
      return `https://mumbai.polygonscan.com`;
  }
};

export const convertDesChainIdToBlockExplorerTrxUrl = ({
  chainId,
  trxHash,
}: Props & { trxHash: string }) => {
  const baseUrl = convertDesChainIdToBlockExplorerBaseUrl({ chainId });

  return `${baseUrl}/tx/${trxHash}`;
};

export const convertDesChainIdToBlockExplorerBaseUrl = ({ chainId }: Props) => {
  switch (chainId) {
    case "1":
      return "https://etherscan.io";
    case "137":
      return "https://polygonscan.com";
  }
};

export const convertAddressToBlockExplorerUrlForMarket = (
  chain: TMarketSupportedChain,
  address?: string,
) =>
  address
    ? `${blockExplorerBaseUrl(chain)}/address/${address}`
    : blockExplorerBaseUrl(chain);

export const toDec = (value: string): string => {
  return ethers.BigNumber.from(value).toString();
};
