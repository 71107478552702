import { MarketSupportedChain } from "~/api/dto";

export const PUBLIC_RPC_ENDPOINT: Record<MarketSupportedChain, string> = {
  EthereumMainnet: "https://eth.public-rpc.com/",
  PolygonMainnet: "https://polygon-rpc.com/",
  Goerli: "https://rpc.ankr.com/eth_goerli/",
  Mumbai: "https://rpc-mumbai.maticvigil.com/",
};

export const ALCHEMY_RPC_ENDPOINT: Record<MarketSupportedChain, string> = {
  EthereumMainnet:
    "https://eth-mainnet.g.alchemy.com/v2/jdz9SjqKT7cxFNqbCEMGVQKkgR0Fqj39",
  PolygonMainnet:
    "https://polygon-mainnet.g.alchemy.com/v2/-Boi6UlInKOETcj2SB9zL3wrB5BRxDx7",
  Goerli:
    "https://eth-goerli.g.alchemy.com/v2/jdz9SjqKT7cxFNqbCEMGVQKkgR0Fqj39",
  Mumbai:
    "https://polygon-mumbai.g.alchemy.com/v2/-Boi6UlInKOETcj2SB9zL3wrB5BRxDx7",
};

export const ChainToEthersNetworkName: Record<MarketSupportedChain, string> = {
  EthereumMainnet: "homestead",
  PolygonMainnet: "matic",
  Goerli: "goerli",
  Mumbai: "maticmum",
};
