import { useEffect, useState } from "react";
import { postCommonDaoHome } from "~/_api/daoHome/postCommonDaoHome";
import { ExploreContainer } from "~/_app/[dao]/explore/components/mobile/ExploreContainer";
import { ECode } from "~/_enums/ECode";
import { EDevice } from "~/_enums/EDevice";
import { TDaoHome } from "~/_types/daoHome/TDaoHome";
import { ERROR_OOPS } from "~/_constants/errors";
import { useErrorBoundary } from "react-error-boundary";

type TProps = {
  daoHome: TDaoHome;
};

export function MobileExploreCSR({ subdomain }: { subdomain: string }) {
  const [isLoading, setIsLoading] = useState(true);
  const [csrState, setCsrState] = useState<TProps | null>(null);
  const { showBoundary } = useErrorBoundary();

  useEffect(() => {
    (async () => {
      setIsLoading(true);

      try {
        const { code, data } = await postCommonDaoHome(
          {
            daoSubdomain: subdomain,
            device: EDevice.Mobile,
          },
          {},
        );

        if (code !== ECode.Success) {
          throw new Error(ERROR_OOPS);
        }

        setCsrState({
          daoHome: data,
        });
      } catch (err) {
        showBoundary(err);
      } finally {
        setIsLoading(false);
      }
      //
    })();
  }, [subdomain, showBoundary]);

  if (isLoading) {
    return null;
  }

  if (csrState == null) {
    /**
     * @TODO 에러처리
     */
    return null;
  }

  return <ExploreContainer {...csrState} />;
}
