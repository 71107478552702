import { omit } from "@fxts/core";

import { env } from "~/env";
import { buildQuery } from "~/utils/calibrator";
import { wrapHeaders } from "~/utils/fetch";
import { defaultErrorHandle } from "~/_libs/fetch/defaultErrorHandle";

type TFetchDataArgs = {
  url: string;
  isMock?: boolean;
  queries?: Record<string, unknown>;
  includeCredential?: boolean;
  signal?: AbortSignal | null;
};

const fetchData = <T>({
  url,
  isMock = false,
  queries,
  includeCredential = true,
  signal = null,
}: TFetchDataArgs): Promise<T | never> => {
  const REQUIRED_INIT: RequestInit = {
    method: "GET",
    credentials: "include",
    referrerPolicy: "no-referrer-when-downgrade",
  };

  let init: RequestInit = wrapHeaders(REQUIRED_INIT);

  if (!includeCredential) {
    init = omit(["credentials" as const], REQUIRED_INIT);
  }

  const queryString = queries ? "?" + buildQuery(queries) : "";

  const requestURL = `${isMock ? "" : env.API_BASE_URL}${url}${queryString}`;
  return fetch(requestURL, { ...init, signal }).then(async (res) => {
    if (res.ok) {
      return res.json();
    }

    await defaultErrorHandle(res);
  });
};

export const wrapPromise = <T>(promise: Promise<T>) => {
  let status: "pending" | "success" | "error" = "pending";
  let response: T;

  const suspender = promise.then(
    (res) => {
      status = "success";
      response = res;
    },
    (err) => {
      status = "error";
      response = err;
    },
  );

  const read = () => {
    switch (status) {
      case "pending":
        throw suspender;
      case "error":
        throw response;
      default:
        return response;
    }
  };

  return { read };
};

const fetchDataSuspense = <T>(promise: Promise<T>) => wrapPromise<T>(promise);

export { fetchData, fetchDataSuspense };
