import { useTranslation } from "react-i18next";
import { EmptySectionItem } from "~/_app/[dao]/home/components/common/EmptySectionItem";
import { EmptySectionTitle } from "~/_app/[dao]/home/components/common/EmptySectionTitle";
import { ENameSpace } from "~/i18n/@types/types";

export function EmptyAllPosts() {
  const { t } = useTranslation([ENameSpace.Home]);

  return (
    <EmptySectionItem isMobile>
      <EmptySectionTitle
        title={t(`${ENameSpace.Home}:Root.Sections.AllPosts.Empty.Title`)}
        className="typo-m1"
      />
    </EmptySectionItem>
  );
}
