import { createContext } from "react";

export type AuthDispatch = {
  handleOpenCommunityJoinModal: () => void;
  handleCloseCommunityJoinModal: () => void;
  // 지갑/이메일 인증 완료 후 호출하는 인터페이스
  updateAuthState: () => Promise<void>;
  updateDaoMemberInfo: ({ subdomain }: { subdomain: string }) => Promise<void>;
  updateDaoId: (daoId: string) => void;
  updateCategoryId: (categoryId: string) => void;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => {};
// eslint-disable-next-line @typescript-eslint/no-empty-function
const asyncNoop = async () => {};

// AuthGuard, MainModule 컴포넌트에서 Provider의 값을 주입합니다.
const defaultValue = {
  handleOpenCommunityJoinModal: noop,
  handleCloseCommunityJoinModal: noop,
  updateAuthState: asyncNoop,
  updateDaoMemberInfo: asyncNoop,
  updateDaoId: noop,
  updateCategoryId: noop,
};
export const AuthDispatchContext = createContext<AuthDispatch>(defaultValue);
