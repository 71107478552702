import { AccountEnum, ResultType } from "~/modules/Router/Auth/types";

import { createConnectRequest } from "~/modules/Router/Auth/utils/account/createConnectRequest";
import { getConnectRequestsRegister } from "~/modules/Router/Auth/utils/account/getConnectRequestsRegister";
import { verifyConnectAccount } from "~/modules/Router/Auth/utils/account/verifyConnectAccount";
import { getAddress } from "../auth/getAddress";
import { getChain } from "../auth/getChain";
import { signConnect } from "../auth/signConnect";
import { consumeConnectRequestId } from "~/_utils/account/consumeConnectRequestId";
import { EErrorTypes } from "~/_enums/EErrorTypes";

export const connectWalletAccount = async (
  userId: string,
): Promise<ResultType<boolean, EErrorTypes>> => {
  // step 1. id, key 생성
  const createAuthRequestResult = await createConnectRequest();
  if (!createAuthRequestResult.ok) {
    return createAuthRequestResult;
  }

  const id = createAuthRequestResult.value.id;
  const key = createAuthRequestResult.value.key;

  // step 2. chain, address 를 담아서 요청 후 서명에 사용할 message 받음
  const getChainResult = await getChain();
  if (!getChainResult.ok) {
    return getChainResult;
  }
  const {
    value: { chain },
  } = getChainResult;

  const getAddressResult = await getAddress({
    isRequest: true,
  });
  if (!getAddressResult.ok) {
    return getAddressResult;
  }
  const {
    value: { address },
  } = getAddressResult;

  const getRequestsRegisterResult = await getConnectRequestsRegister({
    id,
    key,
    account: AccountEnum.EvmCompatibleAccount,
    chain,
    address,
    userId,
  });
  if (!getRequestsRegisterResult.ok) {
    return getRequestsRegisterResult;
  }

  const {
    value: {
      validation: { message },
      account: walletAccount,
    },
  } = getRequestsRegisterResult;

  // 지갑 서명 진행
  const signConnectResult = await signConnect({
    address,
    message,
  });
  if (!signConnectResult.ok) {
    return signConnectResult;
  }
  const {
    value: { signature },
  } = signConnectResult;

  // step 3. signature 전달
  const verifyAccountResult = await verifyConnectAccount({
    id,
    key,
    account: walletAccount,
    signatureOrCode: signature,
  });
  if (!verifyAccountResult.ok) {
    return verifyAccountResult;
  }

  // step 4. Cookie 생성
  const consumeRequestIdResult = await consumeConnectRequestId({
    id,
    key,
  });
  if (!consumeRequestIdResult.ok) {
    return consumeRequestIdResult;
  }

  return {
    ok: true,
    value: true,
  };
};
