import { BytesLike } from "ethers";
import { defaultAbiCoder } from "ethers/lib/utils";

export const decodeUint256From32Bytes = (bytes: BytesLike) => {
  return defaultAbiCoder.decode(["uint256"], bytes)[0];
};

export const decodeUint8From32Bytes = (bytes: BytesLike) => {
  return defaultAbiCoder.decode(["uint8"], bytes)[0];
};
