import { createRoot } from "react-dom/client";
import { ReactNode } from "react";

export const renderToString = (element: ReactNode): Promise<string> =>
  new Promise((resolve) => {
    const container = document.createElement("div");
    const renderCallback = () => {
      resolve(container.firstElementChild?.innerHTML || "");
    };

    createRoot(container).render(<div ref={renderCallback}>{element}</div>);
  });
