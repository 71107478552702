import { ReactNode } from "react";
import { ExploreSectionCover } from "~/_app/[dao]/explore/components/mobile/ExploreSectionConver";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { useDaoSubdomainStore } from "~/_store/dao/useDaoSubdomainStore";
import { assertIsDefined } from "~/_utils/assert";
import { downloadApp } from "~/_utils/mobile/downloadApp";
import { cietyConfirm } from "~/_utils/overlay/cietyConfirm";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";

type TProps = {
  children: ReactNode;
  sectionType: EDaoHomeSection;
};

export function ExploreSectionContainer({ children }: TProps) {
  const { t } = useTranslation([ENameSpace.Explore]);
  const { daoSubdomain } = useDaoSubdomainStore();

  const handleClick = async () => {
    const ok = await cietyConfirm({
      body: (
        <div className="flex flex-col gap-y-4 text-ciety-wh">
          <div className="typo-m1">
            {t(
              `${ENameSpace.Explore}:Root.AppDownloadConfirm.MeetUsThroughOurMobileApp`,
            )}
          </div>
          <p className="typo-r2">
            {t(`${ENameSpace.Explore}:Root.AppDownloadConfirm.Message`)}
          </p>
        </div>
      ),
      left: {
        text: t(`${ENameSpace.Explore}:Root.AppDownloadConfirm.Cancel`),
        color: "gray",
      },
      right: {
        text: t(`${ENameSpace.Explore}:Root.AppDownloadConfirm.Ok`),
        color: "green",
      },
    });

    if (ok) {
      assertIsDefined(daoSubdomain);
      downloadApp(daoSubdomain);
    }
  };

  return (
    <div className="relative">
      <ExploreSectionCover onClickCover={handleClick} />
      {children}
    </div>
  );
}
