import React, { forwardRef } from "react";

type Props = React.HTMLAttributes<HTMLElement> & {
  children: React.ReactNode;
  overflowAuto?: boolean;
};

export type CietyScrollBarRef = HTMLDivElement | null;

export const CietyScrollBar = forwardRef<CietyScrollBarRef, Props>(
  ({ children, overflowAuto = false, ...props }, ref) => {
    const overflowClassName = overflowAuto
      ? "overflow-y-auto"
      : "overflow-y-scroll";

    return (
      <div
        {...props}
        className={`ciety-scroll ${overflowClassName} ${props.className ?? ""}`}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);
