import { entries, isNil, omitBy, pipe, reject, toArray } from "@fxts/core";
import querystring from "query-string";

export const compactQuery = (
  queries: Record<string, unknown>,
  rejectByLength?: boolean,
) => {
  return pipe(
    entries(queries),
    reject(([, v]) =>
      rejectByLength && typeof v === "string"
        ? isNil(v) || v.length <= 0
        : isNil(v),
    ),
    toArray,
  );
};

export const buildQuery = (queryObject: Record<string, unknown>) =>
  pipe(
    queryObject,
    omitBy(([, v]) => isNil(v)),
    (params) => querystring.stringify(params, { arrayFormat: "bracket" }),
  );
