const sizeRegex = /^[hw]-/;

const parse = (className: string) => className.split(" ").map((s) => s.trim());

export const hasSizeClass = (className: string) =>
  filterSizeClass(className).length > 0;

export const filterSizeClass = (className: string) =>
  parse(className)
    .filter((name) => sizeRegex.test(name))
    .join(" ");

export const removeSizeClass = (className: string) =>
  parse(className)
    .filter((name) => !sizeRegex.test(name))
    .join(" ");
