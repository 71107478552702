import { apiBaseUrl } from "~/modules/Router/Auth/constant";
import { ErrorTypes, ResultType } from "../../types";

export const disconnect = async (): Promise<ResultType<null, ErrorTypes>> => {
  try {
    const url = `${apiBaseUrl}/auth/sign-out`;

    const fetchResult = await fetch(url, {
      method: `POST`,
      headers: {
        [`Content-Type`]: `application/json`,
      },
      referrerPolicy: `no-referrer-when-downgrade`,
      credentials: `include`,
    })
      .then((res) => {
        return { ok: true as const, value: res };
      })
      .catch(() => {
        return {
          ok: false as const,
          value: ErrorTypes.UnCategorizedError,
        };
      });
    if (!fetchResult.ok) {
      return fetchResult;
    }
    const { value: res } = fetchResult;

    if (res.status !== 200) {
      return {
        ok: false,
        value: ErrorTypes.UnCategorizedError,
      };
    }

    const json = await res.json();
    if (json?.code !== `000`) {
      return {
        ok: false,
        value: ErrorTypes.UnCategorizedError,
      };
    }

    return { ok: true, value: null };
  } catch (error) {
    return {
      ok: false,
      value: ErrorTypes.UnCategorizedError,
    };
  }
};
