import { compact, filter, map, pipe, toArray, values } from "@fxts/core";

import { SpaceMenu } from "~/modules/Space/types";

export const EBasePath = {
  Profile: "Profile",
  DigitalCollectibles: "Digital collectibles",
  DigitalCollectibles2: "Digital Collectibles",
  MyNfts: "MyNfts",
  MarketHistory: "Market history",
  Cryptocurrency: "Cryptocurrency",
  CreatorEarning: "Creator earning",
  MyAccount: "My Account",
  Language: "Language",
} as const;
export const ESubPath = {
  EditProfile: "Edit Profile",
} as const;
export type ESubPath = typeof ESubPath[keyof typeof ESubPath];

export const BASE_PATH_LIST = Object.values(EBasePath);

// @description Space 의 메뉴 구조
export const SPACE_MENU = {
  community: {
    isPrivate: false,
    group: "Community",
    menu: [
      {
        title: EBasePath.Profile,
        iconName: "User Profile 2",
        viewPages: ["ProfileInfo", "Setting"],
        isHidden: false,
        isPrivate: false,
      },
    ],
  },
  digitalAssets: {
    isPrivate: false,
    group: "Digital Assets",
    menu: [
      {
        title: EBasePath.DigitalCollectibles,
        iconName: "NFT",
        viewPages: [
          "ContractList",
          "ItemList",
          "ItemDetail",
          "OrderCreation",
        ] as const,
        isHidden: false,
        isPrivate: false,
      },
      {
        title: EBasePath.MarketHistory,
        iconName: "History",
        viewPages: ["MarketHistory"] as const,
        isHidden: false,
        isPrivate: true,
      },
      {
        title: EBasePath.Cryptocurrency,
        iconName: "Token Type",
        viewPages: ["Currencies"],
        isHidden: true,
        isPrivate: false,
      },
      {
        title: EBasePath.CreatorEarning,
        iconName: "Blockchain",
        viewPages: ["FeeSetting"],
        isHidden: false,
        isPrivate: true,
      },
    ],
  },
  accountSettings: {
    isPrivate: true,
    group: "Account settings",
    menu: [
      {
        title: EBasePath.MyAccount,
        iconName: "Lock",
        viewPages: ["AccountSetting"],
        isHidden: false,
        isPrivate: true,
      },
      {
        title: EBasePath.Language,
        iconName: "Globe",
        viewPages: ["Language"],
        isHidden: false,
        isPrivate: true,
      },
    ],
  },
} as const;

export const SPACE_HEIGHT = "tall:h-[51rem] h-[43rem]";
export const SPACE_SPLIT_WIDTH = "w-[24.5rem]";

export const spaceMenuIndexByTitle = Object.values(SPACE_MENU)
  .map(({ menu }) => menu)
  .flat()
  .reduce((acc, menuItems) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (acc[menuItems.title] as any) = menuItems;
    return acc;
  }, {} as { [T in SpaceMenu["title"]]: Extract<SpaceMenu, { title: T }> });

export const spaceGroupPrivate = pipe(
  SPACE_MENU,
  values,
  map(({ group, menu }) => ({
    group,
    menu: [
      ...pipe(
        menu,
        filter(({ isHidden }) => !isHidden),
        compact,
        toArray,
      ),
    ],
  })),
  compact,
  toArray,
);

export const spaceGroupPublic = pipe(
  SPACE_MENU,
  values,
  filter(({ isPrivate }) => !isPrivate),
  map(({ group, menu }) => ({
    group,
    menu: [
      ...pipe(
        menu,
        filter(({ isPrivate, isHidden }) => !isHidden && !isPrivate),
        compact,
        toArray,
      ),
    ],
  })),
  compact,
  toArray,
);

export const MOBILE_SPACE_MENU = {
  digitalAssets: {
    isPrivate: false,
    group: "Digital Assets",
    menu: [
      {
        title: "Digital collectibles",
        iconName: "NFT",
      },
    ],
  },
  accountSettings: {
    isPrivate: true,
    group: "Account settings",
    menu: [
      {
        title: "My Account",
        iconName: "Lock",
      },
      {
        title: "Logout",
        iconName: "Logout",
      },
    ],
  },
} as const;

export const mobileSpaceGroupPrivate = pipe(MOBILE_SPACE_MENU, values, toArray);

export const mobileSpaceGroupPublic = pipe(
  MOBILE_SPACE_MENU,
  values,
  filter(({ isPrivate }) => !isPrivate),
  toArray,
);
