import { EErrorTypes } from "~/_enums/EErrorTypes";
import { getSupportedChainsMessage } from "../auth/getSupportedChainsMessage";

export const toConnectCommandErrorMessage = (
  error: EErrorTypes,
  isRn: boolean = false,
) => {
  if (
    error === "CantGoToAuthStatusError" ||
    error === "DismissFromWalletError"
  ) {
    return;
  }

  /**
   * @TODO ppeeou: i18n 대응되도록 수정
   */

  if (error === "ReJoinDaoNotAllowedError") {
    // return `Your account is not allowed to join this community.`;
    return "해당 계정은 이 커뮤니티를 이용할 수 없어요.";
  }

  if (error === "AlreadyJoinedDaoError") {
    // return `You have already joined this community. Please check the community's CIETY URL or invite code and try again.`;
    return "이미 이 커뮤니티에 가입되어있어요. CIETY URL 또는 초대코드를 확인해주세요";
  }

  if (error === "AlreadyUsedAccountError") {
    // return "Your account has already been registered with CIETY. Please use a different account or log in with the same wallet you used before.";
    return "이 계정은 이미 CIETY에 등록되어있어요. 다른 계정을 사용해주세요.";
  }

  if (error === "NotSupportedChainError") {
    // return `Selected chain is not supported. ${getSupportedChainsMessage()}`;
    return `선택한 chain은 지원하지 않아요. ({${getSupportedChainsMessage()}})`;
  }

  if (error === "NotDetectedWalletError") {
    // return `Cannot find MetaMask on your chrome browser. Please install MetaMask extension and try again.`;
    return `크롬브라우져에서 MetaMask를 찾을 수 없어요. MetaMask 확장 프로그램을 설치하고 다시 시도해 주세요.`;
  }

  if (error === "AlreadyProcessingFromWalletError") {
    // const app = isRn ? `CIETY app` : `a new Chrome browser`;

    // return `If your Metamask wallet connection was not successful, Please open ${app} and retry connecting Metamask wallet. `;

    const app = isRn ? `CIETY 앱` : `새 크롬브라우져`;

    return `Metamask 지갑 연결에 실패한 경우, ${app}을 열고 다시 Metamask 지갑에 연결해주세요.`;
  }

  if (error === "DaoJoinDeficientChainsError") {
    // return `Please use the wallet connected to chain.`;

    return `chain에 연결된 지갑을 사용해주세요.`;
  }

  if (error === EErrorTypes.AlreadyRegisteredError) {
    // return "The wallet is already registered. Please try another one.";

    return "해당 지갑이 이미 등록되어 있어요. 다른 것을 시도해주세요.";
  }

  // return `An error occurred, please try again later. If this issue persists, contact support.`;
  return `오류가 발생했어요. 잠시 후 다시 시도 해주세요. 이 문제가 지속되면 지원팀에 문의해주세요.`;
};
