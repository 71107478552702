// export const convertContractToBlockExplorerUrlForMarket = (
//   chain: MarketSupportedChain,
//   contractAddress?: string,
// ) =>
//   contractAddress
//     ? `${blockExplorerBaseUrl(chain)}/token/${contractAddress}`
//     : blockExplorerBaseUrl(chain);

// export const convertAddressToBlockExplorerUrlForMarket = (
//   chain: MarketSupportedChain,
//   address?: string,
// ) =>
//   address
//     ? `${blockExplorerBaseUrl(chain)}/address/${address}`
//     : blockExplorerBaseUrl(chain);

// import { ItemType } from "@opensea/seaport-js/lib/constants";
// import {
//   MatchOrdersFulfillment,
//   Order,
//   OrderParameters,
//   OrderWithCounter,
// } from "@opensea/seaport-js/lib/types";
// import { isCurrencyItem } from "@opensea/seaport-js/lib/utils/item";
// import { BigNumber, BigNumberish, BytesLike, ethers } from "ethers";
// import { formatUnits } from "ethers/lib/utils";
// import numbro from "numbro";

// import { MarketSupportedChain } from "~/_api/dto";
// import { PostOrderCreation, PostProposalCreation } from "~/_api/Market/types";
// import {
//   ellipseItemName,
//   ellipseTokenId,
//   getCietySalt,
// } from "~/modules/BlockChain/Order/utils/OrderHandling";
// import {
//   CietySupportedChainIdsOutput,
//   OrderOrProposal,
// } from "~/modules/Market/types";
// import { isAddressMatch } from "~/modules/Market/utils/address";
// import { extractInteger } from "~/_utils/converter";

// type Props = {
//   chainId: CietySupportedChainIdsOutput;
// };

// export const createPrivateCounterOrder = (
//   orderParameters: OrderParameters,
//   privateSaleRecipient: string,
// ): Order => {
//   const { offer, consideration } = orderParameters;
//   // Counter order offers up all the items in the private listing consideration
//   // besides the items that are going to the private listing recipient
//   const paymentItems = consideration.filter((c) => {
//     return !offer.some((o) => {
//       const { itemType, token, identifierOrCriteria, startAmount, endAmount } =
//         o;

//       return (
//         c.itemType === itemType &&
//         c.token === token &&
//         c.identifierOrCriteria === identifierOrCriteria &&
//         c.startAmount === startAmount &&
//         c.endAmount === endAmount &&
//         isAddressMatch(c.recipient, privateSaleRecipient)
//       );
//     });
//   });

//   if (!paymentItems.every((item) => isCurrencyItem(item))) {
//     throw new Error(
//       "The consideration for the private listing did not contain only currency items",
//     );
//   }
//   if (
//     !paymentItems.every((item) => item.itemType === paymentItems[0].itemType) ||
//     !paymentItems.every((item) => item.token === paymentItems[0].token)
//   ) {
//     throw new Error("Not all currency items were the same for private order");
//   }

//   const { aggregatedStartAmount, aggregatedEndAmount } = paymentItems.reduce(
//     ({ aggregatedStartAmount, aggregatedEndAmount }, item) => ({
//       aggregatedStartAmount: aggregatedStartAmount.add(item.startAmount),
//       aggregatedEndAmount: aggregatedEndAmount.add(item.endAmount),
//     }),
//     {
//       aggregatedStartAmount: BigNumber.from(0),
//       aggregatedEndAmount: BigNumber.from(0),
//     },
//   );

//   return {
//     parameters: {
//       ...orderParameters,
//       offerer: privateSaleRecipient,
//       offer: [
//         {
//           itemType: paymentItems[0].itemType,
//           token: paymentItems[0].token,
//           identifierOrCriteria: paymentItems[0].identifierOrCriteria,
//           startAmount: aggregatedStartAmount.toString(),
//           endAmount: aggregatedEndAmount.toString(),
//         },
//       ],
//       // The consideration here is empty as the original private listing order supplies
//       // the taker address to receive the desired items.
//       consideration: [],
//       salt: getCietySalt(),
//       totalOriginalConsiderationItems: 0,
//     },
//     signature: "0x",
//   };
// };

// export const createOrderMatchFulfillments = (
//   orderParameters: OrderParameters,
// ): MatchOrdersFulfillment[] => {
//   const nftRelatedFulfillments: MatchOrdersFulfillment[] = [];

//   // For the original order, we need to match everything offered with every consideration item
//   // on the original order that's set to go to the private listing recipient
//   orderParameters.offer.forEach((offerItem, offerIndex) => {
//     const considerationIndex = orderParameters.consideration.findIndex(
//       (considerationItem) =>
//         considerationItem.itemType === offerItem.itemType &&
//         considerationItem.token === offerItem.token &&
//         considerationItem.identifierOrCriteria ===
//           offerItem.identifierOrCriteria,
//     );
//     if (considerationIndex === -1) {
//       throw new Error(
//         "Could not find matching offer item in the consideration for private listing",
//       );
//     }
//     nftRelatedFulfillments.push({
//       offerComponents: [
//         {
//           orderIndex: 0,
//           itemIndex: offerIndex,
//         },
//       ],
//       considerationComponents: [
//         {
//           orderIndex: 0,
//           itemIndex: considerationIndex,
//         },
//       ],
//     });
//   });

//   const currencyRelatedFulfillments: MatchOrdersFulfillment[] = [];

//   // For the original order, we need to match everything offered with every consideration item
//   // on the original order that's set to go to the private listing recipient
//   orderParameters.consideration.forEach(
//     (considerationItem, considerationIndex) => {
//       if (!isCurrencyItem(considerationItem)) {
//         return;
//       }
//       // We always match the offer item (index 0) of the counter order (index 1)
//       // with all the payment items on the private listing
//       currencyRelatedFulfillments.push({
//         offerComponents: [
//           {
//             orderIndex: 1,
//             itemIndex: 0,
//           },
//         ],
//         considerationComponents: [
//           {
//             orderIndex: 0,
//             itemIndex: considerationIndex,
//           },
//         ],
//       });
//     },
//   );

//   return [...nftRelatedFulfillments, ...currencyRelatedFulfillments];
// };

// export const createMatchAdvancedOrdersInput = (
//   order: OrderOrProposal,
//   membersOnlyExtraData: string,
//   privateCounterOrder: Order,
// ) => [
//   {
//     parameters: order.seaportOrder.orderParameters,
//     numerator: 1,
//     denominator: 1,
//     extraData: membersOnlyExtraData,
//     signature: order.seaportOrder.signature,
//   },
//   {
//     parameters: privateCounterOrder.parameters,
//     numerator: 1,
//     denominator: 1,
//     extraData: membersOnlyExtraData,
//     signature: "0x",
//   },
// ];

// export const transformCietyOrderOrProposalToSeaportOrder = (
//   order: OrderOrProposal,
// ): OrderWithCounter => ({
//   parameters: order.seaportOrder.orderParameters,
//   signature: order.seaportOrder.signature,
// });

// export const truncateDecimal = ({
//   numString,
//   viewDecimals,
//   withTrailingZeros,
// }: {
//   numString: string;
//   viewDecimals: number;
//   withTrailingZeros: boolean;
// }) => {
//   const dotIndex = numString.indexOf(".");
//   if (dotIndex === -1) {
//     return numString;
//   }
//   const decimalLen = numString.length - dotIndex - 1;

//   if (viewDecimals > decimalLen) {
//     return withTrailingZeros
//       ? numString + "0".repeat(viewDecimals - decimalLen)
//       : numString;
//   } else {
//     return numString.substring(0, dotIndex + viewDecimals + 1);
//   }
// };

// export const convertIntToDecimalNum = ({
//   decimals,
//   numString,
//   viewDecimals,
//   withTrailingZeros = false,
// }: {
//   decimals: number;
//   numString: string;
//   viewDecimals: number;
//   withTrailingZeros?: boolean;
// }) => {
//   const value = ethers.BigNumber.from(numString);
//   const decimalNumber = ethers.utils.formatUnits(value, decimals);

//   return truncateDecimal({
//     numString: decimalNumber,
//     viewDecimals: Math.min(viewDecimals, decimals),
//     withTrailingZeros,
//   });
// };

// export const convertDecChainIdToChainName = ({
//   chainId,
// }: Props): MarketSupportedChain => {
//   switch (chainId) {
//     case "1":
//       return "EthereumMainnet";
//     case "5":
//       return "Goerli";
//     case "137":
//       return "PolygonMainnet";
//     case "80001":
//       return "Mumbai";
//     default:
//       throw new Error(`Not supported chain id for Ciety`);
//   }
// };

// export const convertDesChainIdToBlockExplorerTrxUrl = ({
//   chainId,
//   trxHash,
// }: Props & { trxHash: string }) => {
//   const baseUrl = convertDesChainIdToBlockExplorerBaseUrl({ chainId });

//   return `${baseUrl}/tx/${trxHash}`;
// };

// export const convertDesChainIdToBlockExplorerBaseUrl = ({ chainId }: Props) => {
//   switch (chainId) {
//     case "1":
//       return "https://etherscan.io";
//     case "137":
//       return "https://polygonscan.com";
//   }
// };

import { EChain } from "~/_enums/EChain";
import { toDec } from "~/_utils/blockchain/convertsions";
import { ellipsis } from "~/_utils/converter/ellipsis";
import { ellipseTokenId } from "~/_utils/converter/ellipsisTokenId";

const blockExplorerBaseUrl = (chain: EChain) => {
  switch (chain) {
    case "EthereumMainnet":
      return `https://etherscan.io`;
    case "Goerli":
      return `https://goerli.etherscan.io`;
    case "PolygonMainnet":
      return `https://polygonscan.com`;
    case "Mumbai":
      return `https://mumbai.polygonscan.com`;
  }
};

export const convertTrxToBlockExplorerUrlForMarket = (
  chain: EChain,
  trxHash?: string,
) =>
  trxHash
    ? `${blockExplorerBaseUrl(chain)}/tx/${trxHash}`
    : blockExplorerBaseUrl(chain);

export const convertDateStrToBlockchainTime = (date: Date): string => {
  return "" + Math.round(date.getTime() / 1000);
};

// export const arrayBufferToHexStr = (buffer: Uint8Array): string => {
//   return [...buffer].map((byte) => byte.toString(16).padStart(2, "0")).join("");
// };

// export const getWeiFromEth = (eth: string) =>
//   ethers.utils.parseEther(eth).toString();

// export const convertChainNameToDecChainId = ({
//   chainName,
// }: {
//   chainName: MarketSupportedChain;
// }): CietySupportedChainIdsOutput => {
//   switch (chainName) {
//     case "EthereumMainnet":
//       return "1";
//     case "Goerli":
//       return "5";
//     case "PolygonMainnet":
//       return "137";
//     case "Mumbai":
//       return "80001";
//     default:
//       throw new Error(`Not supported chain id for Ciety`);
//   }
// };

// export const convertDecChainIdToNativeTokenName = ({
//   chainId,
// }: Props): TCurrencyType => {
//   switch (chainId) {
//     case "1":
//       return "ETH";
//     case "137":
//       return "MATIC";
//     default:
//       throw new Error(`Not supported chain id (except testnet) for Ciety`);
//   }
// };

// const convertDecChainIdToOpenseaChainPrefix = ({
//   chainName,
// }: {
//   chainName: MarketSupportedChain;
// }): string => {
//   switch (chainName) {
//     case "EthereumMainnet":
//       return "ethereum";
//     case "Goerli":
//       return "goerli";
//     case "PolygonMainnet":
//       return "matic";
//     case "Mumbai":
//       return "mumbai";
//   }
// };

// export const convertOpenseaUrl = ({
//   contractAddress,
//   decTokenId,
//   chainName,
// }: {
//   contractAddress: string;
//   decTokenId: string;
//   chainName: MarketSupportedChain;
// }) => {
//   return `https://opensea.io/assets/${convertDecChainIdToOpenseaChainPrefix({
//     chainName,
//   })}/${contractAddress}/${decTokenId}`;
// };

// export const toHex = (value: BytesLike | string | number | bigint) => {
//   if (typeof value === "string" && !value.includes("0x")) {
//     value = Number(value);
//   }

//   return ethers.utils.hexValue(value);
// };

// export const toDec = (value: string): string => {
//   return ethers.BigNumber.from(value).toString();
// };

export const getTokenDisplayName = ({
  tokenName,
  tokenId,
  withEllipse = false,
}: {
  tokenName: string | null;
  tokenId: string;
  withEllipse?: boolean;
}) => {
  tokenName = tokenName ?? "Item";
  const hashTokenIdRegexr = /#[\s0-9]+/;
  const execResult = hashTokenIdRegexr.exec(tokenName);
  const decTokenId = toDec(tokenId);
  let name: string;
  if (execResult == null) {
    name = withEllipse ? ellipsis(tokenName, 25) : tokenName;
  } else {
    const sanitizedName = execResult.input
      .substring(0, execResult.index)
      .trim();
    name = withEllipse ? ellipsis(sanitizedName, 25) : sanitizedName;
  }

  return `${name} #${withEllipse ? ellipseTokenId(decTokenId) : decTokenId}`;
};

export const getTokenDisplayNameWithoutShop = ({
  tokenName,
}: {
  tokenName: string;
}) => {
  tokenName = tokenName === "" ? "item" : tokenName;
  const hashTokenIdRegexr = /\s+#\d+$/;
  const execResult = hashTokenIdRegexr.exec(tokenName);
  if (execResult == null) {
    return `${tokenName}`;
  }
  const name = execResult.input.substring(0, execResult.index).trim();

  return `${name}`;
};

// export const convertOrderCreationParams = ({
//   chain,
//   order,
//   isMembersOnly,
//   privateRecipient,
// }: {
//   chain: MarketSupportedChain;
//   order: OrderWithCounter;
//   isMembersOnly: boolean;
//   privateRecipient?: string;
// }): PostOrderCreation["request"]["order"] => {
//   const {
//     offerer,
//     zone,
//     offer,
//     consideration,
//     orderType,
//     startTime,
//     endTime,
//     zoneHash,
//     salt,
//     conduitKey,
//     totalOriginalConsiderationItems,
//     counter,
//   } = order.parameters;

//   return {
//     chain,
//     signature: order.signature,
//     isMembersOnly,
//     privateRecipient: sanitizeEmptyString(privateRecipient),
//     orderParameters: {
//       conduitKey,
//       counter: toNumber(counter),
//       offerer,
//       orderType,
//       salt,
//       startTime: startTime.toString(),
//       endTime: endTime.toString(),
//       zone,
//       zoneHash,
//       totalOriginalConsiderationItems: toNumber(
//         totalOriginalConsiderationItems,
//       ),
//       offer: [
//         ...offer.map((item) => ({
//           ...item,
//           itemType: narrowingItemTypeToErc721Erc1155(item.itemType),
//         })),
//       ],
//       consideration: [
//         ...consideration.map((item) => ({
//           ...item,
//           itemType: narrowingItemTypeNotCriteria(item.itemType),
//         })),
//       ],
//     },
//   };
// };

// export const convertProposalCreationParams = ({
//   chain,
//   order,
//   isMembersOnly,
//   privateRecipient,
// }: {
//   chain: MarketSupportedChain;
//   order: OrderWithCounter;
//   isMembersOnly: boolean;
//   privateRecipient?: string;
// }): PostProposalCreation["request"]["proposal"] => {
//   const {
//     offerer,
//     zone,
//     offer,
//     consideration,
//     orderType,
//     startTime,
//     endTime,
//     zoneHash,
//     salt,
//     conduitKey,
//     totalOriginalConsiderationItems,
//     counter,
//   } = order.parameters;

//   return {
//     chain,
//     signature: order.signature,
//     isMembersOnly,
//     privateRecipient: sanitizeEmptyString(privateRecipient),
//     orderParameters: {
//       conduitKey,
//       counter: toNumber(counter),
//       offerer,
//       orderType,
//       salt,
//       startTime: startTime.toString(),
//       endTime: endTime.toString(),
//       zone,
//       zoneHash,
//       totalOriginalConsiderationItems: toNumber(
//         totalOriginalConsiderationItems,
//       ),
//       offer: [
//         ...offer.map((item) => ({
//           ...item,
//           itemType: narrowingItemTypeToErc20(item.itemType),
//         })),
//       ],
//       consideration: [
//         ...consideration.map((item) => ({
//           ...item,
//           itemType: narrowingItemTypeNotCriteriaWithouNative(item.itemType),
//         })),
//       ],
//     },
//   };
// };

// const narrowingItemTypeNotCriteriaWithouNative = (itemType: ItemType) => {
//   if (itemType === 4 || itemType === 5 || itemType === 0) {
//     throw new Error(
//       `Criteria or native token type is not allowed, but received ${itemType}`,
//     );
//   } else {
//     return itemType;
//   }
// };

// const narrowingItemTypeNotCriteria = (itemType: ItemType) => {
//   if (itemType === 4 || itemType === 5) {
//     throw new Error(
//       `Criteria token type is not allowed, but received ${itemType}`,
//     );
//   } else {
//     return itemType;
//   }
// };

// const narrowingItemTypeToErc721Erc1155 = (itemType: ItemType) => {
//   if (itemType === 2 || itemType === 3) {
//     return itemType;
//   } else {
//     throw new Error(
//       `Only Erc721 or Erc1155 token type allowed, but received ${itemType}`,
//     );
//   }
// };

// const narrowingItemTypeToErc20 = (itemType: ItemType) => {
//   if (itemType === 1) {
//     return itemType;
//   } else {
//     throw new Error(`Only Erc20 token type allowed, but received ${itemType}`);
//   }
// };

// const sanitizeEmptyString = (string?: string) =>
//   string === "" ? undefined : string;

// export const toNumber = (BN: BigNumberish) => Number(BN.toString());

// export const convertChainNameToDisplayName = ({
//   chainName,
// }: {
//   chainName: MarketSupportedChain;
// }) => {
//   switch (chainName) {
//     case "EthereumMainnet":
//       return "Ethereum mainnet";
//     case "Goerli":
//       return "Goerli";
//     case "PolygonMainnet":
//       return "Polygon mainnet";
//     case "Mumbai":
//       return "Mumbai";
//   }
// };

// export const tokenTypeToDisplayName = (tokenType: "ERC721" | "ERC1155") => {
//   switch (tokenType) {
//     case "ERC721":
//       return "ERC-721";
//     case "ERC1155":
//       return "ERC-1155";
//   }
// };

// export const toTokenDisplayPriceFromEth = ({
//   eth,
//   displayDecimals = 5,
//   average = true,
// }: {
//   eth: string;
//   displayDecimals?: number;
//   average?: boolean;
// }) => {
//   const isOverKilo = extractInteger(eth).length > 3;

//   /* k m b t 표현에서는 최대 소수 2자리까지, 그 외에는 최대 displayDecimal 까지 */
//   const formatNumStr = numbro(eth).format({
//     average,
//     mantissa: isOverKilo ? Math.min(2, displayDecimals) : displayDecimals,
//   });

//   if (formatNumStr === "NaN") {
//     return "0.0";
//   }

//   // removing tailing zeros
//   return formatNumStr.replace(/(\.\d+?)0*(\D)?$|(\D$)/, "$1$2");
// };

// export const toTokenDisplayPrice = ({
//   token,
//   displayDecimals = 4,
//   average = true,
// }: {
//   token: { amount: BigNumberish; decimals: number };
//   displayDecimals?: number;
//   average?: boolean;
// }) => {
//   const { amount, decimals } = token;
//   if (typeof amount === "string") {
//     if (amount.indexOf(".") >= 0) {
//       throw new Error(
//         "TokenAmount must be BigNumberish, but have float point.",
//       );
//     }
//   }

//   return toTokenDisplayPriceFromEth({
//     eth: formatUnits(amount, decimals),
//     displayDecimals,
//     average,
//   });
// };

// export const fromTokenToUsd = ({
//   receiveAmount,
//   decimals,
//   usdCurrency,
// }: {
//   receiveAmount: string;
//   decimals: number;
//   usdCurrency: string | null;
// }) => {
//   if (usdCurrency == null) {
//     return "$ - ";
//   }
//   const tokenAmount = formatUnits(receiveAmount, decimals);
//   const price = Number(tokenAmount) * Number(usdCurrency);

//   const formatted = numbro(price).formatCurrency({
//     average: true,
//     mantissa: 2,
//   });

//   const tooSmall = "< $0.01";

//   return formatted === "$NaN" || formatted === "$0.00"
//     ? tooSmall
//     : formatted.replace(/(\.\d+?)0*(\D)?$|(\D$)/, "$1$2");
// };

// export const toUsdPriceFormat = (usdPrice: string) => {
//   return numbro(usdPrice).formatCurrency({
//     average: true,
//     mantissa: 2,
//   });
// };

// export const fromEthToUsd = ({
//   eth,
//   usdCurrency,
//   mantissa = 2,
// }: {
//   eth: string;
//   usdCurrency: string | null;
//   mantissa?: number;
// }) => {
//   if (usdCurrency == null) {
//     return "$ - ";
//   }
//   const price = Number(eth) * Number(usdCurrency);

//   const formatted = numbro(price).formatCurrency({
//     average: true,
//     mantissa,
//   });

//   const tooSmall = "< $0.01";

//   return formatted === "$NaN" || formatted === "$0.00"
//     ? tooSmall
//     : formatted.replace(/(\.\d+?)0*(\D)?$|(\D$)/, "$1$2");
// };
