export type TAppScreenParams = {
  screen: string;
  params?: TAppScreenParams | Record<string, unknown>;
};

/*
 ** =============================================================================
 ** 홈 탭
 ** =============================================================================
 */

// 채널 목록
export const getChannelScreenParams = (params?: {
  path: string;
}): TAppScreenParams => ({
  screen: "DaoTabNavigation",
  params: {
    screen: "Home",
    params: {
      screen: "Channels",
      params,
    },
  },
});
// 피드 목록
export const getFeedScreenParams = (params?: {
  path: string;
  subdomain?: string;
}): TAppScreenParams => ({
  screen: "DaoTabNavigation",
  params: {
    screen: "Home",
    params: {
      screen: "Feed",
      params,
    },
  },
});
// 글 작성 페이지
export const getPostEditorScreenParams = (params?: {
  path: string;
}): TAppScreenParams => ({
  screen: "PostEditor",
  params,
});

/*
 ** =============================================================================
 ** 검색 탭
 ** =============================================================================
 */

// 검색 홈
export const getSearchHomeScreenParams = (params?: {
  path: string;
}): TAppScreenParams => ({
  screen: "DaoTabNavigation",
  params: {
    screen: "Search",
    params: {
      screen: "SearchHome",
      params,
    },
  },
});
// 검색 결과
export const getSearchResultScreenParams = (params: {
  path: string;
}): TAppScreenParams => ({
  screen: "DaoTabNavigation",
  params: {
    screen: "Search",
    params: {
      screen: "SearchResult",
      params,
    },
  },
});
// 검색 필터
export const getSearchFilterScreenParams = (params: {
  path: string;
}): TAppScreenParams => ({
  screen: "SearchFilter",
  params,
});

/*
 ** =============================================================================
 ** 알림 탭
 ** =============================================================================
 */

export const getNotificationScreenParams = (params?: {
  path: string;
}): TAppScreenParams => ({
  screen: "DaoTabNavigation",
  params: {
    screen: "RelatedToMe",
    params,
  },
});

/*
 ** =============================================================================
 ** DM 탭
 ** =============================================================================
 */

// DM 목록
export const getDmStackScreenParams = (params?: {
  path: string;
}): TAppScreenParams => ({
  screen: "DmStack",
  params,
});

/** @description 마켓플레이스 */
// 마켓 홈
export const getMarketHomeScreenParams = (params: { path: string }) => ({
  screen: "MarketStackNavigation",
  params: {
    screen: "MarketHome",
    params,
  },
});

/** @description 온보딩 */
// 커뮤니티 생성
export const getCreateCommunityScreenParamsInOnboarding =
  (): TAppScreenParams => ({
    screen: "OnboardingStackNavigation",
    params: {
      screen: "CreateCommunityScreen",
    },
  });
// 커뮤니티 찾기
export const getFindCommunityScreenParamsInOnboarding =
  (): TAppScreenParams => ({
    screen: "OnboardingStackNavigation",
    params: {
      screen: "FindCommunityScreen",
    },
  });

/*
 ** =============================================================================
 ** 탭 위에 뜨는 화면들 (탭바 가려지는 화면)
 ** =============================================================================
 */

// MerchStore
export const getMerchStoreScreenParams = (params?: {
  path: string;
}): TAppScreenParams => ({
  screen: "MerchStore",
  params,
});
// 피드 상세
export const getThreadScreenParams = (params: {
  path: string;
  subdomain?: string;
  threadId?: string;
  channelId?: string;
  commentId?: string;
  channelType?: string;
}): TAppScreenParams => ({
  screen: "Thread",
  params,
});
// 멤버 목록 (채널...)
export const getMemberListScreenParams = (params: {
  path: string;
  subdomain?: string;
}): TAppScreenParams => ({
  screen: "MemberList",
  params,
});
// 첨부파일 상세
export const getAttachmentDetailScreenParams = (params: {
  path: string;
}): TAppScreenParams => ({
  screen: "AttachmentDetail",
  params,
});
// @TODO 첨부파일 이미지 상세 (AttachmentImageDetail)

// 스페이스 스택 (스페이스 에서 여러 화면 띄울 때)
export const getSpaceStackScreenParams = (params: {
  path: string;
}): TAppScreenParams => ({
  screen: "SpaceStack",
  params,
});
// 스페이스 모달
export const getSpaceModalScreenParams = (params: {
  path: string;
}): TAppScreenParams => ({
  screen: "SpaceModal",
  params,
});

// 채팅형 채널
export const getChatFeedScreenParams = (params: {
  path: string;
  subdomain?: string;
}): TAppScreenParams => ({
  screen: "ChatFeed",
  params,
});
// 신고 페이지
export const getReportScreenParams = (params: {
  path: string;
}): TAppScreenParams => ({
  screen: "Report",
  params,
});
// 이미지 상세 보기
export const getAttachmentImageDetailScreenParams = (params: {
  images: { type: "image"; url: string }[];
  selectedIndex: number;
}): TAppScreenParams => ({
  screen: "AttachmentImageDetail",
  params,
});
// AutolinkAdapter 클릭 시 쌓이는 화면 (ex 다른 커뮤니티 링크)
export const getOtherCommunityScreenParams = (params: { path: string }) => ({
  screen: "OtherCommunityStackNavigation",
  params: {
    screen: "DefaultScreen",
    params,
  },
});
// 더이상 커뮤니티에 접근할 수 없는 경우
export const getEnterOtherCommunityScreenParams = (params: {
  displayName: string;
}) => ({
  screen: "OtherCommunityStackNavigation",
  params: {
    screen: "EnterOtherCommunity",
    params,
  },
});
// 마켓플레이스
export const getMarketplaceStackScreenParams = (params: { path: string }) => ({
  screen: "MarketStackNavigation",
  params: {
    screen: "MarketStack",
    params,
  },
});

export const getExploreScreenParams = (params: {
  path: string;
  subdomain: string;
}) => ({
  screen: "ExploreScreen",
  params,
});
