import { ReactNode } from "react";

import { BaseButton } from "./BaseButton";

type Props = {
  type?: "button" | "reset" | "submit";
  onClick?: () => void;
  children?: ReactNode;
  icon?: ReactNode;
  disabled?: boolean;
  className?: string;
};

export const GreenButton = ({
  type,
  disabled,
  onClick,
  children,
  className,
  icon,
}: Props) => (
  <BaseButton
    type={type}
    disabled={disabled}
    onClick={onClick}
    className={`bg-ciety-lg disabled:bg-ciety-dk-90 ${className}`}
  >
    {icon}
    <span className="typo-m1 pt-[0.1rem] text-ciety-dk">{children}</span>
  </BaseButton>
);
