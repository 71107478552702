import { cn } from "~/_utils/converter/cn";

type TProps = {
  title: string;
  className?: string;
};

function EmptySectionTitle({ title, className }: TProps) {
  return (
    <h1 className={cn(`typo-headline-m-20 text-ciety-wh`, className)}>
      {title}
    </h1>
  );
}

export { EmptySectionTitle };
