import { fromEntries, map, pipe } from "@fxts/core";
import { createSlice } from "@reduxjs/toolkit";

import { MarketSupportedChain } from "~/api/dto";
import { MARKET_CHAINS } from "~/modules/Market/utils/testnetHandler";
import {
  pageKeyController,
  scrollController,
  selectionController,
} from "~/store/inventory/reducers";
import { InventoryState } from "~/store/inventory/types";

const INITAIL_PAGE_KEYS_BY_CHAIN = pipe(
  MARKET_CHAINS(),
  map((chain) => [chain, null] as [MarketSupportedChain, null]),
  fromEntries,
);
const INITAIL_SCROLL_BY_CHAIN = pipe(
  MARKET_CHAINS(),
  map((chain) => [chain, 0] as [MarketSupportedChain, number]),
  fromEntries,
);

const initialState: InventoryState = {
  selected: {
    chain: "EthereumMainnet",
    contract: null,
    item: null,
  },
  pageKey: {
    contract: INITAIL_PAGE_KEYS_BY_CHAIN,
    item: {},
  },
  scroll: {
    contract: INITAIL_SCROLL_BY_CHAIN,
    item: {},
  },
};

export const inventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    reset: selectionController.reset,
    // Chain 선택 업데이트 Action
    updateSelectedChain: selectionController.updateSelectedChain,

    // Contract (컬렉션) 선택 업데이트 Action
    updateSelectedContract: selectionController.updateSelectedContract,

    // Item (Nft) 선택 업데이트 Action
    updateSelectedItem: selectionController.updateSelectedItem,

    // 유저의 선택 초기화
    resetSelected: selectionController.resetSelected,

    // 페이지 키 관리
    resetPageKey: pageKeyController.resetPageKey,
    updateContractPageKey: pageKeyController.updateContractPageKey,
    updateItemPageKey: pageKeyController.updateItemPageKey,

    // 스크롤 관리
    resetScroll: scrollController.resetScroll,
    updateContractScroll: scrollController.updateContractScroll,
    updateItemScroll: scrollController.updateItemScroll,
  },
});
