import { ErrorLayout } from "~/_components/Fallback/ErrorLayout";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { Icon } from "~/modules/Common/Icons";
import { useEffect, useState } from "react";
import { toSubdomainPolicy } from "~/_utils/converter/toSubdomainPolicy";
import { useDaoStore } from "~/_store/dao/useDaoStore";
import { getDao } from "~/api";
import { ECode } from "~/_enums/ECode";

type TProps = {
  message?: string;
};

function NotFound({ message }: TProps) {
  const { t } = useTranslation([ENameSpace.Common]);
  const [show, setShow] = useState(Boolean(message));

  const { dao } = useDaoStore();
  /** @description daoId를 통해 Subdomain 조회 후 있으면 이동 없으면 404 페이지 이동 */
  useEffect(() => {
    (async () => {
      if (!dao || message !== "NO_SUBDOMAIN") {
        return setShow(true);
      }

      try {
        const { code, data } = await getDao({
          daoId: dao.id,
        });

        if (code !== ECode.Success) {
          return setShow(true);
        }

        location.replace(getPathnameForRedirect(data.subdomain));
      } catch (err) {
        setShow(true);
      }
    })();
  }, [dao, message]);

  return show ? (
    <ErrorLayout
      renderIcon={
        <Icon className="w-13.5rem mb-5 h-[8.753rem]" iconType="Error-404" />
      }
      renderSubText={
        <>
          <span>{t(`${ENameSpace.Common}:Error.404.SubText1`)}</span>
          <span>{t(`${ENameSpace.Common}:Error.404.SubText2`)}</span>
        </>
      }
      title={t(`${ENameSpace.Common}:Error.404.MainText`)}
    >
      <button
        type="button"
        className="typo-m1 mt-4 flex h-10 w-[13.125rem] items-center justify-center gap-x-1 rounded-full bg-ciety-wh text-ciety-dk"
        onClick={() => window.history.back()}
      >
        {t(`${ENameSpace.Common}:Error.404.ButtonText`)}
      </button>
    </ErrorLayout>
  ) : null;
}

function getPathnameForRedirect(nextSubdomain: string) {
  const { subdomain: prevSubdomain, pathname } = getCurrentLocationInfo();
  const _nextSubdomain = toSubdomainPolicy(nextSubdomain);

  // 서브도메인이 정상적으로 존재 하는 경우는 현재 페이지를 유지 시키기 위해 변경된 subdomain으로 변경
  if (prevSubdomain) {
    return pathname.replace(prevSubdomain, _nextSubdomain);
  } else {
    // 서브도메인이 정상적으로 존재 하지 않는 경우 커뮤니티 메인으로 이동
    return `/${_nextSubdomain}`;
  }
}

function getCurrentLocationInfo() {
  const pathname = location.pathname;
  const subdomain = pathname.split("/")[1];
  const isSubdomain = subdomain.startsWith("@");
  return {
    pathname,
    subdomain: isSubdomain ? subdomain : null,
  };
}

export { NotFound };
