import { Icon } from "~/modules/Common/Icons";
import { cn } from "~/utils/css";

type TProps = {
  onClick: () => void;
  className?: string;
};

export function NavigationCancelCloseButton({ onClick, className }: TProps) {
  return (
    <button
      onClick={onClick}
      className={cn(className, "flex-center h-14 w-14 p-2")}
    >
      <Icon
        iconType="Cancel Close"
        className="h-10 w-10 text-ciety-bright-80"
      />
    </button>
  );
}
