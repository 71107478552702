import { CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import { MarketItemWithFloorPrice } from "~/api/Market/types";
import { InfiniteTypes } from "~/modules/Common/Utility/Infinite/types";
import { MarketState } from "~/store/market/types";

export const listController = {
  updateResetCounter: {
    liveItems: ((state) => {
      state.liveItems.resetCounter += 1;
    }) as MarketReducer,
  },
  set: {
    liveItems: ((state, { payload: items }) => {
      state.liveItems.originLiveItems = items;
    }) as MarketReducer<MarketItemWithFloorPrice[]>,
  },
  merge: {
    liveItems: ((state, { payload: { newItems, direction } }) => {
      state.liveItems.originLiveItems =
        direction === "top"
          ? [...newItems, ...(state.liveItems.originLiveItems ?? [])]
          : [...(state.liveItems.originLiveItems ?? []), ...newItems];
    }) as MarketReducer<{
      newItems: MarketItemWithFloorPrice[];
      direction: InfiniteTypes["direction"];
    }>,
  },
  reset: {
    liveItems: ((state) => {
      state.liveItems.originLiveItems = null;
      state.liveItems.resetCounter += 1;
    }) as MarketReducer,
  },
};

type MarketReducer<PayloadType = void> = CaseReducer<
  MarketState,
  PayloadAction<PayloadType>
>;
