import { unicodeToArray } from "@fxts/core";

/**
 * text mention 껴 있는 경우
 *
 * `hello world <@12304>`
 */
export function getHomeTextFromPost<
  T extends
    | {
        // sns
        text: string;
        mentions: {
          id: string;
          nickname: string;
        }[];
      }
    | {
        // video
        streamUrl: string | null;
      },
>({ post }: { post: T }) {
  if (!("text" in post)) {
    return "";
  }

  const memberNicknameMap = new Map(
    post.mentions.map((mention) => [mention.id, mention.nickname]),
  );

  /**
   * 유니코드도 넘어올수 있어 배열로 다룸
   */
  const textArr = unicodeToArray(post.text);
  const text = [];

  for (let i = 0; i < textArr.length; i++) {
    if (
      textArr[i] === "<" &&
      i + 1 < textArr.length &&
      textArr[i + 1] === "@"
    ) {
      for (let j = i + 2; j < textArr.length; j++) {
        if (textArr[j] === ">") {
          const memberId = textArr.slice(i + 2, j).join("");
          const nickname = memberNicknameMap.get(memberId);

          // mention으로 된 값이 아닌 경우
          if (nickname == null) {
            text.push(`<@${memberId}>`);
          } else {
            text.push(nickname);
          }
          i += j - i;
          break;
        }
      }
    } else {
      text.push(textArr[i]);
    }
  }

  return text.join("");
}
