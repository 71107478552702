import { env } from "~/env";

export type TToResizeUrlProps = {
  url: string;
  width?: number;
  timeLastUpdated?: string;
  isHQ?: boolean;
};

const DEFAULT_WIDTH = 75; // 3배수 이미지 처리

export const toResizeUrl = ({
  url,
  width,
  timeLastUpdated,
  isHQ = false,
}: TToResizeUrlProps) => {
  return `${env.API_RESIZE_MEDIA_BASE_URL}?url=${encodeURI(
    url,
  )}&width=${Math.ceil((width || DEFAULT_WIDTH) * (isHQ ? 2 : 1))}${
    timeLastUpdated ? `&timeLastUpdated=${+new Date(timeLastUpdated)}` : ""
  }`;
};
