import {
  EthereumNativeCurrency,
  EthereumStandardErc20Currency,
  PolygonNativeCurrency,
  PolygonStandardErc20Currency,
} from "~/api/apiRouteTypes/MarketplaceRoute";
import { MarketSupportedChain } from "~/api/dto";

export const DEFAULT_CURRENCY_FOR_ORDER_CREATION_BY_CHAIN: {
  [chain in MarketSupportedChain]: chain extends "EthereumMainnet" | "Goerli"
    ? EthereumNativeCurrency["tokenSymbol"]
    : PolygonNativeCurrency["tokenSymbol"];
} = {
  EthereumMainnet: "ETH",
  Goerli: "ETH",
  PolygonMainnet: "MATIC",
  Mumbai: "MATIC",
} as const;

// @description - proposal 에서 native token 선택 불가
export const DEFAULT_CURRENCY_FOR_PROPOSAL_CREATION_BY_CHAIN: {
  [chain in MarketSupportedChain]: chain extends "EthereumMainnet" | "Goerli"
    ? EthereumStandardErc20Currency["tokenSymbol"]
    : PolygonStandardErc20Currency["tokenSymbol"];
} = {
  EthereumMainnet: "wETH",
  Goerli: "wETH",
  PolygonMainnet: "wMATIC",
  Mumbai: "wMATIC",
} as const;

export const ORDER_CREATION_STEPS = [
  "Standby", // 입력 단계
  "Initiating", // 오더 유효성 검증
  "Initiated",
  "Approving", // 토큰 컨트랙 오픈씨 승인 단계
  "Already Approved", // 이미 승인 됨
  "Approved",
  "Signing", // 오더 사인 단계
  "Signed",
  "Posting", // 서버에 오더 등록 단계
  "Posted",
  "Complete", // 리스팅 완료
] as const;

export const TRANSACTION_STEPS = [
  "Standby",
  "Validating",
  "Validated",
  "Approving",
  "Already Approved",
  "Approved",
  "TransactionSigning",
  "TransactionSigned",
  "TransactionValidated",
  "ServerInvalidating",
  "ServerInvalidated",
  "Complete",
  "Error",
] as const;

export const MAX_SAFE_INTEGER = 8640000000000000;
export const MIN_USD_PRICE = 0.01;
export const DEFAULT_ORDER_DURATION_DAYS = 10;

export const BLOCK_WAIT_NO: Record<MarketSupportedChain, number> = {
  // 12 초 가량 대기
  EthereumMainnet: 1,
  Goerli: 1,
  PolygonMainnet: 6,
  Mumbai: 6,
};
