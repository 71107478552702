const subdomainMap = new Map<string, string>();

export const wrapHeaders = (init: RequestInit) => {
  const currentPathname = location.pathname;

  if (!subdomainMap.has(currentPathname)) {
    const [, _subdomain] = /(?:@([a-zA-Z0-9-_]+))/.exec(currentPathname) ?? [];
    subdomainMap.set(currentPathname, _subdomain);
  }

  const subdomain = subdomainMap.get(currentPathname);

  if (subdomain) {
    return {
      ...init,
      headers: {
        ...(init?.headers ?? {}),
        "x-dao-sub-domain": subdomain,
      },
    };
  }

  return init;
};
