type TProps = {
  roleColor?: string;
  nickname: string;
};

function NicknameWithRoleColor({ roleColor = "b1b1b1", nickname }: TProps) {
  return (
    <div
      className="max-w-full truncate text-ciety-gy"
      style={{ color: `#${roleColor}` }}
    >
      {nickname}
    </div>
  );
}

export { NicknameWithRoleColor };
