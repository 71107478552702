import { ESnsType, THomeSns } from "~/_types/daoHome/TDaoHomeSectionProfile";
import { Icon, IconType } from "~/modules/Common/Icons";
import { KeyboardEventHandler, useEffect, useRef, useState } from "react";
import { useSettingsHomeStore } from "~/_store/settings/useSettingsHomeStore";
import { cn } from "~/_utils/converter/cn";
import { sanitizeUrl } from "~/_utils/converter/sanitizeUrl";

const PROTOCOL = "^(?:http(?:s)?://)?(?:www.|[a-z0-9-_]{1,30}.)?";

export const SNS_LIST: Record<
  ESnsType,
  { iconName: IconType; regexp: string }
> = {
  [ESnsType.Instagram]: {
    iconName: "Instagram",
    regexp: "instagram.com",
  },
  [ESnsType.Youtube]: {
    iconName: "SNS_Youtube",
    regexp: "youtu.be|youtube.com",
  },
  [ESnsType.Facebook]: {
    iconName: "SNS-Facebook",
    regexp: "facebook.com",
  },
  [ESnsType.NaverBlog]: {
    iconName: "SNS-Blog",
    regexp: "blog.naver.com",
  },
  [ESnsType.NaverChzzk]: {
    iconName: "SNS-CHZZK",
    regexp: "chzzk.naver.com",
  },
  [ESnsType.X]: {
    iconName: "SNS-X",
    regexp: "x.com",
  },
  [ESnsType.TikTok]: {
    iconName: "SNS-Tiktok",
    regexp: "tiktok.com",
  },
  [ESnsType.Opensea]: {
    iconName: "SNS-Opensea",
    regexp: "opensea.io",
  },
  [ESnsType.Afreecatv]: {
    iconName: "SNS-afreecaTV",
    regexp: "afreecatv.com",
  },
  [ESnsType.Marpple]: {
    iconName: "SNS-MarppleShop",
    regexp: "marpple.com|marpple.shop",
  },
  [ESnsType.Github]: {
    iconName: "logo-Github",
    regexp: "github.com",
  },
  [ESnsType.Other]: {
    iconName: "Public",
    regexp: ".*",
  },
};

function SnsItem({ id, type, url }: THomeSns) {
  const { setSectionProfileSns, setSectionProfileRemoveSns } =
    useSettingsHomeStore();
  const handleRemoveSnsItem = (id: string) => () => {
    setSectionProfileRemoveSns({ id });
  };
  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === "Enter") {
      // Blur 될 때 sanitizeUrl 처리
      const sanitizedUrl = sanitizeUrl(url);
      setSectionProfileSns({
        nextSns: {
          id,
          type,
          url: sanitizedUrl,
        },
      });

      event.currentTarget.blur();
    }
  };

  const handleFocusToggle = (focus: boolean) => () => {
    setFocusedInput(focus);
  };

  const [focusedInput, setFocusedInput] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);
  // 추가하기 버튼을 통해 생성된 경우 input에 포커스 되도록
  useEffect(() => {
    if (url === "") {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex w-full items-center gap-x-2">
      <Icon
        iconType={SNS_LIST[type].iconName}
        className={cn(
          "h-5 w-5 flex-shrink-0",
          focusedInput ? "text-ciety-lg" : "text-ciety-wh",
        )}
      />
      <input
        type="text"
        ref={inputRef}
        className="typo-m2 h-[2.8125rem] w-full truncate border-b border-transparent bg-transparent text-ciety-wh focus:border-ciety-lg"
        onChange={(event) => {
          setSectionProfileSns({
            nextSns: {
              id,
              type: CheckSnsType(event.target.value),
              url: event.target.value,
            },
          });
        }}
        onKeyDown={handleKeyDown}
        onFocus={handleFocusToggle(true)}
        onBlur={handleFocusToggle(false)}
        value={url}
      />
      <button
        type="button"
        onClick={handleRemoveSnsItem(id)}
        className="flex-shrink-0"
      >
        <Icon iconType="Cancel Close" className="h-5 w-5 text-ciety-dk-60" />
      </button>
      <button type="button" className="sortable-handle flex-shrink-0">
        <Icon iconType="Move list" className="h-5 w-5 text-ciety-dk-60" />
      </button>
    </div>
  );
}

const regex = new RegExp(
  PROTOCOL +
    "(" +
    Object.entries(SNS_LIST)
      .filter(([type]) => type !== ESnsType.Other)
      .map(([, { regexp }]) => regexp)
      .join("|") +
    ")",
  "g",
);

function CheckSnsType(url: string) {
  const [matched] = url.match(regex) ?? [];

  for (const [type, { regexp }] of Object.entries(SNS_LIST)) {
    if (type === ESnsType.Other) continue;

    const regexList = regexp.split("|");
    for (const _regexp of regexList) {
      if (matched?.includes(_regexp)) {
        return type as unknown as ESnsType;
      }
    }
  }

  return ESnsType.Other;
}

export { SnsItem };
