import { ChainEnum, ErrorTypes, ResultType } from "../../types";

export const createChainVo = (params: {
  chainId: number;
  supportedChainEnums: Set<ChainEnum>;
}): ResultType<{ chain: ChainEnum }, ErrorTypes> => {
  let chainEnum: ChainEnum | null = null;
  if (params.chainId === 1) {
    chainEnum = ChainEnum.EthereumMainnet;
  } else if (params.chainId === 5) {
    chainEnum = ChainEnum.Goerli;
  } else if (params.chainId === 137) {
    chainEnum = ChainEnum.PolygonMainnet;
  } else if (params.chainId === 80001) {
    chainEnum = ChainEnum.Mumbai;
  }

  if (chainEnum == null) {
    return {
      ok: false,
      value: ErrorTypes.NotSupportedChainError,
    };
  }

  if (!params.supportedChainEnums.has(chainEnum)) {
    return {
      ok: false,
      value: ErrorTypes.NotSupportedChainError,
    };
  }

  return { ok: true, value: { chain: chainEnum } };
};
