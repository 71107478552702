// @ref src/styles/global.css 파일내 media query 참고

const GLOBAL_CSS = {
  MAX_WIDTH_1440: 1.1111,
  MAX_WIDTH_1360: 1.1765,
};

export const getRemSize = () => {
  const windowWidth = window.innerWidth;

  let remSize;
  if (windowWidth > 1440 || windowWidth < 560) {
    remSize = 16;
  } else if (windowWidth > 1360) {
    remSize = (windowWidth / 100) * GLOBAL_CSS.MAX_WIDTH_1440;
  } else {
    remSize = (windowWidth / 100) * GLOBAL_CSS.MAX_WIDTH_1360;
  }

  return remSize;
};

export const remToPx = (rem: number) => rem * getRemSize();
export const pxToRem = (px: number) => px / getRemSize();

export const getElRemBottomMarginInModal = (
  el: HTMLElement,
  centerModalHeightInRem: number,
) => {
  const modalBottomPosition =
    (window.innerHeight + centerModalHeightInRem * getRemSize()) / 2;
  return (modalBottomPosition - el.getBoundingClientRect().top) / getRemSize();
};

export const isCssUnit = (unit: string) => (target: string) =>
  new RegExp(`${unit}$`).test(target.replace(/\s/g, ""));
export const isRemStr = (remStr: string) => isCssUnit("rem")(remStr);
export const isPxStr = (remStr: string) => isCssUnit("px")(remStr);
export const removeCssUnit = (cssStr: string) => /\d+/.exec(cssStr)?.[1] || "";

export const remStrToPx = (remStr: string) => {
  if (!isRemStr(remStr)) throw new Error("error: convert not rem to px");
  return +remStr.replace("rem", "") * getRemSize();
};

export const getInputDefaultStyle = (params: {
  error?: boolean;
  type?: "solid" | "line";
}) =>
  params.type === "line"
    ? `bg-transparent placeholder:text-ciety-dk-60 ${
        params.error
          ? "text-ciety-rd border-b border-ciety-rd caret-ciety-rd"
          : "text-ciety-wh border-b border-ciety-wh focus:border-b focus:border-ciety-lg focus:text-ciety-lg caret-ciety-lg"
      }`
    : `bg-ciety-dk-95 text-ciety-wh placeholder:text-ciety-dk-60 ${
        params.error
          ? "border border-ciety-rd caret-ciety-rd"
          : "border border-ciety-dk-95 hover:border hover:border-ciety-dk-60 focus:border focus:border-ciety-lg caret-ciety-lg"
      }`;
