import { ENameSpace } from "~/i18n/@types/types";
import { useTranslation } from "~/hooks/useTranslation";
import { useEffect } from "react";
import { cietyAlert } from "~/_utils/overlay/cietyAlert";
import { disconnect } from "~/modules/Router/Auth/utils/auth/disconnect";

function Unauthorized() {
  const { t } = useTranslation([ENameSpace.Common]);

  // 로그아웃 처리
  useEffect(() => {
    (async () => {
      await cietyAlert({
        body: t(`${ENameSpace.Common}:Error.401.alert`),
      });

      const { ok } = await disconnect();

      if (ok) {
        location.href = "/login";
        return;
      }

      window.location.reload();
    })();
  }, [t]);

  return null;
}

export { Unauthorized };
