import { MoreButton } from "~/_app/[dao]/home/components/common/MoreButton";
import { MobileSectionContainer } from "~/_app/[dao]/home/components/mobile/MobileSectionContainer";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";

import { Swiper, SwiperSlide } from "swiper/react";
import { remToPx } from "~/_utils/converter/remConverter";
import { getTokenDisplayNameWithoutShop } from "~/_utils/market/conversions";
import { toTokenDisplayPrice } from "~/_utils/converter/toTokenDisplayPrice";
import { ellipseTokenId } from "~/_utils/converter/ellipsisTokenId";
import { SectionHeader } from "~/_app/[dao]/home/components/common/SectionHeader";
import { EmptyMarketplace } from "~/_app/[dao]/home/components/mobile/Marketplace/EmptyMarketplace";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { getMarketplaceStackScreenParams } from "~/_api/mobile/appScreenParams";
import { useDaoSubdomainStore } from "~/_store/dao/useDaoSubdomainStore";
import { appNavigation } from "~/_api/mobile/toMobileNavigation";
import { EChain } from "~/_enums/EChain";
import { ResizedImage } from "~/_components/Image/OptimalImage/ResizedImage";
import { assertIsDefined } from "~/_utils/assert";
import { DAO_HOME } from "~/_constants";
import { useState } from "react";

type TProps = {
  sectionId: string;
};

export function Marketplace({ sectionId }: TProps) {
  const [isLoading, setIsLoading] = useState(true);
  const { getSection } = useDaoHomeStore();
  const { daoSubdomain } = useDaoSubdomainStore();
  assertIsDefined(daoSubdomain);
  const { title, items } = getSection(EDaoHomeSection.Marketplace, sectionId);

  const isEmptyItem = items.length === 0;

  const handleMoveMarketplace = () => {
    appNavigation(({ push }) => {
      push(
        getMarketplaceStackScreenParams({
          path: generateUrl({
            type: "market",
            subdomain: daoSubdomain,
          }),
        }),
      );
    });
  };

  const handleMoveMarketplaceItem = (props: {
    chain: EChain;
    contractAddress: string;
    tokenId: string;
  }) => {
    appNavigation(({ push }) => {
      push(
        getMarketplaceStackScreenParams({
          path: generateUrl({
            type: "marketItem",
            subdomain: daoSubdomain,
            chain: props.chain,
            contractAddress: props.contractAddress,
            tokenId: props.tokenId,
          }),
        }),
      );
    });
  };

  return (
    <MobileSectionContainer className="home-marketplace py-6">
      <div className="px-5">
        <SectionHeader
          title={title}
          typoClass="typo-headline-m-20"
          className="w-[calc(100%-4.5rem)] truncate"
        >
          <MoreButton onClick={handleMoveMarketplace} />
        </SectionHeader>
      </div>

      <div className="mt-4">
        {isEmptyItem ? (
          <div className="px-5">
            <EmptyMarketplace />
          </div>
        ) : (
          <Swiper
            slidesPerView={3}
            slidesPerGroupAuto={true}
            spaceBetween={remToPx(0.75)}
            slidesOffsetBefore={remToPx(1.25)}
            slidesOffsetAfter={remToPx(1.25)}
            onInit={() => setIsLoading(false)}
          >
            {items
              .slice(0, DAO_HOME.CONTENT_COUNT.MOBILE_MARKETPLACE)
              .map((item) => {
                return (
                  <button
                    type="button"
                    key={item.nft.id}
                    className="block w-[8.75rem]"
                    onClick={() =>
                      handleMoveMarketplaceItem({
                        chain: item.nft.chain,
                        contractAddress: item.nft.contractAddress,
                        tokenId: item.nft.tokenId,
                      })
                    }
                  >
                    <SwiperSlide
                      key={item.nft.id}
                      style={{ opacity: isLoading ? "0" : "1" }}
                    >
                      <div className="isolate flex aspect-[1/1] overflow-hidden rounded-lg">
                        <ResizedImage
                          toResizeUrlParams={{
                            url: item.nft.imageUrl,
                            width: remToPx(8.75),
                            qualityMultiplier: 2,
                          }}
                          className="h-full w-full object-cover"
                        />
                      </div>

                      <div className="mt-3 flex flex-col">
                        <div className="flex flex-col">
                          <span className="typo-r3 truncate text-ciety-gy">
                            {getTokenDisplayNameWithoutShop({
                              tokenName: item.nft.name ?? "Item",
                            })}
                          </span>
                          <span className="typo-m2 truncate text-ciety-wh">
                            {`#${ellipseTokenId(item.nft.tokenId)}`}
                          </span>
                        </div>

                        {item.floorPrice &&
                          item.floorPrice.decimals &&
                          Number.isInteger(item.floorPrice.decimals) && (
                            <span className="typo-m2 mt-[0.25rem] truncate text-ciety-lg">
                              {(() => {
                                const { amount, decimals, symbol } =
                                  item.floorPrice;

                                return `${toTokenDisplayPrice({
                                  token: {
                                    amount,
                                    decimals,
                                  },
                                })} ${symbol}`;
                              })()}
                            </span>
                          )}
                      </div>
                    </SwiperSlide>
                  </button>
                );
              })}
          </Swiper>
        )}
      </div>
    </MobileSectionContainer>
  );
}
