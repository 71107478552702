import { atom } from "jotai";

import { NEED_HYDRATION } from "~/_constants";
import { EAtomName } from "~/_enums/EAtomName";
import { TDaoHome, TDaoHomeSection } from "~/_types/daoHome/TDaoHome";
import { getHydrationErrorMessage } from "~/_utils/messageGetter/getHydrationErrorMessage";

/**
 * 초기 설정 값으로 리셋이 필요한 경우를 위해 아래처럼 작성
 *
 * data: TDaoHome -> 초기에 전달 받은 데이터를 저장 (변경 X)
 * copiedData: TDaoHome -> 초기에 전달 받은 데이터 + 설정 변경시 업데이트 될 데이터 (변경 O)
 * */

const daoHomeAtom = atom<
  { data: TDaoHome; copiedData: TDaoHome } | typeof NEED_HYDRATION
>(NEED_HYDRATION);

// 초기 상태로 리셋
const writeResetDataAtom = atom(null, (get, set) => {
  const daoHome = get(daoHomeAtom);

  if (daoHome === NEED_HYDRATION) {
    throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
  }

  set(daoHomeAtom, {
    data: daoHome.data,
    copiedData: daoHome.data,
  });
});

const readOriginalDaoHome = atom((get) => {
  const daoHome = get(daoHomeAtom);

  if (daoHome === NEED_HYDRATION) {
    throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
  }

  return daoHome.data;
});

const readWriteDaoHome = atom(
  (get) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    return daoHome.copiedData;
  },
  (get, set, payload: { sections: TDaoHomeSection[] }) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(daoHomeAtom, {
      ...daoHome,
      copiedData: {
        ...daoHome.copiedData,
        sections: payload.sections,
      },
    });
  },
);

const readSectionsAtom = atom((get) => {
  const daoHome = get(daoHomeAtom);

  if (daoHome === NEED_HYDRATION) {
    throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
  }

  return daoHome.copiedData.sections;
});

const writeSectionAtom = atom(
  null,
  (get, set, payload: { section: TDaoHomeSection }) => {
    const daoHome = get(daoHomeAtom);

    if (daoHome === NEED_HYDRATION) {
      throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
    }

    set(daoHomeAtom, {
      ...daoHome,
      copiedData: {
        ...daoHome.copiedData,
        sections: daoHome.copiedData.sections.map((section) =>
          section.id === payload.section.id ? payload.section : section,
        ),
      },
    });
  },
);

const readDeviceAtom = atom((get) => {
  const daoHome = get(daoHomeAtom);

  if (daoHome === NEED_HYDRATION) {
    throw new Error(getHydrationErrorMessage(EAtomName.DaoHome));
  }

  return daoHome.copiedData.device;
});

export {
  // 다오 홈
  daoHomeAtom,
  readWriteDaoHome,
  readOriginalDaoHome,
  //
  readDeviceAtom,
  //
  writeResetDataAtom,
  // 섹션
  readSectionsAtom,
  writeSectionAtom,
};
