import { simplifyAddress } from "~/utils/calibrator";
import { Icon } from "../../../../Common/Icons";
import { addressLinkEnum } from "../../constant";
import type { ContractCondition as TContractCondition } from "../../types";
import { ConditionContainer } from "./ConditionContainer";

type Props = {
  contractCondition: TContractCondition;
};

export const Contract = ({ contractCondition }: Props) => (
  <ConditionContainer
    status={contractCondition.status}
    type="Digital collection"
  >
    <span className="typo-m2 w-[8.125rem] truncate text-ciety-sk">
      {contractCondition.amount}
      {" or more"}
    </span>
    <div className="w-[10rem]">
      <a
        href={`${addressLinkEnum[contractCondition.contract.chain]}${
          contractCondition.contract.contractAddress
        }`}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center gap-x-2"
      >
        <span className="typo-r2 block truncate text-ciety-wh underline">
          {contractCondition.contract.contractName ??
            simplifyAddress({
              address: contractCondition.contract.contractAddress,
              crop: 5,
            })}
        </span>
        <Icon
          iconType="New Window"
          className="h-4 w-4 shrink-0 text-ciety-gy"
        />
      </a>
    </div>
  </ConditionContainer>
);
