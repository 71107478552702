export const ELanguage = {
  En: "en",
  Ko: "ko",
} as const;

export type ELanguage = typeof ELanguage[keyof typeof ELanguage];

export const ENameSpace = {
  Channels: "channels",
  Common: "common",
  Home: "home",
  CommunitySettings: "communitysettings",
  Create: "create",
  Dm: "dm",
  Find: "find",
  Notifications: "notifications",
  Invite: "invite",
  Login: "login",
  Market: "market",
  Merch: "merch",
  Search: "search",
  Space: "space",
  Settings: "settings",
  Oauth: "oauth",
  GoogleConnect: "googleconnect",
  Entrance: "entrance",
  Explore: "explore",
} as const;

export type ENameSpace = typeof ENameSpace[keyof typeof ENameSpace];
