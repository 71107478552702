export const ERolePolicyCode = {
  Admin: "admin",
  UpdateDaoInfo: "update_dao_info",
  UpdateDaoTokenGating: "update_dao_token_gating",
  ManageRole: "manage_role",
  ManageMemberOnRole: "manage_member_on_role",
  KickMember: "kick_member",
  CreateAndUpdateChannel: "create_and_update_channel",
  DeleteAndRecoverChannel: "delete_and_recover_channel",
  GroupAndSortChannel: "group_and_sort_channel",
  DeletePostAndComment: "delete_post_and_comment",
  ManageGiftok: "manage_giftok",
} as const;

export type ERolePolicyCode =
  typeof ERolePolicyCode[keyof typeof ERolePolicyCode];
