import { LexicalEditor } from "lexical";

type State = {
  postEditor: LexicalEditor | null;
  commentEditor: LexicalEditor | null;
};

type Actions =
  | { type: "SET_POST_EDITOR"; payload: LexicalEditor }
  | { type: "REMOVE_POST_EDITOR" }
  | { type: "SET_COMMENT_EDITOR"; payload: LexicalEditor }
  | { type: "REMOVE_COMMENT_EDITOR" };

const initialState = {
  postEditor: null,
  commentEditor: null,
};

function reducer(state: State, action: Actions) {
  switch (action.type) {
    case "SET_POST_EDITOR":
      return {
        ...state,
        postEditor: action.payload,
      };
    case "SET_COMMENT_EDITOR":
      return {
        ...state,
        commentEditor: action.payload,
      };
    case "REMOVE_POST_EDITOR":
      return {
        ...state,
        postEditor: null,
      };
    case "REMOVE_COMMENT_EDITOR":
      return {
        ...state,
        commentEditor: null,
      };
    default:
      return state;
  }
}

export { initialState };
export type { State, Actions };
export default reducer;
