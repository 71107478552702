import { ReactNode } from "react";

type TProps = {
  className: string;
  children: ReactNode | ReactNode[];
};

function SnsList({ className, children }: TProps) {
  return (
    <ul
      className={`home-profile__sns-list flex items-center gap-x-3 ${className}`}
    >
      {children}
    </ul>
  );
}

export { SnsList };
