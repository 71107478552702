import { BaseProvider } from "@ethersproject/providers";
import { defaultAbiCoder } from "ethers/lib/utils";

import { MarketSupportedChain } from "~/api/dto";
import {
  decodeUint8From32Bytes,
  decodeUint256From32Bytes,
} from "~/modules/Market/utils/decode";
import { getPublicProvider } from "~/modules/Market/utils/provider";

export const getErc20BalanceToRPC = ({
  chain,
  contractAddress,
  ownerAddress,
}: {
  chain: MarketSupportedChain;
  contractAddress: string;
  ownerAddress: string;
}) => {
  const provider = getPublicProvider({ chain });

  return Promise.all([
    callBalance({ provider, contractAddress, ownerAddress }),
    callDecimal({ provider, contractAddress }),
  ]).then(([balance, decimal]) => {
    return {
      chain,
      contractAddress: contractAddress,
      ownerAddress,
      balance,
      decimal,
    };
  });
};

export const callBalance = ({
  provider,
  contractAddress,
  ownerAddress,
}: {
  provider: BaseProvider;
  contractAddress: string;
  ownerAddress: string;
}) => {
  const sig = "0x70a08231"; // balanceOf(address)
  const paramEncoded = defaultAbiCoder.encode(["address"], [ownerAddress]);
  const data = sig + paramEncoded.substring(2);

  return provider
    .call({
      to: contractAddress,
      data,
    })
    .then((balance) => decodeUint256From32Bytes(balance).toString());
};

export const callDecimal = ({
  provider,
  contractAddress,
}: {
  provider: BaseProvider;
  contractAddress: string;
}) => {
  const sig = "0x313ce567";
  return provider
    .call({
      to: contractAddress,
      data: sig,
    })
    .then((decimal) => decodeUint8From32Bytes(decimal));
};
