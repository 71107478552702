import { useDaoHomeStore } from "~/_store/dao/useDaoHomeStore";
import { useTranslation } from "~/hooks/useTranslation";
import { ENameSpace } from "~/i18n/@types/types";
import { ViewCount } from "~/_app/[dao]/home/components/common/ViewCount";
import { NicknameWithRoleColor } from "~/_app/[dao]/home/components/common/NicknameWithRoleColor";
import { EDaoHomeSection } from "~/_enums/daoHome/EDaoHomeSection";
import { useRelativeTimeFormat } from "~/_hooks/time/useRelativeTimeFormat";
import { commonTimeDiffOptions } from "~/_utils/converter/timeConverter";
import { MobileSectionContainer } from "~/_app/[dao]/home/components/mobile/MobileSectionContainer";
import { generateUrl } from "~/_utils/converter/generateUrl";
import { useDaoSubdomainStore } from "~/_store/dao/useDaoSubdomainStore";
import { getThreadScreenParams } from "~/_api/mobile/appScreenParams";
import { appNavigation } from "~/_api/mobile/toMobileNavigation";
import { assertIsDefined } from "~/_utils/assert";

type TProps = {
  sectionId: string;
};

function Live({ sectionId }: TProps) {
  const { daoSubdomain } = useDaoSubdomainStore();
  assertIsDefined(daoSubdomain);
  const { getSection } = useDaoHomeStore();
  const live = getSection(EDaoHomeSection.LiveStreaming, sectionId);
  const { formatRelativeTimeWithTranslation } = useRelativeTimeFormat();
  const { t } = useTranslation([ENameSpace.Home]);

  const handleMoveThread = () => {
    appNavigation(({ push }) => {
      assertIsDefined(live.post);

      push(
        getThreadScreenParams({
          path: generateUrl({
            type: "threadMobile",
            subdomain: daoSubdomain,
            channelId: live.channel.id,
            postId: live.post.id,
            channelType: live.channel.type,
          }),
        }),
      );
    });
  };

  if (live.post == null) {
    return null;
  }

  return (
    <MobileSectionContainer className="home-live px-5 py-6">
      <h1 className="typo-headline-m-20 mb-4 w-full truncate text-ciety-wh">
        <span
          className={
            live.post.author.roles.at(0)?.displayColor
              ? `text-[#${live.post.author.roles[0].displayColor}]`
              : `text-ciety-gy`
          }
        >
          {live.post.author.nickname}
        </span>
        {t(`${ENameSpace.Home}:Root.Sections.Live.Title`)}
      </h1>
      <div className="flex-center aspect-video overflow-hidden rounded-xl border border-ciety-bk text-ciety-dk">
        <img
          className="f-ull w-full object-cover"
          src={live.post.vodUrl}
          alt="thumbnail"
        />
      </div>
      <div className="mt-3 w-full">
        <button type="button" onClick={handleMoveThread}>
          <h2 className="typo-r1 w-full truncate text-ciety-wh">
            {live.post.title}
          </h2>
        </button>
        <div className="flex gap-x-[0.375rem]">
          <div className="typo-r2 mt-2 flex gap-x-2">
            <NicknameWithRoleColor
              nickname={live.post.author.nickname}
              roleColor={live.post.author.roles[0]?.displayColor}
            />
            <span>
              {formatRelativeTimeWithTranslation(
                new Date(),
                new Date(live.post.createdAt),
                commonTimeDiffOptions.basic,
              )}
            </span>
            <ViewCount count={live.post.viewCount} color="text-ciety-lg" />
          </div>
        </div>
      </div>
    </MobileSectionContainer>
  );
}

export { Live };
